<template>
  <div id="add-to-cover-form-step-1">
    <LoadingMask v-if="fetchingPrice" />
    <FormulateForm @submit="onSubmit" v-if="insurancePolicy">
      <sc-rich-text :field="fields.stepContent" />

      <!-- Start Renew Option -->
      <div class="py-3">
        <h4 style="font-size: 28px">
          <sc-text :field="fields[titleFieldName]" />
        </h4>
        <p style="font-size: 16px">
          <sc-rich-text :field="fields[subTitleFieldName]" />
        </p>
      </div>

      <div class="border-racq px-3 py-2" v-if="showRenewal">
        <div class="w-100 row my-0 py-3">
          <div class="col-12 col-md-7 pt-1">
            <h4 class="text-bold font-arial mb-3" style="font-size: 22px">
              <sc-text :field="fields.renewTitle" />
            </h4>
            <p style="font-size: 16px">
              <sc-rich-text :field="fields.renewSubtitle" />
            </p>
          </div>
          <div class="text-right col-12 col-md-5" v-if="insurancePolicy.renewalTermPaymentDetails">
            <div class="d-flex">
              <h6 class="mr-2 ml-auto mb-0 mt-2 font-arial" style="font-size: 14px; font-weight: 600; max-width: 120px" v-if="!showAsAdditional && notPaid">
                <sc-text :field="fields.stepAnualPremium" v-if="insurancePolicy.renewalTermPaymentDetails.isAnnual != false" />
                <sc-text :field="fields.stepMonthlyPremium" v-else />
              </h6>
              <h6 class="mr-2 ml-auto mb-0 mt-2 font-arial" style="font-size: 14px; font-weight: 600; max-width: 120px" v-else>
                <sc-text :field="fields.stepAdditionalAnnualPremium" v-if="insurancePolicy.renewalTermPaymentDetails.isAnnual != false" />
                <sc-text :field="fields.stepAdditionalMonthlyPremium" v-else />
              </h6>
              <DollarAmount :amount="amountDue" />
            </div>
            <input type="button" class="w-100 btn-renew text-center btn btn-primary" @click="renewPolicy" :value="fields.renewButtonText.value" />
          </div>
        </div>
      </div>

      <div class="d-flex my-4" v-if="canAddBenefits && showRenewal">
        <div class="col-5 px-0">
          <hr />
        </div>
        <div class="col-2">
          <h4 class="text-center mb-0 mt-1" style="font-size: 20px">
            <b> OR </b>
          </h4>
        </div>
        <div class="col-5 px-0">
          <hr />
        </div>
      </div>
      <!-- End Renew Option -->

      <!-- Start Main Panel -->
      <div class="border-racq rounded px-4 py-4" v-if="canAddBenefits">
        <h4 class="text-bold font-arial" style="font-size: 22px">
          <sc-text :field="fields.optionalBenefitsTitle" />
        </h4>
        <p style="font-size: 16px">
          <sc-rich-text :field="fields.optionalBenefitsSubtitle" />
        </p>

        <!-- Start Optional Benefits -->
        <div class="mb-3" v-for="(item, index) in filteredCoverages" :key="index">
          <OptionalBenefits
            :index="index"
            :fields="fields"
            :coverage="item"
            @update-optional-benefits="updateOptionalBenefits(index, $event)"
            :ref="'optional-benefits-' + index"
          />
        </div>
        <!-- End Optional Benefits -->

        <div class="row">
          <div class="col-12 col-md-7 pl-0">
            <a class="pointer" @click="showVehicleNotListedDescription = !showVehicleNotListedDescription">
              <sc-text :field="fields.vehicleNotListed" />
            </a>
            <p v-show="showVehicleNotListedDescription">
              <sc-text :field="fields.vehicleNotListedDescription" />
            </p>
          </div>
          <div class="py-3 px-2 w-100 d-md-none"></div>
          <div class="d-flex col-12 col-md-5 px-0">
            <div class="w-100 text-right">
              <div class="d-flex" v-if="newTotalPremium">
                <h6 class="mr-2 mt-2 ml-auto font-arial" style="font-size: 14px; font-weight: 600; max-width: 120px" v-if="notPaid && !showAsAdditional">
                  <sc-text
                    :field="fields.stepAnualPremium"
                    v-if="!insurancePolicy.renewalTermPaymentDetails || insurancePolicy.renewalTermPaymentDetails.isAnnual != false"
                  />
                  <sc-text :field="fields.stepMonthlyPremium" v-else />
                </h6>
                <h6 class="mr-2 mt-2 ml-auto font-arial" style="font-size: 14px; font-weight: 600; max-width: 120px" v-else>
                  <sc-text
                    :field="fields.stepAdditionalAnnualPremium"
                    v-if="!insurancePolicy.renewalTermPaymentDetails || insurancePolicy.renewalTermPaymentDetails.isAnnual != false"
                  />
                  <sc-text :field="fields.stepAdditionalMonthlyPremium" v-else />
                </h6>
                <DollarAmount
                  :amount="newTotalPremium"
                  v-if="!insurancePolicy.renewalTermPaymentDetails || insurancePolicy.renewalTermPaymentDetails.isAnnual != false"
                />
                <DollarAmount :amount="newTotalPremium / 12" v-else />
              </div>
              <button :disabled="!canContinue || fetchingPrice" type="submit" class="btn btn-primary w-100 btn-continue text-center">
                {{ fields.nextButtonText.value }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="alert alert-secondary mt-4 d-flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#003478" viewBox="0 0 16 16">
          <path
            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
          />
        </svg>
        <div class="ml-2">
          <ScText :field="fields.allBenefitsAdded_Text" />
        </div>
      </div>
      <!-- End Main Panel -->

      <!-- Start Click to Chat -->
      <p class="mt-4">
        <sc-rich-text :field="fields.clickToChatSentence"></sc-rich-text>
      </p>
      <!-- End Click to Chat -->
      <div class="pt-1">
        <hr class="mt-4" />
      </div>
      <!-- Start Bottom Buttons -->
      <div class="form-elements-container border-top-0">
        <div class="form-buttons border-top-0">
          <FormulateInput
            @click="backToDod"
            type="button"
            :name="(fields.previousButtonText && fields.previousButtonText.value) || 'Back'"
            input-class="btn btn-secondary"
            outer-class=""
          />
        </div>
      </div>
      <!-- End Bottom Buttons -->
    </FormulateForm>
  </div>
</template>

<style>
.border-racq {
  border: 2px solid #003478;
}
.btn-renew,
.btn-continue {
  max-width: 120px;
}
@media (max-width: 767px) {
  .btn-renew,
  .btn-continue {
    max-width: 100%;
  }
}
.pointer {
  cursor: pointer;
}
</style>

<script>
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormsMixin from '../../../foundation/forms/mixins/RACQFormsMixins';
import OptionalBenefits from './shared/OptionalBenefits.vue';
import DollarAmount from '@/components/foundation/forms/DollarAmount.vue';
import LoadingMask from '@/components/foundation/forms/LoadingMask.vue';
import { mapState } from 'vuex';
import { jpCodes } from './shared/jpCodes';
import { Benefits } from './constants/benefits.const';
import { CMSFields, Unpaid, AddBenefits } from './constants/cms-fields.const';
import { apiIdentityService } from '../../../foundation/shared/apiservices/apiIdentityService';
import DataAnalytics from './constants/data-analytics.const';
import { CoverageCodes } from './constants/coverage-codes.const';

export default {
  name: 'Step1AddToCoverForm',
  components: {
    ScText: Text,
    ScRichText: RichText,
    OptionalBenefits,
    DollarAmount,
    LoadingMask,
  },
  computed: {
    ...mapState('renewalOffer', ['email', 'insurancePolicy', 'hasJeopardy']),
    ...mapState({
      identity: (state) => state.identityModule.identity,
      quoteSessionId: (state) => state.addToCoverFormModule.quoteSessionId,
      newTotalPremium: (state) => state.addToCoverFormModule.newTotalPremium,
      quotedPremium: (state) => state.addToCoverFormModule.quotedPremium,
    }),
    acceptedRenewal: function () {
      return this.insurancePolicy && this.insurancePolicy.futureRenewalPreference;
    },
    amountDue: function () {
      if (this.insurancePolicy && this.insurancePolicy.renewalTermPaymentDetails && this.insurancePolicy.renewalTermPaymentDetails.isAnnual == false)
        return this.insurancePolicy.renewalTermPaymentDetails.nextInvoiceAmountDue;
      return this.insurancePolicy.renewalTermPaymentDetails.totalAmountDue || this.insurancePolicy.renewalTermPaymentDetails.nextInvoiceAmountDue;
    },
    filteredCoverages: function () {
      if (this.insurancePolicy && this.insurancePolicy.coverages && this.insurancePolicy.coverages.length) {
        return this.insurancePolicy.coverages.filter((cover) => {
          if (cover.coverageCode != CoverageCodes.MC) return false;
          const windscreen = cover.benefits.some((benefit) => benefit.toLowerCase() == Benefits.WINDSCREEN);
          const hireCar = cover.benefits.some((benefit) => benefit.toLowerCase() == Benefits.FREE_HIRE_CAR);
          return !windscreen || !hireCar;
        });
      } else {
        return [];
      }
    },
  },
  mixins: [RACQFormsMixin],
  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    isDD: {
      type: Boolean,
      default: () => false,
    },
    isManualPaid: {
      type: Boolean,
      default: () => false,
    },
    showAsAdditional: {
      type: Boolean,
      default: () => false,
    },
    notPaid: {
      type: Boolean,
      default: () => false,
    },
    isDDOptedOut: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      canContinue: false,
      canAddBenefits: false,
      showRenewal: false,
      fetchingPrice: false,
      optionalBenefits: [],
      titleFieldName: '',
      subTitleFieldName: '',
      showVehicleNotListedDescription: false,
    };
  },
  mounted() {
    this.optionalBenefits = new Array(this.coverages).fill({
      hireCarChecked: false,
      windscreenChecked: false,
    });
  },
  watch: {
    identity: function (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.checkPolicyStatus(this.insurancePolicy, newVal);
      }
    },
    insurancePolicy: function (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.checkPolicyStatus(newVal, this.identity);
        const vm = this;
        setTimeout(() => {
          if (document.getElementById('ctc-link'))
            document.getElementById('ctc-link').addEventListener('click', function () {
              vm.$root.$emit('start-chat', jpCodes.genericError, 'Remove Optional Benefits', {});
              vm.buttonClicked('Click to chat', 'Link', 'Bottom');
            });
        }, 500);
      }
    },
  },
  /**
   * Clean up
   */
  unmounted() {
    if (document.getElementById('ctc-link')) document.getElementById('ctc-link').removeEventListener('click');
  },
  methods: {
    buttonClicked(name, type, region) {
      this.$emit('add-data-analytics', {
        step: 'Add Optional Benefits',
        name,
        type,
        region,
      });
    },
    sendMoveToReviewAnalysis: function () {
      const payload = JSON.parse(JSON.stringify(DataAnalytics.MoveToReview));
      payload.form.amount = this.quotedPremium;
      payload.form.details = this.filteredCoverages.map((cover) => ({
        makeName: cover.vehicle && cover.vehicle.make,
        modelName: cover.vehicle && cover.vehicle.model,
        vehicleYear: cover.vehicle && cover.vehicle.year,
      }));
      this.$root.$emit('add-data-analytics', payload);
    },
    /**
     * Check policy status
     * 1. can add optional benefits and no paid
     * 2. paid, can only add optional benefits
     * 3. not paid, but all covered
     * 4. paid, and all covered
     *
     * @param {InsurancePolicy} policy
     */
    checkPolicyStatus: function (policy, identity) {
      if (!policy || !identity) return;
      try {
        this.canAddBenefits = !!this.filteredCoverages.length;

        // show renewal if there is a valid prn or renewal term not accepted yet
        if (this.isDDOptedOut && !policy.renewalTermAcceptance) {
          this.showRenewal = true;
        } else if (!this.isDD && !this.isManualPaid) {
          this.showRenewal = true;
        } else {
          this.showRenewal = false;
        }

        // Nothing the user can do, return back to account overview
        if (!this.canAddBenefits && !this.showRenewal) {
          this.$root.$emit('cancel-form');
        }

        const fieldSuffix = `${this.showRenewal && !this.isDD ? Unpaid : ''}${this.canAddBenefits ? AddBenefits : ''}`;
        this.titleFieldName = CMSFields.TITLE + fieldSuffix;
        this.subTitleFieldName = CMSFields.SUBTITLE + fieldSuffix;
        if (
          (this.insurancePolicy.renewalTermAcceptance && this.isDDOptedOut) || // DD Opted out and accepted
          (!this.notPaid && !this.isDD)
        ) {
          // Paid manually
          this.subTitleFieldName += '_PaidManual';
        } else if (this.isDDOptedOut && !this.insurancePolicy.renewalTermAcceptance) {
          // for DD Opted Out and yet to accept
          this.subTitleFieldName += '_UnpaidDD';
          this.titleFieldName = CMSFields.TITLE + Unpaid;
        }
        // Case: All benefits covered and auto renew is on
        if (!this.isDDOptedOut && this.isDD && !this.canAddBenefits) {
          this.titleFieldName = CMSFields.TITLE;
          this.subTitleFieldName = CMSFields.TITLE;
        }

        this.$emit('step-one-loaded', {
          showRenewal: this.showRenewal,
          amountDue: this.amountDue,
        });
      } catch (error) {
        console.warn('Unable to check policy status:', error);
      }
    },
    /**
     * Go to renew policy journey
     */
    renewPolicy: async function () {
      this.buttonClicked(this.fields.renewButtonText.value, 'Button', 'top-right');
      // check if policy contains prn and is manual
      try {
        if (
          (this.insurancePolicy.currentTermPaymentDetails.paymentReferenceNumber &&
            this.insurancePolicy.currentTermPaymentDetails.totalAmountDue &&
            this.insurancePolicy.currentTermPaymentDetails.isPaymentDue == true) ||
          (this.insurancePolicy.renewalTermPaymentDetails.paymentReferenceNumber &&
            this.insurancePolicy.renewalTermPaymentDetails.totalAmountDue &&
            this.insurancePolicy.renewalTermPaymentDetails.isPaymentDue == true)
        ) {
          this.fetchingPrice = true;
          const url = await apiIdentityService.getSelfServiceMakePaymentUrl();
          location.replace(url.replace('{0}', this.insurancePolicy.currentTermPaymentDetails.paymentReferenceNumber));
        } else {
          this.$emit('renew-policy');
        }
      } catch {
        this.$emit('renew-policy');
      }
    },
    /**
     * Move to next step
     */
    onSubmit: function () {
      this.buttonClicked(this.fields.nextButtonText.value, 'Button', 'Bottom');
      this.sendMoveToReviewAnalysis();
      const vm = this;
      this.fetchingPrice = true;
      this.$store.dispatch('addToCoverFormModule/submitChanges', {
        payload: {
          policyNumber: this.insurancePolicy.policyNumber,
        },
        callback: (res, error) => {
          vm.fetchingPrice = false;
          if (error && !res) {
            let jpCode = jpCodes.genericError;
            if (error.jeopardies && error.jeopardies.length) {
              jpCode = error.jeopardies[0].code;
            }
            vm.$root.$emit('show-jeopardy', {
              displayCode: jpCode,
              jpCode: jpCode,
              stepDetails: vm.step.stepTitle,
            });
          } else {
            this.$emit('go-next');
          }
          vm.submitting = false;
        },
      });
    },
    /**
     * Move back to Dod
     */
    backToDod: function () {
      this.$root.$emit('show-disclaimer');
    },
    /**
     * Update Optional Benefits
     * @param {*} index
     */
    updateOptionalBenefits: function (index, evt) {
      const coverage = this.filteredCoverages[index];
      this.optionalBenefits[index] = evt;

      this.canContinue = this.optionalBenefits.some((item, i) => {
        const c = this.filteredCoverages[i];
        const windscreen = c.benefits.some((benefit) => benefit.toLowerCase() == Benefits.WINDSCREEN);
        const hireCar = c.benefits.some((benefit) => benefit.toLowerCase() == Benefits.FREE_HIRE_CAR);
        return (!windscreen && item.windscreenChecked) || (!hireCar && item.hireCarChecked);
      });

      this.fetchingPrice = true;
      const vm = this;

      this.$store.dispatch('addToCoverFormModule/updateCoveragePricing', {
        payload: {
          policyNumber: this.insurancePolicy.policyNumber,
          coverageId: coverage.id,
          hireCar: evt.hireCarChecked,
          windscreen: evt.windscreenChecked,
        },
        outstanding: !this.showAsAdditional ? this.amountDue : 0,
        partiallyPaid: this.showAsAdditional,
        paid: !this.notPaid,
        callback: function (res, error) {
          if (error) {
            vm.$root.$emit('show-jeopardy', {
              displayCode: jpCodes.genericError,
              jpCode: jpCodes.genericError,
              stepDetails: vm.step.stepTitle,
            });
          }
          if (res) {
            vm.fetchingPrice = false;
            const coverages = vm.optionalBenefits
              .map((item, index) => {
                const coverage = JSON.parse(JSON.stringify(vm.filteredCoverages[index]));
                coverage.includeHireCar = item.hireCarChecked;
                coverage.includeWindscreen = item.windscreenChecked;
                coverage.hireCarExisted = coverage.benefits && coverage.benefits.some((b) => b.toLowerCase() == Benefits.FREE_HIRE_CAR);
                coverage.windscreenExisted = coverage.benefits && coverage.benefits.some((b) => b.toLowerCase() == Benefits.WINDSCREEN);
                return coverage;
              })
              .filter((item) => item.includeHireCar || item.includeWindscreen);
            vm.$store.commit('addToCoverFormModule/updateLocalCoverages', { coverages });
          }
        },
      });
    },
  },
};
</script>
