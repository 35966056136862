var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormulateForm',{attrs:{"id":"find-your-car-form"},on:{"submit":_vm.submitFindCarForm},model:{value:(_vm.formValues),callback:function ($$v) {_vm.formValues=$$v},expression:"formValues"}},[_c('div',{staticClass:"step-content"},[_c('div',{staticClass:"d-flex"},[_c('FormulateInput',_vm._b({attrs:{"validation-rules":{ customRequired: function (ref) {
        var value = ref.value;

        return _vm.validateSelect(value, _vm.formValues['yearSelect']);
} },"validation-messages":{ customRequired: _vm.validationMessages.requiredRegoNumber },"validation":"customRequired","validation-name":"Registration number"},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('FormulateInput',{attrs:{"type":"button","name":"Find car","input-class":"btn btn-primary","outer-class":"find-your-car-btn"},on:{"click":_vm.getVehicleFromRego}})]},proxy:true}])},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.vehicleRegistration),false))],1),(_vm.regoLookupError)?_c('p',{staticClass:"text-error pb-2",domProps:{"innerHTML":_vm._s(_vm.formInputData(_vm.motorPolicyModel.regoLookupErrorMessage).label)}}):_vm._e(),_c('div',{staticClass:"separator"},[_c('h5',{staticClass:"text-italic racq-dark-blue no-margin px-12"},[_c('b',[_vm._v("OR")])])]),_c('article',{staticClass:"find-car-manually"},[_c('p',{staticClass:"text-italic racq-blue"},[_c('strong',[_vm._v(_vm._s(_vm.formInputData(_vm.motorPolicyModel.findCarManually).label))])]),_c('FormulateInput',_vm._b({attrs:{"validation":"customRequired","validation-messages":{ customRequired: _vm.validationMessages.requiredVehicleYear },"validation-rules":{
          customRequired: function (ref) {
                  var value = ref.value;

                  return _vm.formValues[_vm.motorPolicyModel.vehicleRegistration] !== '' || _vm.validateSelect(_vm.formValues['yearSelect']);
},
        },"options":_vm.vehicleYearsList},on:{"input":_vm.searchVehicleMakesByYear}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.yearSelect),false)),(_vm.formValues[_vm.motorPolicyModel.yearSelect] !== '1900')?_c('div',[_c('FormulateInput',_vm._b({attrs:{"disabled":_vm.formValues[_vm.motorPolicyModel.yearSelect] === '1900' || (!_vm.formValues[_vm.motorPolicyModel.yearSelect] && !_vm.vehicleMakesList.length),"validation":"customRequired","validation-messages":{ customRequired: _vm.validationMessages.requiredMake },"validation-rules":{
            customRequired: function (ref) {
                    var value = ref.value;

                    return _vm.validateSelect(value, !_vm.formValues['yearSelect'] || !_vm.vehicleMakesList.length);
},
          },"error-behaviour":"submit","options":_vm.vehicleMakesList},on:{"input":_vm.searchModelsByYearAndMake}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.makeSelect),false)),_c('FormulateInput',_vm._b({attrs:{"disabled":!_vm.formValues[_vm.motorPolicyModel.makeSelect] && !_vm.vehicleModelsList.length,"validation":"customRequired","error-behaviour":"submit","validation-messages":{ customRequired: _vm.validationMessages.requiredModel },"validation-rules":{
            customRequired: function (ref) {
                      var value = ref.value;

                      return _vm.validateSelect(value, _vm.formValues['yearSelect'] === '1900' || (!_vm.formValues['makeSelect'] && !_vm.vehicleModelsList.length));
},
          },"options":_vm.vehicleModelsList},on:{"input":_vm.searchVehicleVariants}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.modelSelect),false)),(!_vm.noVariantsFound)?_c('FormulateInput',_vm._b({attrs:{"disabled":!_vm.formValues[_vm.motorPolicyModel.modelSelect] && !_vm.vehicleVariantsList.length,"error-behaviour":"submit","validation":"customRequired","validation-messages":{ customRequired: _vm.validationMessages.requiredVehicleSeries },"validation-rules":{
            customRequired: function (ref) {
                    var value = ref.value;

                    return _vm.validateSelect(value, !_vm.formValues['modelSelect'] && !_vm.vehicleVariantsList.length);
},
          },"options":_vm.vehicleVariantsList},on:{"input":_vm.searchVehicleBodies}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.seriesSelect),false)):_vm._e(),(_vm.formValues[_vm.motorPolicyModel.seriesSelect] !== 'ALL' && !_vm.noVariantsFound && !_vm.noBodiesFound)?_c('FormulateInput',_vm._b({attrs:{"disabled":!_vm.formValues[_vm.motorPolicyModel.seriesSelect] && !_vm.vehicleBodiesList.length,"error-behaviour":"submit","validation-messages":{ customRequired: _vm.validationMessages.requiredVehicleBodyType },"validation":"customRequired","validation-rules":{
            customRequired: function (ref) {
                    var value = ref.value;

                    return _vm.validateSelect(value, !_vm.formValues[_vm.motorPolicyModel.seriesSelect] && !_vm.vehicleBodiesList.length);
},
          },"options":_vm.vehicleBodiesList},on:{"input":_vm.searchVehicleDetails}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.bodyTypeSelect),false)):_vm._e()],1):_vm._e()],1)]),(_vm.showVehicleDetailsList)?_c('div',[_c('hr'),_c('CustomRadioInput',{attrs:{"options":_vm.vehicleDetailsList,"default-selected":_vm.firstVehicleFromList,"id":"vehicle-selection-radio-group"},on:{"on-checked":_vm.selectVehicleFromList},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.formInputData(_vm.motorPolicyModel.carNotFoundMessage).label)}})]},proxy:true}],null,false,4263413591)}),(_vm.submissionError)?_c('p',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.validationMessages.requiredVehicle))]):_vm._e()],1):_vm._e(),_c('StepFooter',_vm._b({attrs:{"on-go-back":_vm.onGoBack}},'StepFooter',_vm.$props,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }