<template>
  <div class="product-tile">
    <div class="product-tile__image">
      <sc-image :media="productIcon"></sc-image>
    </div>
    <div class="product-tile__content">
      <h5>{{ productTitle }}</h5>
      <div v-html="productBody"></div>
    </div>
  </div>
</template>

<script>
import { Image } from '@sitecore-jss/sitecore-jss-vue';
export default {
  name: 'ProductTile',
  components: {
    ScImage: Image,
  },
  props: {
    productTitle: String,
    productBody: String,
    productIcon: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
.product-tile {
  display: flex;
  background-color: $racq-blue-background;
  font-size: 1rem;
  margin-bottom: 40px;
  gap: 25px;
  padding: 16px 30px 16px;

  img {
    width: 50;
    height: 50;
  }

  .product-tile__content {
    display: flex;
    flex-direction: column;
    margin-top: auto;

    h5 {
      color: $racq-dark-grey;
      font-weight: bold;
      margin-bottom: 0px;
    }
  }
}
</style>
