<template>
  <div class="pt-3">
    <FormulateForm @submit="onSubmit" v-model="step2Values">
      <LoadingMask v-if="submitting" style="margin-top: -1em" />
      <!-- Start Premium Title -->
      <div class="row" v-if="insurancePolicy && insurancePolicy.renewalTermPaymentDetails">
        <div class="col-12 col-md-7 px-0">
          <h4 style="font-size: 28px" v-if="!showAsAdditional && notPaid">
            <ScText :field="fields.PremiumTitle_Annual" v-if="showAnnual" />
            <ScText :field="fields.PremiumTitle_Monthly" v-else />
          </h4>
          <h4 v-else style="font-size: 28px">
            <ScText :field="fields.PremiumTitle_Additional" v-if="isManualPaid && !isDD" />
            <ScText :field="fields.PremiumTitle_AdditionalAnnual" v-else-if="showAnnual" />
            <ScText :field="fields.PremiumTitle_AdditionalMonthly" v-else />
          </h4>
          <DollarAmount :font-size="58" :amount="newTotalPremium" v-if="showAnnual" />
          <DollarAmount :font-size="58" :amount="newTotalPremium / 12" v-else />
        </div>
        <div class="col-md-5 px-0 py-2">
          <div class="upper-button-section w-100 ml-auto">
            <FormulateInput
              :disabled="!canSubmit"
              @click="
                submitButtonRegion = 'top-right';
                onSubmit();
              "
              type="button"
              :name="(fields.nextButtonText && fields.nextButtonText.value) || 'Submit'"
              input-class="btn btn-primary w-100 text-center"
              outer-class="mb-3"
            />
            <discard-modal :fields="fields" />
          </div>
        </div>
      </div>
      <div class="my-3">
        <ScRichText :field="fields.TitleDescription" v-if="!isDDOptedOutYetToAccepted" />
        <div v-else style="border-color: #grey !important" class="border renewal-title-box px-4 py-4 d-flex">
          <div class="bi-info-circle-fill mt-1 mr-2"></div>
          <ScRichText :field="fields.TitleDescription_RenewalOption" />
        </div>
      </div>
      <!-- End Premium Title -->

      <!-- Start Summary -->
      <summary-table v-if="coverages && coverages.length" :fields="fields" />
      <!-- End Summary -->

      <!-- Start Direct Debit -->
      <div v-if="isDDOptedOutYetToAccepted">
        <ScRichText :field="fields.Description_RenewalOption" />
        <FormulateInput
          name="dodAgreed"
          :validation="['required']"
          type="checkbox"
          class="mt-3 mb-0"
          input-class="mr-2"
          :validation-messages="{
            required: (fields.Checkbox_RenewalOption_ErrorMessage && fields.Checkbox_RenewalOption_ErrorMessage.value) || 'This field is required',
          }"
          :label="fields.Checkbox_RenewalOption.value"
        />

        <ScRichText :field="fields.CheckBoxDescription_RenewalOption" v-if="step2Values.dodAgreed" />
        <racq-formulate-input
          type="radio"
          v-if="step2Values.dodAgreed"
          :options="{ true: 'Yes', false: 'No' }"
          :label="fields.AutoRenew_CheckboxTitle"
          :validation="['required']"
          name="autoRenew"
        ></racq-formulate-input>
        <div class="alert alert-secondary" v-if="step2Values.autoRenew != null">
          <ScText :field="fields.AutoRenew_NoAlert" v-if="step2Values.autoRenew == 'false'" />
          <ScText :field="fields.AutoRenew_YesAlert" v-else-if="step2Values.autoRenew == 'true'" />
        </div>
      </div>
      <!-- End Direct Debit -->

      <div class="py-2">
        <hr />
      </div>

      <!-- Start Bottom Buttons -->
      <div class="form-elements-container border-top-0">
        <div class="form-buttons d-flex border-top-0 w-100">
          <FormulateInput
            @click="back"
            type="button"
            :name="(fields.previousButtonText && fields.previousButtonText.value) || 'Cancel'"
            input-class="btn btn-secondary"
            outer-class=""
          />

          <FormulateInput
            :disabled="!canSubmit"
            @click="
              submitButtonRegion = 'bottom-right';
              onSubmit();
            "
            type="button"
            :name="(fields.nextButtonText && fields.nextButtonText.value) || 'Done'"
            input-class="btn btn-primary text-center btn-width-180"
            outer-class="ml-auto"
          />
        </div>
      </div>
      <!-- End Bottom Buttons -->
    </FormulateForm>
  </div>
</template>

<style>
@media only screen and (min-width: 768px) {
  .upper-button-section {
    max-width: 180px;
  }

  .btn-width-180 {
    width: 180px;
  }
}
.renewal-title-box h5 {
  font-family: Arial !important;
  font-weight: bold !important;
}
</style>

<script>
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormsMixin from '../../../foundation/forms/mixins/RACQFormsMixins';
import RacqFormulateInput from '../../../foundation/forms/controls/racqFormulateInput.vue';
import DiscardModal from './shared/DiscardModal.vue';
import SummaryTable from './shared/SummaryTable.vue';
import LoadingMask from '@/components/foundation/forms/LoadingMask.vue';
import DollarAmount from '@/components/foundation/forms/DollarAmount.vue';
import { mapState } from 'vuex';
import { jpCodes } from './shared/jpCodes';
import DataAnalytics from './constants/data-analytics.const';

export default {
  name: 'Step2AddToCoverForm',
  components: {
    ScRichText: RichText,
    ScText: Text,
    DollarAmount,
    RacqFormulateInput,
    DiscardModal,
    LoadingMask,
    SummaryTable,
  },
  computed: {
    ...mapState('renewalOffer', ['email', 'insurancePolicy', 'hasJeopardy']),
    ...mapState({
      identity: (state) => state.identityModule.identity,
      quoteSessionId: (state) => state.addToCoverFormModule.quoteSessionId,
      newTotalPremium: (state) => state.addToCoverFormModule.newTotalPremium,
      coverages: (state) => state.addToCoverFormModule.localCoverages,
    }),
    showAnnual: function () {
      return (
        (this.insurancePolicy && this.insurancePolicy.renewalTermPaymentDetails && this.insurancePolicy.renewalTermPaymentDetails.isAnnual != false) ||
        !this.notPaid
      );
    },
    canSubmit: function () {
      return !this.isDDOptedOutYetToAccepted || (this.step2Values.dodAgreed && this.step2Values.autoRenew != undefined);
    },
    isDDOptedOutYetToAccepted: function () {
      return this.isDDOptedOut && (!this.insurancePolicy || !this.insurancePolicy.renewalTermAcceptance);
    },
  },
  mixins: [RACQFormsMixin],
  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    isDD: {
      type: Boolean,
      default: () => false,
    },
    isManualPaid: {
      type: Boolean,
      default: () => false,
    },
    showAsAdditional: {
      type: Boolean,
      default: () => false,
    },
    notPaid: {
      type: Boolean,
      default: () => false,
    },
    isDDOptedOut: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      submitting: false,
      submitButtonRegion: '',
      step2Values: {
        dodAgreed: undefined,
        autoRenew: undefined,
      },
    };
  },
  mounted() {},
  methods: {
    buttonClicked(name, type, region) {
      this.$emit('add-data-analytics', {
        step: 'Review',
        name,
        type,
        region,
      });
    },
    sendReviewNextAnalysis: function () {
      const payload = JSON.parse(JSON.stringify(DataAnalytics.ReviewStepOnNext));
      payload.form.amount = this.newTotalPremium;
      this.$root.$emit('add-data-analytics', payload);

      this.buttonClicked((this.fields.nextButtonText && this.fields.nextButtonText.value) || 'Submit', 'Button', this.submitButtonRegion);
    },
    /**
     * Move to next step
     */
    onSubmit: function () {
      this.sendReviewNextAnalysis();
      this.submitting = true;
      const vm = this;
      this.$store.dispatch('addToCoverFormModule/submitChanges', {
        payload: {
          acceptOffer: this.step2Values.dodAgreed,
          autoRenew: this.step2Values.autoRenew,
          policyNumber: this.insurancePolicy.policyNumber,
          bindChange: true, // Only bind change in this step
        },
        callback: (res, error) => {
          if (error && !res) {
            let jpCode = jpCodes.genericError;
            if (error.jeopardies && error.jeopardies.length) {
              jpCode = error.jeopardies[0].code;
            }
            vm.$root.$emit('show-jeopardy', {
              displayCode: jpCode,
              jpCode: jpCode,
              stepDetails: vm.step.stepTitle,
            });
          } else {
            this.$emit('go-next');
          }
          vm.submitting = false;
        },
      });
    },
    /**
     * Move back to Step 1
     */
    back: function () {
      this.$emit('go-back');
    },
  },
};
</script>
