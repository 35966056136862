<template>
  <div id="existing-primary-driver-details">
    <p class="font-bold" v-text="formInputData(motorPolicyModel.primaryDriverFormTitle).label" />

    <div class="form-field-wrapper">
      <p class="form-field-label" v-text="formInputData(motorPolicyModel.longTermClaims).label" />
      <p class="form-field-value" v-text="formattedLongTermClaims" />
    </div>

    <div class="form-field-wrapper">
      <p class="form-field-label" v-text="formInputData(motorPolicyModel.shortTermClaims).label" />
      <p class="form-field-value" v-text="formattedShortTermClaims" />
    </div>

    <div class="form-field-wrapper">
      <p class="form-field-label" v-text="formInputData(motorPolicyModel.yearsLicenced).label" />
      <p class="form-field-value">{{ selectedDriver.yearsLicenced }} years</p>
    </div>

    <div class="form-field-wrapper" v-if="isMotorComprehensive(prefillData.coverageCode)">
      <p class="form-field-label">{{ formInputData(motorPolicyModel.isRatingOne).label }}</p>
      <div class="form-field-value">
        <FormulateInput
          v-bind="formInputData(motorPolicyModel.isRatingOne)"
          label=""
          :options="[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No', id: `${motorPolicyModel.isRatingOne}_last-child` },
          ]"
          validation="required"
          :validation-messages="{
            required: validationMessages.defaultRequired,
          }"
        />
      </div>
    </div>

    <div class="form-field-wrapper" v-if="formValues.isRatingOne === 'true'">
      <p class="form-field-label">{{ formInputData(motorPolicyModel.yearsRatingOneHeld).label }}</p>
      <div class="form-field-value">
        <FormulateInput
          v-if="formValues.isRatingOne === 'true'"
          v-bind="formInputData(motorPolicyModel.yearsRatingOneHeld)"
          label=""
          :options="yearsRatingOneHeldOptions"
          validation="required"
          :validation-messages="{
            required: validationMessages.yearsRatingOneHeldRequired,
          }"
        />
      </div>

      <hr />
    </div>

    <p class="py-2" v-html="formInputData(motorPolicyModel.primaryDriverFormHelpText).label" />

    <FormulateInput
      v-bind="formInputData(motorPolicyModel.confirmPrimaryDriver)"
      validation="required"
      :validation-messages="{
        required: validationMessages.primaryDriverDetailsConfirm,
      }"
    />
  </div>
</template>

<script>
import { validationMessages, motorPolicyModel } from '@/components/feature/newmotorinsuranceform/services/mappings';
import { getYearsRatingOneHeld } from '@/components/foundation/shared/apiservices/apiRefdataService';
import { coverageTypeCodes } from '@/components/feature/newmotorinsuranceform/services/mappings';

export default {
  name: 'AddExistingPrimaryDriver',

  props: {
    selectedDriver: {
      type: Object,
      required: true,
    },
    formValues: {
      type: Object,
      required: true,
    },
    formInputData: {
      type: Function,
      required: true,
    },
    prefillData: {
      type: Object,
      required: true,
    },
  },

  async created() {
    const ratingOneRefData = await getYearsRatingOneHeld();
    // filter out value 0 = 'I don't know'
    this.yearsRatingOneHeldOptions = ratingOneRefData
      .filter((item) => item.value > 0)
      .map((data) => ({
        label: data.name,
        value: data.value,
      }));
  },

  data() {
    return {
      motorPolicyModel,
      validationMessages,
      yearsRatingOneHeldOptions: [],
    };
  },

  computed: {
    formattedLongTermClaims() {
      if (!this.selectedDriver?.longTermClaims) {
        return 'No claims';
      }

      return `${this.selectedDriver.longTermClaims} claims`;
    },

    formattedShortTermClaims() {
      if (!this.selectedDriver?.shortTermClaims) {
        return 'No claims';
      }

      return `${this.selectedDriver.shortTermClaims} claims`;
    },

    isMotorComprehensive() {
      return (coverageCode) => coverageCode === coverageTypeCodes.MotorComprehensive;
    },
  },
};
</script>

<style lang="scss">
#existing-primary-driver-details {
  background: $racq-pale-white;
  border: 1px solid $racq-pale-white;
  border-radius: 3px;
  padding: 1.5rem 1rem;

  .form-field-wrapper {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    border-bottom: 1px solid $racq-pale-blue-border;
    margin-bottom: 1rem;
  }

  .form-field-label {
    grid-area: 1 / 1 / 2 / 2;
  }
  .form-field-wrapper {
    grid-area: 1 / 2 / 2 / 3;
  }

  .form-field-label {
    width: 12rem;
    color: $racq-navy;
  }

  .form-field-value {
    .formulate-input-element--select {
      min-width: 270px !important;
      max-width: 270px !important;
    }

    .formulate-input-element--selectbox {
      min-width: 270px !important;
      max-width: 270px !important;
      margin-left: 85px !important;
    }

    .forms-input-wrapper {
      &[data-type='selectbox'] {
        margin-top: -1.5rem;

        .formulate-input-errors {
          max-width: 400px !important;
          margin-top: 0.5rem !important;
        }
      }

      .formulate-input-errors {
        margin-left: 30px !important;
      }
    }

    [data-type='radio'] {
      .formulate-input-element.formulate-input-element--group.formulate-input-group {
        margin-left: 0 !important;
      }
    }
  }

  .forms-input-wrapper {
    &[data-type='checkbox'] {
      margin-bottom: 0 !important;
    }
  }

  .formulate-input-element--checkbox label.formulate-input-element-decorator {
    display: none !important;
  }
}
</style>
