<template>
  <VTooltip :placement="tooltipPlacement" :flip="true" :triggers="['click']" :distance="tooltipDistance" :skidding="tooltipSkidding" :auto-hide="autoHide">
    <slot name="tooltip-trigger">
      <div class="d-flex items-center">
        <span v-if="contentBeforeTrigger" class="pr-2" v-text="contentBeforeTrigger" />
        <button :class="[triggerIcon]" :id="tooltipId" @click="onQuestionMarkClicked" type="button" name="tooltip-trigger" />
      </div>
    </slot>

    <template #popper="{ hide }">
      <slot name="tooltip-content">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. A, suscipit! Hic, explicabo? Exercitationem est deleniti cumque, temporibus delectus modi
        quidem placeat quo dignissimos maxime, quibusdam id distinctio tempore, eaque officiis!
      </slot>
      <button v-if="isSmallLaptopAndBelow" class="tooltip-close-button" @click="hide()" />
    </template>
  </VTooltip>
</template>

<script>
import Vue from 'vue';
import viewportMixin from '@/components/foundation/forms/mixins/viewportMixin';
import VTooltip from 'floating-vue';
import { hideAllPoppers } from 'floating-vue';
import { nanoid } from 'nanoid';

Vue.use(VTooltip, {
  themes: {
    tooltip: {
      $extend: 'racq-form-tooltip',
    },
  },
});

export default {
  name: 'RacqFormTooltip',

  mixins: [viewportMixin],

  props: {
    contentBeforeTrigger: {
      type: String,
      required: false,
      default: '',
    },
    triggerIcon: {
      type: String,
      default: 'questionmark-icon',
    },
    tooltipId: {
      type: String,
      required: false,
      default: nanoid(),
    },
    autoHide: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      tooltipTrigger: null,
      multipleTriggers: null,
      recalculateTooltipDistance: false,
    };
  },

  mounted() {
    setTimeout(() => {
      if (this.tooltipId) {
        this.tooltipTrigger = document?.querySelector?.(`#${this.tooltipId}`);
        this.multipleTriggers = document?.querySelectorAll?.(`#${this.tooltipId}`);
      }
    }, 300);
  },

  computed: {
    /**
     * By default the tooltip will be placed to the right of the form.
     * Once the user starts to decrease the viewport, the tooltip
     * will shift it's position accordinly to these controls.
     */
    tooltipPlacement() {
      if (this.isSmallLaptopAndBelow) {
        return 'bottom';
      }

      return 'right';
    },

    /**
     * Skidding is an offset value that positions the tooltip arrow
     * up or down the y axis. On large screens the arrow is offset
     * to be positioned closer to the top of the tooltip.
     */
    tooltipSkidding() {
      return 0; // this.isLargeScreen ? 24 : 0;
    },
    /**
     * Set the distance between the tooltip trigger and the tooltip.
     *
     * The UI design requirement is to place the tooltip outside
     * and to the right of the form itself.
     *
     * If the viewport is reduced then the tooltip adjusts to
     * sit to the left, above or underneath the trigger.
     */
    tooltipDistance() {
      this.recalculateTooltipDistance; //add this as reactive dependency so this computed property will recalculate when this value changes

      if (!this.tooltipTrigger) return;
      if (!this.isLargeScreen) return 10;

      // Search the DOM for a tooltip wrapper which is generally going to be the form container
      const wrapper = document.querySelector('[data-tooltip-wrapper]');

      if (!wrapper) {
        throw new Error('No data tooltip wrapper specified.');
      }

      if (!this.tooltipId) {
        throw new Error('No tooltipId specified.');
      }

      // Get the DOM dimensions of the tooltip and wrapper
      let tooltipRect = this.tooltipTrigger.getBoundingClientRect();
      let wrapperRect = wrapper.getBoundingClientRect();

      // Hack, sorry, but VueFormulate (VF) has it's limitations and isn't actively supported anymore.
      // The only reason this value exists is because VueFormulate adds on a suffix for every radio
      // input value. This isn't what we want. The suffix slot in VF is used to show the tooltip
      // button and it should be located at the end of the form input group, not at the end of
      // every individual input. To remedy this, we look for all trigger IDs and target only
      // the last ID, as we know that is the element the user clicks the trigger button.
      if (this.multipleTriggers.length > 1) {
        tooltipRect = this.multipleTriggers[this.multipleTriggers.length - 1].getBoundingClientRect();
      }

      // Provide an offset so that the tooltip sits just outside the wrapper element
      const wrapperOffset = 36;

      // Calculate the distance between the tooltip trigger and the wrapper edge, plus offset
      return wrapperRect.right - tooltipRect.right + wrapperOffset;
    },
  },

  methods: {
    onQuestionMarkClicked() {
      //check if the tooltip container is visible yet
      const wrapper = document.querySelector('[data-tooltip-wrapper]');
      let wrapperRect = wrapper?.getBoundingClientRect();
      if (wrapperRect?.width !== 0 && wrapperRect?.height !== 0) {
        this.recalculateTooltipDistance = true; //trigger repositioning of the tooltip
      }

      if (this.autoHide != false) return;

      // we're handling the show/hide manually
      const toolTipTriggerElements = document?.querySelectorAll?.(`#${this.tooltipId}`);

      if (toolTipTriggerElements?.length > 1) {
        const toolTipQuestionButtonTrigger = toolTipTriggerElements[toolTipTriggerElements.length - 1];
        if (toolTipQuestionButtonTrigger.parentElement.parentElement.classList.contains('v-popper--shown')) {
          return; //don't do anything perform default behaviour which is to hide the tooltip on subsequent triggers
        } else {
          hideAllPoppers(); //only show this tooltip, hide everything else
        }
      }
    },
  },
};
</script>

<style lang="scss">
.v-popper--theme-racq-form-tooltip .v-popper__wrapper {
  max-width: 24rem;
}

.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-container {
  top: -15px;
  bottom: unset;
}

.v-popper__popper[data-popper-placement^='right'] .v-popper__arrow-container {
  left: -15px;
  right: unset;
}

.v-popper--theme-tooltip.v-popper--theme-racq-form-tooltip {
  .v-popper__arrow-outer {
    border-color: $racq-blue-background;
    border-width: 1rem;
    left: -10px;
    top: -10px;
  }

  .v-popper__inner {
    background: $racq-blue-background;
    padding: 1.5rem 3rem 1.5rem 1.5rem;
    color: $racq-navy;
    font-size: 0.875rem;
    font-weight: 400;
    position: relative;

    ul {
      list-style: disc;
    }
  }

  .tooltip-close-button {
    background: transparent url('~@/assets/img/icons/close-icon.svg');
    background-repeat: no-repeat;
    width: 1.875rem;
    height: 1.875rem;
    border: none;
    padding: 0;
    position: fixed;
    top: 1rem;
    right: 1rem;

    &:hover {
      background: transparent url('~@/assets/img/icons/close-icon-hover.svg');
    }
  }
}

button[name='tooltip-trigger'] {
  outline: none !important;
}

.info-icon-transparent-blue,
.questionmark-icon {
  border: none;
  display: block;
  outline: none;
  width: 2rem;
  height: 2rem;
  align-self: center;
  background: transparent;
}

.questionmark-icon {
  background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20baseProfile%3D%22tiny%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%220%200%2030%2030%22%3E%3Cpath%20fill%3D%22%23003478%22%20d%3D%22M14.86%204.5C9.06%204.58%204.424%209.344%204.5%2015.14c.08%205.797%204.842%2010.436%2010.64%2010.36%205.798-.08%2010.437-4.843%2010.36-10.64C25.42%209.06%2020.657%204.423%2014.86%204.5zm1.187%2016h-2.283v-2.24h2.283v2.24zm2.35-7.473c-.122.3-.272.558-.452.77-.18.215-.378.396-.595.546-.22.15-.425.3-.62.45-.193.15-.365.323-.515.517-.15.194-.245.436-.284.727v.553h-1.96v-.655c.027-.417.108-.766.238-1.047.13-.28.284-.52.458-.72.175-.2.36-.37.553-.517.193-.144.373-.29.538-.435.164-.146.298-.305.4-.48.102-.175.148-.392.138-.655%200-.446-.11-.775-.328-.99-.218-.212-.52-.32-.91-.32-.26%200-.485.052-.675.154-.19.102-.344.238-.465.408-.122.17-.212.368-.27.596-.058.228-.086.473-.086.734h-2.14c.01-.524.1-1.004.27-1.44.17-.436.408-.814.713-1.134.305-.32.673-.57%201.105-.748.43-.18.914-.27%201.446-.27.69%200%201.263.095%201.723.284.46.19.83.424%201.112.705.282.28.483.583.604.908.122.325.182.628.182.91%200%20.466-.06.848-.182%201.15z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;

  &:focus,
  &:hover,
  &.active {
    background-image: url('data:image/svg+xml;base64,PHN2ZyBiYXNlUHJvZmlsZT0idGlueSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCI+PHBhdGggZmlsbD0iIzAwNmVmZiIgZD0iTTE0Ljg2IDQuNUM5LjA2IDQuNTggNC40MjQgOS4zNDQgNC41IDE1LjE0Yy4wOCA1Ljc5NyA0Ljg0MiAxMC40MzYgMTAuNjQgMTAuMzYgNS43OTgtLjA4IDEwLjQzNy00Ljg0MyAxMC4zNi0xMC42NEMyNS40MiA5LjA2IDIwLjY1NyA0LjQyMyAxNC44NiA0LjV6bTEuMTg3IDE2aC0yLjI4M3YtMi4yNGgyLjI4M3YyLjI0em0yLjM1LTcuNDczYy0uMTIyLjMtLjI3Mi41NTgtLjQ1Mi43Ny0uMTguMjE1LS4zNzguMzk2LS41OTUuNTQ2LS4yMi4xNS0uNDI1LjMtLjYyLjQ1LS4xOTMuMTUtLjM2NS4zMjMtLjUxNS41MTctLjE1LjE5NC0uMjQ1LjQzNi0uMjg0LjcyN3YuNTUzaC0xLjk2di0uNjU1Yy4wMjctLjQxNy4xMDgtLjc2Ni4yMzgtMS4wNDcuMTMtLjI4LjI4NC0uNTIuNDU4LS43Mi4xNzUtLjIuMzYtLjM3LjU1My0uNTE3LjE5My0uMTQ0LjM3My0uMjkuNTM4LS40MzUuMTY0LS4xNDYuMjk4LS4zMDUuNC0uNDguMTAyLS4xNzUuMTQ4LS4zOTIuMTM4LS42NTUgMC0uNDQ2LS4xMS0uNzc1LS4zMjgtLjk5LS4yMTgtLjIxMi0uNTItLjMyLS45MS0uMzItLjI2IDAtLjQ4NS4wNTItLjY3NS4xNTQtLjE5LjEwMi0uMzQ0LjIzOC0uNDY1LjQwOC0uMTIyLjE3LS4yMTIuMzY4LS4yNy41OTYtLjA1OC4yMjgtLjA4Ni40NzMtLjA4Ni43MzRoLTIuMTRjLjAxLS41MjQuMS0xLjAwNC4yNy0xLjQ0LjE3LS40MzYuNDA4LS44MTQuNzEzLTEuMTM0LjMwNS0uMzIuNjczLS41NyAxLjEwNS0uNzQ4LjQzLS4xOC45MTQtLjI3IDEuNDQ2LS4yNy42OSAwIDEuMjYzLjA5NSAxLjcyMy4yODQuNDYuMTkuODMuNDI0IDEuMTEyLjcwNS4yODIuMjguNDgzLjU4My42MDQuOTA4LjEyMi4zMjUuMTgyLjYyOC4xODIuOTEgMCAuNDY2LS4wNi44NDgtLjE4MiAxLjE1eiIvPjwvc3ZnPg==');
  }
}

.info-icon-transparent-blue {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48Y2xpcFBhdGggaWQ9ImIiPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiLz48L2NsaXBQYXRoPjwvZGVmcz48ZyBpZD0iYSIgY2xpcC1wYXRoPSJ1cmwoI2IpIj48ZyBmaWxsPSJub25lIj48cGF0aCBkPSJNMTIgMEExMiAxMiAwIDEgMSAwIDEyIDEyIDEyIDAgMCAxIDEyIDB6Ii8+PHBhdGggZD0iTTEyIDJDNi40ODYgMiAyIDYuNDg2IDIgMTJzNC40ODYgMTAgMTAgMTAgMTAtNC40ODYgMTAtMTBTMTcuNTE0IDIgMTIgMm0wLTJjNi42MjcgMCAxMiA1LjM3MyAxMiAxMnMtNS4zNzMgMTItMTIgMTJTMCAxOC42MjcgMCAxMiA1LjM3MyAwIDEyIDB6IiBmaWxsPSIjMDAzNDc4Ii8+PC9nPjxwYXRoIGQ9Ik0xLjA2NCAwdi05LjI1OGgyLjkyVjB6TS45NTctMTIuMjU2YTEuNTA4IDEuNTA4IDAgMCAxIC40NTQtMS4xMDggMS41MDggMS41MDggMCAwIDEgMS4xMS0uNDU0IDEuNTEyIDEuNTEyIDAgMCAxIDEuMTEyLjQ1IDEuNTEyIDEuNTEyIDAgMCAxIC40NSAxLjExMiAxLjUgMS41IDAgMCAxLS40NSAxLjEgMS41MTIgMS41MTIgMCAwIDEtMS4xMTIuNDU2IDEuNTA4IDEuNTA4IDAgMCAxLTEuMTA4LS40NTQgMS41IDEuNSAwIDAgMS0uNDU1LTEuMTAyeiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOS41NDMgMTkpIiBmaWxsPSIjMDAzNDc4Ii8+PC9nPjwvc3ZnPg==');
  background-repeat: no-repeat;
}

.v-popper--theme-racq-form-tooltip.v-popper--shown .questionmark-icon {
  background-image: url('data:image/svg+xml;base64,PHN2ZyBiYXNlUHJvZmlsZT0idGlueSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCI+PHBhdGggZmlsbD0iIzAwNmVmZiIgZD0iTTE0Ljg2IDQuNUM5LjA2IDQuNTggNC40MjQgOS4zNDQgNC41IDE1LjE0Yy4wOCA1Ljc5NyA0Ljg0MiAxMC40MzYgMTAuNjQgMTAuMzYgNS43OTgtLjA4IDEwLjQzNy00Ljg0MyAxMC4zNi0xMC42NEMyNS40MiA5LjA2IDIwLjY1NyA0LjQyMyAxNC44NiA0LjV6bTEuMTg3IDE2aC0yLjI4M3YtMi4yNGgyLjI4M3YyLjI0em0yLjM1LTcuNDczYy0uMTIyLjMtLjI3Mi41NTgtLjQ1Mi43Ny0uMTguMjE1LS4zNzguMzk2LS41OTUuNTQ2LS4yMi4xNS0uNDI1LjMtLjYyLjQ1LS4xOTMuMTUtLjM2NS4zMjMtLjUxNS41MTctLjE1LjE5NC0uMjQ1LjQzNi0uMjg0LjcyN3YuNTUzaC0xLjk2di0uNjU1Yy4wMjctLjQxNy4xMDgtLjc2Ni4yMzgtMS4wNDcuMTMtLjI4LjI4NC0uNTIuNDU4LS43Mi4xNzUtLjIuMzYtLjM3LjU1My0uNTE3LjE5My0uMTQ0LjM3My0uMjkuNTM4LS40MzUuMTY0LS4xNDYuMjk4LS4zMDUuNC0uNDguMTAyLS4xNzUuMTQ4LS4zOTIuMTM4LS42NTUgMC0uNDQ2LS4xMS0uNzc1LS4zMjgtLjk5LS4yMTgtLjIxMi0uNTItLjMyLS45MS0uMzItLjI2IDAtLjQ4NS4wNTItLjY3NS4xNTQtLjE5LjEwMi0uMzQ0LjIzOC0uNDY1LjQwOC0uMTIyLjE3LS4yMTIuMzY4LS4yNy41OTYtLjA1OC4yMjgtLjA4Ni40NzMtLjA4Ni43MzRoLTIuMTRjLjAxLS41MjQuMS0xLjAwNC4yNy0xLjQ0LjE3LS40MzYuNDA4LS44MTQuNzEzLTEuMTM0LjMwNS0uMzIuNjczLS41NyAxLjEwNS0uNzQ4LjQzLS4xOC45MTQtLjI3IDEuNDQ2LS4yNy42OSAwIDEuMjYzLjA5NSAxLjcyMy4yODQuNDYuMTkuODMuNDI0IDEuMTEyLjcwNS4yODIuMjguNDgzLjU4My42MDQuOTA4LjEyMi4zMjUuMTgyLjYyOC4xODIuOTEgMCAuNDY2LS4wNi44NDgtLjE4MiAxLjE1eiIvPjwvc3ZnPg==');
}
</style>
