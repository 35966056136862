<template>
  <div class="loading-mask">
    <div class="loader"><img src="./../../../assets/img/loader-34.gif" /></div>
  </div>
</template>

<style>
.loading-mask {
  z-index: 999;
  height: calc(100% - 16px);
  width: calc(100% - 60px);
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
}
.loading-mask .loader {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>

<script>
export default {
  name: 'LoadingMask',
};
</script>
