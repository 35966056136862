<template>
  <div :data-jp-code="activeJpCode">
    <div class="step-content">
      <p><span v-html="jeopardyMessage"></span></p>
      <sc-rich-text v-if="chatVisible" :field="fields.jeopardyContent" />
      <sc-rich-text v-if="!chatVisible" :field="fields.jeopardyNonBusinessHoursContent" />
    </div>
    <div class="form-elements-container">
      <div class="form-jeopardy-wrapper">
        <div class="chat-container" v-if="chatVisible">
          <h3><sc-text :field="fields.liveChatHeading" /></h3>
          <div class="chat-text-content">
            <sc-text :field="fields.liveChatContent" />
          </div>
          <div class="chat-button-holder">
            <button @click="startChat" class="btn btn-primary cta btn-arrow">
              <sc-text :field="fields.liveChatButtonText" />
            </button>
          </div>
          <div class="text-wrapper">
            <sc-text :field="fields.liveChatTimingText" />
          </div>
        </div>
        <div class="chat-container callback-container" v-if="callbackVisible">
          <h3><sc-text :field="fields.callBackHeading" /></h3>
          <div class="chat-text-content">
            <sc-text :field="fields.callBackContent" />
          </div>
          <div class="chat-button-holder">
            <button @click="goToCallBackForm" class="btn btn-primary cta btn-arrow">
              <sc-text :field="fields.callBackButtonText" />
            </button>
          </div>
          <div class="text-wrapper">
            <sc-text :field="fields.callBackChatResponseMessage" />
          </div>
        </div>

        <div class="phone-container pb-4" v-if="callUsVisible">
          <h3><sc-text :field="fields.callusHeading" /></h3>
          <div class="phone-text-content">
            <sc-text :field="fields.callusContent" />
          </div>
          <strong>
            <a :href="`tel:${fields.callUsNumber.value}`" class="phone-text"><sc-text :field="fields.callUsNumber" /></a>
          </strong>
        </div>
      </div>
      <div class="form-buttons">
        <FormulateInput
          type="button"
          name="Cancel"
          :value="jeopardyData.cancelBtnText"
          input-class="btn btn-secondary"
          v-show="!hideButtons"
          outer-class=""
          @click="onCancel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormsMixin from './mixins/RACQFormsMixins';
import { JP_LOGGED_OUT_CODE } from '../shared/utils/constants';
export default {
  name: 'JeopardyV2',
  components: {
    ScText: Text,
    ScRichText: RichText,
  },

  mixins: [RACQFormsMixin],

  props: {
    params: {
      type: Object,
    },

    stepData: {
      type: [Object, Array],
    },

    jeopardyData: {
      type: Object,
    },

    onCancel: {
      type: Function,
      required: true,
    },
  },

  mounted() {
    this.$root.$on('show-chat', (showChat) => {
      this.chatVisible = showChat;
    });
  },

  updated() {
    if (this.activeJpCode) {
      this.$store.commit('callBackFormModule/clearCallBackFormJeopardyData');
      this.$store.commit('callBackFormModule/addCallBackFormJeopardyData', this.getJeopardyDataForCallBack());
      this.$root.$emit('add-data-analytics', this.getAnalyticsModel());
    }
  },

  watch: {
    jeopardyActive: function(newValue, oldValue) {
      newValue && !oldValue && this.$root.$emit('initial-live-chat');
    }
  },

  data() {
    return {
      chatVisible: false,
      jeopardyDictionary: this.$i18n.i18next.store.data.en.translation, // Sitecore Jeopardy codes dictionary
      callbackVisible: this?.jeopardyData?.params?.callbackVisible,
      callUsVisible: this?.jeopardyData?.params?.callUsVisible, //by default we are not showing number to users to call us
    };
  },

  computed: {
    IsRenewalOfferForm() {
      return this.jeopardyData?.params?.formName === 'Renewal Offer Form';
    },

    activeJpCode() {
      return this.jeopardyData?.jpCodes?.[0]?.code;
    },

    jeopardyActive() {
      return this.jeopardyData?.jeopardyActive || false;
    },

    jpDescription() {
      return this.jeopardyDictionary[this.activeJpCode];
    },

    fields() {
      return this.jeopardyData?.fields;
    },

    step() {
      return {
        // Step data can come from either sitecore step data or Vuex store step data
        stepNo: this.stepData?.stepNumber?.value || this.stepData?.stepNo,
        stepTitle: this.stepData?.stepTitle?.value || this.stepData?.stepTitle,
        // TODO: Do we need these fields??
        stepState: 'active',
        stepControlName: 'stepjeopardy',
        stepChildControlName: 'jeopardy-child',
        hideFromNavigation: true,
      };
    },

    jeopardyMessage() {
      if (this.activeJpCode == JP_LOGGED_OUT_CODE) {
        return this.jpDescription;
      }

      return this.fields?.jeopardyMessageContent?.value?.replace('{jpDescription}', this?.jpDescription || '');
    },
  },

  methods: {
    startChat() {
      // this is to start the chat and it is captured in LiveChat.vue - passsing jeopardy details to Genesys
      this.$root.$emit(
        'start-chat',
        this.activeJpCode,
        this.jpDescription,
        this?.jeopardyData?.params?.additionalComments,
        this?.jeopardyData?.params?.cancellationReasons
      );
    },

    goToCallBackForm() {
      let url = this.fields?.callBackFormLink?.value?.href + '?policyid=';
      url += this.$route.query.policyId ? this.$route.query.policyId : this.$route.query.policyNumber;

      window.location.href = url;
    },

    getAnalyticsModel() {
      let formAnalyticsModel = this.getAnalyticsBaseModel();

      formAnalyticsModel.event = 'form.jeopardy';

      if (this.$store.state.cancelRoadsideModule.assistanceProduct) {
        formAnalyticsModel.form.productCategory = this.$store.state.cancelRoadsideModule.assistanceProduct.productCover;
      }

      formAnalyticsModel.form.stepName = this.step;
      formAnalyticsModel.form.jeopardy = this.activeJpCode;
      formAnalyticsModel.form.jeopardyDetail = this.jpDescription;

      return formAnalyticsModel;
    },

    getJeopardyDataForCallBack() {
      return {
        jpCode: this.activeJpCode,
        jpDescription: this.jpDescription,
        cancellationReasons: this?.jeopardyData?.params?.cancellationReasons,
        additionalComments: this?.jeopardyData?.params?.additionalComments,
        formName: this?.params?.formName,
      };
    },
  },
};
</script>
