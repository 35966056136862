<template>
  <ul>
    <li v-for="item in fields.items" :key="item.id">
      <div class="field-link">
        <sc-link :field="item.fields.link" />
      </div>
    </li>
  </ul>
</template>

<script>
import { Link } from '@sitecore-jss/sitecore-jss-vue';
export default {
  name: 'LinkList',
  components: {
    ScLink: Link,
  },
  props: {
    rendering: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
