<template>
  <div class="main">
    <visitor-identification />
    <placeholder name="jss-header" :rendering="route" :title="pageTitle" />
    <login v-if="!isExperienceEditorRunning && this.performLogin" />
    <placeholder name="headless-main" :params="{ formTitle: pageTitle }" :rendering="route" class="headless-main" />
    <placeholder name="jss-footer" :rendering="route" />
  </div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import VisitorIdentification from './VisitorIdentification';
import Login from './components/feature/account/Login.vue';
import RACQFormsMixins from './components/foundation/forms/mixins/RACQFormsMixins';
import { isProduction } from './components/foundation/shared/apiservices/apiConstant';

export default {
  name: 'Layout',
  props: {
    route: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [RACQFormsMixins],
  data: function () {
    return {
      isProduction,
      formTitle: this.route.fields && this.route.fields.Title && this.route.fields.Title.value ? this.route.fields.Title.value : '',
      performLogin: this.route.fields.AuthenticationRequired === undefined || this.route.fields.AuthenticationRequired.value,
    };
  },
  metaInfo() {
    return {
      title: this.pageTitle,
    };
  },
  computed: {
    pageTitle() {
      return (this.route.fields && this.route.fields.Title && this.route.fields.Title.value) || '';
    },
  },
  components: {
    Placeholder,
    VisitorIdentification,
    Login
  },
};
</script>
<style lang="scss">
.main {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .headless-main {
    flex-grow: 1;
  }
}
</style>
