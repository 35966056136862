<template>
  <section id="additional-driver-form" class="py-4">
    <h3>Add new Primary Driver</h3>

    <TitleFirstLastName :form-values="formValues" id="new-additional-driver-title-first-last-name" />

    <FormulateInput
      label="Date of birth"
      placeholder="DD/MM/YYYY"
      validation="required|isValidDate"
      error-behaviour="submit"
      validation-name="Date of birth"
      :validation-rules="validationRules"
      :disabled-dates="disabledFutureDates"
      name="dateOfBirth"
      type="datepicker"
      format="dd/MM/yyyy"
      :tooltip="{
        content: 'tooltipContent',
        tooltipId: `dateOfBirth-id`,
      }"
      :validation-messages="{
        required: validationMessages.dobRequired,
      }"
    />

    <FormulateInput
      label="Your gender"
      name="gender"
      type="radio"
      :options="[
        { value: 'MALE', label: 'Male' },
        { value: 'FEMALE', label: 'Female', id: 'gender_last-child' },
      ]"
      validation="required"
      :validation-messages="{
        required: validationMessages.genderRequired,
      }"
    />

    <br />

    <FormulateInput
      label="Number of years fully licensed"
      name="licenseYearsCount"
      validation="required|number"
      :tooltip="{
        title: '<h5>Why are we asking this?</h5>',
        content: '<span>Hello I am a tooltip with content applied.</span>',
        tooltipId: 'licenseYearsCount-tooltip-id',
      }"
      :validation-messages="{
        required: 'Please select the number of years licenced',
        number: 'Please enter a valid number',
      }"
    />

    <FormulateInput
      label="In the last 3 years, how many at fault claims have you had?"
      name="atFaultClaimsCount"
      type="button-group"
      :options="[
        { value: '0', label: '0' },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5+', label: '5+', id: 'atFaultClaimsCount_last-child' },
      ]"
      :tooltip="{
        title: '<h5>Why are we asking this?</h5>',
        content: '<span>Hello I am a tooltip with content applied.</span>',
        tooltipId: 'atFaultClaimsCount-tooltip-id',
      }"
      validation="required"
      :validation-messages="{
        required: validationMessages.atFaultClaimsRequired,
      }"
    />

    <div v-if="formValues['atFaultClaimsCount'] === '0'" class="custom-info custom-tag-icon">
      <img src="@/assets/img/icons/tag-icon.svg" alt="An icon of a tag" /> Congratulations, you qualify for a No Claim discount!

      <div class="info-box--basic">
        <p>The No Claim Discount you are offered is based on:</p>

        <ul class="list-disc">
          <li>The number of at fault claims the primary driver has had in the last three (3) years; and</li>
          <li>The number of years the primary driver has held a driver’s license (excluding the time on a learner’s license).</li>
        </ul>
      </div>
    </div>

    <RepeatableAtFaultClaims v-if="hasMoreThanOne('atFaultClaimsCount')" name="repeatableAtFaultClaims" id="repeatableAtFaultClaims" />

    <div class="form-field-with-custom-help-text licenceRestrictionCount-wrapper">
      <strong class="label">In the last 3 years, how many of the following have you had?</strong>

      <div class="flex-col custom-help-text">
        <ul class="list-disc">
          <li>Any loss, cancellation, disqualification, or suspension of licence</li>
          <li>Any altered or restricted licence conditions or Good Driver Behaviour period imposed</li>
        </ul>

        <FormulateInput
          name="licenceRestrictionCount"
          type="button-group"
          :options="[
            { value: '0', label: '0' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5+', label: '5+', id: 'licenceRestrictionCount_last-child' },
          ]"
          :tooltip="{
            title: '<h5>Why are we asking this?</h5>',
            content: '<span>Hello I am a tooltip with content applied.</span>',
            tooltipId: 'licenceRestrictionCount-tooltip-id',
          }"
          validation="required"
          :validation-messages="{
            required: validationMessages.licenceHistoryRequired,
          }"
        />
      </div>
    </div>

    <RepeatableOccurrenceInputGroup
      v-if="hasMoreThanOne('licenceRestrictionCount')"
      name="repeatableOccurrenceCount"
      id="repeatableOccurrenceCount"
      :count="licenceRestrictionCount"
    />

    <FormulateInput
      label="Has this driver had any driving under the influence of drug or alcohol offences in the last 3 years?"
      name="hasAlcoholOffences"
      type="radio"
      :options="[
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No', id: 'hasAlcoholOffences_last-child' },
      ]"
      validation="required"
      :validation-messages="{
        required: validationMessages.duiOffencesRequired,
      }"
    />

    <FormulateInput
      type="checkbox"
      name="confirmDriverDetails"
      label="I confirm the primary driver details are correct"
      validation="required"
      :validation-messages="{
        required: 'Please confirm that the additional driver details are correct',
      }"
    />

    <div class="flex-center">
      <button class="btn btn-secondary" type="submit" @click="onSave">Save additional driver</button>
    </div>
  </section>
</template>

<script>
import RepeatableOccurrenceInputGroup from '@/components/feature/newmotorinsuranceform/shared/RepeatableOccurrenceInputGroup.vue';
import RepeatableAtFaultClaims from '@/components/feature/newmotorinsuranceform/shared/RepeatableAtFaultClaims.vue';
import TitleFirstLastName from '@/components/feature/newmotorinsuranceform/shared/TitleFirstLastName.vue';

import { validationMessages } from '@/components/feature/newmotorinsuranceform/services/mappings';

import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import dayjs from 'dayjs';

export default {
  name: 'NewAdditionalDriver',

  components: {
    RepeatableOccurrenceInputGroup,
    RepeatableAtFaultClaims,
    TitleFirstLastName,
  },

  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    onSave: {
      type: Function,
      required: true,
    },
    formValues: {
      type: Object,
      required: true,
    },
  },

  mixins: [RACQFormsMixins],

  data() {
    return {
      validationMessages,
      yearsHeldRatingOneOptions: [
        { value: '<5', label: 'Less than five years' },
        { value: '5>9', label: 'Between 5 and 9 years' },
        { value: '10+', label: '10 years or more' },
      ],
    };
  },

  methods: {
    checkDateIsValid(value) {
      if (!value) return true;

      return dayjs(value).isBefore(new Date());
    },

    hasMoreThanOne(fieldName) {
      const count = this.formValues[fieldName];
      return (count === '5+' ? 5 : count) > 0;
    },
  },

  computed: {
    validationRules() {
      return {
        isValidDate: ({ value }) => this.checkDateIsValid(value),
      };
    },

    disabledFutureDates() {
      return {
        to: new Date('January 01, 1900'),
        from: new Date(),
      };
    },

    licenceRestrictionCount() {
      if (this.formValues.licenceRestrictionCount) {
        return Number(this.formValues.licenceRestrictionCount?.match(/(\d+)/)[0] || 0);
      }

      return 0;
    },
  },
};
</script>
<style lang="scss">
#additional-driver-form {
  .form-fields-flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    input[type='text'] {
      width: 100%;
    }

    .flex-field-with-label {
      .formulate-input-element--text,
      .formulate-input-element--vue-select {
        @include respond-to(desktop) {
          margin-left: 9.5rem;
          max-width: 8rem;
        }
      }
    }

    .formulate-input-element--text {
      margin-left: 1rem;
      width: 10rem;
    }
  }

  .simple-vue-select {
    .formulate-input-element--vue-select.v-select {
      margin-left: 4.25rem;
    }
  }

  .forms-input-wrapper {
    min-width: min-content !important;
  }

  .form-field-with-custom-help-text.licenceRestrictionCount-wrapper {
    display: flex;

    .formulate-input-errors {
      margin-left: 0;
      margin-top: -0.5rem;
      padding-left: 1.25rem;
    }

    .label {
      max-width: 180px;
      margin-right: 45px;
    }

    .custom-help-text {
      max-width: 445px;
    }

    .formulate-input-element[data-type='button-group'] {
      margin-left: 1.4rem !important;
    }
  }

  .formulate-input-element--checkbox label.formulate-input-element-decorator {
    display: none !important;
  }
}
</style>
