<template>
  <div class="d-flex items-center gap-2">
    <div class="formulate-input-element formulate-input-element--selectbox">
      <div :class="`formulate-input-element--select`" :data-type="context.type" tabindex="0" @focusout="handleFocusOut">
        <a class="dropdown-label" @click="showDropDown = !showDropDown" @blur="context.blurHandler"
          >{{ selectedModel.label }}
          <div><b></b></div>
        </a>
        <ul class="formulate-input-dropdown" v-if="showDropDown">
          <li
            v-for="(option, index) in options"
            :key="option.value"
            :data-is-selected="selection && selection.value === option.value"
            @mouseenter="selectedIndex = index"
            @keydown.enter.prevent="context.model = selection.label"
            @keydown.down.prevent="increment"
            @keydown.up.prevent="decrement"
          >
            <a
              @click="
                context.model = selection.value;
                showDropDown = false;
              "
              >{{ option.label }}</a
            >
          </li>
        </ul>
      </div>
    </div>

    <div v-if="context.attributes.tooltip && tooltipContent">
      <RacqFormTooltip :tooltip-id="context.attributes.tooltip.tooltipId">
        <template #tooltip-trigger>
          <button :class="[triggerIcon]" :id="context.attributes.tooltip.tooltipId" type="button" name="tooltip-trigger" />
        </template>
        <template #tooltip-content>
          <p v-if="tooltipTitle"><strong v-text="tooltipTitle" /></p>
          <div v-html="tooltipContent" v-if="tooltipContent"></div>
        </template>
      </RacqFormTooltip>
    </div>
  </div>
</template>

<script>
import RacqFormTooltip from '@/components/foundation/forms/controls/RacqFormTooltip.vue';

export default {
  components: {
    RacqFormTooltip,
  },

  mixins: [RacqFormTooltip],

  props: {
    context: {
      type: Object,
      required: true,
    },
    inputclass: {
      type: String,
    },
  },
  data() {
    return {
      selectedIndex: 0,
      showDropDown: false,
    };
  },
  watch: {
    model() {
      this.selectedIndex = 0;
    },
  },
  computed: {
    model() {
      return this.context.model;
    },
    selection() {
      if (this.options[this.selectedIndex]) {
        return this.options[this.selectedIndex];
      }
      return false;
    },
    options() {
      return this.context.options || {};
    },
    selectedModel() {
      if (this.context.model) {
        const selectedOption = this.options.find((option) => option.value == this.context.model);
        if (selectedOption) {
          return selectedOption;
        } else {
          return { value: undefined, label: this.context.placeholder };
        }
      } else {
        return { value: undefined, label: this.context.placeholder };
      }
    },

    tooltipContent() {
      const { tooltip } = this.context.attributes;

      if (tooltip && tooltip.content) {
        return tooltip.content;
      }

      return '';
    },

    tooltipTitle() {
      const { tooltip } = this.context.attributes;

      if (tooltip && tooltip.title) {
        return tooltip.title;
      }

      return '';
    },
  },
  methods: {
    increment() {
      const length = this.filteredOptions.length;
      if (this.selectedIndex + 1 < length) {
        this.selectedIndex++;
      } else {
        this.selectedIndex = 0;
      }
    },
    decrement() {
      const length = this.filteredOptions.length;
      if (this.selectedIndex - 1 >= 0) {
        this.selectedIndex--;
      } else {
        this.selectedIndex = length - 1;
      }
    },
    handleFocusOut() {
      setTimeout(() => {
        this.showDropDown = false;  
      }, 150);
    },
  },
};
</script>
