<template>
  <racq-form>
    <step-tracker :steps="steps" :key="reloadSteps" />
    <div>
      <racq-form-step ref="step1" :stepNumber="fields.childItems[0].fields.stepNumber" :stepTitle="fields.childItems[0].fields.stepTitle">
        <renewal-offer-form-step-1
          ref="step1-child"
          v-if="isExperienceEditorRunning || insurancePolicy"
          :formTitle="params.formTitle"
          @go-jeopardy="showJeopardy"
          @go-next="moveToStep('step2')"
          @visibility-requested="moveToStep('step1')"
          :fields="fields.childItems[0].fields"
          :step="steps[0]"
        />
      </racq-form-step>

      <racq-form-step ref="step2" :stepNumber="fields.childItems[1].fields.stepNumber" :stepTitle="fields.childItems[1].fields.stepTitle">
        <renewal-offer-form-step-2
          ref="step2-child"
          v-if="isExperienceEditorRunning || insurancePolicy"
          @go-jeopardy="moveToStep('stepjeopardy')"
          @go-back="moveToStep('step1')"
          @go-next="moveToStep('step3')"
          :fields="fields.childItems[1].fields"
          :step="steps[1]"
        />
      </racq-form-step>

      <racq-form-step ref="step3" :stepNumber="fields.childItems[2].fields.stepNumber" :stepTitle="fields.childItems[2].fields.stepTitle">
        <renewal-offer-form-step-3
          ref="step3-child"
          v-if="isExperienceEditorRunning || insurancePolicy"
          @go-jeopardy="moveToStep('stepjeopardy')"
          @go-back="moveToStep('step1')"
          :fields="fields.childItems[2].fields"
          :step="steps[2]"
        />
      </racq-form-step>
      <racq-form-step ref="stepjeopardy" :stepTitle="fields.jeopardyFolder.childItems[0].fields.renewalFormJeopardyTitle">
        <placeholder
          ref="jeopardy-child"
          :step="steps[3]"
          name="jeopardy"
          :rendering="rendering"
          :params="{
            displayCode: displayCode,
            jpCode: jpcode,
            formName: formName,
            additionalComments: additionalComments,
            stepDetails: stepDetails,
            callUsVisible: true,
            callbackVisible: false,
          }"
        />
      </racq-form-step>
    </div>
  </racq-form>
</template>

<script>
import StepTracker from '../../foundation/forms/StepTracker.vue';
import RenewalOfferFormStep1 from './steps/Step1RenewalOfferForm.vue';
import RenewalOfferFormStep2 from './steps/Step2RenewalOfferForm.vue';
import RenewalOfferFormStep3 from './steps/Step3RenewalOfferForm.vue';
import RacqForm from '../../foundation/forms/RACQForm.vue';
import RacqFormStep from '../../foundation/forms/RACQFormStep.vue';
import RACQFormsMixins from '../../foundation/forms/mixins/RACQFormsMixins';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import { mapState } from 'vuex';
import { jpCodes } from '../renewalofferform/steps/shared/jpCodes';

export default {
  name: 'RenewalOfferForm',
  components: {
    RacqForm,
    RacqFormStep,
    StepTracker,
    RenewalOfferFormStep1,
    RenewalOfferFormStep2,
    RenewalOfferFormStep3,
    Placeholder,
  },
  mixins: [RACQFormsMixins],
  data: function () {
    return {
      reloadSteps: 0,
      jpcode: undefined,
      displayCode: undefined,
      additionalComments: undefined,
      stepDetails: undefined,
      formName: 'Renewal Offer Form',
      steps: [
        {
          stepNo: 1,
          stepTitle: '',
          stepState: 'active',
          stepControlName: 'step1',
          stepChildControlName: 'step1-child',
        },
        {
          stepNo: 2,
          stepTitle: '',
          stepState: '',
          stepControlName: 'step2',
          stepChildControlName: 'step2-child',
        },
        {
          stepNo: 3,
          stepTitle: '',
          stepState: '',
          stepControlName: 'step3',
          stepChildControlName: 'step3-child',
        },
        {
          stepNo: 4,
          stepTitle: 'Jeopardy',
          stepState: '',
          stepControlName: 'stepjeopardy',
          stepChildControlName: 'jeopardy-child',
          hideFromNavigation: true,
        },
      ],
      currentStep: {},
    };
  },
  mounted() {
    this.addCancelFormEvent();
    this.currentStep = this.steps[0];
    this.$refs[this.currentStep.stepControlName].moveInto();
    for (var i = 0; i < this.steps.length - 1; i++) {
      //length - 1 because we don't want to include jeopardy step
      this.steps[i].stepTitle = this.fields.childItems[i].fields.stepTitle.value;
    }
    //global event to show jeopardy on any step
    this.$root.$on('show-jeopardy', (jpData) => {
      if (!this.isExperienceEditorRunning)
        //do not show jeopardy if experience editor is running
        this.showJeopardy(jpData);
    });

    this.$store.dispatch('renewalOffer/fetchInsurancePolicy', this.$route.query.policyId);
  },
  computed: {
    ...mapState('renewalOffer', ['email', 'insurancePolicy', 'hasJeopardy']),
  },
  watch: {
    // Watch jeopardy state from Vuex store
    hasJeopardy(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.$root.$emit('show-jeopardy', {
            displayCode: jpCodes.genericError,
            jpCode: jpCodes.genericError,
            formName: 'Renewal Offer Form',
          });
        }, 500);
      }
    },
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
    },
  },
};
</script>
