<template>
  <div>
    <button type="button" @click="showWarning = true" class="w-100 text-center btn btn-secondary" data-toggle="modal" data-target="#exampleModal">
      {{ fields.DiscardButton_Text.value }}
    </button>
    <div class="modal-backdrop show" v-if="showWarning"></div>
    <div
      class="modal show fade"
      style="display: unset"
      v-if="showWarning"
      ref="discardModal"
      role="dialog"
      aria-labelledby="DiscardConfirmation"
      traria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h4 class="modal-title">{{ fields.DiscardModal_Title.value }}</h4>
            <h6 class="mt-3">{{ fields.DiscardModal_Body.value }}</h6>
          </div>
          <div class="modal-footer w-100 d-flex">
            <button type="button" @click="cancelForm()" class="btn btn-secondary" data-dismiss="modal">{{ fields.DiscardButton_Text.value }}</button>
            <button type="button" @click="showWarning = false" class="btn btn-primary ml-auto">{{ fields.ContinueButton_Text.value }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.show {
  display: unset;
}
</style>

<script>
export default {
  name: 'DiscardModal',
  data: function () {
    return {
      showWarning: false,
    };
  },
  methods: {
    cancelForm: function () {
      this.$root.$emit('cancel-form');
    },
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
