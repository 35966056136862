<template>
  <div class="repeatable-form-wrapper" id="repeatable-radio-group">
    <FormulateInput
      type="group"
      :name="id"
      :repeatable="false"
      :label="label"
      validation="required"
      validation-name="Occurrence"
      :minimum="count"
      :limit="5"
      #default="{ index }"
      class="repeatable"
    >
      <div>
        <FormulateInput
          type="radio"
          :label="`Occurrence ${index + 1}`"
          :name="`occurrence-${index + 1}`"
          validation="required"
          :validation-name="`Occurrence ${index + 1}`"
          :options="{
            drivingUnderInfluence: 'Driving under the influence conviction',
            otherSuspension: 'Other licence suspension, cancellation, disqualification or restriction',
            goodDrivingPeriod: 'Good driving behaviour period',
          }"
        />
      </div>
    </FormulateInput>
  </div>
</template>

<script>
export default {
  name: 'RepeatableOccurrenceRadioGroup',
  props: {
    count: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  computed: {
    total() {
      const count = Array.isArray(this.formData.attendees) ? this.formData.attendees.length : 1;
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(count * 100);
    },
  },
};
</script>

<style lang="scss">
#repeatable-radio-group {
  &.repeatable-form-wrapper {
    .formulate-input-group-item.forms-input-wrapper,
    .forms-input-wrapper {
      min-width: max-content !important;
    }

    .formulate-input-group-repeatable {
      margin-bottom: 2rem;
    }

    .formulate-input-group-item.forms-input-wrapper {
      margin-left: 140px;
    }

    .formulate-input-wrapper .formulate-input-element--group.formulate-input-group input[type='radio'] {
      width: max-content !important;
    }

    .forms-input-wrapper .formulate-input-wrapper .formulate-input-element .formulate-input-element--radio {
      display: block !important;
      width: max-content !important;
    }

    .formulate-input-element--group.formulate-input-group {
      display: flex;
      flex-direction: column;
      margin-left: 0;

      .formulate-input-label--after {
        background-color: $white;
        border: none;
        color: black;
        font-weight: normal;
        height: auto;
        line-height: inherit;
        margin: 0 0.25rem 0.5rem;
        padding: 0;
        text-align: left;
        width: auto;
      }
    }
  }
}
</style>
