<template>
  <div id="additional-driver-details">
    <p class="font-bold" v-text="formInputData(motorPolicyModel.additionalDriversFormHelpText).label" />

    <div class="form-field-wrapper">
      <p class="form-field-label" v-text="formInputData(motorPolicyModel.longTermClaims).label" />
      <p class="form-field-value" v-text="formattedLongTermClaims" />
    </div>

    <div class="form-field-wrapper">
      <p class="form-field-label" v-text="formInputData(motorPolicyModel.shortTermClaims).label" />
      <p class="form-field-value" v-text="formattedShortTermClaims" />
    </div>

    <div class="form-field-wrapper">
      <p class="form-field-label" v-text="formInputData(motorPolicyModel.yearsLicenced).label" />
      <p class="form-field-value">{{ selectedDriver.yearsLicenced }} years</p>
    </div>

    <p class="py-2" v-html="formInputData(motorPolicyModel.additionalDriverConfirmHelpText).label" />

    <FormulateInput
      v-bind="formInputData(motorPolicyModel.confirmAdditionalDrivers)"
      :id="`${motorPolicyModel.confirmAdditionalDrivers}__${selectedDriver.externalId}`"
      :name="`${motorPolicyModel.confirmAdditionalDrivers}__${selectedDriver.externalId}`"
      validation="required"
      :validation-messages="{
        required: validationMessages.additionalDriverDetailsConfirm,
      }"
    />
  </div>
</template>

<script>
import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import { motorPolicyModel } from '@/components/feature/newmotorinsuranceform/services/mappings';

export default {
  name: 'AdditionalDriverInfo',

  props: {
    selectedDriver: {
      type: Object,
      required: true,
    },
    step: {
      type: Object,
      required: true,
    },
  },

  mixins: [RACQFormsMixins],

  data() {
    return {
      motorPolicyModel,
    };
  },

  computed: {
    formattedLongTermClaims() {
      if (!this.selectedDriver?.longTermClaims) {
        return 'No claims';
      }

      return `${this.selectedDriver.longTermClaims} claims`;
    },

    formattedShortTermClaims() {
      if (!this.selectedDriver?.shortTermClaims) {
        return 'No claims';
      }

      return `${this.selectedDriver.shortTermClaims} claims`;
    },
  },
};
</script>

<style lang="scss">
[id^='additional-driver-details'] {
  background: $racq-pale-white;
  border: 1px solid $racq-pale-white;
  border-radius: 3px;
  padding: 1.5rem 1rem;

  .form-field-wrapper {
    display: grid;
    width: 80%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .form-field-label {
    grid-area: 1 / 1 / 2 / 2;
  }
  .form-field-wrapper {
    grid-area: 1 / 2 / 2 / 3;
  }

  .form-field-label {
    width: 12rem;
    color: $racq-navy;
  }

  .form-field-value {
    .formulate-input-element--select {
      min-width: 270px !important;
      max-width: 270px !important;
    }

    .formulate-input-element--selectbox {
      min-width: 270px !important;
      max-width: 270px !important;
      margin-left: 65px !important;
    }

    .forms-input-wrapper {
      &[data-type='selectbox'] {
        margin-top: -1.5rem;

        .formulate-input-errors {
          max-width: 400px !important;
          margin-top: 0.5rem !important;
        }
      }

      .formulate-input-errors {
        margin-left: 30px !important;
      }
    }
  }

  .forms-input-wrapper {
    &[data-type='checkbox'] {
      margin-bottom: 0 !important;
    }
  }

  .formulate-input-element--checkbox label.formulate-input-element-decorator {
    display: none !important;
  }
}
</style>
