<template>
  <div id="excess-sum-insured-details">
    <h4 v-text="quoteExcessInfo[motorPolicyModel.excessesSectionHeader].label.value" />
    <div v-html="quoteExcessInfo[motorPolicyModel.excessesSectionIntroText].label.value"></div>

    <div v-if="isHighPerformanceVehicle">
      <div v-html="quoteExcessInfo[motorPolicyModel.highPerformanceExcess].label.value"></div>
    </div>

    <div class="excess-details-info-grid gap-2">
      <div id="" class="pr-2">
        <div v-if="isNewTermStartDate" v-html="quoteExcessInfo[motorPolicyModel.listedDriverAgeExcess].label.value"></div>
        <div v-else-if="isOldTermStartDate" v-html="quoteExcessInfo[motorPolicyModel.listedDriverAgeExcess_OLD].label.value"></div>
      </div>

      <div id="" class="pr-2">
        <div v-if="isNewTermStartDate" v-html="quoteExcessInfo[motorPolicyModel.inexperiencedDriverExcess].label.value"></div>
        <div v-else-if="isOldTermStartDate" v-html="quoteExcessInfo[motorPolicyModel.inexperiencedDriverExcess_OLD].label.value"></div>
      </div>

      <div id="" class="pr-2">
        <div v-if="isNewTermStartDate" v-html="quoteExcessInfo[motorPolicyModel.unlistedDriverAgeExcess].label.value"></div>
        <div v-else-if="isOldTermStartDate" v-html="quoteExcessInfo[motorPolicyModel.unlistedDriverAgeExcess_OLD].label.value"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { NEW_MOTOR_INSURANCE_MODULE, motorPolicyModel } from '@/components/feature/newmotorinsuranceform/services/mappings';
import { mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { EXCESS_TRANSITION_DATE } from '@/components/foundation/shared/utils/constants';

dayjs.extend(isSameOrAfter);

export default {
  name: 'ExcessDetails',

  props: {},

  data() {
    return {
      motorPolicyModel,
      highMarketVale: 100000,
    };
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, ['viewModelData', 'selectedPolicy']),
    ...mapGetters(NEW_MOTOR_INSURANCE_MODULE, ['quoteExcessInfo']),

    isHighMarketValue() {
      return this.viewModelData?.glassesGuideVehicleDetails?.marketValue > this.highMarketVale;
    },

    isOldTermStartDate() {
      return dayjs(this.selectedPolicy.termStart).isBefore(EXCESS_TRANSITION_DATE);
    },

    isNewTermStartDate() {
      return dayjs(this.selectedPolicy.termStart).isSameOrAfter(EXCESS_TRANSITION_DATE);
    },

    isHighPerformanceVehicle() {
      return this.viewModelData?.glassesGuideVehicleDetails?.highPerformance === 'HP';
    },
  },
};
</script>

<style lang="scss" scoped>
#excess-sum-insured-details {
  .excess-details-info-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}
</style>
