import { addToCoverService } from '../../../foundation/shared/apiservices/apiAddToCoverService';

export default {
  namespaced: true,
  state: {
    quoteSessionId: null,
    policyRenewal: null,
    newTotalPremium: null,
    updatedPremium: null,
    insurancePolicy: null,
    quotedPremium: null,
    error: [],
    localCoverages: [],
  },
  mutations: {
    pushError(state, { error }) {
      state.error.push(error);
    },
    updateLocalCoverages(state, { coverages }) {
      state.localCoverages = coverages;
    },
    updatePolicyRenewal(state, { insurancePolicy }) {
      state.policyNumber = insurancePolicy && insurancePolicy.insurancePolicy;
      state.insurancePolicy = insurancePolicy;
    },
    updatePremium(state, { res, paid, partiallyPaid, outstanding }) {
      state.updatedPremium = res;
      state.quotedPremium = res.policyPremium;
      state.newTotalPremium = paid || partiallyPaid ? res.changeAmount : res.policyPremium;
      if (partiallyPaid && outstanding) {
        state.newTotalPremium += outstanding;
      }
      if (!res.changeAmount) state.newTotalPremium = 0;
    },
    getQuoteSessionId(state, { id, error }) {
      if (error) {
        state.error.push(error);
      } else {
        state.quoteSessionId = id;
      }
    },
  },
  actions: {
    submitChanges({ state, commit }, { payload, callback }) {
      addToCoverService
        .submitChanges({
          ...payload,
          quotedPremium: state.quotedPremium,
          coverages: state.localCoverages.map((cover) => ({
            coverId: cover.id,
            includeHireCar: cover.includeHireCar,
            includeWindscreen: cover.includeWindscreen,
          })),
          quoteSessionId: state.quoteSessionId,
        })
        .then((res) => {
          if (res.jeopardies && res.jeopardies.length) {
            callback && callback(false, res);
          } else {
            callback && callback(res);
          }
        })
        .catch((err) => {
          callback && callback(false, err);
          commit('pushError', err);
        });
    },
    getQuoteSessionId({ commit }, { policyNumber, callback }) {
      addToCoverService
        .getQuoteSessionId(policyNumber)
        .then((result) => {
          commit('getQuoteSessionId', { id: result });
        })
        .catch((error) => {
          commit('pushError', error);
          callback && callback(error);
        });
    },
    updateCoveragePricing({ state, commit }, { payload, paid, partiallyPaid, outstanding, callback }) {
      addToCoverService.updateCoveragePricing(state.quoteSessionId, payload).then(
        (res) => {
          commit('updatePremium', { res, paid, partiallyPaid, outstanding });
          callback && callback(res); // set loading flag false
        },
        (error) => {
          commit('pushError', { error });
          callback && callback(false, error); // set loading flag false
        }
      );
    },
    getPolicyRenewal({ commit }, { policyNumber, callback }) {
      addToCoverService.getPolicyRenewal(policyNumber).then(
        (res) => {
          // commit("updatePolicyRenewal", res)
          callback && callback(res); // set loading flag false
        },
        (error) => {
          // Wait for 5s to show JP, because we error could caused by refreshing credential from s8
          setTimeout(() => {
            commit('pushError', { error });
          }, 5 * 1000);
          callback && callback(false, error); // set loading flag false
        }
      );
    },
  },
};
