<template>
  <div class="pale-grey-bg">
    <h5 v-text="title" />
    <div class="policy-discounts-grid">
      <div v-for="discount in policyDiscounts" :key="discount" class="d-flex items-center gap-2" style="max-width: 250px">
        <img src="@/assets/img/icons/tag-icon.svg" alt="An icon of a tag" /> &nbsp;
        <span v-text="discount" />
      </div>
    </div>
  </div>
</template>

<script>
import { NEW_MOTOR_INSURANCE_MODULE } from '../services/mappings';
import { mapState } from 'vuex';
import { ONLINE_DISCOUNT_KEY } from '@/components/foundation/shared/utils/constants';

export default {
  name: 'PolicyDiscounts',

  props: {
    title: {
      type: String,
      default: 'Your policy discounts',
    },
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, ['businessRulesResponse']),

    policyDiscounts() {
      const sitecoreDictionary = this.$i18n.i18next.store.data.en.translation;
      const discountsFromPolicy = this.businessRulesResponse?.viewModel?.discounts;
      const discountsMap = [];

      if (discountsFromPolicy?.length) {
        discountsFromPolicy.forEach((discount) => {
          if (discount && sitecoreDictionary[discount]) {
            discountsMap.push(sitecoreDictionary[discount]);
          }
        });
      }

      // Validate online discount
      const isAnnual = this.businessRulesResponse?.viewModel?.responseModel?.isAnnual;
      const isOnlineDiscountEligible = this.businessRulesResponse?.viewModel?.responseModel[isAnnual ? 'eligibleForYearlyOnlineDiscount' : 'eligibleForMonthlyOnlineDiscount'];
      if (isOnlineDiscountEligible == 'true') discountsMap.push(sitecoreDictionary[ONLINE_DISCOUNT_KEY]);

      return discountsMap;
    },
  },
};
</script>

<style lang="scss" scoped>
.pale-grey-bg {
  background: $racq-pale-grey;
}

.policy-discounts-grid {
  grid-gap: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 40px;
}
</style>
