<template>
  <div class="form-elements-container border-top-0 py-3">
    <div class="pb-4">
      <hr class="my-2" />
    </div>
    <h4 class="mb-4">
      <ScText :field="fields.TableTitle_Text" />
    </h4>
    <div class="summary w-100 rounded px-3 py-3">
      <table class="w-100">
        <tbody>
          <tr>
            <th colspan="2">
              <h5 class="mt-2">
                <ScText :field="fields.VehicleTitle_Text" />
              </h5>
            </th>
          </tr>
        </tbody>
        <tbody v-for="(coverage, index) in filteredCoverages" :key="index">
          <tr :class="{ 'border-top': index }">
            <td>
              <div class="svg-confirmation-car-bluegrey px-4 py-4"></div>
            </td>
            <td>
              <span class="text-bold">
                {{ coverage.vehicle.formattedLine1 }}
              </span>
              <br />
              <span class="text-bold">
                {{ coverage.vehicle.formattedLine2 }}
              </span>
            </td>
          </tr>
          <tr class="border-top" v-if="coverage.includeHireCar && !coverage.hireCarExisted">
            <td class="text-right" width="35%">
              <h6>
                <ScText :field="fields.HireCar_Title" />
              </h6>
            </td>
            <td>Yes</td>
          </tr>
          <tr class="border-top" v-if="coverage.includeWindscreen && !coverage.windscreenExisted">
            <td class="text-right" width="35%">
              <h6>
                <ScText :field="fields.Windscreen_Title" />
              </h6>
            </td>
            <td>Yes</td>
          </tr>
        </tbody>
        <tbody>
          <tr class="border-top border-bottom">
            <td class="text-right" width="35%">
              <h6>
                <ScText :field="fields.ChangeEffective_Text" />
              </h6>
            </td>
            <td>
              {{ termStart }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="css">
.summary {
  background-color: #f2f5f7;
}
td h6 {
  font-size: 16px !important;
  margin: 0;
}
td {
  padding: 8px 12px;
}

.svg-confirmation-car-bluegrey {
  background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20baseProfile%3D%22tiny%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2286%22%20height%3D%2240%22%20viewBox%3D%220%200%2086%2040%22%3E%3Cpath%20fill%3D%22%234b5869%22%20d%3D%22M82.487%2021.052c-.345-.754-.742-1.644-1.1-2.498-.954-2.283-2.85-3.097-4.553-3.387-3.426-.583-6.974-1.19-9.57-1.633-5.904-4.805-13.91-9.55-14.86-10.107-2.846-1.67-4.62-2.416-7.616-2.416H31.69c-3.748%200-6.895%201.244-10.464%203.11-3.474%201.816-9.102%204.712-11.124%205.752-1.203-.017-.953.07-3.038-.08-2.25-.16-2.468%202.038-2.468%202.038l-.646%204.237L2.9%2024.24s-.453%201.72%201.88%203.07l6.093%204.44s.757.236%203.277.52c.265%203.75%203.37%206.718%207.16%206.718%203.52%200%206.45-2.56%207.065-5.926l1.81.087c6.754.154%2015.77.19%2027.767-.016.642%203.33%203.558%205.855%207.053%205.855%203.612%200%206.602-2.7%207.105-6.196l4.77-.127c.817-.025%201.61-.048%202.446-.076h.992s1.634.417%201.96-1.302l.754-5.688c.406-2.626-.523-4.54-.545-4.548zM21.312%2035.848c-2.013%200-3.673-1.476-3.998-3.41-.038-.222-.068-.448-.068-.682%200-2.26%201.82-4.092%204.066-4.092%202.246%200%204.066%201.832%204.066%204.092%200%20.37-.064.72-.155%201.06-.466%201.742-2.032%203.032-3.91%203.032zm1.092-25.672c-2.114-1.228.5-2.8.5-2.8%203.097-1.62%205.776-2.697%208.786-2.697h6.625l.498%206.48c-5.95-.39-11.887-.755-16.41-.984zm19.57-5.497h2.814c2.045%200%203.125.357%205.78%201.915%202.204%201.294%205.824%203.55%209.363%206.025-3.845-.277-10.442-.745-17.44-1.215l-.516-6.726zm23.03%2031.168c-1.83%200-3.358-1.225-3.87-2.898-.115-.38-.195-.776-.195-1.194%200-2.26%201.82-4.092%204.065-4.092%202.246%200%204.066%201.832%204.066%204.092%200%20.347-.055.678-.135%201-.445%201.773-2.028%203.092-3.93%203.092z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  background-size: 64px;
}
</style>

<script>
import { mapState } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'SummaryTable',
  components: {
    ScText: Text,
  },
  computed: {
    ...mapState('renewalOffer', ['email', 'insurancePolicy', 'hasJeopardy']),
    ...mapState({
      coverages: (state) => state.addToCoverFormModule.localCoverages,
    }),
    filteredCoverages: function () {
      return this.coverages.filter(
        (coverage) => (coverage.includeWindscreen && !coverage.windscreenExisted) || (coverage.includeHireCar && !coverage.hireCarExisted)
      );
    },
    termStart: function () {
      try {
        const d = new Date(this.insurancePolicy.renewalTermStart || this.insurancePolicy.termStart);
        const dString = new Intl.DateTimeFormat('en-AU', {
          dateStyle: 'long',
        }).format(d);
        return dString;
      } catch (error) {
        return '';
      }
    },
  },
  methods: {
    vehicleIndex: function (field, index) {
      if (!field || !field.value) return { value: index + 1 };
      field.value = field.value.replace('{0}', index + 1);
      return field;
    },
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
