<template>
  <racq-form>
    <step-tracker v-show="!isDisclaimerStepActive" :steps="steps" :key="reloadSteps" />
    <placeholder v-if="isExperienceEditorRunning || assistanceProduct" v-show="isDisclaimerStepActive" :rendering="rendering" name="form-disclaimer" />
    <div v-show="!isDisclaimerStepActive">
      <racq-form-step ref="step1" :stepNumber="fields.childItems[0].fields.stepNumber" :stepTitle="fields.childItems[0].fields.stepTitle">
        <step-1
          v-if="isExperienceEditorRunning || Object.keys(this.assistanceProduct).length > 0"
          ref="step1-child"
          :formTitle="params.formTitle"
          @go-jeopardy="showJeopardy"
          @go-next="moveToStep('step2')"
          @visibility-requested="moveToStep('step1')"
          :fields="fields.childItems[0].fields"
          :cancellationReasons="fields.RoadsideCancellationReasons.childItems"
          :step="steps[0]"
        />
      </racq-form-step>

      <racq-form-step ref="step2" :stepNumber="fields.childItems[1].fields.stepNumber" :stepTitle="fields.childItems[1].fields.stepTitle">
        <step-2
          v-if="isExperienceEditorRunning || assistanceProduct"
          ref="step2-child"
          @go-jeopardy="moveToStep('stepjeopardy')"
          @go-back="moveToStep('step1')"
          @go-next="moveToStep('step3', true)"
          :fields="fields.childItems[1].fields"
          :cancellationReasons="fields.RoadsideCancellationReasons.childItems"
          :step="steps[1]"
        />
      </racq-form-step>
      <racq-form-step ref="step3" :stepNumber="fields.childItems[2].fields.stepNumber" :stepTitle="fields.childItems[2].fields.stepTitle">
        <step-3 v-if="isExperienceEditorRunning || assistanceProduct" ref="step3-child" :fields="fields.childItems[2].fields" :step="steps[2]" />
      </racq-form-step>
      <racq-form-step ref="stepjeopardy" :stepTitle="fields.jeopardyFolder.childItems[0].fields.jeopardyTitle">
        <placeholder
          ref="jeopardy-child"
          :step="steps[3]"
          name="jeopardy"
          :rendering="rendering"
          :params="{
            displayCode: displayCode,
            jpCode: jpcode,
            additionalComments: additionalComments,
            stepDetails: stepDetails,
            cancellationReasons: cancellationReasons,
            formName: formName,
            callbackVisible: true,
          }"
        />
      </racq-form-step>
    </div>
  </racq-form>
</template>

<script>
import StepTracker from '../../foundation/forms/StepTracker.vue';
import Step1 from './steps/Step1CancelRoadside.vue';
import Step2 from './steps/Step2CancelRoadside.vue';
import Step3 from './steps/Step3CancelRoadside.vue';
import RacqForm from '../../foundation/forms/RACQForm.vue';
import RacqFormStep from '../../foundation/forms/RACQFormStep.vue';
import RACQFormsMixins from '../../foundation/forms/mixins/RACQFormsMixins';
//import Jeopardy from '../../foundation/forms/Jeopardy.vue';
import { isExperienceEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CancelRoadsideForm',
  components: {
    StepTracker,
    Step1,
    Step2,
    Step3,
    RacqForm,
    RacqFormStep,
    Placeholder,
  },
  mixins: [RACQFormsMixins],
  computed: {
    ...mapState('cancelRoadsideModule', ['assistanceProduct']),
  },
  data: function () {
    return {
      reloadSteps: 0,
      jpcode: undefined,
      displayCode: undefined,
      additionalComments: undefined,
      stepDetails: undefined,
      cancellationReasons: undefined,
      formName: undefined,
      isDisclaimerStepActive: true, //show disclaimer on form start
      steps: [
        {
          stepNo: 1,
          stepTitle: '',
          stepState: 'active',
          stepControlName: 'step1',
          stepChildControlName: 'step1-child',
        },
        {
          stepNo: 2,
          stepTitle: '',
          stepState: '',
          stepControlName: 'step2',
          stepChildControlName: 'step2-child',
        },
        {
          stepNo: 3,
          stepTitle: '',
          stepState: '',
          stepControlName: 'step3',
          stepChildControlName: 'step3-child',
        },
        {
          stepNo: 4,
          stepTitle: 'Jeopardy',
          stepState: '',
          stepControlName: 'stepjeopardy',
          stepChildControlName: 'jeopardy-child',
          hideFromNavigation: true,
        },
      ],
      currentStep: {},
    };
  },
  async mounted() {
    if (!this.isExperienceEditorRunning) {
      await this.addRoadsideProductData(this.$route.query.policyId);
    }
    this.currentStep = this.steps[0];
    this.addCancelFormEvent();
    this.$refs[this.currentStep.stepControlName].moveInto();
    for (var i = 0; i < this.steps.length - 1; i++) {
      //length - 1 because we don't want to include jeopardy step
      this.steps[i].stepTitle = this.fields.childItems[i].fields.stepTitle.value;
    }
    //global event to show jeopardy on any step
    this.$root.$on('show-jeopardy', (jpData) => {
      if (!isExperienceEditorActive())
        //do not show jeopardy if experience editor is running
        this.showJeopardy(jpData);
    });
    //global event to go next on show disclaimer step
    this.$root.$on('proceed-to-form', () => {
      this.isDisclaimerStepActive = false;
    });
  },
  methods: {
    ...mapActions('cancelRoadsideModule', ['addRoadsideProductData']),
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
    },
  },
};
</script>
