<template>
  <div class="step-title" :class="stepNumber ? '' : 'no--padding'">
    <h3 class="step-title-with-icon-container" :style="{ paddingLeft: showStepNo ? '2rem' : '0' }">
      <span v-if="stepId === 'confirmation'" class="step-icon step-icon-white"> </span>
      <span v-if="showStepNo && stepNumber && stepId !== 'confirmation'" class="step-icon">
        <span v-text="(stepNumber && stepNumber.value) || stepNumber" />
      </span>
      <span> <span v-text="(stepTitle && stepTitle.value) || stepTitle" /> </span>
    </h3>
  </div>
</template>

<script>
export default {
  name: 'StepTitle',

  props: {
    stepNumber: {
      required: true,
      type: [String, Number, Object],
    },
    stepTitle: {
      type: [String, Object],
      required: true,
    },
    stepId: {
      type: String,
      required: false,
    },
    showStepNo: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
