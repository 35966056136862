<template>
  <racq-form>
    <step-tracker :steps="steps" :key="reloadSteps" v-show="!isDisclaimerStepActive" />
    <placeholder
      v-if="isExperienceEditorRunning || insuranceProduct"
      v-show="isDisclaimerStepActive"
      :params="{ formType: 'CancelInsurance' }"
      :rendering="rendering"
      name="form-disclaimer"
    />
    <div v-show="!isDisclaimerStepActive">
      <racq-form-step ref="step1" :stepNumber="fields.childItems[0].fields.stepNumber" :stepTitle="fields.childItems[0].fields.stepTitle">
        <step-1
          v-if="isExperienceEditorRunning || insuranceProduct"
          ref="step1-child"
          :formTitle="params.formTitle"
          @go-next="moveToStep('step2')"
          @visibility-requested="moveToStep('step1')"
          :fields="fields.childItems[0].fields"
          :cancellationReasons="fields.InsuranceCancellationReasons.childItems"
          :step="steps[0]"
        />
      </racq-form-step>

      <racq-form-step ref="step2" :stepNumber="fields.childItems[1].fields.stepNumber" :stepTitle="fields.childItems[1].fields.stepTitle">
        <step-2
          v-if="isExperienceEditorRunning || insuranceProduct"
          ref="step2-child"
          @go-back="moveToStep('step1')"
          @go-next="moveToStep('step3')"
          :fields="fields.childItems[1].fields"
          :cancellationReasons="fields.InsuranceCancellationReasons.childItems"
          :step="steps[1]"
          @visibility-requested="moveToStep('step2')"
        />
      </racq-form-step>
      <racq-form-step ref="step3" :stepNumber="fields.childItems[2].fields.stepNumber" :stepTitle="fields.childItems[2].fields.stepTitle">
        <step-3
          v-if="isExperienceEditorRunning || insuranceProduct"
          ref="step3-child"
          :fields="fields.childItems[2].fields"
          :step="steps[2]"
          @go-back="moveToStep('step2')"
          @go-next="moveToStep('step4')"
          @visibility-requested="moveToStep('step3')"
        />
      </racq-form-step>
      <racq-form-step ref="step4" :stepNumber="fields.childItems[3].fields.stepNumber" :stepTitle="fields.childItems[3].fields.stepTitle">
        <step-4
          v-if="isExperienceEditorRunning || insuranceProduct"
          ref="step4-child"
          :fields="fields.childItems[3].fields"
          :step="steps[3]"
          @go-back="moveToStep('step3')"
          @go-next="moveToStep('step5', true)"
          @visibility-requested="moveToStep('step4')"
        />
      </racq-form-step>
      <racq-form-step ref="step5" :stepNumber="fields.childItems[4].fields.stepNumber" :stepTitle="fields.childItems[4].fields.stepTitle">
        <step-5 v-if="isExperienceEditorRunning || insuranceProduct" ref="step5-child" :fields="fields.childItems[4].fields" :step="steps[4]" />
      </racq-form-step>
      <racq-form-step ref="stepjeopardy" :stepTitle="fields.jeopardyFolder.childItems[0].fields.jeopardyTitle">
        <placeholder
          ref="jeopardy-child"
          :step="steps[5]"
          name="jeopardy"
          :rendering="rendering"
          :params="{
            displayCode: displayCode,
            jpCode: jpcode,
            additionalComments: additionalComments,
            cancellationReasons: cancellationReasons,
            stepDetails: stepDetails,
            callbackVisible: true,
            formName: formName,
          }"
        />
      </racq-form-step>
    </div>
  </racq-form>
</template>

<script>
import StepTracker from '../../foundation/forms/StepTracker.vue';
import Step1 from './steps/Step1CancelInsurance.vue';
import Step2 from './steps/Step2CancelInsurance.vue';
import Step3 from './steps/Step3CancelInsurance.vue';
import Step4 from './steps/Step4CancelInsurance.vue';
import Step5 from './steps/Step5CancelInsurance.vue';
import RacqForm from '../../foundation/forms/RACQForm.vue';
import RacqFormStep from '../../foundation/forms/RACQFormStep.vue';
import RACQFormsMixins from '../../foundation/forms/mixins/RACQFormsMixins';
import { isExperienceEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CancelInsuranceForm',
  components: {
    StepTracker,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    RacqForm,
    RacqFormStep,
    Placeholder,
  },
  mixins: [RACQFormsMixins],
  data: function () {
    return {
      reloadSteps: 0,
      jpcode: undefined,
      displayCode: undefined,
      additionalComments: undefined,
      stepDetails: undefined,
      formName: undefined,
      cancellationReasons: undefined,
      isDisclaimerStepActive: true,
      steps: [
        {
          stepNo: 1,
          stepTitle: '',
          stepState: 'active',
          stepControlName: 'step1',
          stepChildControlName: 'step1-child',
        },
        {
          stepNo: 2,
          stepTitle: '',
          stepState: '',
          stepControlName: 'step2',
          stepChildControlName: 'step2-child',
        },
        {
          stepNo: 3,
          stepTitle: '',
          stepState: '',
          stepControlName: 'step3',
          stepChildControlName: 'step3-child',
        },
        {
          stepNo: 4,
          stepTitle: '',
          stepState: '',
          stepControlName: 'step4',
          stepChildControlName: 'step4-child',
        },
        {
          stepNo: 5,
          stepTitle: '',
          stepState: '',
          stepControlName: 'step5',
          stepChildControlName: 'step5-child',
        },
        {
          stepNo: 6,
          stepTitle: 'Jeopardy',
          stepState: '',
          stepControlName: 'stepjeopardy',
          stepChildControlName: 'jeopardy-child',
          hideFromNavigation: true,
        },
      ],
      currentStep: {},
    };
  },
  computed: {
    ...mapState('cancelInsuranceModule', ['insuranceProduct']),
  },
  created() {},
  async beforeCreate() {},
  async mounted() {
    if (!this.isExperienceEditorRunning) {
      await this.addInsuranceProductData(this.$route.query.policyNumber);
    }
    this.$root.$on('proceed-to-form', () => {
      this.isDisclaimerStepActive = false;
    });
    this.currentStep = this.steps[0];
    //commit data to store
    this.$refs[this.currentStep.stepControlName].moveInto();
    for (var i = 0; i < this.steps.length - 1; i++) {
      //length - 1 because we don't want to include jeopardy step
      this.steps[i].stepTitle = this.fields.childItems[i].fields.stepTitle.value;
    }
    //global event to show jeopardy on any step
    this.$root.$on('show-jeopardy', (jpData) => {
      if (!isExperienceEditorActive())
        //do not show jeopardy if experience editor is running
        this.showJeopardy(jpData);
    });
    //global event to go next on show disclaimer step
    this.addCancelFormEvent();
  },
  methods: {
    ...mapActions('cancelInsuranceModule', ['addInsuranceProductData']),
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
    },
  },
};
</script>
