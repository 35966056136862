<template>
  <FormulateForm id="find-your-car-form" v-model="formValues" @submit="submitFindCarForm">
    <div class="step-content">
      <div class="d-flex">
        <FormulateInput
          v-bind="formInputData(motorPolicyModel.vehicleRegistration)"
          :validation-rules="{ customRequired: ({ value }) => validateSelect(value, formValues['yearSelect']) }"
          :validation-messages="{ customRequired: validationMessages.requiredRegoNumber }"
          validation="customRequired"
          validation-name="Registration number"
        >
          <template #suffix>
            <FormulateInput type="button" name="Find car" input-class="btn btn-primary" outer-class="find-your-car-btn" @click="getVehicleFromRego" />
          </template>
        </FormulateInput>
      </div>

      <p v-if="regoLookupError" class="text-error pb-2" v-html="formInputData(motorPolicyModel.regoLookupErrorMessage).label"></p>

      <div class="separator">
        <h5 class="text-italic racq-dark-blue no-margin px-12">
          <b>OR</b>
        </h5>
      </div>

      <article class="find-car-manually">
        <p class="text-italic racq-blue">
          <strong>{{ formInputData(motorPolicyModel.findCarManually).label }}</strong>
        </p>

        <FormulateInput
          v-bind="formInputData(motorPolicyModel.yearSelect)"
          validation="customRequired"
          :validation-messages="{ customRequired: validationMessages.requiredVehicleYear }"
          :validation-rules="{
            customRequired: ({ value }) => formValues[motorPolicyModel.vehicleRegistration] !== '' || validateSelect(formValues['yearSelect']),
          }"
          @input="searchVehicleMakesByYear"
          :options="vehicleYearsList"
        />

        <div v-if="formValues[motorPolicyModel.yearSelect] !== '1900'">
          <FormulateInput
            :disabled="formValues[motorPolicyModel.yearSelect] === '1900' || (!formValues[motorPolicyModel.yearSelect] && !vehicleMakesList.length)"
            v-bind="formInputData(motorPolicyModel.makeSelect)"
            validation="customRequired"
            :validation-messages="{ customRequired: validationMessages.requiredMake }"
            :validation-rules="{
              customRequired: ({ value }) => validateSelect(value, !formValues['yearSelect'] || !vehicleMakesList.length),
            }"
            error-behaviour="submit"
            @input="searchModelsByYearAndMake"
            :options="vehicleMakesList"
          />

          <FormulateInput
            :disabled="!formValues[motorPolicyModel.makeSelect] && !vehicleModelsList.length"
            v-bind="formInputData(motorPolicyModel.modelSelect)"
            validation="customRequired"
            error-behaviour="submit"
            :validation-messages="{ customRequired: validationMessages.requiredModel }"
            :validation-rules="{
              customRequired: ({ value }) =>
                validateSelect(value, formValues['yearSelect'] === '1900' || (!formValues['makeSelect'] && !vehicleModelsList.length)),
            }"
            @input="searchVehicleVariants"
            :options="vehicleModelsList"
          />

          <FormulateInput
            :disabled="!formValues[motorPolicyModel.modelSelect] && !vehicleVariantsList.length"
            v-bind="formInputData(motorPolicyModel.seriesSelect)"
            v-if="!noVariantsFound"
            error-behaviour="submit"
            validation="customRequired"
            :validation-messages="{ customRequired: validationMessages.requiredVehicleSeries }"
            :validation-rules="{
              customRequired: ({ value }) => validateSelect(value, !formValues['modelSelect'] && !vehicleVariantsList.length),
            }"
            @input="searchVehicleBodies"
            :options="vehicleVariantsList"
          />

          <FormulateInput
            v-if="formValues[motorPolicyModel.seriesSelect] !== 'ALL' && !noVariantsFound && !noBodiesFound"
            :disabled="!formValues[motorPolicyModel.seriesSelect] && !vehicleBodiesList.length"
            v-bind="formInputData(motorPolicyModel.bodyTypeSelect)"
            error-behaviour="submit"
            :validation-messages="{ customRequired: validationMessages.requiredVehicleBodyType }"
            validation="customRequired"
            :validation-rules="{
              customRequired: ({ value }) => validateSelect(value, !formValues[motorPolicyModel.seriesSelect] && !vehicleBodiesList.length),
            }"
            @input="searchVehicleDetails"
            :options="vehicleBodiesList"
          />
        </div>
      </article>
    </div>

    <div v-if="showVehicleDetailsList">
      <hr />

      <CustomRadioInput
        :options="vehicleDetailsList"
        @on-checked="selectVehicleFromList"
        :default-selected="firstVehicleFromList"
        id="vehicle-selection-radio-group"
      >
        <template #footer>
          <div v-html="formInputData(motorPolicyModel.carNotFoundMessage).label"></div>
        </template>
      </CustomRadioInput>

      <p v-if="submissionError" class="error-message">{{ validationMessages.requiredVehicle }}</p>
    </div>

    <StepFooter v-bind="$props" :on-go-back="onGoBack" />
  </FormulateForm>
</template>

<script>
import CustomRadioInput from '@/components/feature/newmotorinsuranceform/shared/CustomRadioInput.vue';
import StepFooter from '@/components/feature/newmotorinsuranceform/shared/StepFooter.vue';
import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import {
  regoLookup,
  getVehicleYears,
  getVehicleMakesByYear,
  getVehicleModelsByYearAndMake,
  getVehicleVariantsByYearMakeModel,
  getVehicleBodiesByYearMakeModelVariant,
  getVehicleDetailsByYearMakeModelVariantBody,
} from '@/components/foundation/shared/apiservices/apiPolicyService';
import { formattedCarSpecs } from '@/components/feature/newmotorinsuranceform/services/text-transforms';
import { NEW_MOTOR_INSURANCE_MODULE, motorPolicyModel, validationMessages } from '@/components/feature/newmotorinsuranceform/services/mappings';

import { mapMutations, mapState } from 'vuex';
import ApiStatus from '@/models/foundation/forms/enums/apiStatus';
import { uniqBy } from 'lodash';

export default {
  name: 'FindYourCar',

  components: {
    StepFooter,
    CustomRadioInput,
  },

  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    onGoBack: {
      type: Function,
      required: true,
    },
    prefillData: {
      type: Object,
      required: false,
    },
  },

  mixins: [RACQFormsMixins],

  async created() {
    await this.fetchVehicleYearsList();

    if (this.viewModelData) {
      // check vuex model data and prefill if available

      await this.prefillFromViewModel();
    }
  },

  data() {
    return {
      motorPolicyModel,
      validationMessages,
      formValues: {
        nvic: null,
        vehicleRegistration: '',
        yearSelect: null,
        makeSelect: null,
        modelSelect: null,
        seriesSelect: null,
        bodyTypeSelect: null,
        glassesGuideVehicleDetails: null,
      },
      submissionError: false,
      regoLookupError: false,
      noVariantsFound: false,
      noBodiesFound: false,
      vehicleMakesList: [],
      vehicleModelsList: [],
      vehicleVariantsList: [],
      vehicleBodiesList: [],
      vehicleDetailsList: [],
      vehicleYearsList: [],
    };
  },

  watch: {
    vehicleBodiesList(val) {
      if (val.length === 1) {
        this.formValues['bodyTypeSelect'] = val[0].value;
        this.searchVehicleDetails(val[0].value);
      }
    },
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, ['steps', 'viewModelData']),

    formInputs() {
      return this.step.content.formInputs;
    },

    firstVehicleFromList() {
      if (this.formValues?.glassesGuideVehicleDetails) {
        return { ...this.formValues.glassesGuideVehicleDetails, value: this.formValues.nvic, selected: true };
      }

      if (this.vehicleDetailsList.length === 1) {
        return this.vehicleDetailsList[0];
      }

      return undefined;
    },

    showVehicleDetailsList() {
      if (!this.vehicleDetailsList.length) return false;
      else if (this.formValues.seriesSelect === 'ALL') return true;
      else if (this.formValues.bodyTypeSelect) return true;
      else if (this.noBodiesFound || this.noVariantsFound) return true;
      return false;
    },
  },

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, ['UPDATE_STEP_STATUS']),

    /**
     * Submit Find Your Car Form.
     *
     */
    submitFindCarForm() {
      this.submissionError = false;

      if (this.formValues['yearSelect'] === '1900') {
        // This will trigger Jeopardy as a vehicle older than 1960 is not allowed.
        return this.onSubmit({
          metadata: {},

          glassesGuideVehicleDetails: {
            manufacturingYear: 1900,
          },
        });
      }

      if (this?.firstVehicleFromList?.rawData?.nvic) {
        // Manual vehicle data exists, clear errors and submit
        this.submissionError = false;
        this.regoLookupError = false;

        this.selectVehicleFromList({ value: this.firstVehicleFromList.rawData.nvic });

        return this.onSubmit({
          glassesGuideVehicleDetails: this.formValues.glassesGuideVehicleDetails,
          vehicleRegistration: '',
          metadata: {
            foundVehicleManually: true,
          },
        });
      }

      if (!this.formValues.glassesGuideVehicleDetails && !this.formValues.vehicleRegistration) {
        this.submissionError = true;
        return;
      } else if (this.formValues.vehicleRegistration && !this.vehicleDetailsList.length) {
        return this.getVehicleFromRego();
      }

      // Manual vehicle data exists, clear errors and submit
      this.regoLookupError = false;

      return this.onSubmit({
        glassesGuideVehicleDetails: this.formValues.glassesGuideVehicleDetails,
        vehicleRegistration: '',
        metadata: {
          foundVehicleManually: true,
        },
      });
    },

    async fetchVehicleYearsList() {
      const vehicleYearsData = await getVehicleYears();

      this.vehicleYearsList = vehicleYearsData.map((d) => ({
        value: d.value,
        label: d.name,
      }));
    },

    /**
     * Select a vehicle from the custom radio input list.
     *
     * @param {any} data The selected vehicle input data
     */
    selectVehicleFromList(data) {
      if (this.vehicleDetailsList?.length) {
        const selected = this.vehicleDetailsList.find((v) => v.rawData.nvic === data.value);

        this.vehicleDetailsList = this.vehicleDetailsList.map((item) => ({
          ...item,
          selected: selected.id === item.id,
          value: item.id,
        }));

        if (selected?.rawData) {
          this.formValues.glassesGuideVehicleDetails = selected?.rawData;
          this.submissionError = false;
        }
      }
    },

    /**
     * Get a vehicle from rego number input, then submit the form.
     *
     */
    async getVehicleFromRego() {
      this.regoLookupError = false;

      try {
        const regoData = await regoLookup(this.formValues['vehicleRegistration']);

        if (!regoData) {
          this.regoLookupError = true;
          return;
        }

        this.onSubmit({
          ...this.formValues,
          glassesGuideVehicleDetails: regoData,
          metadata: { foundVehicleManually: false },
        });
        this.resetManualLookupInputs();
      } catch (e) {
        console.error('Rego lookup failed.', e);
        this.regoLookupError = true;
      }
    },

    /**
     * Search vehicle makes by year
     * @param {*} val
     */
    async searchVehicleMakesByYear(val) {
      if (!val || this.vehicleMakesList.length) {
        this.vehicleMakesList = [];
        this.vehicleModelsList = [];
        this.vehicleVariantsList = [];
        this.vehicleBodiesList = [];
        this.vehicleDetailsList = [];

        this.formValues['makeSelect'] = null;
        this.formValues['modelSelect'] = null;
        this.formValues['seriesSelect'] = null;
        this.formValues['bodyTypeSelect'] = null;
        this.formValues['glassesGuideVehicleDetails'] = null;
      }

      if (!this.formValues['yearSelect']) return;

      try {
        const vehicleMakesData = await getVehicleMakesByYear(this.formValues['yearSelect']);

        if (vehicleMakesData) {
          this.vehicleMakesList = vehicleMakesData.map(this.mapVehicleDataToUI);
        }
      } catch (e) {
        console.error('Get vehicle makes by year error.', e);
      }
    },

    /**
     * Search vehicle models by year and make
     *
     * @param {*} val
     */
    async searchModelsByYearAndMake(val) {
      if (!val || this.vehicleModelsList.length) {
        this.vehicleModelsList = [];
        this.vehicleVariantsList = [];
        this.vehicleBodiesList = [];
        this.vehicleDetailsList = [];

        this.formValues['modelSelect'] = null;
        this.formValues['seriesSelect'] = null;
        this.formValues['bodyTypeSelect'] = null;
        this.formValues['glassesGuideVehicleDetails'] = null;
      }

      if (!this.formValues['yearSelect'] && !this.formValues['makeSelect']) return;

      try {
        const vehicleModelsData = await getVehicleModelsByYearAndMake(this.formValues['yearSelect'], this.formValues['makeSelect']);

        if (vehicleModelsData) {
          this.vehicleModelsList = vehicleModelsData.map(this.mapVehicleDataToUI);
        }
      } catch (e) {
        console.error('Get vehicle models by year and make error.', e);
      }
    },

    /**
     *
     * @param {*} val
     */
    async searchVehicleVariants(val) {
      // reset flags when variant changed
      this.noVariantsFound = false;
      this.noBodiesFound = false;

      if (!val || this.vehicleVariantsList.length) {
        this.vehicleVariantsList = [];
        this.vehicleBodiesList = [];
        this.vehicleDetailsList = [];

        // this.formValues['modelSelect'] = null;
        this.formValues['seriesSelect'] = null;
        this.formValues['bodyTypeSelect'] = null;
        this.formValues['glassesGuideVehicleDetails'] = null;
      }

      const { yearSelect: year, makeSelect: make, modelSelect: model } = this.formValues;

      if (!year && !make && !model) return;

      try {
        const vehicleVariantsData = await getVehicleVariantsByYearMakeModel(year, make, model);

        if (vehicleVariantsData?.length) {
          const formattedVariants = vehicleVariantsData.map(this.mapVehicleDataToUI);
          this.vehicleVariantsList = [{ value: 'ALL', label: 'All vehicles' }, ...formattedVariants];
        }
        // Fetch vehicle details by make + year + model
        else {
          this.noVariantsFound = true;
          this.searchVehicleDetails();
        }
      } catch (e) {
        console.error('Get vehicle variants error.', e);
      }
    },

    async searchVehicleBodies(val) {
      if (!val || this.vehicleBodiesList.length) {
        this.vehicleBodiesList = [];
        this.vehicleDetailsList = [];
        this.formValues['glassesGuideVehicleDetails'] = null;
        this.formValues['bodyTypeSelect'] = null;
      }

      const { yearSelect: year, makeSelect: make, modelSelect: model, seriesSelect: variant } = this.formValues;

      if (val === 'ALL') {
        return this.searchVehicleDetails(val);
      }

      if (!year && !make && !model && !variant) return;

      try {
        const vehicleBodiesData = await getVehicleBodiesByYearMakeModelVariant(year, make, model, variant);

        if (vehicleBodiesData?.length) {
          this.vehicleBodiesList = vehicleBodiesData.map(this.mapVehicleDataToUI);
          this.noBodiesFound = false;
        }
        // Fetch vehicle details by make + year + model + body
        else {
          this.noBodiesFound = true;
          this.searchVehicleDetails();
        }
      } catch (e) {
        console.error('Get vehicle bodies error.', e);
      }
    },

    async searchVehicleDetails(val) {
      if (this.formValues.glassesGuideVehicleDetails) return;

      if (!val || this.vehicleDetailsList.length) {
        this.vehicleDetailsList = [];
      }

      const { yearSelect: year, makeSelect: make, modelSelect: model, seriesSelect: variant, bodyTypeSelect: body } = this.formValues;

      try {
        const vehicleDetailsData = await getVehicleDetailsByYearMakeModelVariantBody(year, make, model, variant || '', body || '');

        if (!vehicleDetailsData) return;

        this.vehicleDetailsList = uniqBy(
          vehicleDetailsData?.map((vehicle) => ({
            value: vehicle.nvic,
            label: formattedCarSpecs(vehicle),
            id: vehicle.code || vehicle.nvic,
            rawData: vehicle,
          })),
          'id'
        );
      } catch (e) {
        console.error('Get vehicle details error.', e);
      }
    },

    validateSelect(value, isDisabled) {
      if (isDisabled) return true;

      return !!value;
    },

    resetManualLookupInputs() {
      this.formValues = {
        ...this.formValues,
        yearSelect: null,
        makeSelect: null,
        modelSelect: null,
        seriesSelect: null,
        bodyTypeSelect: null,
      };
    },

    mapVehicleDataToUI(vehicleData) {
      return {
        value: vehicleData.code,
        label: vehicleData.description,
      };
    },

    async prefillFromViewModel() {
      const regoNumberFromQuote = this.viewModelData?.vehicleRegistration;

      if (regoNumberFromQuote && regoNumberFromQuote !== 'TBA') {
        return (this.formValues.vehicleRegistration = regoNumberFromQuote);
      }

      this.UPDATE_STEP_STATUS({ stepIndex: this.step.stepIndex, status: ApiStatus.LOADING });

      const {
        nvic,
        manufacturingYear: yearSelect,
        make: { description: makeSelect, code: makeCode },
        model: { description: modelSelect, code: modelCode },
        body: { description: bodyTypeSelect, code: bodyTypeCode },
        variant: { description: seriesSelect, code: seriesSelectCode },
      } = this.viewModelData?.glassesGuideVehicleDetails;

      this.formValues.nvic = nvic;

      //
      // TODO: If we have codes from the viewModel when doing fetching always use them
      // That way we can populate the select values with the description and use the
      // code for fetching.
      this.formValues.yearSelect = yearSelect;
      this.formValues.makeSelect = makeCode;
      this.formValues.modelSelect = modelCode;
      this.formValues.seriesSelect = seriesSelectCode;
      this.formValues.bodyTypeSelect = bodyTypeCode;

      this.searchVehicleMakesByYear(yearSelect);
      this.searchModelsByYearAndMake(makeSelect);
      this.searchVehicleVariants(modelSelect);
      this.searchVehicleBodies(seriesSelect);

      await this.searchVehicleDetails(bodyTypeSelect);
      await this.selectVehicleFromList({ value: nvic });

      this.UPDATE_STEP_STATUS({ stepIndex: this.step.stepIndex, status: ApiStatus.IDLE });
    },
  },
};
</script>
<style lang="scss">
#find-your-car-form {
  .formulate-input-element--vue-select {
    margin-left: 10.5rem;
    min-width: 20rem;
  }

  .formulate-input-element {
    margin-left: 0;
  }

  .formulate-input-element.formulate-input-element--text {
    @include respond-to(all-mobile) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 1rem;
    }
  }

  .find-your-car-btn {
    .formulate-input-wrapper,
    .formulate-input-element {
      margin-top: 0;
      display: flex;
      align-items: center;
    }

    .btn {
      padding: 0.35rem 1.5rem;
      margin: 0 0.25rem 0 1rem;

      @include respond-to(all-mobile) {
        margin-left: 0 !important;
      }
    }
  }

  .formulate-input-errors {
    margin-left: 8.85rem !important;

    @include respond-to(all-mobile) {
      margin-left: 0 !important;
    }
  }
}

.find-car-manually {
  padding: 2rem 0;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid $racq-pale-blue-border;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}
</style>
