<template>
  <div class="multi-step-form">
    <div class="container form-container px-0">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RacqForm',
  components: {},
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/css/components/forms/multi-step-form.scss';
</style>
