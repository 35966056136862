var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"step-content"},[_c('sc-rich-text',{attrs:{"field":_vm.fields.reasonForCancellationContent}})],1),_c('div',{staticClass:"form-elements-container"},[_c('FormulateForm',{on:{"submit":_vm.onSubmit},model:{value:(_vm.step1Values),callback:function ($$v) {_vm.step1Values=$$v},expression:"step1Values"}},[_c('racq-formulate-input',{key:"reasonForCancellation",attrs:{"options":_vm.getDropDownListItems(this.firstDropDownOptions),"name":"reasonForCancellation","type":"selectbox","placeholder":"Please select","validation":"required","inputclass":"","label":_vm.fields.reasonForCancellationLabel,"inputhaserrorsclass":"input-error","validation-messages":{
          required: _vm.fields.reasonForCancellationRequiredErrorMessage,
        },"experienceEditableValidationErrors":[_vm.fields.reasonForCancellationRequiredErrorMessage]}}),(this.step1Values.reasonForCancellation && this.secondDropDownOptions.data && this.secondDropDownOptions.data.length)?_c('racq-formulate-input',{key:"subReasonForCancellation",attrs:{"options":_vm.getDropDownListItems(this.secondDropDownOptions.data),"name":"subReasonForCancellation","type":this.step1Values.reasonForCancellation == this.vehicleNotRegistered || this.step1Values.reasonForCancellation == this.noLongerOwnVehicle //only show radio button if the reason is vehicle not registered or no longer own the vehicle
            ? 'radio'
            : 'selectbox',"placeholder":"Please select","validation":"required","label":this.secondDropDownOptions.label,"inputhaserrorsclass":"input-error","validation-messages":{
          required: this.secondDropDownOptions.requiredErrorMessage,
        }}}):_vm._e(),(this.step1Values.subReasonForCancellation && this.thirdDropDownOptions.data && this.thirdDropDownOptions.data.length)?_c('racq-formulate-input',{key:"anotherVehicle",attrs:{"options":_vm.getDropDownListItems(this.thirdDropDownOptions.data),"name":"anotherVehicle","type":"radio","placeholder":"Please select","validation":"required","label":this.thirdDropDownOptions.label,"inputhaserrorsclass":"input-error","validation-messages":{
          required: this.thirdDropDownOptions.requiredErrorMessage,
        }}}):_vm._e(),(this.step1Values.anotherVehicle && this.fourthDropDownOptions.data && this.fourthDropDownOptions.data.length)?_c('racq-formulate-input',{key:"changeVehicle",attrs:{"options":_vm.getDropDownListItems(this.fourthDropDownOptions.data),"name":"changeVehicle","type":this.fourthDropDownOptions.data.length > 2 ? 'selectbox' : 'radio',"placeholder":"Please select","validation":"required","label":this.fourthDropDownOptions.label,"inputhaserrorsclass":"input-error","validation-messages":{
          required: this.fourthDropDownOptions.requiredErrorMessage,
        }}}):_vm._e(),(_vm.showAdditionalComments())?_c('racq-formulate-input',{attrs:{"name":"additionalComments","type":"textarea","inputhaserrorsclass":"input-error","label":_vm.fields.additionalCommentLabel,"error-behavior":"live","validation":this.step1Values.reasonForCancellation == this.otherCancellationReason
            ? 'required|max:250,length|matches:/^[\\w\\-\\.\\s]*$/'
            : 'max:250,length|matches:/^[\\w\\-\\.\\s]*$/'}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showButtons),expression:"showButtons"}],staticClass:"form-buttons"},[_c('FormulateInput',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideButtons),expression:"!hideButtons"}],attrs:{"type":"button","name":"Cancel","input-class":"btn btn-secondary","outer-class":""},on:{"click":_vm.cancelForm}}),_c('FormulateInput',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideButtons),expression:"!hideButtons"}],attrs:{"type":"submit","name":"Next","input-class":"btn btn-primary","outer-class":""}}),_c('FormulateInput',{directives:[{name:"show",rawName:"v-show",value:(_vm.hideButtons),expression:"hideButtons"}],staticStyle:{"margin-left":"auto"},attrs:{"type":"button","name":"Edit","input-class":"btn btn-primary btn-edit","outer-class":""},on:{"click":_vm.onEdit}})],1)],1),(!_vm.showButtons)?_c('change-vehicle-tile-insurance',{attrs:{"fields":_vm.fields}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }