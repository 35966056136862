const priceReasonSitecoreId = '00344377-2ef7-43d3-8244-c7d395858439';
const vehicleNotRegisteredSitecoreId = '79d1ecdc-58f4-47a9-9254-84fc7d70f4f9';
const noLongerOwnVehicleSitecoreId = 'c5177c29-44d8-4a9c-9a4e-5362f9540abe';
const noLongerRideSitecoreId = '484f07a8-4a89-4ccf-bd59-58ae31ad416f';
const manufacturerSitecoreId = '942d20a8-10dc-4e56-8681-ca4af7ed8df0';
const otherSitecoreId = '9124772f-d75d-4f1d-aeaf-a47c4665fa2f';

export const reasonCodes = {
  priceReasonSitecoreId,
  vehicleNotRegisteredSitecoreId,
  noLongerOwnVehicleSitecoreId,
  noLongerRideSitecoreId,
  manufacturerSitecoreId,
  otherSitecoreId,
};
