import * as ApiConstant from './apiConstant';
import * as apiServiceBase from './apiServiceBase';

const updateFloodCycloneDetails = async function (quoteSessionId, updateRequest) {
  const response = await apiServiceBase.HttpPost(`${ApiConstant.updateFloodCycloneDetails.replace('{0}', quoteSessionId)}`, updateRequest);
  return response;
};

const getIsCycloneCovered = async function (request) {
  const response = await apiServiceBase.HttpPost(`${ApiConstant.getIsCycloneCovered}`, request);
  return response;
};

export const cycloneInsuranceApiService = {
  updateFloodCycloneDetails,
  getIsCycloneCovered,
};
