<template>
  <div class="steps-aside">
    <div>
      <ul>
        <li class="step-item" v-for="(step, index) in filteredSteps" :key="step.stepNo" :step="step">
          <a href="#" class="scrollto" data-scrollto="Panel_GetStarted">
            <span class="step-inner">
              <span class="step-line-separator" v-if="!(index === filteredSteps.length - 1)"></span>
              <span class="step-bar-icon" v-bind:class="getClassName(step)">{{ step.stepNo }} </span>
            </span>
            <span class="step-text">{{ step.stepTitle }} </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormStepsSidePanel',
  components: {},
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    getClassName(step) {
      return {
        'step--complete': step.stepState == 'completed',
        'step--active': step.stepState == 'active',
        '': step.stepState == 'disabled',
      };
    },
  },
  computed: {
    filteredSteps() {
      return this.steps.filter((s) => !s.hideFromNavigation);
    },
  },
};
</script>
