import * as ApiConstant from './apiConstant';
import * as apiServiceBase from './apiServiceBase';

export async function getMotorInsurancePolicies() {
  return await apiServiceBase.HttpGet(`${ApiConstant.getSelectableMotorInsurancePolicies}`);
}

export async function getAllInsurancePolicies() {
  return await apiServiceBase.HttpGet(`${ApiConstant.getInsurancePolicies}`);
}

// https://pj336-dev-s10-form-racq.racqgroup.local/formsapi/v1/vehicle/getvehiclebyrego?rego=DOB33
export async function regoLookup(rego) {
  if (!rego) return;

  return await apiServiceBase.HttpGet(`${ApiConstant.getVehicleByRego}?rego=${rego}`);
}

// https://pj336-dev-s10-form-racq.racqgroup.local/formsapi/v1/vehicle/getmakes?year=2020
export async function getVehicleMakesByYear(year) {
  if (!year) return;

  return await apiServiceBase.HttpGet(`${ApiConstant.getVehiclesByMake}?year=${year}`);
}

// https://pj336-dev-s10-form-racq.racqgroup.local/formsapi/v1/vehicle/getmodels?year=2021&make=FORD
export async function getVehicleModelsByYearAndMake(year, make) {
  if (!year || !make) return;

  return await apiServiceBase.HttpGet(`${ApiConstant.getVehicleModels}?year=${year}&make=${make}`);
}

// https://pj336-dev-s10-form-racq.racqgroup.local/formsapi/v1/vehicle/getvariants?year=2021&make=MERE&model=GLA
export async function getVehicleVariantsByYearMakeModel(year, make, model) {
  if (!year || !make || !model) return;

  return await apiServiceBase.HttpGet(`${ApiConstant.getVehicleVariants}?year=${year}&make=${make}&model=${model}`);
}

// https://pj336-dev-s10-form-racq.racqgroup.local/formsapi/v1/vehicle/getbodies?year=2021&make=MERE&model=GLA&variant=35%204MATIC
export async function getVehicleBodiesByYearMakeModelVariant(year, make, model, variant = '') {
  if (!year || !make || !model) return;

  return await apiServiceBase.HttpGet(`${ApiConstant.getVehicleBodies}?year=${year}&make=${make}&model=${model}&variant=${variant}`);
}

// https://pj336-dev-s10-form-racq.racqgroup.local/formsapi/v1/vehicle/getvehicledetails?year=2021&make=MERE&model=GLA&variant=35%204MATIC&body=WAGON
// Note: An empty variant and body string searches all vehicle bodies
export async function getVehicleDetailsByYearMakeModelVariantBody(year, make, model, variant = '', body = '') {
  if (!year || !make || !model) return;

  return await apiServiceBase.HttpGet(
    `${ApiConstant.getVehicleDetails}?year=${year}&make=${make}&model=${model}&variant=${variant === 'ALL' ? '' : variant}&body=${body}`
  );
}

// https://pj336-dev-s10-form-racq.racqgroup.local/formsapi/v1/vehicle/getvehiclebynvic?nvic=0A7F21
export async function getVehicleByNVIC(nvic) {
  if (!nvic) return;

  return await apiServiceBase.HttpGet(`${ApiConstant.getVehiclesByNVIC}?nvic=${nvic}`);
}

export async function getVehicleYears() {
  return await apiServiceBase.HttpGet(ApiConstant.getVehicleYears);
}

/**
 * POST form data to Business Rules Engine
 * @param {*} model
 * @returns
 */
export async function applyBusinessRules(model) {
  return await apiServiceBase.HttpPost(ApiConstant.applyBusinessRulesUrl, model);
}

/**
 * Withdraw quote session.
 *
 * @param {String} quoteSessionId
 * @returns
 */
export async function withdrawQuoteSession(quoteSessionId) {
  return await apiServiceBase.HttpGet(`${ApiConstant.withdrawQuoteSession}?quoteSessionId=${quoteSessionId}`);
}

/**
 * Save the Fat Zebra payment receipt to Policy Centre.
 *
 * @returns
 */
export function savePaymentReceipt({ quoteSessionId, paymentReceiptNumber }) {
  return apiServiceBase.HttpPost(`${ApiConstant.savePaymentReceiptUrl}?quoteSessionId=${quoteSessionId}&paymentReceiptNumber=${paymentReceiptNumber}`);
}

/**
 * Bind the work order ???
 * @param {*} data
 *
 * @returns
 */
export function bindQuoteSession({ quoteSessionId }) {
  return apiServiceBase.HttpPost(`${ApiConstant.bindQuoteSessionUrl}?paymentReceiptNumber=${quoteSessionId}`);
}

/**
 * Process Fat Zebra payment.
 *
 * @param {*} options
 */
export async function processPaymentResponse({ quoteSessionId, policyNumber, cardToken, amount }) {
  return await apiServiceBase.HttpPost(`${ApiConstant.processPaymentResponseUrl}?quoteSessionId=${quoteSessionId}&policyNumber=${policyNumber}`, {
    CardToken: cardToken,
    Amount: amount,
    saveInterimHeader: true,
    interimHeaderComments: "Completed by Add Vehicle functionality."
  });
}
