import Vuex from 'vuex';
import Vue from 'vue';
import cancelRoadsideModule from './modules/cancelRoadside';
import cancelInsuranceModule from './modules/cancelInsurance';
import callBackFormModule from './modules/callBackForm';
import identityModule from './modules/identity';
import renewalOfferModule from './modules/renewalOffer';
import newMotorInsuranceModule from './modules/newMotorInsurance';
import addToCoverFormModule from './modules/addToCoverForm';
import cycloneInsuranceModule from './modules/cycloneInsurance';
Vue.use(Vuex);

const formDataStore = new Vuex.Store({
  state: {},
  mutations: {},
  modules: {
    cancelRoadsideModule: cancelRoadsideModule,
    cancelInsuranceModule: cancelInsuranceModule,
    renewalOffer: renewalOfferModule,
    identityModule: identityModule,
    callBackFormModule: callBackFormModule,
    addToCoverFormModule: addToCoverFormModule,
    cycloneInsuranceModule: cycloneInsuranceModule,
    newMotorInsuranceModule: newMotorInsuranceModule,
  },
});

export default formDataStore;
