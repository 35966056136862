import * as ApiConstant from './apiConstant';
import * as apiServiceBase from './apiServiceBase';

export async function getParkingLocationRefData() {
  return await apiServiceBase.HttpGet(`${ApiConstant.getParkingLocation}`);
}

export async function getFinanceTypesRefData() {
  return await apiServiceBase.HttpGet(`${ApiConstant.getFinanceTypes}`);
}

export async function getGendersRefData() {
  return await apiServiceBase.HttpGet(`${ApiConstant.getGenders}`);
}

export async function getCombinedAccessoriesListData() {
  return await apiServiceBase.HttpGet(`${ApiConstant.getCombinedAccessoriesList}`);
}

export async function getVehicleBusinessUseTypes() {
  return await apiServiceBase.HttpGet(`${ApiConstant.getVehicleBusinessUseTypesList}`);
}

export async function getPersonTitles() {
  return await apiServiceBase.HttpGet(`${ApiConstant.getPersonTitles}`);
}

export async function getYearsRatingOneHeld() {
  return await apiServiceBase.HttpGet(`${ApiConstant.getYearsRatingOneHeld}`);
}

export async function getExcessAmounts(quoteType = '', effectiveDate = '') {
  if (!quoteType) {
    throw new Error('No quote type specified');
  }

  if (!effectiveDate) {
    throw new Error('No effective date specified');
  }

  return await apiServiceBase.HttpGet(`${ApiConstant.getExcess}?quoteType=${quoteType}&effectiveDate=${effectiveDate}`);
}

/**
 * Get a range of min and max values based on a vehicles market value.
 *
 * @param {*} marketValue
 * @param {*} quoteType
 *
 * @returns
 */
export async function getVehicleAgreedValueRange(marketValue = 0, quoteType = '') {
  if (!marketValue) {
    throw new Error('No market value specified');
  }

  if (!quoteType) {
    throw new Error('No quote type specified');
  }

  return await apiServiceBase.HttpGet(`${ApiConstant.getVehicleAgreedValueRange}?marketValue=${marketValue}&quoteType=${quoteType}`);
}

/**
 * Get a list of suburbs that match a postcode or suburb query
 *
 * @param {String} query Either a postcode or suburb name
 *
 * @returns
 */
export async function getSuburbList(query) {
  return await apiServiceBase.HttpGet(`${ApiConstant.getInsuranceRiskSuburbs}?query=${query}`);
}

export async function getNumOfStoreysRefData(buildingType) {
  return await apiServiceBase.HttpGet(`${ApiConstant.getNumOfStoreys.replace('{0}', buildingType)}`);
}

export async function getLowestOccupiedFloorRefData(buildingType) {
  return await apiServiceBase.HttpGet(`${ApiConstant.getLowestOccupiedFloor.replace('{0}', buildingType)}`);
}

export async function getPrimaryConstructionMaterials(quoteType) {
  return await apiServiceBase.HttpGet(`${ApiConstant.getPrimaryConstructionMaterials.replace('{0}', quoteType)}`);
}

export async function getRoofTypeMaterials(quoteType) {
  return await apiServiceBase.HttpGet(`${ApiConstant.getRoofTypeMaterials.replace('{0}', quoteType)}`);
}

export async function getBuildingTypes(quoteType) {
  return await apiServiceBase.HttpGet(`${ApiConstant.getBuildingTypes.replace('{0}', quoteType)}`);
}
