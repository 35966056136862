import { render, staticRenderFns } from "./FatZebraIframe.vue?vue&type=template&id=6227796e&scoped=true&"
import script from "./FatZebraIframe.vue?vue&type=script&lang=js&"
export * from "./FatZebraIframe.vue?vue&type=script&lang=js&"
import style0 from "./FatZebraIframe.vue?vue&type=style&index=0&id=6227796e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6227796e",
  null
  
)

export default component.exports