<template>
  <aside class="new-motor-insurance-form-steps-aside">
    <h3 id="form-name" v-text="formTitle" />

    <div class="steps-flex">
      <span class="steps-progress">{{ activeStep }} of {{ steps.length }}</span>

      <ul class="new-motor-insurance-form-steps-aside__list">
        <li :class="`step-item scrollspy-step-item-id__${step.id}`" v-for="(step, index) in steps" :key="step.stepTitle" :step="step">
          <button class="scrollto step-item__link" @click="scrollToElement(`#${step.id}`)" :data-scroll-to="step.id">
            <span class="step-inner">
              <span class="step-line-separator" v-if="!(index === steps.length - 1)"></span>
              <span class="step-bar-icon" :class="getClassName(step)">{{ step.stepNo }} </span>
            </span>
            <span class="step-text" v-text="step.stepTitle" />
          </button>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import { StepStates } from '@/models/foundation/forms/enums/stepStates';
import { scrollToElement } from '@/components/foundation/shared/utils/dom-helpers';

export default {
  name: 'MotorInsuranceQuoteStepTracker',
  components: {},
  props: {
    steps: {
      type: Array,
      default: () => [],
    },

    formTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    scrollToElement,

    getClassName(step) {
      return {
        'step--complete': step.stepCompleted,
        'step--active': step.stepState === StepStates.ACTIVE,
        'step--active-complete': step.stepState === StepStates.ACTIVE_COMPLETED,
        'step-disabled': step.stepState === StepStates.DISABLED,
      };
    },
  },

  computed: {
    activeStep() {
      return this.steps.find((step) => step.stepState.includes('active'))?.stepNo;
    },
  },
};
</script>

<style lang="scss" scoped>
.new-motor-insurance-form-steps-aside {
  padding: 50px 26px;
  width: 296px;

  @include respond-to(all-mobile) {
    padding: 2rem 0 0;
    width: auto;
  }

  @include respond-to(desktop) {
    position: sticky;
    height: 100%;
    top: 0;
  }
}

.new-motor-insurance-form-steps-aside__list {
  padding: 0;

  @include respond-to(all-mobile) {
    margin-bottom: 0;
  }

  .step-item {
    @include respond-to(all-mobile) {
      display: table-cell;
      padding-left: 0;
    }
  }
}

.step-item__link {
  background: transparent;
  outline: 0 !important;
  border: none;
  color: $racq-navy;
  display: flex;
  flex-direction: row;

  .step-inner {
    display: block;
    padding-left: 0.5rem;
    margin-bottom: 1.5rem;
    position: relative;

    @include respond-to(all-mobile) {
      margin-bottom: 0;
    }
  }

  .step-line-separator {
    display: block;
    width: 2px;
    height: 42px;
    position: absolute;
    top: 29px;
    left: 23px;
    background: $racq-navy-30p;
    overflow: hidden;

    @include respond-to(all-mobile) {
      width: 40px;
      height: 2px;
      top: 15px;
      left: 30px;
    }
  }

  .step-bar-icon {
    background-image: url('~@/assets/img/forms/form-step-ico.colors-racqnavy.svg');
    background-repeat: no-repeat;
    display: block;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    padding-top: 2px;
    line-height: 26px;
    text-align: center;
    background-color: $racq-grey-background;
    color: $racq-navy-30p;
    color: $racq-navy;
    overflow: hidden;
    z-index: 2;
    font-family: $firasans-regular-font;
    font-weight: bold;

    @include respond-to(all-mobile) {
      background: center;
      background-image: url('~@/assets/img/forms/form-step-ico-small.colors-racqnavy.svg');
      background-repeat: no-repeat;
      width: 2rem;
      height: 2rem;
      color: transparent;
    }

    &.step--active {
      background-image: url('~@/assets/img/forms/form-step-ico-active-complete-unobserved.svg');
      color: $racq-navy;

      &.scrollspy-active-observed {
        color: #fff;
        background-image: url('~@/assets/img/forms/form-step-ico-active.colors-racqnavy.svg');
      }

      @include respond-to(all-mobile) {
        color: transparent;
        background-image: url('~@/assets/img/forms/form-step-ico-active-small.svg');
      }
    }

    &.step--active-complete {
      background-image: url('~@/assets/img/forms/form-step-ico-complete-active.svg');
      color: transparent;

      @include respond-to(all-mobile) {
        background-image: url('~@/assets/img/forms/form-step-ico-active-small.svg');
      }
    }

    &.step--complete {
      color: transparent;
      background-image: url('~@/assets/img/forms/form-step-ico-complete.svg');

      &.scrollspy-active-observed {
        background-image: url('~@/assets/img/forms/form-step-ico-complete-active.svg');
      }

      @include respond-to(all-mobile) {
        background-image: url('~@/assets/img/forms/form-step-ico-small.complete.svg');
      }
    }
  }

  // Set the line separator before any "complete" steps to a darker navy color
  .step-line-separator:has(+ .step--complete) {
    background: $racq-navy;
  }

  .step-text {
    font-family: $firasans-regular-font;
    font-weight: 600;
    min-width: 10rem;
    max-width: 12rem;
    display: inline-block;
    margin-left: 0.5rem;
    text-align: left;
    font-size: 1rem;
    padding-top: 0.25rem;

    @include respond-to(all-mobile) {
      display: none;
      margin-left: 0;
    }
  }
}

.steps-flex {
  @include respond-to(all-mobile) {
    display: flex;
    align-items: center;
    flex-direction: column !important;
    padding-top: 1rem;
  }

  .steps-progress {
    display: none;
    font-family: $firasans-regular-font;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.11111;
    color: $racq-navy;
    margin: 0;

    @include respond-to(all-mobile) {
      display: block;
    }
  }
}

#form-name {
  color: $racq-navy;
  -webkit-font-smoothing: antialiased;
  font-family: $firasans-regular-font;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1;
  display: none;

  @include respond-to(all-mobile) {
    display: block;
  }
}
</style>
