export const StepStates = Object.freeze({
  COMPLETED: 'completed',
  ACTIVE: 'active',
  ACTIVE_COMPLETED: 'active-completed',
  DISABLED: 'disabled',
  DISABLED_UNEDITABLE: 'disabled-uneditable',
  EDITING: 'editing',
  LOADING: 'loading',
  UNTOUCHED: '',
});
