<template>
  <FormulateForm class="form-step-container change-effective-start-date-form">
    <div class="form-step center-aligned" data-tooltip-wrapper>
      <section class="step-title">
        <h3 class="text-white"><sc-text :field="step.stepTitle" /></h3>
      </section>

      <div class="step-side-icon"></div>

      <div class="form-step-content">
        <div v-show="showCurtain" class="form-step-curtain">
          <div v-show="formStatus === 'FETCHING'" class="form-loader"></div>
        </div>
        <section class="step-content">
          <div class="bottom-margin">
            <div class="text-container">
              <sc-rich-text :field="step.stepContent" />

              <FormulateInput
                v-bind="formInputData(motorPolicyModel.changeEffectiveDate)"
                v-model="changeEffectiveDate"
                :disabled-dates="disabledDates"
                :validation-rules="validationRules"
                :validation-messages="{
                  isValidDate: validationMessages.dateThirtyDaysFromToday,
                }"
                validation="required|isValidDate"
                error-behaviour="submit"
                validation-name="Effective start date"
                format="dd/MM/yyyy"
              />
            </div>
          </div>
        </section>

        <StepFooter
          v-bind="$props"
          :on-go-back="onCancelForm"
          :on-submit="submitForm"
          :disable-next="!formIsValid"
          :prelim-step="true"
          :form-status="formStatus"
          @on-edit="editStep"
        />
      </div></div
  ></FormulateForm>
</template>

<script>
import RACQFormsMixin from '@/components/foundation/forms/mixins/RACQFormsMixins';
import StepFooter from '@/components/feature/newmotorinsuranceform/shared/StepFooter.vue';
import { NEW_MOTOR_INSURANCE_MODULE, motorPolicyModel } from '@/components/feature/newmotorinsuranceform/services/mappings';
import { scrollToElement } from '@/components/foundation/shared/utils/dom-helpers';

import { mapMutations, mapState } from 'vuex';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { validationMessages } from '@/components/feature/newmotorinsuranceform/services/mappings';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export default {
  name: 'ChangechangeEffectiveDate',

  components: {
    ScText: Text,
    ScRichText: RichText,
    StepFooter,
  },

  mixins: [RACQFormsMixin],

  props: {
    step: {
      type: Object,
      default: () => ({
        fields: [],
      }),
    },
    onSubmitForm: {
      type: Function,
      default: () => undefined,
    },
    onCancelForm: {
      type: Function,
      default: () => undefined,
    },
    goNextStep: {
      type: Function,
      required: true,
    },
  },

  created() {
    if (this.viewModelData?.changeEffectiveDate) {
      this.changeEffectiveDate = this.viewModelData.changeEffectiveDate;
    }

    const discriminator = this?.selectedPolicy?.currentTermPaymentDetails?.discriminator;
    const isDirectDebit = discriminator === 'NonResponsivePaymentDetails';

    if (isDirectDebit) {
      this.REMOVE_PAYMENT_STEP();
    }
  },

  data() {
    return {
      validationMessages,
      motorPolicyModel,
      formStatus: 'IDLE',
      changeEffectiveDate: null,
    };
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, ['jeopardyActive', 'viewModelData', 'selectedPolicy']),

    disabledDates() {
      return {
        to: new Date(dayjs().subtract(1, 'days')),
        from: new Date(dayjs().add(29, 'days')),
      };
    },

    validationRules() {
      return {
        isValidDate: ({ value }) => this.checkDateIsValid(value),
      };
    },

    formIsValid() {
      return this.changeEffectiveDate && this.checkDateIsValid(this.changeEffectiveDate);
    },

    showCurtain() {
      return (this.jeopardyActive && this.formStatus !== 'EDITING') || this.formStatus === 'FETCHING';
    },
  },

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, ['UPDATE_JEOPARDY_ACTIVE', 'REMOVE_PAYMENT_STEP']),

    checkDateIsValid(value) {
      // bail early because "required" validation handles empty input form submissions
      if (!value) return true;

      return dayjs(value).isBetween(new Date(dayjs().subtract(1, 'days')), new Date(dayjs().add(29, 'days')));
    },

    async submitForm() {
      if (this.formIsValid) {
        this.formStatus = 'FETCHING';

        const response = await this.onSubmitForm({
          metadata: { stepId: 'changeEffectiveDate' },
          formData: {
            ...this.viewModelData,
            changeEffectiveDate: this.changeEffectiveDate,
          },
        });

        if (response?.data?.jeopardies?.length) {
          return this.showJeopardy();
        }

        if (response?.success) {
          this.goNextStep();
        }
      }

      this.formStatus = 'IDLE';
    },

    showJeopardy() {
      this.UPDATE_JEOPARDY_ACTIVE({ jeopardyActive: true });
      this.formStatus = 'ERROR';
      scrollToElement('#jeopardy-container', 100);
    },

    editStep() {
      this.formStatus = 'EDITING';
    },
  },
};
</script>

<style lang="scss">
.new-motor-insurance-form .change-effective-start-date-form {
  .forms-input-wrapper .formulate-input-wrapper .formulate-input-label {
    width: 100%;
  }

  .forms-input-wrapper {
    margin-bottom: 4rem;
  }

  @media (min-width: $breakpoint-lg) {
    .formulate-input-label.formulate-input-label--before {
      container-type: size;
    }

    .forms-input-wrapper .formulate-input-wrapper {
      .formulate-input-element.formulate-input-element--datepicker {
        margin-left: 2rem;
      }

      .formulate-input-label {
        width: 30%;
      }
    }
  }
}
</style>
