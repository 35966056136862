import * as ApiConstant from './apiConstant';
import * as apiServiceBase from './apiServiceBase';

const getAssistanceProduct = async function (policyId) {
  const response = await apiServiceBase.HttpGet(`${ApiConstant.getAssistanceProduct.replace('{0}', policyId)}`);
  return response;
};

const setAssistanceAutoRenewFlag = async function (policyId, model) {
  const response = await apiServiceBase.HttpPut(`${ApiConstant.setAssistanceAutoRenewFlag.replace('{0}', policyId)}`, model);
  return response;
};

const isEligibilityForCancellation = async function (policyId, model) {
  const response = await apiServiceBase.HttpPost(`${ApiConstant.isEligibilityForCancellation.replace('{0}', policyId)}`, model);
  return response;
};
const getInsuranceProduct = async function (policNumber) {
  const response = await apiServiceBase.HttpGet(`${ApiConstant.getInsuranceProduct.replace('{0}', policNumber)}`);
  return response;
};

const isInsuranceEligibleForCancellation = async function (policNumber, model) {
  const response = await apiServiceBase.HttpPost(`${ApiConstant.isInsuranceEligibleForCancellation.replace('{0}', policNumber)}`, model);
  return response;
};

const isCancellationDateEligible = async function (model) {
  const response = await apiServiceBase.HttpPost(`${ApiConstant.isCancellationDateEligible}`, model);
  return response;
};

const getFinancialInstitutionFromBsb = async function (bsb) {
  const response = await apiServiceBase.HttpGet(`${ApiConstant.getFinancialInstitutionFromBsb.replace('{0}', bsb)}`);
  return response;
};

const submitCallBackDetails = async function (policyId, model) {
  const response = await apiServiceBase.HttpPost(`${ApiConstant.submitCallBackDetails.replace('{0}', policyId)}`, model);
  return response;
};

const cancelInsurance = async function (model) {
  const response = await apiServiceBase.HttpPost(`${ApiConstant.cancelInsurance}`, model);
  return response;
};

export const productApiService = {
  getAssistanceProduct,
  setAssistanceAutoRenewFlag,
  isEligibilityForCancellation,
  getInsuranceProduct,
  isInsuranceEligibleForCancellation,
  getFinancialInstitutionFromBsb,
  submitCallBackDetails,
  isCancellationDateEligible,
  cancelInsurance,
};
