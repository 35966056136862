import { renewalOfferApiService } from '../../../foundation/shared/apiservices/apiRenewalOfferService';
export default {
  namespaced: true,
  state: {
    insurancePolicy: null,
    email: null,
    emailReceipt: false,
    hasJeopardy: false,
  },
  mutations: {
    loadPolicyFailed(state, error) {
      error && (state.hasJeopardy = true);
    },
    populateInsurancePolicy(state, insurancePolicy) {
      // set the inital value for renewalPreference otherwise vuex won't track Undefined value
      // this property is used for state management only. It's unrelated to futureRenewalPreference returned by OSAPI
      if (insurancePolicy.renewalPreference == null) {
        insurancePolicy.renewalPreference = false;
      }

      if (insurancePolicy.renewalTermAcceptance == null) {
        insurancePolicy.renewalTermAcceptance = false;
      }

      // go to jeopardy screen if there is previous outstanding payments from a previous term
      var hasOverduePayment = insurancePolicy?.status === 'Cancelled' && insurancePolicy?.state.isOverdue === true;
      if (hasOverduePayment) {
        state.hasJeopardy = true;
      }
      state.insurancePolicy = insurancePolicy;
    },
    populateOwnerDetails(state, email) {
      state.email = email;
    },
    updateInsurancePolicy(state, setRenewalOfferRequest) {
      state.insurancePolicy.renewalTermAcceptance = setRenewalOfferRequest.offerAccepted;
      state.insurancePolicy.renewalPreference = setRenewalOfferRequest.renewalPreference;
      state.insurancePolicy.rejectionReason = setRenewalOfferRequest.offerRejectionReason;
    },
    updateEmailReceipt(state, emailReceiptSelected) {
      state.emailReceipt = emailReceiptSelected;
    },
  },
  actions: {
    fetchInsurancePolicy({ commit }, policyId) {
      renewalOfferApiService
        .getRenewalPolicyDetails(policyId)
        .then((result) => {
          commit('populateInsurancePolicy', result.insurancePolicy);
          commit('populateOwnerDetails', result.email);
        })
        .catch((error) => {
          commit('loadPolicyFailed', error);
        });
    },
    async setRenewalOfferPreference({ commit }, setRenewalOfferRequest) {
      await renewalOfferApiService.setRenewalOfferPreference(setRenewalOfferRequest).then((result) => {
        if (result) {
          commit('updateInsurancePolicy', setRenewalOfferRequest);
        }
      });
    },
    async reinstatePolicy({ commit }, setRenewalOfferRequest) {
      await renewalOfferApiService.reinstatePolicy(setRenewalOfferRequest.policyNumber).then(() => {
        commit('updateInsurancePolicy', setRenewalOfferRequest);
      });
    },
  },
};
