import { StepVisibility } from '@/models/foundation/forms/enums';
import formAnalyticsModel from '@/models/foundation/forms/analytics/formAnalyticsModel';
import { apiIdentityService } from '@/components/foundation/shared/apiservices/apiIdentityService';
import { isDevEnv } from '../../shared/utils/dom-helpers';
import { validationMessages } from '@/components/feature/newmotorinsuranceform/services/mappings';

let RACQFormsMixin = {
  computed: {
    isExperienceEditorRunning() {
      return this.$jss.sitecoreContext().pageEditing === true;
    },
    isDev() {
      return isDevEnv();
    },
  },

  data() {
    return {
      validationMessages,
      isCancelEventLoaded: false,
      hideButtons: false,
    };
  },

  methods: {
    addCancelFormEvent() {
      this.$root.$on('cancel-form', () => {
        this.getSelfServiceUrl().then((url) => {
          window.location.href = url;
        });
      });
    },
    async getSelfServiceUrl() {
      return await apiIdentityService.getSelfServiceAccountOverviewPage();
    },
    cancelForm() {
      this.$root.$emit('cancel-form'); //redirect to account overview page
    },
    getFieldValue(label, value, text) {
      return {
        label: label,
        value: value,
        text: text,
      };
    },
    showJeopardy(jpData) {
      // displayCode, jpCode, additionalComments, stepDetails){
      this.jpcode = jpData.jpCode;
      this.displayCode = jpData.displayCode;
      this.additionalComments = jpData.additionalComments;
      this.stepDetails = jpData.stepDetails;
      this.cancellationReasons = jpData.cancellationReasons;
      this.formName = jpData.formName;
      this.moveToStep('stepjeopardy', true);
      this.reloadSteps += 1;
    },

    getAnalyticsBaseModel(step, isLastStep) {
      let formAnalyticsModelDeepCopy = JSON.parse(JSON.stringify(formAnalyticsModel));
      const lastStep = isLastStep || step?.isLastStep;

      formAnalyticsModelDeepCopy.event = lastStep ? 'form.completes' : 'form.steps';
      formAnalyticsModelDeepCopy.form.name = this.formTitle;
      formAnalyticsModelDeepCopy.form.stepName = this.getStepDetails(step, lastStep);

      if (!formAnalyticsModelDeepCopy.form.productCategory && !formAnalyticsModelDeepCopy.form.productName) {
        formAnalyticsModelDeepCopy.form.productCategory = step?.productCategory;
        formAnalyticsModelDeepCopy.form.productName = step?.productName;
      }

      if (lastStep) {
        formAnalyticsModelDeepCopy.form.amount = step?.amount;
        formAnalyticsModelDeepCopy.form.termEndDate = step?.termEnd;
      }

      return formAnalyticsModelDeepCopy;
    },

    // Get's the model for analytics
    getAnalyticsModel(cancelData, productCategory, productName = '', step) {
      let formAnalyticsModel = this.getAnalyticsBaseModel(step);
      formAnalyticsModel.form.productCategory = productCategory;

      if (productName) {
        formAnalyticsModel.form.productName = productName;
      }

      if (cancelData && cancelData.length) {
        formAnalyticsModel.form.cancelReason = '';

        cancelData.forEach(function (elem) {
          formAnalyticsModel.form.cancelReason += `${elem.label}: ${elem.text}`;
          formAnalyticsModel.form.cancelReason += '|';
        });
      }

      return formAnalyticsModel;
    },

    // Raise an event to analytics
    addAnalyticsFormData(cancelData, productCategory, productName = '', step, event = '') {
      const analyticsData = this.getAnalyticsModel(cancelData, productCategory, productName, step);
      analyticsData.event = event || (step || cancelData ? 'form.steps' : 'form.starts');

      this.$root.$emit('add-data-analytics', analyticsData);
    },

    getStepDetails(step, lastStep) {
      if (!step && !this.step) return;

      const title = lastStep ? 'Complete' : 'Step';

      return step ? `${title}: ${step?.stepTitle}: ${step?.stepNo}` : `${title}: ${this.step.stepTitle}: ${this.step.stepNo}`;
    },

    getDropDownListItems(sourceData, useListLabel = false) {
      if (sourceData && sourceData.length) {
        let options = [];

        sourceData.forEach((element) => {
          options.push({
            value: useListLabel ? element.fields.listValue.value : element.id,
            label: useListLabel ? element.fields.listLabel.value : element.fields.listValue.value,
          });
        });

        return options;
      }
    },

    addDataToAnalytics(step) {
      let formAnalyticsModel = this.getAnalyticsBaseModel(step);

      formAnalyticsModel.event = 'form.starts';

      if (step?.productCategory && step?.productName) {
        formAnalyticsModel.form.productCategory = step?.productCategory;
        formAnalyticsModel.form.productName = step?.productName;
      }

      this.$root.$emit('add-data-analytics', formAnalyticsModel);
    },

    moveToStep(toStep, hidePreviousSteps) {
      this.$refs[this.currentStep.stepControlName].moveAway(hidePreviousSteps);
      this.$refs[this.currentStep.stepChildControlName].hideButtons = true;

      this.$refs[toStep].moveInto();

      const selfStep = this;

      // Trying to understand and refactor and make this better but not 100% how it works (21.11.2023)
      this.steps.forEach((value, index) => {
        if (hidePreviousSteps) {
          value.hideFromNavigation = true;
          this.reloadSteps += 1;

          if (value.stepControlName !== toStep) {
            selfStep.$refs[value.stepControlName].moveAway(hidePreviousSteps);
          }
        }

        if (value.stepControlName === toStep) {
          if (value.stepControlName != 'stepjeopardy' && !value.doNotSendAnalysis) {
            // do not send event to analytics for jeopardy event
            this.addDataToAnalytics(value, index == selfStep.steps.length - 2);
          }

          this.currentStep.stepState = 'completed';
          value.stepState = 'active';
          this.currentStep = value;

          this.$refs[this.currentStep.stepChildControlName].hideButtons = false;

          setTimeout(() => {
            scroll({
              top: selfStep.$refs[selfStep.currentStep.stepChildControlName].$el.closest('.form-step').offsetTop - 10,
              behavior: 'smooth',
            });
          }, 100);
        }

        if (value.stepNo > this.currentStep.stepNo && this.$refs[value.stepControlName].stepvisibility != StepVisibility.NOTSEEN) {
          // tell the steps to hide all buttons
          this.$refs[value.stepChildControlName].hideButtons = true;

          this.$refs[value.stepControlName].moveAway(hidePreviousSteps);
        }
      });
    },
    coverageCodeToLabel(code) {
      const labelsByCode = {
        HC: 'Contents Insurance',
        HH: 'Home Insurance',
        HHCOMB: 'Home & Contents Insurance',
        MCOther: 'Comprehensive Insurance',
        MC: 'Comprehensive Car Insurance',
        MT: 'Third Party Liability Insurance',
        MTOther: 'Third Party Liability Insurance',
        MF: 'Fire, Theft & Third Party Insurance',
        MFOther: 'Fire, Theft & Third Party Insurance',
        Unique: 'Unique Car Insurance',
        Classic: 'Classic Car Insurance',
        CTP: 'Compulsory Third Party Insurance',
        Caravan: 'Caravan & Trailer Insurance',
        BC: 'Comprehensive Boat Insurance',
        BT: 'Third Party Boat Insurance',
        Cat: 'Pet Insurance',
        Dog: 'Pet Insurance',
        PET: 'Pet Insurance',
        Motorcycle: 'Motorcycle Insurance',
        BodyCorp: 'BodyCorp',
      };

      return labelsByCode[code];
    },

    formatCurrency(dollarAmount) {
      return new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(dollarAmount);
    },

    /**
     * Create an Object with all VueFormulate fields mapped from Sitecore.
     *
     * @param {string} keyName The policy data payload key name (mapped as ID in Sitecore)
     * @param {boolean} showContentBeforeTooltip Boolean to hide/show content before the tooltip trigger
     * @param {string} tooltipContentString The tooltip content string value
     */
    formInputData(keyName, showContentBeforeTooltip, tooltipContentString = '%') {
      // Sometimes content comes from sitecore layout params
      let pageContent;

      // other times content comes from the store after being massaged/updated
      if (this.step?.content?.formInputs?.length) {
        pageContent = this.step.content.formInputs;
      } else {
        pageContent = this.params?.formInputs;
      }

      const formData = pageContent.find((i) => i.formInputId.value === keyName);

      const vBindFormData = {
        label: formData?.label?.value || 'Label missing in SC - keyName:' + keyName,
        name: formData?.formInputId?.value || 'FormInputId mising in SC - keyName:' + keyName,
        id: formData?.formInputId?.value || 'FormInputId mising in SC - keyName:' + keyName,
        type: formData?.inputType?.value || 'FormInputType missing in SC - keyName:' + keyName,
        placeholder: formData?.placeholderText?.value || '',
        postContent: formData?.postContent?.value,
        preContent: formData?.preContent?.value,
        postContentClass: formData?.postContentClass?.value,
        preContentClass: formData?.preContentClass?.value,
      };

      if (formData?.tooltipContent) {
        vBindFormData.tooltip = {
          title: formData?.tooltipTitle?.value,
          content: formData?.tooltipContent?.value,
          tooltipId: formData?.formInputId?.value + 'tooltip_id',
          // this is an edge case where a '%' symbol is required before the tooltip trigger
          // not ideal, but Vue Formulate is very hard to modify otherwise
          contentBeforeTrigger: showContentBeforeTooltip ? tooltipContentString : '',
        };
      }

      return vBindFormData;
    },
  },
};

export default RACQFormsMixin;
