const callBackFormModule = {
  namespaced: true,
  state: () => ({
    callBackFormJeopardyData: {},
  }),
  mutations: {
    addCallBackFormJeopardyData(state, data) {
      state.callBackFormJeopardyData = data;
      const jsonData = JSON.stringify(data);
      localStorage.setItem('callBackFormJeopardyData', jsonData);
    },
    clearCallBackFormJeopardyData(state) {
      state.callBackFormJeopardyData = {};
      localStorage.removeItem('callBackFormJeopardyData'); //clear data from localstorage
    },
  },
  getters: {
    getCallBackJeopardyData: () => {
      if (localStorage.getItem('callBackFormJeopardyData')) {
        return localStorage.getItem('callBackFormJeopardyData');
      }
    },
  },
  actions: {
    addCallBackFormJeopardyData({ commit }, data) {
      if (data) {
        commit('addCallBackFormJeopardyData', data);
      }
    },
    clearCallBackFormJeopardyData({ commit }) {
      commit('clearCallBackFormJeopardyData');
    },
  },
};
export default callBackFormModule;
