import * as ApiConstant from './apiConstant';
import * as apiServiceBase from './apiServiceBase';

const getQuoteSessionId = async function (policyNumber) {
  const response = await apiServiceBase.HttpGet(ApiConstant.addToCoverGetQuoteSessionId.replace('{0}', policyNumber));
  return response;
};

const updateCoveragePricing = function (sessionQuoteId, payload) {
  return apiServiceBase.HttpPost(ApiConstant.addToCoverUpdateCoveragePricing.replace('{0}', sessionQuoteId), payload);
};

const getPolicyRenewal = function (policyNumber) {
  return apiServiceBase.HttpGet(ApiConstant.addToCoverGetPolicyRenewal.replace('{0}', policyNumber));
};

const submitChanges = function (payload) {
  return apiServiceBase.HttpPost(ApiConstant.addToCoverSubmitChanges, payload);
};

const getRenewalPolicyDetails = async function (policyId) {
  const response = await apiServiceBase.HttpGet(`${ApiConstant.getRenewalPolicyDetails.replace('{0}', policyId)}`);
  return response;
};

export const addToCoverService = {
  getQuoteSessionId,
  updateCoveragePricing,
  getPolicyRenewal,
  submitChanges,
  getRenewalPolicyDetails,
};
