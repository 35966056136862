<template>
  <FormulateForm v-model="formValues" @submit="submitForm">
    <div class="step-content">
      <CustomRadioInput id="primary-driver-radio-group" class="py-2 light-theme" :options="drivers" @on-checked="selectExistingDriver">
        <template #legend>
          <span v-html="primaryDriverInfoTitle"></span>
        </template>

        <template #label="{ option }">
          <div class="primary-driver-option-label">
            <strong class="px-2" v-text="option.label" />
            <span>
              <span v-text="formattedDOB(option.dateOfBirth)" />
            </span>
          </div>
        </template>
      </CustomRadioInput>

      <template v-if="drivers.length">
        <div v-for="driver in drivers" :key="driver.externalId">
          <AddExistingPrimaryDriver
            v-if="showExistingDriverDetails(driver)"
            :form-input-data="formInputData"
            :selected-driver="selectedDriver"
            :form-values="formValues"
            :prefill-data="prefillData"
          />
        </div>
      </template>
    </div>

    <br />
    <br />

    <StepFooter v-bind="$props" :disable-next="!selectedDriver" />
  </FormulateForm>
</template>

<script>
import StepFooter from '@/components/feature/newmotorinsuranceform/shared/StepFooter.vue';
import CustomRadioInput from '@/components/feature/newmotorinsuranceform/shared/CustomRadioInput.vue';
import AddExistingPrimaryDriver from './AddExistingPrimaryDriver.vue';

import dayjs from 'dayjs';
import { mapGetters, mapMutations, mapState } from 'vuex';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import AddVehicleFormMixin from '@/components/foundation/forms/mixins/AddVehicleFormMixin';
import { driverFormStates, NEW_MOTOR_INSURANCE_MODULE, motorPolicyModel } from '@/components/feature/newmotorinsuranceform/services/mappings';

dayjs.extend(customParseFormat);

export default {
  name: 'PrimaryDriverDetails',

  components: {
    StepFooter,
    CustomRadioInput,

    AddExistingPrimaryDriver,
  },

  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    onGoBack: {
      type: Function,
      required: true,
    },
    prefillData: {
      type: Object,
      required: false,
    },
    formErrors: {
      type: [Array, Object],
      required: false,
    },
  },

  mixins: [RACQFormsMixins, AddVehicleFormMixin],

  data() {
    return {
      formValues: {
        yearsRatingOneHeld: '',
        isRatingOne: '',
        confirmPrimaryDriver: '',
      },
      selectedDriver: null,
      driverFormStates,
    };
  },

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, ['UPDATE_JEOPARDY_ACTIVE', 'SET_ACTIVE_STEP', 'UPDATE_PRIMARY_DRIVER_FORM_STATE']),

    /**
     * Submit the form with the updated primary driver
     *
     */
    submitForm(values) {
      return this.onSubmit({ primaryDriverId: this.selectedDriver?.externalId || null, ...values });
    },

    /**
     * Select an existing primary driver from the prefilled list of drivers
     *
     * @param {*} driver
     */
    selectExistingDriver(driver) {
      this.selectedDriver = driver;
      this.formValues = {
        yearsRatingOneHeld: '',
        isRatingOne: '',
        confirmPrimaryDriver: '',
      };

      this.UPDATE_PRIMARY_DRIVER_FORM_STATE(driverFormStates.EXISTING_DRIVER_SELECTED);
    },
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, ['primaryDriverFormState']),
    ...mapGetters(NEW_MOTOR_INSURANCE_MODULE, ['foundVehicleMakeModel']),

    showExistingDriverDetails() {
      return (driver) => this.primaryDriverFormState === driverFormStates.EXISTING_DRIVER_SELECTED && driver.externalId === this.selectedDriver.externalId;
    },

    drivers() {
      return this.prefillData.drivers.map((driver) => ({
        ...driver,
        selected: driver.externalId === this.selectedDriver?.externalId,
      }));
    },

    primaryDriverInfoTitle() {
      return this?.formInputData(motorPolicyModel.primaryDriverIntroText)?.label?.replace('{brandMake}', this.foundVehicleMakeModel);
    },
  },
};
</script>
<style lang="scss" scoped>
.primary-driver-option-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.edit-primary-driver-btn {
  background: transparent;
  border: none;
  color: $racq-navy;
  opacity: 0;
  pointer-events: none;

  &.is-editable {
    opacity: 1;
    pointer-events: none;
  }
}
</style>
