import { productApiService } from '../../shared/apiservices/apiProductService';
import { cycloneInsuranceApiService } from '../../shared/apiservices/apiCycloneInsuranceService';
import { quoteSessionApiService } from '../../shared/apiservices/apiQuoteSessionService';
import {
  getNumOfStoreysRefData,
  getLowestOccupiedFloorRefData,
  getPrimaryConstructionMaterials,
  getRoofTypeMaterials,
  getBuildingTypes,
} from '@/components/foundation/shared/apiservices/apiRefdataService';

const cycloneInsuranceModule = {
  namespaced: true,
  state: () => ({
    insuranceProduct: { insurancePolicy: { state: { isPolicyChangeAllowed: true }, coverages: [{ numOfStoreys: '' }] } },
    isCycloneCovered: null,
    quoteSessionId: '',
    numOfStoreys: [],
    lowestOccupiedFloor: [],
    primaryConstructionMaterials: [],
    roofConstructionMaterials: [],
    buildingTypes: [],
    step1Data: {},
    step2Data: {},
    step3Data: {},
    formState: {
      step3: [],
    },
    reviewItemOrder: [
      'propertyElevated',
      'numOfStoreys',
      'lowestOccupiedFloor',
      'garageRollerDoors',
      'garageRollerDoorsBracing',
      'windowWeatherProtection',
      'roofReplacedUpgraded',
      'roofStructureTieDown',
    ],
    jeopardy: null,
    showLoader: false,
    totalPremium: 0,
    transactionCost: 0,
  }),
  mutations: {
    addInsuranceProductData(state, insuranceProduct) {
      state.insuranceProduct = insuranceProduct;
    },
    addIsCycloneCovered(state, isCycloneCovered) {
      state.isCycloneCovered = isCycloneCovered;
    },
    addStepData(state, { stepData, stepNumber }) {
      state[`step${stepNumber}Data`] = stepData;
    },
    addFormValues(state, { stepValues, stepNumber }) {
      state.formState[`step${stepNumber}`] = stepValues;
    },
    addQuoteSessionData(state, quoteSessionId) {
      state.quoteSessionId = quoteSessionId;
    },
    addUpdateResponse(state, response) {
      state.totalPremium = response.totalPremium;
      state.transactionCost = response.transactionCost;
    },
    addRefDataResponse(state, { response, statePropertyName }) {
      state[statePropertyName] = response;
    },
  },
  actions: {
    async updateFloodCycloneDetails({ commit, state }) {
      state.showLoader = true;

      const transformReviewItems = state.formState.step3.reduce((accumulator, item) => {
        return {
          ...accumulator,
          [item.key]: item.value,
        };
      }, {});

      const requestModel = {
        policyNumber: state.insuranceProduct?.insurancePolicy?.policyNumber,
        floodMitigationDetails: {
          buildingType: state.insuranceProduct?.insurancePolicy?.coverages[0]?.propertyType,
          propertyElevated: transformReviewItems?.propertyElevated === 'Yes' ? true : transformReviewItems?.propertyElevated === 'No' ? false : null,
          numOfStoreys: transformReviewItems?.numOfStoreys,
          occupiedFloor: transformReviewItems?.lowestOccupiedFloor,
        },
        cycloneMitigationDetails: {
          garageRollerDoors: transformReviewItems?.garageRollerDoors === 'Yes' ? true : transformReviewItems?.garageRollerDoors === 'No' ? false : null,
          rollerDoorBracing: transformReviewItems?.garageRollerDoorsBracing,
          windownWeatherProtection: transformReviewItems?.windowWeatherProtection,
          ugradedReplacedRoof: transformReviewItems?.roofReplacedUpgraded,
          roofStructureTiedown: transformReviewItems?.roofStructureTieDown,
        },
      };
      try {
        const response = await cycloneInsuranceApiService.updateFloodCycloneDetails(state.quoteSessionId, requestModel);
        commit('addUpdateResponse', response.viewModel);

        // show jeopardy if any
        if (response?.jeopardies?.length > 0) {
          state.jeopardy = response.jeopardies[0]?.code;
        }
      } catch {
        state.jeopardy = 'generic_error';
      } finally {
        state.showLoader = false;
      }
    },
    async createQuoteSession({ state, commit }, { policyId, coverageId }) {
      state.showLoader = true;
      const quoteSessionId = await quoteSessionApiService.getQuoteSessionIdByCoverage(policyId, 'CII', coverageId); //cyclone insurance information
      state.showLoader = false;
      commit('addQuoteSessionData', quoteSessionId);
    },
    async loadInsuranceData({ state, commit }, { policyNumber, coverageId }) {
      const insuranceProduct = await productApiService.getInsuranceProduct(policyNumber);
      let isCycloneCovered = null;

      // grab the coverage selected only
      if (insuranceProduct.insurancePolicy?.coverages?.length >= 1) {
        const coverageToKeep = insuranceProduct.insurancePolicy.coverages.filter((item) => item.id == coverageId);
        insuranceProduct.insurancePolicy.coverages = coverageToKeep;

        // check the address of the specific property
        const propertyToCheck = coverageToKeep[0]?.riskAddress;
        if (propertyToCheck !== null) {
          const request = { postcode: propertyToCheck.postcode, state: propertyToCheck.state, suburb: propertyToCheck.suburb };
          isCycloneCovered = await cycloneInsuranceApiService.getIsCycloneCovered(request);
          commit('addIsCycloneCovered', isCycloneCovered);
        }
      }

      // check if we're allowed to make this change
      // we can make some changes still if cyclone is not covered
      if (
        isCycloneCovered === null ||
        insuranceProduct?.insurancePolicy?.state?.hasMultipleInSequenceFutureChanges === true ||
        insuranceProduct?.insurancePolicy?.state?.hasMultipleOutOfSequenceFutureChanges === true ||
        insuranceProduct?.insurancePolicy?.state?.hasSingleFutureChange === true
      ) {
        state.jeopardy = 'generic_error';
      }

      commit('addInsuranceProductData', insuranceProduct);
    },
    async loadReferenceData({ commit }, coverage) {
      if (coverage) {
        const numOfStoreysResponse = await getNumOfStoreysRefData(coverage.propertyType);
        const numOfStoreysData = numOfStoreysResponse.map(({ value, name }) => ({ value: value, label: name }));
        var lastNumOfStoreysItem = numOfStoreysData[numOfStoreysData.length - 1];
        numOfStoreysData[numOfStoreysData.length - 1] = { ...lastNumOfStoreysItem, id: 'num-of-storeys_last-child' };
        commit('addRefDataResponse', { response: numOfStoreysData, statePropertyName: 'numOfStoreys' });

        const lowestOccupiedFloorResponse = await getLowestOccupiedFloorRefData(coverage.propertyType);
        const lowestOccupiedFloorData = lowestOccupiedFloorResponse.map(({ value, name }) => ({ value: value, label: name }));
        var lastLowestOccupiedFloorItem = lowestOccupiedFloorData[lowestOccupiedFloorData.length - 1];
        lowestOccupiedFloorData[lowestOccupiedFloorData.length - 1] = { ...lastLowestOccupiedFloorItem, id: 'lowest-occupied-floor_last-child' };
        commit('addRefDataResponse', { response: lowestOccupiedFloorData, statePropertyName: 'lowestOccupiedFloor' });

        const primaryConstructionMaterialsResponse = await getPrimaryConstructionMaterials(coverage.coverageCode);
        commit('addRefDataResponse', { response: primaryConstructionMaterialsResponse, statePropertyName: 'primaryConstructionMaterials' });

        const roofConstructionMaterials = await getRoofTypeMaterials(coverage.coverageCode);
        commit('addRefDataResponse', { response: roofConstructionMaterials, statePropertyName: 'roofConstructionMaterials' });

        const buildingTypes = await getBuildingTypes();
        commit('addRefDataResponse', { response: buildingTypes, statePropertyName: 'buildingTypes' });
      }
    },
    addStepData({ commit }, { stepData, stepNumber }) {
      commit('addStepData', { stepData, stepNumber });
    },
    updateFormValues({ commit }, { stepValues, stepNumber }) {
      commit('addFormValues', { stepValues, stepNumber });
    },
  },
  getters: {
    insuranceStreetAddress: (state) => {
      return state.insuranceProduct?.insurancePolicy?.coverages[0]?.riskAddress?.formattedStreet;
    },
    insuranceSuburbState: (state) => {
      return `${state.insuranceProduct?.insurancePolicy?.coverages[0]?.riskAddress?.suburb}, ${state.insuranceProduct?.insurancePolicy?.coverages[0]?.riskAddress?.state} ${state.insuranceProduct?.insurancePolicy?.coverages[0]?.riskAddress?.postcode}`;
    },
    insuranceSuburbStateAndPropertyDtls: (state) => {
      const wallDescription =
        state.primaryConstructionMaterials.find(
          (element) => element.value === state.insuranceProduct?.insurancePolicy?.coverages[0]?.buildingPrimaryConstructionMaterial
        )?.name ?? state.insuranceProduct?.insurancePolicy?.coverages[0]?.buildingPrimaryConstructionMaterial;
      const roofDescription =
        state.roofConstructionMaterials.find((element) => element.value === state.insuranceProduct?.insurancePolicy?.coverages[0]?.roofConstructionMaterial)
          ?.name ?? state.insuranceProduct?.insurancePolicy?.coverages[0]?.roofConstructionMaterial;
      return `</br>${state.step3Data.yearBuiltLabel?.value}: ${state.insuranceProduct?.insurancePolicy?.coverages[0]?.yearBuilt} </br>${state.step3Data.roofMaterialLabel?.value}: ${roofDescription} </br>${state.step3Data.wallMaterialLabel?.value}: ${wallDescription}`;
    },
    isChangeAllowed: (state) => {
      return state.isCycloneCovered && state.insuranceProduct?.insurancePolicy?.state?.isPolicyChangeAllowed;
    },
    buildingTypeDescription: (state) => {
      return (
        state.buildingTypes.find((element) => element.value === state.insuranceProduct?.insurancePolicy?.coverages[0]?.propertyType)?.name ??
        state.insuranceProduct?.insurancePolicy?.coverages[0]?.propertyType
      );
    },
  },
};
export default cycloneInsuranceModule;
