<template>
  <div>
    <div class="form-elements-container">
      <FormulateForm @submit="onSubmit">
        <div>
          <div class="forms-review-content form-review-content-border">
            <sc-rich-text :field="isExperienceEditorRunning ? fields.welcomeTileMessage : welcomeTileMessage" />
          </div>
          <sc-rich-text :field="fields.whatYouShouldTell" />
          <sc-rich-text :field="fields.privacyTileMessage" />
          <sc-rich-text :field="fields.fsg" />
          <sc-rich-text :field="fields.racqMemberShip" />
        </div>
        <div class="form-buttons" v-show="showButtons">
          <FormulateInput type="button" name="Back" input-class="btn btn-secondary" v-show="!hideButtons" @click="cancelForm" outer-class="" />
          <div>
            <FormulateInput type="submit" name="Agree" v-show="!hideButtons" input-class="btn btn-primary" outer-class="" />
            <a href="#" v-show="!hideButtons" @click="onDisagree">Disagree</a>
          </div>
          <FormulateInput
            type="button"
            name="Edit"
            style="margin-left: auto"
            v-show="hideButtons"
            @click="onEdit"
            input-class="btn btn-primary btn-edit"
            outer-class=""
          />
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { scPlaceholders } from '../../renewalofferform/steps/shared/sitecorePlaceholders';
import RACQFormsMixin from '../../../foundation/forms/mixins/RACQFormsMixins';
import { jpCodes } from '../../renewalofferform/steps/shared/jpCodes';
import { mapState } from 'vuex';
export default {
  name: 'RenewalOfferFormStep1',
  data() {
    return {
      showButtons: true,
      welcomeTileMessage: {},
    };
  },
  components: {
    ScRichText: RichText,
  },
  computed: {
    ...mapState('renewalOffer', ['email', 'insurancePolicy']),
  },
  mounted() {
    this.welcomeTileMessage = this.fields.welcomeTileMessage;
    this.welcomeTileMessage.value = this.fields.welcomeTileMessage.value.replace(scPlaceholders.firstname, this.insurancePolicy?.policyHolders[0]?.firstName);
  },
  mixins: [RACQFormsMixin],
  methods: {
    onSubmit() {
      this.$emit('go-next');
    },
    onEdit() {
      this.$emit('visibility-requested');
    },
    onDisagree() {
      this.$root.$emit('show-jeopardy', {
        displayCode: jpCodes.genericError,
        jpCode: jpCodes.genericError,
        stepDetails: this.step.stepTitle,
        formName: 'Renewal Offer Form',
      });
    },
  },
  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    stepNumber: String,
    stepTitle: String,
    formTitle: String,

    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
