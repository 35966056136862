<template>
  <div class="loader-container my-5 py-5 text-center d-flex">
    <div class="loading-spinner mx-auto"></div>
  </div>
</template>

<style>
.loading-spinner {
  width: 30px;
  height: 30px;
  margin-left: 12px;
  background-image: url('./../../../assets/img/loader-34.gif');
  background-size: 100%;
  background-position: center;
}
</style>

<script>
export default {
  name: 'Loader',
};
</script>
