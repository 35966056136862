<template>
  <section class="policy-summary-container">
    <PolicyDiscounts />

    <hr />

    <h3 class="policy-summary-table-heading">Your vehicle details</h3>

    <table class="policy-summary-table" v-for="[label, { editable, value }] in vehicleDetails" :key="label">
      <tr class="policy-summary-table-row">
        <td v-if="label === 'car-icon'" class="policy-summary-table-data data-label">
          <div class="car-icon" />
        </td>
        <td v-else class="policy-summary-table-data data-label" v-text="label" />
        <td class="policy-summary-table-data data-value">
          <span v-if="label === 'car-icon'" v-html="value"></span>
          <span v-else v-text="value" />
          <button v-if="isEditable && editable" type="button" class="button-as-link" @click="gotoCarDetailsStep">Edit</button>
        </td>
      </tr>
    </table>

    <h3 class="policy-summary-table-heading">Your Policy details</h3>

    <table class="policy-summary-table" v-for="[label, { editable, value }] in policyDetails" :key="label">
      <tr class="policy-summary-table-row">
        <td class="policy-summary-table-data data-label" v-text="label" />
        <td class="policy-summary-table-data data-value">
          <span v-text="value" />
          <button v-if="isEditable && editable" type="button" class="button-as-link" @click="gotoExcessStep">Edit</button>
        </td>
      </tr>
    </table>

    <h3 class="policy-summary-table-heading">Primary driver</h3>

    <table class="policy-summary-table" v-for="[label, { editable, value }] in primaryDriverDetails" :key="label">
      <tr class="policy-summary-table-row">
        <td class="policy-summary-table-data data-label" v-text="label" />
        <td class="policy-summary-table-data data-value">
          <span v-text="value" />
          <button v-if="isEditable && editable" type="button" class="button-as-link" @click="gotoPrimaryDriverStep">Edit</button>
        </td>
      </tr>
    </table>
  </section>
</template>

<script>
import PolicyDiscounts from '@/components/feature/newmotorinsuranceform/shared/PolicyDiscounts.vue';

import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import {
  NEW_MOTOR_INSURANCE_MODULE,
  motorPolicyModel,
  validationMessages,
  policyCoverageTypes,
  coverageTypeCodes,
  vehicleUseType,
} from '@/components/feature/newmotorinsuranceform/services/mappings';
import { formattedCarSpecs, toTitleCase } from '@/components/feature/newmotorinsuranceform/services/text-transforms';

import dayjs from 'dayjs';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { scrollToElement } from '@/components/foundation/shared/utils/dom-helpers';

export default {
  name: 'PolicySummary',

  components: {
    PolicyDiscounts,
  },

  props: {
    prefillData: {
      type: Object,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  mixins: [RACQFormsMixins],

  data() {
    return {
      validationMessages,
      motorPolicyModel,
      formValues: {},
      modalName: 'confirm-cancel-review-quote-modal',
    };
  },

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, ['SET_ACTIVE_STEP']),

    showConfirmDialog() {
      this.$vfm.show(this.modalName);
    },

    gotoFindCarStep() {
      this.SET_ACTIVE_STEP({ stepIndex: 0 });
      scrollToElement(`#findCar`);
    },

    gotoCarDetailsStep() {
      this.SET_ACTIVE_STEP({ stepIndex: 1 });
      scrollToElement(`#carDetails`);
    },

    gotoPrimaryDriverStep() {
      this.SET_ACTIVE_STEP({ stepIndex: 2 });
      scrollToElement(`#primaryDriver`);
    },

    gotoExcessStep() {
      this.SET_ACTIVE_STEP({ stepIndex: 4 });
      scrollToElement(`#excess`);
    },

    formatAccessories(arr) {
      if (!arr || !arr.length) return 'No';

      const formattedAccessories = [];

      arr.forEach((accessory) => {
        if (this.accessoriesMap.has(accessory.vehicleAccessoryOption)) {
          formattedAccessories.push(this.accessoriesMap.get(accessory.vehicleAccessoryOption));
        }
      });

      return formattedAccessories?.join(', ');
    },
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, ['accessoriesMap', 'excessAmounts', 'businessRulesResponse']),
    ...mapGetters(NEW_MOTOR_INSURANCE_MODULE, ['foundVehicleMakeModel', 'foundVehicle']),

    isAllowedToSelectNcdp() {
      return this.businessRulesResponse.viewModel.isAllowedToSelectNcdp;
    },

    isAllowedToSelectCarHire() {
      return this.businessRulesResponse.viewModel.isAllowedToSelectCarHire;
    },

    isAllowedToSelectWindscreenReplacement() {
      return this.businessRulesResponse.viewModel.isAllowedToSelectWindscreenReplacement;
    },

    vehicleDetails() {
      const vehicleSpecs = formattedCarSpecs(this.foundVehicle, 'basic');
      const vehicleAccessoriesDetails = this.formatAccessories(this.prefillData?.vehicleAccessories);
      const hasFinance = this.prefillData?.hasFinance === 'true';
      const financeDetails = hasFinance ? `${this.prefillData?.financeCode || ''} ${this.prefillData?.financeProvider || ''}` : 'No finance';
      const businessUseType = this.prefillData?.businessUseType;
      const vehicleUseDetails =
        Number(this.prefillData.vehicleUseType) === Number(vehicleUseType.Business) ? `Business ${businessUseType ? ' - ' + businessUseType : ''}` : 'Private';

      const vehicleDetailsMap = new Map([
        [
          'car-icon',
          {
            editable: false,
            value: `<div><div><b>${this.foundVehicleMakeModel}</b></div><div>${vehicleSpecs}</div></div>`,
          },
        ],
        [
          'Kept overnight',
          {
            editable: true,
            value: toTitleCase(this.prefillData.overnightParkingLocationCode),
          },
        ],
        [
          'Suburb and postcode',
          {
            editable: true,
            value: `${this.prefillData.overnightParkingLocationSuburb?.toUpperCase()}, ${this.prefillData.overnightParkingLocationPostcode}`,
          },
        ],
        [
          'Finance',
          {
            editable: true,
            value: financeDetails,
          },
        ],
        [
          'Use',
          {
            editable: true,
            value: vehicleUseDetails,
          },
        ],
      ]);

      if ([coverageTypeCodes.MotorComprehensive, coverageTypeCodes.MotorThirdPartyFireAndTheft].includes(this.prefillData.coverageCode)) {
        vehicleDetailsMap.set('Accessories and modifications', {
          editable: true,
          value: vehicleAccessoriesDetails,
        });
      }

      return vehicleDetailsMap;
    },

    policyDetails() {
      const sumInsured = this.prefillData?.howVehicleInsured === 'AGREED' ? this.formatCurrency(this.prefillData?.agreedVehicleValue) || 'N/A' : 'Market Value';
      const { isAllowedToSelectNcdp, highValueBasicExcess, isAllowedToSelectCarHire, isAllowedToSelectWindscreenReplacement } =
        this.businessRulesResponse.viewModel;

      const excessAmountsArr = Object.keys(this.excessAmounts);

      const excessAmount = highValueBasicExcess
        ? this.formatCurrency(highValueBasicExcess)
        : excessAmountsArr?.length
        ? this.excessAmounts[this.prefillData.standardExcessCode]?.label
        : '';

      const policyDetailsMap = new Map([
        [
          'Insurance type',
          {
            editable: false,
            value: policyCoverageTypes[this.prefillData.coverageCode],
          },
        ],
        [
          'Effective date',
          {
            editable: false,
            value: dayjs(this.prefillData.changeEffectiveDate).format('DD/MM/YYYY'),
          },
        ],
        [
          'Sum insured',
          {
            editable: true,
            value: sumInsured,
          },
        ],
        [
          'Basic excess',
          {
            editable: highValueBasicExcess || excessAmountsArr.length <= 1 ? false : true,
            value: excessAmount,
          },
        ],
        [
          'No claim discount protection',
          {
            editable: this.isAllowedToSelectNcdp,
            value: this.prefillData?.includeNcdp === 'true' ? 'Yes' : 'No',
          },
        ],
        [
          'Hire car',
          {
            editable: this.isAllowedToSelectCarHire,
            value: this.prefillData?.includeCarHire === 'true' ? 'Yes' : 'No',
          },
        ],
        [
          'Windscreen replacement',
          {
            editable: this.isAllowedToSelectWindscreenReplacement,
            value: this.prefillData?.includeWindscreenReplacement === 'true' ? 'Yes' : 'No',
          },
        ],
      ]);

      if (isAllowedToSelectNcdp === false) {
        policyDetailsMap.delete('No claim discount protection');
      }

      if (isAllowedToSelectWindscreenReplacement === false) {
        policyDetailsMap.delete('Windscreen replacement');
      }

      if (isAllowedToSelectCarHire === false) {
        policyDetailsMap.delete('Hire car');
      }

      return policyDetailsMap;
    },

    primaryDriverDetails() {
      const primaryDriver = this.prefillData.drivers.find((d) => d.isPrimary);

      return new Map([
        [
          'Name',
          {
            editable: true,
            value: primaryDriver?.name || 'N/A',
          },
        ],
      ]);
    },
  },
};
</script>

<style scoped lang="scss">
.policy-summary-container {
  background: #ebf0f1;
  padding: 1.5rem 1.5rem 2rem;
  margin: 2rem auto;
  text-align: left;
}

.policy-summary-table-heading {
  padding-top: 1.5rem;
}

.policy-summary-table {
  width: 100%;
}

.policy-summary-table-row {
  border-bottom: 1px solid lightgrey;
}

.policy-summary-table-data {
  padding: 1rem;

  &.data-label {
    width: 220px;
    text-align: right;
    padding: 0 2rem;
    color: $racq-navy;
  }

  &.data-value {
    display: flex;
    justify-content: space-between;
  }
}

.car-icon {
  width: 100%;
  background-repeat: no-repeat;
  height: 2.5rem;
  text-align: right;
  background-position: right;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4MC4yNyIgaGVpZ2h0PSIzNy45NzgiIHZpZXdCb3g9IjAgMCA4MC4yNyAzNy45NzgiPjxwYXRoIGQ9Ik04Mi40ODcsMjEuMDUyYy0uMzQ1LS43NTQtLjc0Mi0xLjY0NC0xLjEtMi41YTUuNiw1LjYsMCwwLDAtNC41NTMtMy4zODdjLTMuNDI2LS41ODMtNi45NzQtMS4xOS05LjU3LTEuNjMzQTEzNy41LDEzNy41LDAsMCwwLDUyLjQsMy40MjdjLTIuODQ2LTEuNjctNC42Mi0yLjQxNi03LjYxNi0yLjQxNkgzMS42OWMtMy43NDgsMC02LjksMS4yNDQtMTAuNDY0LDMuMTEtMy40NzQsMS44MTYtOS4xLDQuNzEyLTExLjEyNCw1Ljc1MmEyNi43MzksMjYuNzM5LDAsMCwxLTMuMDM4LS4wOEEyLjI1LDIuMjUsMCwwLDAsNC42LDExLjgzMUwzLjk1LDE2LjA2OCwyLjksMjQuMjRzLS40NTMsMS43MiwxLjg4LDMuMDdsNi4wOTMsNC40NGEyMS41OTMsMjEuNTkzLDAsMCwwLDMuMjc3LjUyLDcuMTc1LDcuMTc1LDAsMCwwLDE0LjIyNS43OTJsMS44MS4wODdjNi43NTQuMTU0LDE1Ljc3LjE5LDI3Ljc2Ny0uMDE2YTcuMTc0LDcuMTc0LDAsMCwwLDE0LjE1OC0uMzQxbDQuNzctLjEyN2MuODE3LS4wMjUsMS42MS0uMDQ4LDIuNDQ2LS4wNzZoLjk5MmExLjU1NSwxLjU1NSwwLDAsMCwxLjk2LTEuM2wuNzU0LTUuNjg4YTguMDM4LDguMDM4LDAsMCwwLS41NDUtNC41NDhabS02MS4xNzUsMTQuOGE0LjA2Nyw0LjA2NywwLDAsMS00LTMuNDEsNC4wMjIsNC4wMjIsMCwwLDEtLjA2OC0uNjgyLDQuMDU0LDQuMDU0LDAsMSwxLDQuMDY3LDQuMDkyWk0yMi40LDEwLjE3NmMtMi4xMTQtMS4yMjguNS0yLjguNS0yLjgsMy4xLTEuNjIsNS43NzYtMi43LDguNzg2LTIuN2g2LjYyNWwuNSw2LjQ4Yy01Ljk1LS4zOS0xMS44ODctLjc1NS0xNi40MS0uOTg0Wm0xOS41Ny01LjVoMi44MTRjMi4wNDUsMCwzLjEyNS4zNTcsNS43OCwxLjkxNSwyLjIsMS4yOTQsNS44MjQsMy41NSw5LjM2Myw2LjAyNS0zLjg0NS0uMjc3LTEwLjQ0Mi0uNzQ1LTE3LjQ0LTEuMjE1bC0uNTE2LTYuNzI2Wk02NSwzNS44NDdhNC4wNTksNC4wNTksMCwwLDEtMy44Ny0yLjlBNC4wMzksNC4wMzksMCwxLDEsNjUsMzUuODQ3WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIuODY1IC0xLjAxMSkiIGZpbGw9IiM0YjU4NjkiLz48L3N2Zz4=');
}
</style>
