/**
 * Vue Formulate related mixins
 */
import $ from 'jquery';

export default {
  methods: {
    /**
     * Add this to the Vue Formulate '@on-failed-validation' event.
     *
     * Ref: https://vueformulate.com/guide/forms/#events
     */
    onFailedValidation() {
      this.positionErrorsUnderInput();
    },

    /**
     * Position error messages neatly under their corresponding input.
     *
     * This helper fn was created to match designs. Without this,
     * the error messages are be pushed down by the input label
     * making it hard to associate the errors with the input.
     */
    positionErrorsUnderInput() {
      const errorWrappers = $('.formulate-input-errors');

      errorWrappers.each((index, el) => {
        const parentHeight = $(el).siblings('.formulate-input-wrapper').height();
        const inputHeightOffset = 50;

        if (window.innerWidth > 960) {
          if (parentHeight > inputHeightOffset) {
            $(el).css({ position: 'relative', top: `-${parentHeight - inputHeightOffset}px`, marginTop: '0' });
          }
        }
      });
    },
  },
};
