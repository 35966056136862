var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.assistanceProduct)?_c('product-details-tile',{attrs:{"assistanceProduct":this.assistanceProduct,"fields":_vm.fields,"hasBorder":true}}):_vm._e(),_c('div',{staticClass:"step-content"},[_c('sc-rich-text',{attrs:{"field":_vm.fields.reasonForCancellationContent}})],1),_c('div',{staticClass:"form-elements-container"},[_c('FormulateForm',{on:{"submit":_vm.onSubmit},model:{value:(_vm.step1Values),callback:function ($$v) {_vm.step1Values=$$v},expression:"step1Values"}},[_c('racq-formulate-input',{key:"reasonForCancellation",attrs:{"options":_vm.getDropDownListItems(_vm.firstDropDownOptions),"name":"reasonForCancellation","type":"selectbox","placeholder":"Please select","validation":"required","inputclass":"","label":_vm.fields.reasonForCancellationLabel,"inputhaserrorsclass":"input-error","validation-messages":{
          required: _vm.fields.reasonForCancellationRequiredErrorMessage,
        },"experienceEditableValidationErrors":[_vm.fields.reasonForCancellationRequiredErrorMessage]}}),(this.step1Values.reasonForCancellation && this.secondDropDownOptions.data && this.secondDropDownOptions.data.length)?_c('racq-formulate-input',{key:"subReasonForCancellation",attrs:{"options":_vm.getDropDownListItems(this.secondDropDownOptions.data),"name":"subReasonForCancellation","type":this.step1Values.reasonForCancellation == this.vehicleNotRegistered ||
          this.step1Values.reasonForCancellation == this.noLongerOwnVehicle ||
          this.step1Values.reasonForCancellation == this.noLongerRideVehicle //only show radio button if the reason is vehicle not registered or no longer own the vehicle
            ? 'radio'
            : 'selectbox',"placeholder":"Please select","validation":"required","label":this.secondDropDownOptions.label,"inputhaserrorsclass":"input-error","validation-messages":{
          required: this.secondDropDownOptions.requiredErrorMessage,
        }}}):_vm._e(),(this.step1Values.subReasonForCancellation && this.thirdDropDownOptions.data && this.thirdDropDownOptions.data.length)?_c('racq-formulate-input',{key:"roadsideProvider",attrs:{"options":_vm.getDropDownListItems(this.thirdDropDownOptions.data),"name":"roadsideProvider","type":"selectbox","placeholder":"Please select","validation":"required","label":_vm.thirdDropDownOptions.label,"inputhaserrorsclass":"input-error","validation-messages":{
          required: this.thirdDropDownOptions.requiredErrorMessage,
        }}}):_vm._e(),(_vm.showAdditionalComments())?_c('racq-formulate-input',{attrs:{"name":"additionalComments","type":"textarea","inputhaserrorsclass":"input-error","label":this.step1Values.reasonForCancellation == this.otherCancellationReason ? _vm.fields.specifyDetailsLabel : _vm.fields.additionalCommentLabel,"error-behavior":"live","validation":this.step1Values.reasonForCancellation == this.otherCancellationReason
            ? 'required|max:250,length|matches:/^[\\w\\-\\.\\s]*$/'
            : 'max:250,length|matches:/^[\\w\\-\\.\\s]*$/',"validation-messages":{
          max: _vm.fields.additionalCommentMaxCharacterErrorMessage,
          matches: _vm.fields.additionalCommentAllowedCharactersErrorMessage,
          required: _vm.fields.specifyDetailsRequiredErrorMessage,
        }}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showButtons),expression:"showButtons"}],staticClass:"form-buttons"},[_c('FormulateInput',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideButtons),expression:"!hideButtons"}],attrs:{"type":"button","name":"Cancel","input-class":"btn btn-secondary","outer-class":""},on:{"click":_vm.cancelForm}}),_c('FormulateInput',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideButtons),expression:"!hideButtons"}],attrs:{"type":"submit","name":"Next","input-class":"btn btn-primary","outer-class":""}}),_c('FormulateInput',{directives:[{name:"show",rawName:"v-show",value:(_vm.hideButtons),expression:"hideButtons"}],staticStyle:{"margin-left":"auto"},attrs:{"type":"button","name":"Edit","input-class":"btn btn-primary btn-edit","outer-class":""},on:{"click":_vm.onEdit}})],1)],1),(!_vm.showButtons)?_c('change-vehicle-tile-vue',{attrs:{"fields":_vm.fields}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }