<template>
  <div class="d-flex items-center gap-2">
    <vSelect
      :class="`formulate-input-element formulate-input-element--${context.type}`"
      :data-type="context.type"
      v-model="context.model"
      :options="context.options"
      v-bind="context.attributes"
      v-on="$listeners"
      :reduce="(x) => x.value"
      label="label"
      transition="none"
      :placeholder="context.attributes.placeholder || 'Please select'"
      @close="onClose"
      @search="onSearch"
    >
      <template #no-options>
        <span>Enter suburb or postcode</span>
      </template>
    </vSelect>

    <div v-if="context.attributes.tooltip && tooltipContent">
      <RacqFormTooltip :tooltip-id="context.attributes.tooltip.tooltipId">
        <template #tooltip-trigger>
          <button :class="[triggerIcon]" :id="context.attributes.tooltip.tooltipId" type="button" name="tooltip-trigger" />
        </template>
        <template #tooltip-content>
          <p v-if="tooltipTitle"><strong v-text="tooltipTitle" /></p>
          <div v-html="tooltipContent" v-if="tooltipContent"></div>
        </template>
      </RacqFormTooltip>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import RacqFormTooltip from '@/components/foundation/forms/controls/RacqFormTooltip.vue';

export default {
  components: {
    RacqFormTooltip,
    vSelect,
  },

  mixins: [RacqFormTooltip],

  props: {
    context: {
      type: Object,
      required: true,
    },
    searchFn: {
      type: Function,
      required: false,
    },
  },

  methods: {
    onClose: function () {
      this.context.blurHandler();
    },

    async onSearch(search, loading) {
      if (this.$listeners.change) {
        loading(true);

        const data = await this.$listeners.change(search);

        this.$emit('on-search', data);

        loading(false);
      }
    },
  },

  computed: {
    tooltipContent() {
      const { tooltip } = this.context.attributes;

      if (tooltip && tooltip.content) {
        return tooltip.content;
      }

      return '';
    },

    tooltipTitle() {
      const { tooltip } = this.context.attributes;

      if (tooltip && tooltip.title) {
        return tooltip.title;
      }

      return '';
    },
  },
};
</script>
