<template>
  <div class="form-step-container" v-if="insurancePolicies.length">
    <div class="form-step center-aligned">
      <div class="step-title">
        <h3 class="text-white">
          <sc-text :field="step.stepTitle" />
        </h3>
      </div>
      <div class="step-side-icon"></div>
      <div class="form-step-content">
        <div v-show="showCurtain" class="form-step-curtain">
          <div v-show="formStatus === 'FETCHING'" class="form-loader"></div>
        </div>

        <div>
          <div class="step-content">
            <div class="bottom-margin">
              <div class="text-container">
                <sc-rich-text :field="step.stepContent" />

                <PolicySelectList
                  v-if="insurancePolicies.length"
                  :policies="insurancePolicies"
                  :selected-policy-number="selectedPolicyNumber"
                  :on-policy-select="onPolicySelect"
                />
              </div>
            </div>
          </div>
          <span v-if="hasError" role="status" class="error-message">Please select a policy to continue.</span>
        </div>

        <StepFooter
          v-bind="$props"
          :on-go-back="onCancelForm"
          :on-submit="submitForm"
          :disable-next="!selectedPolicyNumber"
          :prelim-step="true"
          :form-status="formStatus"
          @on-edit="editStep"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RACQFormsMixin from '@/components/foundation/forms/mixins/RACQFormsMixins';
import PolicySelectList from '@/components/feature/newmotorinsuranceform/steps/preliminary/PolicySelectList.vue';
import { getAllInsurancePolicies, getMotorInsurancePolicies } from '@/components/foundation/shared/apiservices/apiPolicyService';
import { scrollToElement } from '@/components/foundation/shared/utils/dom-helpers';
import StepFooter from '@/components/feature/newmotorinsuranceform/shared/StepFooter.vue';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { mapMutations, mapState } from 'vuex';
import { NEW_MOTOR_INSURANCE_MODULE } from '@/components/feature/newmotorinsuranceform/services/mappings';
import { JP_CHANGE_NOT_ALLOWED_CODE } from '@/components/foundation/shared/utils/constants';

export default {
  name: 'SelectYourPolicy',

  components: {
    ScText: Text,
    ScRichText: RichText,
    PolicySelectList,
    StepFooter,
  },

  mixins: [RACQFormsMixin],

  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    onSubmitForm: {
      type: Function,
      default: () => undefined,
    },
    onCancelForm: {
      type: Function,
      default: () => undefined,
    },
    submissionResponse: {
      type: [Object, Array],
      default: () => ({}),
    },
    goNextStep: {
      type: Function,
      required: true,
    },
  },

  created() {
    this.getAllMotorInsurancePolicies();
  },

  data() {
    return {
      hasError: false,
      formStatus: 'IDLE',
      insurancePolicies: [],
    };
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, ['jeopardyActive', 'selectedPolicyNumber']),

    showCurtain() {
      return (this.jeopardyActive && this.formStatus !== 'EDITING') || this.formStatus === 'FETCHING';
    },
  },

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, [
      'UPDATE_SELECTED_POLICY_NUMBER',
      'SET_SELECTED_POLICY',
      'UPDATE_JEOPARDY_ACTIVE',
      'UPDATE_ALL_INSURANCE_POLICIES',
    ]),

    /**
     * Set selectedPolicy on click of a policy child component.
     *
     * @param {*} policy
     */
    onPolicySelect(policy) {
      this.UPDATE_SELECTED_POLICY_NUMBER(policy.policyNumber);
      this.SET_SELECTED_POLICY(policy);
    },

    /**
     * Submit form to the Business Rules Engine.
     *
     * In this case formData is null because we get
     * the selected policy data from the Vuex store.
     */
    async submitForm() {
      if (!this.selectedPolicyNumber) {
        this.hasError = true;
        return;
      }

      this.formStatus = 'FETCHING';

      // Get insurance policies because OSAPI and OSAPI-IQ have different namespaces.
      // Therefore, we need to pass all policy data into the model for validation.
      const insurancePolicies = await getAllInsurancePolicies();
      this.UPDATE_ALL_INSURANCE_POLICIES(insurancePolicies);

      const selectedPolicy = insurancePolicies.find((policy) => policy.policyNumber === this.selectedPolicyNumber);

      this.SET_SELECTED_POLICY(selectedPolicy);

      const response = await this.onSubmitForm({
        metadata: { stepId: 'SelectYourPolicy', selectedPolicy },
        formData: null,
      });

      if (response?.data?.jeopardies?.length) {
        return this.showJeopardy();
      }

      if (response?.success) {
        this.goNextStep();
      }

      this.formStatus = 'IDLE';
    },

    /**
     *
     */
    async getAllMotorInsurancePolicies() {
      this.formStatus = 'FETCHING';

      try {
        const policies = await getMotorInsurancePolicies();
        this.insurancePolicies = policies;
        this.UPDATE_ALL_INSURANCE_POLICIES(policies);

        if (!policies.length) {
          this.showJeopardy(JP_CHANGE_NOT_ALLOWED_CODE);
        }

        this.formStatus = 'IDLE';
      } catch (e) {
        console.log('Error fetching policies', e);
        this.formStatus = 'ERROR';
      }
    },

    /** */
    showJeopardy(activeJeopardyCode = '') {
      this.UPDATE_JEOPARDY_ACTIVE({ jeopardyActive: true, activeJeopardyCode });
      this.formStatus = 'ERROR';
      scrollToElement('#jeopardy-container', 100);
    },

    /** */
    editStep() {
      //
      this.formStatus = 'EDITING';
    },
  },

  watch: {
    selectedPolicyNumber(val) {
      if (val) {
        this.hasError = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-loader {
  width: 30px;
  height: 30px;
  margin-left: 12px;
  background-image: url('~@/assets/img/loader-34.gif');
  background-size: 100%;
  background-position: center;

  margin: auto;
  z-index: 50;
}
</style>
