<template>
  <div>
    <div id="credit-card">
      <div v-if="informationText">
        <p v-text="informationText" />
      </div>

      <div :class="{ 'iframe-screen-disabled': formDisabled }">
        <iframe :id="fatZebraIframeUrl" :src="fatZebraIframeUrl" :style="iframeStyles" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fatZebraIframeUrl: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default: 'Submit payment',
    },
    informationText: {
      type: String,
      default: '',
    },
    fatZebraCardToken: {
      type: String,
      default: '',
    },
    formDisabled: {
      type: Boolean,
      default: true,
    },
    policyNumber: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      showIFrame: true,
      error: false,
      loading: false,
      quoteSessionId: this.$route.query.quoteSessionId,
    };
  },

  computed: {
    iframeStyles() {
      if (this.showIFrame) {
        return {
          width: '100%',
          height: '700px',
          border: 'none',
        };
      }

      return {
        display: 'none',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.iframe-screen-disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
  user-select: none;
}
</style>
