<template>
  <div>
    <div class="step-content">
      <sc-rich-text :field="fields.cancellationDateHeading" />
    </div>
    <div class="form-elements-container">
      <FormulateForm v-model="step2Values" @submit="onSubmit">
        <racq-formulate-input
          :disabled-dates="disabledDates"
          :label="fields.cancellationDateLabel"
          :validation="getDateValidation()"
          name="cancellationDate"
          type="datepicker"
          v-model="step2Values.cancellationDate"
        >
        </racq-formulate-input>
        <div class="warning-note">
          <sc-image :media="fields.noteIcon"></sc-image>
          <div class="text-container margin--top">
            <sc-rich-text :field="fields.noteOnCoverage"></sc-rich-text>
          </div>
        </div>
        <div class="form-buttons">
          <FormulateInput type="button" name="Back" @click="goBack" input-class="btn btn-secondary" v-show="!hideButtons" outer-class="" />

          <FormulateInput type="submit" name="Next" v-show="!hideButtons" input-class="btn btn-primary" outer-class="" />

          <FormulateInput
            type="button"
            name="Edit"
            style="margin-left: auto"
            v-show="hideButtons"
            @click="onEdit"
            input-class="btn btn-primary btn-edit"
            outer-class=""
          />
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { RichText, Image } from '@sitecore-jss/sitecore-jss-vue';
import RacqFormulateInput from '../../../foundation/forms/controls/racqFormulateInput.vue';
import RACQFormsMixin from '../../../foundation/forms/mixins/RACQFormsMixins';
import { productApiService } from '../../../foundation/shared/apiservices/apiProductService';
import { jpCodes } from './shared/jpCodes';
import Vuex from 'vuex';
export default {
  name: 'Step2CancelInsurance',
  components: {
    ScRichText: RichText,
    ScImage: Image,
    RacqFormulateInput,
  },
  mounted() {},
  updated() {
    if (this.insuranceProduct && this.insuranceProduct.defaultCancellationDate && !this.defaultDateSet) {
      var toDate = new Date(this.getMaxDate());
      toDate.setHours(0, 0, 0, 0); //set hours to zero so that date comparison works correctly
      var fromDate = new Date(this.getCurrentDate());
      fromDate.setHours(0, 0, 0, 0);
      this.disabledDates = {
        customPredictor: function (date) {
          date.setHours(0, 0, 0, 0);
          if (date < fromDate || date > toDate) {
            //if date is between min and max dates
            return true;
          }
        },
      };
      this.step2Values.cancellationDate = new Date(this.insuranceProduct.defaultCancellationDate);
      this.defaultDateSet = true; //once default date is set then let user change the date
    }
  },
  mixins: [RACQFormsMixin],
  methods: {
    goBack() {
      this.$emit('go-back');
    },
    async onSubmit(model) {
      this.$store.dispatch('cancelInsuranceModule/clearStepData', '2');
      this.$store.dispatch('cancelInsuranceModule/addStep2DataToStore', {
        label: this.fields.cancellationDateHeading.value,
        value: model.cancellationDate,
        text: model.cancellationDate,
      });
      this.addAnalyticsFormData(this.step2Data, this.insuranceProduct.insuranceType);
      const response = await productApiService.isCancellationDateEligible({
        policyNumber: this.$route.query.policyNumber,
        cancellationDate: model.cancellationDate,
      });
      if (response.isEligible) {
        this.$emit('go-next');
      } else {
        this.$root.$emit('show-jeopardy', {
          displayCode: jpCodes.genericError,
          jpCode: response.jpCode,
          stepDetails: this.step.stepTitle,
          formName: 'Cancel Insurance Form',
        });
      }
    },
    onEdit() {
      this.$emit('visibility-requested');
    },
    getDateValidation() {
      return 'required';
    },
    getMaxDate() {
      if (this.isExperienceEditorRunning) {
        return null;
      } else {
        return this.insuranceProduct.maxCancellationDate;
      }
    },
    getCurrentDate() {
      if (this.isExperienceEditorRunning) {
        return null;
      } else {
        return this.insuranceProduct.defaultCancellationDate;
      }
    },
  },
  computed: {
    ...Vuex.mapState({
      step1Data: (state) => state.cancelInsuranceModule.step1Data,
      step2Data: (state) => state.cancelInsuranceModule.step2Data,
      insuranceProduct: (state) => state.cancelInsuranceModule.insuranceProduct,
    }),
  },
  data() {
    return {
      step2Values: {},
      defaultDateSet: false,
      disabledDates: {},
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    step: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
