import { productApiService } from './../../shared/apiservices/apiProductService';
const cancelInsuranceModule = {
  namespaced: true,
  state: () => ({
    step1Data: [],
    step2Data: [],
    step3Data: [],
    insuranceProduct: null,
  }),
  mutations: {
    clearStepData(state, stepNumber) {
      if (stepNumber == '1' || stepNumber == 'All') {
        state.step1Data = [];
      }
      if (stepNumber == '2' || stepNumber == 'All') {
        state.step2Data = [];
      }
      if (stepNumber == '3' || stepNumber == 'All') {
        state.step3Data = [];
      }
    },
    addStep1Data(state, stepData) {
      state.step1Data.push(stepData);
    },
    addStep2Data(state, stepData) {
      if (stepData.value) {
        //formating date in dd-mm-yyyy before storing it in store
        stepData.value = stepData.value.split('-')[2] + '-' + stepData.value.split('-')[1] + '-' + stepData.value.split('-')[0];
      }
      state.step2Data.push(stepData);
    },
    addStep3Data(state, stepData) {
      state.step3Data.push(stepData);
    },
    addInsuranceProductData(state, insuranceProduct) {
      state.insuranceProduct = insuranceProduct;
    },
  },
  actions: {
    addStep1DataToStore({ commit }, data) {
      if (data) {
        commit('addStep1Data', data);
      }
    },
    addStep2DataToStore({ commit }, data) {
      if (data) {
        commit('addStep2Data', data);
      }
    },
    addStep3DataToStore({ commit }, data) {
      if (data) {
        commit('addStep3Data', data);
      }
    },
    clearStepData({ commit }, stepNumber) {
      commit('clearStepData', stepNumber);
    },
    async addInsuranceProductData({ commit }, policyId) {
      const insurancePolicy = await productApiService.getInsuranceProduct(policyId);
      commit('addInsuranceProductData', insurancePolicy);
    },
  },
  getters: {
    getCombinedStepData: (state) => (data) => {
      return [
        { heading: data.reasonHeading, data: state.step1Data },
        { heading: data.dateOfCancellationHeading, data: state.step2Data },
        { heading: data.bankDetailsHeading, data: state.step3Data },
      ];
    },
    getCancelInsuranceModel: (state) => () => {
      return {
        policyNumber: state.insuranceProduct.insurancePolicy.policyNumber,
        cancellationReason: state.step1Data[0].text,
        subCancellationReason: state.step1Data[1].text,
        cancellationReasonId: state.step1Data[0].value,
        subCancellationReasonId: state.step1Data[1].value,
        cancellationDate: state.step2Data[0].value.split('-')[2] + '-' + state.step2Data[0].value.split('-')[1] + '-' + state.step2Data[0].value.split('-')[0],
        refundPaymentDetails: {
          accountHolderName: state.step3Data[0].value,
          bsbNumber: state.step3Data[1].value,
          accountNumber: state.step3Data[2].value,
        },
      };
    },
  },
};
export default cancelInsuranceModule;
