export default {
  event: 'form.steps',
  form: {
    name: '',
    type: 'SS',
    stepName: '',
    productCategory: '',
    productName: '',
  },
};
