import { startCase, camelCase } from 'lodash';

export function toTitleCase(str) {
  return startCase(camelCase(str));
}

function truncateDecimals(number, digits = 2) {
  const multiplier = Math.pow(10, digits);
  const adjustedNum = number * multiplier;
  const truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

  return truncatedNum / multiplier;
}

/**
 * Parse Current to Number
 * eg. $16,000.10 -> 16000.1
 * 
 * @param {string} amount
 * @returns {number} number 
 */
export const parseCurrencyToNumber = (amount) => {
  try {
    if (!amount || typeof amount != 'string') return amount;
    const res = Number(amount.split("$").join("").split(",").join(""));
    return Number.isNaN(res) ? amount : res;
  } catch (error) {
    return amount;
  }
}

/**
 * Create a formatted, human readable string from car specs data
 *
 * @param {*} vehicle The vehicle data obtained from NVIC or rego lookup
 * @param {'basic'|'detailed'} type Returns a basic or detailed car specs string
 *
 * @returns {String}
 */
export function formattedCarSpecs(vehicle, type = 'detailed') {
  const vehicleModelVariantSeries = `${toTitleCase(vehicle?.model?.description || '')} ${vehicle?.variant?.description || ''} ${vehicle?.option?.series || ''}`;

  const speeds = `${vehicle?.gears + ' Speed' || ''}`;

  const autoOrManual = vehicle?.option?.transmission === 'A' ? 'Auto' : 'Manual';

  const vehicleDoorsBodyTransmissionDriveType = `${vehicle?.option?.doors + ' Door' || ''} ${vehicle?.body?.description || ''} - ${speeds} ${autoOrManual} ${
    vehicle?.driveType || ''
  } -`;

  const engineCapacity = vehicle?.engineCapacity > 0 ? truncateDecimals(Number(vehicle?.engineCapacity * 0.001)) + 'L' : '';

  const vehicleCylindersEngineAndFuel = `${vehicle?.cylinders || ''} ${engineCapacity} ${vehicle?.fuelType?.code || ''}`;

  const vehicleTurbo = `${vehicle?.hasTurbo ? 'Turbo - ' : ' - '}`;

  if (type === 'detailed') {
    return `${vehicleModelVariantSeries} ${vehicleTurbo} ${vehicleDoorsBodyTransmissionDriveType} ${vehicleCylindersEngineAndFuel}`;
  }

  return `${vehicle?.manufacturingYear || ''} ${vehicle?.model?.description || ''} ${vehicle?.variant?.description || ''} ${vehicle?.body?.description || ''} ${
    vehicle?.option?.series || ''
  } ${autoOrManual}`;
}
