import * as ApiConstant from './apiConstant';
import * as apiServiceBase from './apiServiceBase';

const getRenewalPolicyDetails = async function (policyId) {
  const response = await apiServiceBase.HttpGet(`${ApiConstant.getRenewalPolicyDetails.replace('{0}', policyId)}`);
  return response;
};

const setRenewalOfferPreference = async function (setRenewalOfferRequest) {
  const response = await apiServiceBase.HttpPut(ApiConstant.setRenewalOfferPreference, setRenewalOfferRequest);
  return response;
};

const sendRenewalReceiptEmail = async function (sendRenewalReceiptEmailRequest) {
  await apiServiceBase.HttpPost(ApiConstant.sendRenewalReceiptEmail, sendRenewalReceiptEmailRequest);
};

const reinstatePolicy = async function (policyId) {
  const response = await apiServiceBase.HttpPut(`${ApiConstant.reinstatePolicy.replace('{0}', policyId)}`);
  return response;
};

export const renewalOfferApiService = {
  getRenewalPolicyDetails,
  setRenewalOfferPreference,
  sendRenewalReceiptEmail,
  reinstatePolicy,
};
