<template>
  <div class="main-container">
    <racq-form v-show="pageLoaded && !loadFailed" class="pt-4 pb-5">
      <h1 class="text-center my-3 d-md-none">
        <small><ScText :field="fields.Title" /></small>
      </h1>
      <step-tracker v-show="!isDisclaimerStepActive" :steps="steps" :key="reloadSteps" class="py-0 d-md-none mx-auto" />
      <step-tracker v-if="!isDisclaimerStepActive && !renewalJourney && !showingJP" :steps="steps" :key="reloadSteps" class="d-none d-md-block" />
      <step-tracker v-else-if="!isDisclaimerStepActive && renewalJourney && !showingJP" :steps="renewalSteps" class="d-none d-md-block" />
      <placeholder v-show="isDisclaimerStepActive" :rendering="rendering" name="form-disclaimer" class="mx-auto" />
      <div v-show="!isDisclaimerStepActive" :style="{ width: showingJP ? '100%' : 'auto' }">
        <racq-form-step ref="step1" :stepNumber="step1Fields.stepNumber" :stepTitle="step1Fields.stepTitle">
          <step-1
            ref="step1-child"
            :formTitle="params.formTitle"
            @go-jeopardy="showJeopardy"
            @go-next="
              moveToStep('step2');
              sendReviewOnLoadAnalysis();
              renewalJourney = false;
            "
            @renew-policy="
              moveToRenew();
              renewalJourney = true;
            "
            @add-data-analytics="sendOnClickEventAnalysis($event)"
            @step-one-loaded="stepOneLoaded($event)"
            :fields="step1Fields"
            :isDD="isDD"
            :isManualPaid="isManualPaid"
            :showAsAdditional="showAsAdditional"
            :notPaid="notPaid"
            :isDDOptedOut="isDDOptedOut"
            :step="steps[0]"
          />
        </racq-form-step>

        <racq-form-step ref="step2" :stepNumber="step2Fields.stepNumber" :stepTitle="step2Fields.stepTitle">
          <step-2
            ref="step2-child"
            :isDD="isDD"
            :isManualPaid="isManualPaid"
            :showAsAdditional="showAsAdditional"
            :notPaid="notPaid"
            :isDDOptedOut="isDDOptedOut"
            :fields="step2Fields"
            :step="steps[1]"
            @add-data-analytics="sendOnClickEventAnalysis($event)"
            @go-back="moveToStep('step1')"
            @go-next="
              moveToStep('step3', true);
              sendFormCompletedAnalysis();
            "
          />
        </racq-form-step>

        <racq-form-step ref="step3" v-if="insurancePolicy" :stepNumber="step3Fields.stepNumber" :stepTitle="step3Fields.stepTitle">
          <step-3
            ref="step3-child"
            :isDD="isDD"
            :isManualPaid="isManualPaid"
            :showAsAdditional="showAsAdditional"
            :notPaid="notPaid"
            :isDDOptedOut="isDDOptedOut"
            :fields="step3Fields"
            :step="steps[2]"
          />
        </racq-form-step>

        <racq-form-step
          ref="renewstep1"
          v-if="insurancePolicy && insurancePolicy.renewalTermPaymentDetails"
          :stepNumber="renewStep1Fields.stepNumber"
          :stepTitle="renewStep1Fields.stepTitle"
        >
          <LoadingMask v-if="showLoadingMask" />
          <RenewStep1
            ref="renewstep1-child"
            @go-next="moveToStep('renewstep2', true)"
            @go-back="backToStep1()"
            @show-loading-mask="showLoadingMask = true"
            @hide-loading-mask="showLoadingMask = false"
            :fields="renewStep1Fields"
            :step="steps[3]"
          />
        </racq-form-step>

        <racq-form-step
          ref="renewstep2"
          v-if="insurancePolicy && insurancePolicy.renewalTermPaymentDetails"
          :stepNumber="renewStep2Fields.stepNumber"
          :stepTitle="renewStep2Fields.stepTitle"
        >
          <RenewStep2 ref="renewstep2-child" :fields="renewStep2Fields" :step="steps[4]" />
        </racq-form-step>

        <racq-form-step ref="stepjeopardy" :stepTitle="jpFields.jeopardyTitle">
          <placeholder ref="jeopardy-child" :step="jpStepDetail" name="jeopardy" :rendering="rendering" :params="jpParams" />
        </racq-form-step>
      </div>
    </racq-form>
    <div v-show="!pageLoaded || loadFailed" class="w-100 h-100">
      <Loader />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header-container:not(:first-child) {
  display: none;
}
.form-step-curtain {
  height: calc(100% - 16px) !important;
  width: calc(100% - 60px) !important;
}
.form-step-container .step-title h3 span {
  font-size: 17px;
}
/* Overwrite Form Step for UX */
.form-step {
  margin-left: auto !important;
  margin-right: auto !important;
}
.btn:disabled {
  background-color: #d8d8d8 !important;
  color: #838d92 !important;
}
body.racq-forms .font-arial {
  font-family: Arial;
}
@media (max-width: 767px) {
  .multi-step-form .form-step-container .form-step .form-step-content {
    padding: 10px 20px 6px;
  }
}
</style>

<script>
import Step1 from './steps/Step1AddToCoverForm.vue';
import Step2 from './steps/Step2AddToCoverForm.vue';
import Step3 from './steps/Step3AddToCoverForm.vue';
import StepTracker from '../../foundation/forms/StepTracker.vue';
import RacqForm from '../../foundation/forms/RACQForm.vue';
import RacqFormStep from '../../foundation/forms/RACQFormStep.vue';
import RACQFormsMixins from '../../foundation/forms/mixins/RACQFormsMixins';
import RenewStep1 from '@/components/feature/renewalofferform/steps/Step2RenewalOfferForm.vue';
import RenewStep2 from '@/components/feature/renewalofferform/steps/Step3RenewalOfferForm.vue';
import { isExperienceEditorActive, Placeholder, Text } from '@sitecore-jss/sitecore-jss-vue';
import FormSteps from './steps/constants/steps.const.js';
import FormStepsRenewal from './steps/constants/steps-renewal.const.js';
import { jpCodes } from './steps/shared/jpCodes';
import { mapState } from 'vuex';
import { apiIdentityService } from '@/components/foundation/shared/apiservices/apiIdentityService';
import Loader from '@/components/foundation/forms/Loader.vue';
import DataAnalytics from './steps/constants/data-analytics.const';
import { loginApiService } from '@/components/foundation/shared/apiservices/loginApiService';
import LoadingMask from '@/components/foundation/forms/LoadingMask.vue';

export default {
  name: 'AddToCoverForm',
  components: {
    Step1,
    Step2,
    Step3,
    RenewStep1,
    RenewStep2,
    RacqForm,
    RacqFormStep,
    StepTracker,
    ScText: Text,
    Placeholder,
    Loader,
    LoadingMask,
  },
  mixins: [RACQFormsMixins],
  computed: {
    ...mapState('renewalOffer', ['email', 'insurancePolicy', 'hasJeopardy']),
    ...mapState({
      newTotalPremium: (state) => state.addToCoverFormModule.newTotalPremium,
      identity: (state) => state.identityModule.identity,
      quoteSessionId: (state) => state.addToCoverFormModule.quoteSessionId,
      hasError: (state) => state.addToCoverFormModule.error.length,
      coverages: (state) => state.addToCoverFormModule.localCoverages,
    }),
    showingJP() {
      return this.currentStep.stepControlName == 'stepjeopardy';
    },
    pageLoaded: function () {
      return (this.identity && this.insurancePolicy && this.quoteSessionId) || this.hasJeopardy || this.hasError;
    },
    notPaid: function () {
      return (
        !this.insurancePolicy ||
        !this.insurancePolicy.currentTermPaymentDetails ||
        !!this.insurancePolicy.currentTermPaymentDetails.totalAmountDue ||
        !this.insurancePolicy.renewalTermPaymentDetails ||
        !!this.insurancePolicy.renewalTermPaymentDetails.totalAmountDue
      );
    },
    isDD: function () {
      return this.insurancePolicy && (this.insurancePolicy.currentRenewalPreference != undefined || this.insurancePolicy.futureRenewalPreference != undefined);
    },
    // Only show the additional wording if manual paid or dd opted-out accepted.
    showAsAdditional: function () {
      if (!this.insurancePolicy) return false;
      else if (
        this.insurancePolicy.renewalTermAcceptance || // Opted-out renewal accepted
        this.isManualPaid
      ) {
        // Manual Paid
        return true;
      }
      return false; // always show total
    },
    isDDOptedOut: function () {
      return this.isDD && this.insurancePolicy.currentRenewalPreference == false;
    },
    isManualPaid: function () {
      return (
        !this.isDD && // Not a Direct Debit Policy
        this.insurancePolicy &&
        this.insurancePolicy.renewalTermPaymentDetails &&
        (!this.insurancePolicy.currentTermPaymentDetails ||
          !this.insurancePolicy.currentTermPaymentDetails.totalAmountDue ||
          !this.insurancePolicy.currentTermPaymentDetails.isPaymentDue) && // no current term amount due
        this.insurancePolicy.premium > this.insurancePolicy.renewalTermPaymentDetails.totalAmountDue
      ); // Premium Partially Paid
    },
    jpFields: function () {
      try {
        return this.fields.jeopardyFolder.childItems[0].fields;
      } catch (error) {
        return {};
      }
    },
    step1Fields: function () {
      try {
        return this.fields.childItems[0].fields.childItems[1].fields.childItems[0].fields;
      } catch (error) {
        return {};
      }
    },
    step2Fields: function () {
      try {
        return this.fields.childItems[0].fields.childItems[1].fields.childItems[1].fields;
      } catch (error) {
        return {};
      }
    },
    step3Fields: function () {
      try {
        return this.fields.childItems[0].fields.childItems[1].fields.childItems[2].fields;
      } catch (error) {
        return {};
      }
    },
    renewStep1Fields: function () {
      try {
        return this.fields.childItems[0].fields.childItems[1].fields.childItems[3].fields;
      } catch (error) {
        return {};
      }
    },
    renewStep2Fields: function () {
      try {
        return this.fields.childItems[0].fields.childItems[1].fields.childItems[4].fields;
      } catch (error) {
        return {};
      }
    },
    jpStepDetail: function () {
      return this.steps[this.steps.length - 1];
    },
    jpParams: function () {
      return {
        callUsVisible: true,
        jpCode: this.jpcode,
        displayCode: this.jpcode,
        additionalComments: this.additionalComments,
        stepDetails: this.stepDetails,
        formName: this.formName,
        btnBackText: 'Back',
      };
    },
  },
  data: function () {
    return {
      reloadSteps: 0,
      jpcode: undefined,
      displayCode: undefined,
      additionalComments: undefined,
      stepDetails: undefined,
      formName: undefined,
      isDisclaimerStepActive: true,
      steps: FormSteps,
      renewalSteps: FormStepsRenewal,
      currentStep: {},
      renewalJourney: false,
      loadFailed: false,
      amountDue: null,
      showRenewal: null,
      showLoadingMask: false,
    };
  },
  mounted() {
    this.currentStep = this.steps[0];
    this.$refs[this.currentStep.stepControlName].moveInto();
    for (var i = 0; i < this.steps.length - 1; i++) {
      //length - 1 because we don't want to include jeopardy step
      this.steps[i].stepTitle = this.fields.childItems[0].fields.childItems[1].fields.childItems[i].fields.stepTitle.value;
      this.renewalSteps[i].stepTitle = this.fields.childItems[0].fields.childItems[1].fields.childItems[i].fields.stepTitle.value;
    }
    //global event to show jeopardy on any step
    this.$root.$on('show-jeopardy', (jpData) => {
      this.isDisclaimerStepActive = false;
      if (!isExperienceEditorActive())
        //do not show jeopardy if experience editor is running
        this.showJeopardy(jpData);
    });
    // hide dod if agreed
    this.$root.$on('proceed-to-form', () => {
      this.isDisclaimerStepActive = false;
      window.scroll(0, 0);
      this.sendMoveToStepOneAnalysis();
      this.sendOnClickEventAnalysis({
        step: 'Disclaimer',
        type: 'Button',
        region: 'Bottom',
        name: 'Continue',
      });
    });
    // hide dod if agreed
    this.$root.$on('show-disclaimer', () => {
      this.isDisclaimerStepActive = true;
    });

    this.$root.$on('cancel-form', () => {
      this.sendOnClickEventAnalysis({
        step: this.currentStep.stepTitle,
        type: 'Button',
        region: 'bottom-left',
        name: 'Cancel',
      });
    });

    // back to account overview
    this.addCancelFormEvent();

    // hide dod if agreed
    this.$root.$on('disagree-form', () => {
      this.isDisclaimerStepActive = false;
      this.showJeopardy({
        jpCode: jpCodes.disagreeDod,
        displayCode: jpCodes.disagreeDod,
        stepDetails: this.steps[this.steps.length - 1],
        additionalComments: 'User disagree terms and conditions.',
        formName: this.name,
      });
      this.sendOnClickEventAnalysis({
        step: 'Disclaimer',
        type: 'Button',
        region: 'bottom-right',
        name: 'I do not agree',
      });
    });

    // Load Policy Detail
    const vm = this;
    const _logout = loginApiService.logOut;
    this.$store.dispatch('addToCoverFormModule/getPolicyRenewal', {
      policyNumber: this.$route.query.policyNumber,
      callback: (res, error) => {
        if (res) {
          vm.$store.dispatch('renewalOffer/fetchInsurancePolicy', vm.$route.query.policyNumber);
          vm.sendPolicyLoadedAnalysis();
        } else if (error && error.toString().includes('status code 401')) {
          // Wait for sign in redirect
          this.loadFailed = true;
          setTimeout(() => {
            _logout().then((url) => {
              if (window && window.location) window.location.href = url;
            });
          }, 30 * 1000);
        } else {
          vm.isDisclaimerStepActive = false;
          vm.showJeopardy({
            jpCode: jpCodes.genericError,
            displayCode: jpCodes.genericError,
            additionalComments: `Unable to load policy detail for ${this.$route.query.policyNumber}.`,
            stepDetails: vm.steps[vm.steps.length - 1],
            formName: vm.name,
          });
        }
      },
    });

    // Load Identity
    apiIdentityService
      .getIdentityDetails()
      .then((identity) => {
        if (identity) this.$store.dispatch('identityModule/addIdentityData', identity);
        else throw 'Identity not found.';
      })
      .catch(() => {
        this.isDisclaimerStepActive = false;
        this.showJeopardy({
          jpCode: jpCodes.genericError,
          displayCode: jpCodes.genericError,
          additionalComments: 'Unable to load user identity.',
          stepDetails: this.steps[this.steps.length - 1],
          formName: this.name,
        });
      });
  },
  methods: {
    /**
     * Push data analytics
     */
    sendPageLoadAnalysis: function () {
      const payload = JSON.parse(JSON.stringify(DataAnalytics.FormLoaded));
      payload.web.webPageDetails.browserURL = `${location.protocol}//${location.hostname}${location.pathname}`;
      payload.web.pageInfo.previousPage = window.document.referrer;
      payload.web.pageInfo.versionID = this.rendering && this.rendering.uid;
      this.$root.$emit('add-data-analytics', payload);
    },
    sendPolicyLoadedAnalysis: function () {
      const payload = JSON.parse(JSON.stringify(DataAnalytics.PolicyLoaded));
      this.$root.$emit('add-data-analytics', payload);
    },
    sendMoveToStepOneAnalysis: function () {
      const payload = JSON.parse(JSON.stringify(DataAnalytics.SelectBenefitsClickedNext));
      if (this.showRenewal && this.amountDue) payload.form.amount = this.amountDue;
      this.$root.$emit('add-data-analytics', payload);
    },
    sendFormCompletedAnalysis: function () {
      const payload = JSON.parse(JSON.stringify(DataAnalytics.FormComplete));
      payload.form.Amount = this.newTotalPremium;
      this.$root.$emit('add-data-analytics', payload);
    },
    sendReviewOnLoadAnalysis: function () {
      const payload = JSON.parse(JSON.stringify(DataAnalytics.ReviewStepOnLoad));
      payload.form.details = this.coverages.map((coverage) => ({
        productName: [
          coverage.includeHireCar && !coverage.hireCarExisted ? 'Hire car after an incident' : '',
          coverage.includeWindscreen && !coverage.windscreenExisted ? 'Excess free windscreen' : '',
        ],
        productCategory: 'Optional benefits',
      }));
      this.$root.$emit('add-data-analytics', payload);
    },
    sendOnClickEventAnalysis: function (evt) {
      const payload = JSON.parse(JSON.stringify(DataAnalytics.NavigationLinkClick));
      payload.eventInfo.navigation.name = evt.name;
      payload.eventInfo.navigation.type = evt.type;
      payload.eventInfo.promoInfo.pageRegion = evt.region;
      payload.form.stepName = evt.step;
      this.$root.$emit('add-data-analytics', payload);
    },
    stepOneLoaded(evt) {
      this.amountDue = evt.amountDue;
      this.showRenewal = evt.showRenewal;
    },
    /**
     * Go to renew journey
     */
    moveToRenew: function () {
      const steps = JSON.parse(JSON.stringify(this.steps));
      const renewStep1 = steps.find((step) => step.stepControlName == 'renewstep1');
      renewStep1.hideFromNavigation = false;
      const step2 = steps.find((step) => step.stepControlName == 'step2');
      step2.hideFromNavigation = true;
      this.steps = steps;
      this.moveToStep('renewstep1');
    },
    backToStep1: function () {
      const steps = JSON.parse(JSON.stringify(this.steps));
      const renewStep1 = steps.find((step) => step.stepControlName == 'renewstep1');
      renewStep1.hideFromNavigation = true;
      const step2 = steps.find((step) => step.stepControlName == 'step2');
      step2.hideFromNavigation = false;
      this.steps = steps;
      this.moveToStep('step1', true);
    },
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
    },
  },
  watch: {
    insurancePolicy(newValue, oldValue) {
      if (newValue && !oldValue) {
        // Get Session Quote ID
        const vm = this;
        this.$store.dispatch('addToCoverFormModule/getQuoteSessionId', {
          policyNumber: newValue.policyNumber,
          callback: (error) => {
            this.sendPageLoadAnalysis();
            if (!error) return;
            vm.isDisclaimerStepActive = false;
            vm.showJeopardy({
              jpCode: jpCodes.genericError,
              displayCode: jpCodes.genericError,
              additionalComments: 'Unable to create quote session id.',
              stepDetails: vm.steps[vm.steps.length - 1],
              formName: vm.name,
            });
          },
        });
      }
    },
    hasJeopardy(newValue) {
      if (newValue) {
        this.$root.$emit('show-jeopardy', {
          displayCode: jpCodes.genericError,
          jpCode: jpCodes.genericError,
          formName: this.name,
        });
      }
    },
  },
};
</script>
