<template>
  <div class="user-title-first-last-name" :id="id">
    <div class="form-fields-flex-wrapper">
      <FormulateInput
        id="title"
        name="title"
        class="flex-field-with-label title"
        :options="personTitles"
        type="selectbox"
        placeholder="Title"
        label="Your name"
        validation="required"
        :validation-messages="{
          required: 'Please select a title',
        }"
        validation-name="Title"
        error-behavior="live"
        @validation="titleValidation = $event"
      />

      <FormulateInput
        validation-name="First name"
        class="firstName"
        type="text"
        name="firstName"
        label=""
        placeholder="First name"
        validation="bail|required|alpha"
        :validation-messages="{
          required: 'Please enter your first name',
          alpha: validationMessages.firstNameAlpha,
        }"
        @validation="firstNameValidation = $event"
      />

      <FormulateInput
        validation-name="Last name"
        class="lastName"
        type="text"
        name="lastName"
        label=""
        placeholder="Last name"
        validation="bail|required|alpha"
        :validation-messages="{
          required: 'Please enter your last name',
          alpha: validationMessages.lastNameAlpha,
        }"
        @validation="lastNameValidation = $event"
      />
    </div>

    <ul v-if="titleValidation.hasErrors" class="formulate-input-errors">
      <li
        role="status"
        aria-live="polite"
        class="formulate-input-error"
        v-for="(error, index) in titleValidation.errors"
        :key="`${error}_${index}`"
        v-text="error"
      />
    </ul>

    <ul v-if="firstNameValidation.hasErrors" class="formulate-input-errors">
      <li
        role="status"
        aria-live="polite"
        class="formulate-input-error"
        v-for="(error, index) in firstNameValidation.errors"
        :key="`${error}_${index}`"
        v-text="error"
      />
    </ul>

    <ul v-if="lastNameValidation.hasErrors" class="formulate-input-errors">
      <li
        role="status"
        aria-live="polite"
        class="formulate-input-error"
        v-for="(error, index) in lastNameValidation.errors"
        :key="`${error}_${index}`"
        v-text="error"
      />
    </ul>
  </div>
</template>

<script>
import { getPersonTitles } from '@/components/foundation/shared/apiservices/apiRefdataService';
import { validationMessages } from '@/components/feature/newmotorinsuranceform/services/mappings';

export default {
  name: 'TitleFirstLastName',

  props: {
    formValues: {
      type: Object,
    },
    id: {
      type: String,
      required: true,
    },
  },

  created() {
    this.fetchPersonTitles();
  },

  data() {
    return {
      validationMessages,
      personTitles: [],
      titleValidation: {
        hasErrors: false,
        errors: [],
      },
      firstNameValidation: {
        hasErrors: false,
        errors: [],
      },
      lastNameValidation: {
        hasErrors: false,
        errors: [],
      },
    };
  },

  methods: {
    async fetchPersonTitles() {
      const personTitles = await getPersonTitles();

      this.personTitles = personTitles.map((title) => ({ value: title.Value, label: title.Name }));
    },
  },
};
</script>

<style lang="scss">
.user-title-first-last-name {
  .formulate-input-errors {
    margin-top: -1rem;
  }

  .forms-input-wrapper .formulate-input-wrapper .formulate-input-element.formulate-input-element--selectbox {
    min-width: 100px !important;
    margin-left: 148px;
  }

  .formulate-input-element--text {
    width: 100% !important;
  }

  input[type='text'] {
    width: 150px !important;
  }

  .title .formulate-input-errors,
  .firstName .formulate-input-errors,
  .lastName .formulate-input-errors {
    margin-left: 0;
    padding-left: 1rem;
    display: none;
  }
}
</style>
