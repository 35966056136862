<template>
  <div>
    <sc-rich-text :field="fields.stepContent" />
    <div class="form-elements-container">
      <FormulateForm @submit="onSubmit">
        <div class="forms-light-panel">
          <h5>
            <sc-text :field="fields.cancelHeading" />
          </h5>
          <div class="product-details-container">
            <sc-image v-if="insuranceProduct.insurancePolicy.coverages[0].isMotor" :media="fields.motorIcon"></sc-image>
            <sc-image v-if="insuranceProduct.insurancePolicy.coverages[0].isPet" :media="fields.petIcon"></sc-image>
            <sc-image v-if="insuranceProduct.insurancePolicy.coverages[0].isHome" :media="fields.homeIcon"></sc-image>
            <div class="product-details-content">
              <sc-rich-text :field="productDescription" />
            </div>
          </div>
          <div v-for="(item, index) in this.getCombinedStepData(this.fields)" :key="index" class="review-table-content">
            <div class="header-content-title">
              <h5>
                <sc-text :field="item.heading" />
              </h5>
            </div>
            <div v-if="item && item.data">
              <div v-for="(stepValue, index) in item.data" :key="index" class="review-table-row">
                <div class="review-table-column">
                  <div class="review-table-label">
                    {{ stepValue.label }}
                  </div>
                  <div class="review-table-value">
                    {{ stepValue.text ? stepValue.text : stepValue.value }}
                  </div>
                  <div class="review-edit">
                    <a href="#"> Edit </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="warning-note">
          <sc-image :media="fields.policyNoteIcon"></sc-image>
          <div class="text-container">
            <sc-rich-text :field="fields.policyNote"></sc-rich-text>
          </div>
        </div>
        <div class="form-buttons">
          <FormulateInput type="button" name="Back" input-class="btn btn-secondary" @click="goBack" v-show="!hideButtons" outer-class="" />

          <FormulateInput type="submit" name="Submit" v-show="!hideButtons" input-class="btn btn-primary" outer-class="" />
          <FormulateInput
            type="button"
            name="Edit"
            style="margin-left: auto"
            v-show="hideButtons"
            @click="onEdit"
            input-class="btn btn-primary btn-edit"
            outer-class=""
          />
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-vue';
import Vuex from 'vuex';
import { productApiService } from '../../../foundation/shared/apiservices/apiProductService';
import { jpCodes } from './shared/jpCodes';
import RACQFormsMixin from '../../../foundation/forms/mixins/RACQFormsMixins';
export default {
  name: 'Step4CancelInsurance',
  components: {
    ScText: Text,
    ScRichText: RichText,
    ScImage: Image,
  },
  mixins: [RACQFormsMixin],
  mounted() {
    if (this.insuranceProduct && !this.isExperienceEditorRunning) {
      this.productDescription = this.fields.productDescription;
      this.productDescription.value = this.productDescription.value.replaceAll('${insuranceType}', this.insuranceProduct.insuranceType);
      this.productDescription.value = this.productDescription.value.replaceAll('${coverageDetails}', this.insuranceProduct.coverageDetails);
      this.productDescription.value = this.productDescription.value.replaceAll('${policyNumber}', this.insuranceProduct.insurancePolicy.policyNumber);
    }
  },
  data() {
    return {
      productDescription: {},
    };
  },
  methods: {
    goBack() {
      this.$emit('go-back');
    },
    async onSubmit() {
      try {
        const response = await productApiService.cancelInsurance(this.getCancelInsuranceModel());
        if (response.workOrderId) {
          this.$emit('go-next');
        } else {
          this.$root.$emit('show-jeopardy', {
            displayCode: jpCodes.genericError,
            jpCode: jpCodes.internalServerError,
            stepDetails: this.step.stepTitle,
            formName: 'Cancel Insurance Form',
          });
        }
      } catch (error) {
        console.error('error occurred cancelling insurance', error);
        this.$root.$emit('show-jeopardy', {
          displayCode: jpCodes.genericError,
          jpCode: jpCodes.internalServerError,
          stepDetails: this.step.stepTitle,
          formName: 'Cancel Insurance Form',
        });
      }
    },
    getModel() {},
    onEdit() {
      this.$emit('visibility-requested');
    },
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...Vuex.mapGetters('cancelInsuranceModule', ['getCombinedStepData', 'getCancelInsuranceModel']),
    ...Vuex.mapState({
      insuranceProduct: (state) => state.cancelInsuranceModule.insuranceProduct,
    }),
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    step: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
