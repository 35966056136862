<template>
  <div>
    <sc-rich-text :field="fields.stepContent" />

    <div>
      <summary-panel :items-to-display="itemsToReview" :enable-edit="true" :fields="fields" @edit-clicked="goBack">
        <!-- put some custom content in the default slot -->
        <sc-rich-text :field="fields.reviewHeader" class="step-4__review-header" />

        <div class="step4__review-columns">
          <div class="step4__property-icon">
            <sc-image :media="fields.houseIcon"></sc-image>
          </div>
          <div class="step4__property-details">
            <div class="step4__property-street">{{ insuranceStreetAddress }}</div>
            <div>{{ insuranceSuburbState }}</div>
          </div>
        </div>
      </summary-panel>

      <div class="form-buttons">
        <FormulateInput type="button" :name="fields.previousButtonText.value" input-class="btn btn-secondary" outer-class="" @click="goBack" />

        <FormulateInput type="submit" :name="fields.nextButtonText.value" input-class="btn btn-primary" outer-class="" @click="onSubmit" />
      </div>
    </div>
  </div>
</template>

<script>
import { RichText, Image } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormsMixins from '../../../foundation/forms/mixins/RACQFormsMixins';
import { mapState, mapGetters, mapActions } from 'vuex';
import SummaryPanel from './shared/SummaryPanel.vue';

export default {
  name: 'Step4CycloneInsurance',
  components: {
    ScRichText: RichText,
    ScImage: Image,
    SummaryPanel,
  },
  computed: {
    ...mapState({
      insuranceProduct: (state) => state.cycloneInsuranceModule.insuranceProduct,
      step3FormValues: (state) => state.cycloneInsuranceModule.formState['step3'],
    }),
    ...mapGetters({
      insuranceStreetAddress: 'cycloneInsuranceModule/insuranceStreetAddress',
      insuranceSuburbState: 'cycloneInsuranceModule/insuranceSuburbState',
      buildingTypeDescription: 'cycloneInsuranceModule/buildingTypeDescription',
    }),
    itemsToReview() {
      // display the property type at the top
      let finalItemsToReview = [{ value: this.buildingTypeDescription, enableEditValue: false, label: this.getLabel('propertyType') }];

      // add additional properties for the items in the specified order
      // so we know how to display the review items
      if (this.step3FormValues) {
        this.step3FormValues.forEach((item) => {
          const label = this.getLabel(item.key);
          if (item.value) {
            finalItemsToReview.push({ value: item.displayValue, label: label, enableEditValue: true });
          }
        });
      }

      return finalItemsToReview;
    },
  },
  methods: {
    ...mapActions('cycloneInsuranceModule', ['updateFloodCycloneDetails']),
    async onSubmit() {
      await this.updateFloodCycloneDetails();
      this.$emit('go-next');
    },
    goBack() {
      this.$emit('go-back');
    },
    getLabel(propertyKey) {
      const fieldName = `${propertyKey}Label`;
      return this.fields[fieldName]?.value;
    },
  },
  mixins: [RACQFormsMixins],
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
.step4__property-icon {
  display: flex;
  width: 25%;
  flex-direction: row-reverse;
  margin-right: 50px;
  padding-right: 5px;
}

.step4__property-details {
  display: flex;
  flex-direction: column;
}

.step4__property-street {
  font-weight: bold;
}

.step4__review-columns {
  border-bottom: 1px solid $racq-navy-30p;
  padding: 16px;
  display: flex;
}
</style>
