<template>
  <div>
    <div class="step-content">
      <div v-if="isExperienceEditorRunning || IsRenewalOfferForm"><sc-rich-text :field="fields.renewalFormJeopardyContent" /></div>
      <div v-else>
        <p><span v-html="isExperienceEditorRunning ? '' : getJeopardyMessage()"></span></p>
        <sc-rich-text :field="fields.jeopardyContent" />
      </div>
    </div>
    <div class="form-elements-container">
      <div class="form-jeopardy-wrapper">
        <div class="chat-container" v-if="chatVisible">
          <h3><sc-text :field="fields.liveChatHeading" /></h3>
          <div class="chat-text-content">
            <sc-text :field="fields.liveChatContent" />
          </div>
          <div class="chat-button-holder">
            <button @click="startChat" class="btn btn-primary cta btn-arrow">
              <sc-text :field="fields.liveChatButtonText" />
            </button>
          </div>
          <div class="text-wrapper">
            <sc-text :field="fields.liveChatTimingText" />
          </div>
        </div>
        <div class="chat-container callback-container" v-if="callbackVisible">
          <h3><sc-text :field="fields.callBackHeading" /></h3>
          <div class="chat-text-content">
            <sc-text :field="fields.callBackContent" />
          </div>
          <div class="chat-button-holder">
            <button @click="goToCallBackForm" class="btn btn-primary cta btn-arrow">
              <sc-text :field="fields.callBackButtonText" />
            </button>
          </div>
          <div class="text-wrapper">
            <sc-text :field="fields.callBackChatResponseMessage" />
          </div>
        </div>

        <div class="phone-container" v-if="callUsVisible">
          <h3><sc-text :field="fields.callusHeading" /></h3>
          <div class="phone-text-content">
            <sc-text :field="fields.callusContent" />
          </div>
          <strong>
            <span v-if="IsRenewalOfferForm" class="phone-text"><sc-text :field="fields.renewalFormCallUsNumber" /></span>
            <span v-else class="phone-text"><sc-text :field="fields.callUsNumber" /></span>
          </strong>
        </div>
      </div>
      <div class="form-buttons">
        <FormulateInput
          type="button"
          :name="params.btnBackText || 'Cancel'"
          input-class="btn btn-secondary"
          v-show="!hideButtons"
          outer-class=""
          @click="goBack"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormsMixin from './mixins/RACQFormsMixins';
export default {
  name: 'Jeopardy',
  components: {
    ScText: Text,
    ScRichText: RichText,
  },
  mixins: [RACQFormsMixin],
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
    },
    step: {
      type: [Object, Number],
    },
  },
  data() {
    return {
      jpMessage: '',
      chatVisible: false,
      callbackVisible: this.params.callbackVisible,
      callUsVisible: this.params.callUsVisible, //by default we are not showing number to users to call us
    };
  },
  methods: {
    /**
     * Start GenSys Chat
     */
    startChat() {
      const selfForm = this;
      const jpItem = this.fields.codes.childItems.find((elem) => elem.fields.jpCode.value == selfForm.params.jpCode);
      console.info(jpItem);
      //this is to start the chat and it is captured in LiveChat.vue - passsing jeopardy details to Genesys
      if (jpItem)
        this.$root.$emit('start-chat', this.params.jpCode, jpItem.fields.jpDescription.value, this.params.additionalComments, this.params.cancellationReasons);
    },
    goBack() {
      this.$emit('go-back');
      this.$root.$emit('cancel-form'); //redirect to account overview page
    },
    goToCallBackForm() {
      var url = this.fields.callBackFormLink.value.href + '?policyid=';
      url += this.$route.query.policyId ? this.$route.query.policyId : this.$route.query.policyNumber;
      window.location.href = url;
    },
    getJeopardyMessage() {
      const selfForm = this;
      const jpItem = this.fields.codes.childItems.find((elem) => elem.fields.jpCode.value == selfForm.params.displayCode);
      return (jpItem && jpItem.fields.jpDescription.value) || '';
    },
    getAnalyticsModel(jpItem) {
      var formAnalyticsModel = this.getAnalyticsBaseModel();
      formAnalyticsModel.event = 'form.jeopardy';
      if (this.$store.state.cancelRoadsideModule.assistanceProduct) {
        formAnalyticsModel.form.productCategory = this.$store.state.cancelRoadsideModule.assistanceProduct.productCover;
      }
      formAnalyticsModel.form.stepName = this.params.stepDetails;
      formAnalyticsModel.form.jeopardy = jpItem.fields.jpCode.value;
      formAnalyticsModel.form.jeopardyDetail = jpItem.fields.jpDescription.value;
      return formAnalyticsModel;
    },
    getJeopardyDataForCallBack(jpItemWithError) {
      return {
        jpCode: jpItemWithError.fields.jpCode.value,
        jpDescription: jpItemWithError.fields.jpDescription.value,
        cancellationReasons: this.params.cancellationReasons,
        additionalComments: this.params.additionalComments,
        formName: this.params.formName,
      };
    },
  },
  mounted() {
    this.$root.$on('show-chat', (showChat) => {
      this.chatVisible = showChat;
    });
  },
  updated() {
    if (this.params.displayCode) {
      this.$store.commit('callBackFormModule/clearCallBackFormJeopardyData');
      const selfForm = this;
      let jpItemWithError = this.fields.codes.childItems.filter(function (elem) {
        if (elem.fields.jpCode.value == selfForm.params.jpCode) return elem;
      })[0];
      // If jp code not set in cms, use default
      if (!jpItemWithError) {
        jpItemWithError = this.fields.codes.childItems[0];
      }
      this.$store.commit('callBackFormModule/addCallBackFormJeopardyData', this.getJeopardyDataForCallBack(jpItemWithError));
      this.$root.$emit('add-data-analytics', this.getAnalyticsModel(jpItemWithError));
    }
  },

  computed: {
    IsRenewalOfferForm() {
      return this.params.formName === 'Renewal Offer Form';
    },
  },
};
</script>
