<template>
  <div class="summary-panel__light-panel">
    <!-- add default slot for any customizations -->
    <slot> </slot>

    <div class="summary-panel__review-columns" v-for="item in itemsToDisplay" :key="item.key">
      <div class="summary-panel__review-item-label">{{ item.label }}</div>
      <div class="summary-panel__review-item-value">{{ item.value }}</div>
      <a href="javascript:void(0);" @click="linkClicked(item.key, item.value)" class="summary-panel__review-item-edit" v-if="enableEdit && item.enableEditValue"
        ><sc-text :field="fields.editLabel"
      /></a>
    </div>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'SummaryPanel',
  components: {
    ScText: Text,
  },
  methods: {
    linkClicked(key, value) {
      this.$emit('edit-clicked', key, value);
    },
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    enableEdit: {
      type: Boolean,
      default: false,
    },
    itemsToDisplay: {
      type: Array,
      default: () => [{ key: 'default item key', label: 'default label', value: 'default value', enableEditValue: true }],
    },
  },
};
</script>

<style lang="scss">
.summary-panel__light-panel {
  background-color: $racq-grey-background;
  border-radius: 3px;
  padding: 24px 30px 40px 20px;
  margin: 40px 0px;
}

.summary-panel__review-columns {
  border-bottom: 1px solid $racq-navy-30p;
  padding: 16px;
  display: flex;
}

.summary-panel__review-item-label {
  width: 25%;
  text-align: right;
  margin-right: 50px;
  color: $racq-navy;
}

.summary-panel__review-item-value {
  align-self: center;
}

.summary-panel__review-item-edit {
  width: 30px;
  margin-left: auto;
  align-self: center;
  text-decoration: underline;
}
</style>
