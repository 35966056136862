<template>
  <div>
    <div id="lvv-warning-container" :class="lvvAccepted ? 'lvv-accepted-state' : 'lvv-initial-state'">
      <div class="lvv-warning-tooltip">
        <FormulateInput
          type="hidden"
          :tooltip="{
            content: 'tooltipContent',
            tooltipId: `lvv-warning-id`,
            triggerIcon: 'info-icon-transparent-blue',
          }"
        ></FormulateInput>
      </div>

      <div class="lvv-warning-content-wrapper">
        <div class="d-flex flex-col">
          <slot name="lvv-warning-content">
            <div class="lvv-warning-content">
              <p v-html="formattedInitialBodyText" v-if="!lvvAccepted" />
              <p v-html="acceptedBodyText" v-if="lvvAccepted" />
            </div>
          </slot>
        </div>

        <slot name="lvv-warning-footer" v-if="!lvvAccepted">
          <div class="lvv-warning-footer">
            <!-- <p v-text="footerText" /> -->

            <div>
              <button type="button" class="btn btn-secondary" @click="onAccept" v-text="primaryBtnText" />
              <button type="button" class="btn btn-secondary" @click="onDecline" v-text="secondaryBtnText" />
            </div>
          </div>
        </slot>
      </div>
    </div>
    <FormulateInput type="hidden" ignored name="lvv-accepted" v-model="lvvAccepted" />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { NEW_MOTOR_INSURANCE_MODULE, policyCoverageTypes } from '@/components/feature/newmotorinsuranceform/services/mappings';

export default {
  name: 'LowVehicleValueWarning',

  props: {
    initialBodyText: {
      type: String,
      default: 'Based on your information, there may be a level of cover more suitable for you insurance needs. Lorem ipsum text add here into sitecore.',
    },
    acceptedBodyText: {
      type: String,
      default: 'You have accepted that this level of cover may not be the most suitable for your insurance needs. Lorem ipsum text add here into sitecore.',
    },
    secondaryBtnText: {
      type: String,
      default: 'No',
    },
    primaryBtnText: {
      type: String,
      default: 'Yes',
    },
    // footerText: {
    //   type: String,
    //   default: 'Would you still like to continue purchasing XXX insurance?',
    // },
    secondaryBtnAction: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      lvvAccepted: null,
    };
  },

  computed: {
    ...mapGetters(NEW_MOTOR_INSURANCE_MODULE, ['coverageCode']),

    formattedInitialBodyText() {
      const type = policyCoverageTypes[this.coverageCode];
      return this.initialBodyText.replace("#policyCoverageTypes#", type);
    }
  },

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, ['UPDATE_JEOPARDY_ACTIVE']),

    onAccept() {
      this.lvvAccepted = true;
      this.$emit('lvvWarningAccepted', true);
    },

    onDecline() {
      this.lvvAccepted = false;
      this.UPDATE_JEOPARDY_ACTIVE({ jeopardyActive: true });
      // TODO: Trigger jeopardy if user does not accept.
      // this.secondaryBtnAction();
    },
  },
};
</script>

<style lang="scss">
#lvv-warning-container {
  background-color: $racq-grey-background;
  border-radius: 4px;
  padding: 1rem 0 1.5rem 0.5rem;
  margin-bottom: 1rem;
  display: flex;

  &.lvv-accepted-state {
    border: 2px solid $racq-navy-30p;
  }

  &.lvv-initial-state {
    border: 2px solid #f09400;
  }

  .lvv-warning-tooltip {
    // TODO: Get the width of the hidden input to play nice

    .forms-input-wrapper {
      margin: 0 !important;
      min-width: 100px !important;
      max-width: 100px !important;

      .formulate-input-wrapper {
        margin: 0 !important;
      }
    }
  }

  .lvv-warning-content-wrapper {
    position: relative;
    left: -3rem;
  }

  .lvv-warning-header {
    border: none;
    padding: 0 0 0.5rem;

    .lvv-warning-header-text {
      font-size: 1.5rem;
    }
  }

  .lvv-warning-content {
    border: none;
    padding: 0;
  }

  .lvv-warning-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid #f1f1f1;
    padding: 1rem 0 0;
  }
}
</style>
