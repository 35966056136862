<template>
  <div style="display: none">
    <p>AdobeAnalytics Component</p>
  </div>
</template>

<script>
export default {
  name: 'PageAnalyticsData',
  mounted() {
    const payload = {
      event: 'pageLoaded',
      pageInfo: {
        previousPage: '',
        pageType: 'forms',
        pageId: this.fields.analyticsData.PageId,
        versionID: this.fields.analyticsData.VersionId,
        pageAttributes: this.fields.analyticsData.PageAttributes,
      },
      web: {
        webPageDetails: {
          URL: this.fields.analyticsData.Url,
          name: this.fields.analyticsData.Title,
          browserURL: document.URL,
        },
      },
    };
    var parsePayload = JSON.parse(JSON.stringify(payload)); //to remove observer from the object and have clone payload
    window.adobeDataLayer && window.adobeDataLayer.push(parsePayload);
    const self = this;
    this.$root.$on('add-data-analytics', (payload) => {
      var parsePayload = JSON.parse(JSON.stringify(payload)); //to remove observer from the object and have clone payload
      self.pushEventToAdobe(parsePayload);
    });
  },
  methods: {
    pushEventToAdobe(payload) {
      if (typeof window === 'undefined') return;
      if (payload && window !== undefined) {
        window.adobeDataLayer && window.adobeDataLayer.push(payload);
        return true;
      } else {
        console.warn('Data not present for the event to be pushed');
        return false;
      }
    },
  },
  components: {},
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
