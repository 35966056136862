import * as ApiConstant from './apiConstant';
import * as apiServiceBase from './apiServiceBase';

async function getLoginUrl(currentUrl) {
  const url = ApiConstant.getLoginUrl.replace('{0}', encodeURIComponent(currentUrl));
  return await apiServiceBase.HttpGet(`${url}`);
}

async function authenticate(code) {
  return await apiServiceBase.HttpPost(
    `${ApiConstant.authenticationUrl}`,
    { code: code, redirect_uri: ApiConstant.isProduction ? '' : ApiConstant.localURL },
    {},
    true
  );
}

async function isAuthenticated() {
  return await apiServiceBase.HttpGet(`${ApiConstant.isAuthenticatedUrl}`);
}

async function logOut() {
  return await apiServiceBase.HttpGet(`${ApiConstant.logoutUrl}`);
}

export const loginApiService = {
  getLoginUrl,
  authenticate,
  isAuthenticated,
  logOut,
};
