export default {
  en: {
    webchat: {
      ChatButton: 'Live Chat',
      ChatStarted: 'Chat Started',
      ChatEnded: 'Chat Ended',
      AgentNameDefault: 'Agent',
      AgentConnected: 'You are now chatting with <%Agent%>',
      AgentDisconnected: '<%Agent%> Disconnected',
      SupervisorNameDefault: 'Supervisor',
      SupervisorConnected: '<%Agent%> Connected',
      SupervisorDisconnected: '<%Agent%> Disconnected',
      AgentTyping: '...',
      AgentUnavailable: 'Sorry. There are no agents available. Please try later',
      ChatTitle: 'Live Chat',
      ChatEnd: 'X',
      ChatClose: 'X',
      ChatMinimize: 'Min',
      ChatFormFirstName: 'First Name',
      ChatFormLastName: 'Last Name',
      ChatFormNickname: 'Nickname',
      ChatFormEmail: 'Email',
      ChatFormSubject: 'Subject',
      ChatFormPlaceholderFirstName: 'Required',
      ChatFormPlaceholderLastName: 'Required',
      ChatFormPlaceholderNickname: 'Optional',
      ChatFormPlaceholderEmail: 'Optional',
      ChatFormPlaceholderSubject: 'Optional',
      ChatFormSubmit: 'Start Chat',
      ChatFormCancel: 'Cancel',
      ChatInputPlaceholder: 'Type your message here',
      ChatInputSend: 'SEND',
      ChatEndQuestion: 'Are you sure you want to end this chat session?',
      ChatEndCancel: 'Cancel',
      ChatEndConfirm: 'End chat',
      ActionsDownload: 'Download transcript',
      ActionsEmail: 'Email transcript',
      ActionsPrint: 'Print transcript',
      ActionsCobrowseStart: 'Start Co-browse',
      ActionsCobrowseStop: 'Exit Co-browse',
      ActionsVideo: 'Invite to Video Chat',
      ActionsTransfer: 'Transfer',
      ActionsInvite: 'Invite',
      InstructionsTransfer: 'Open this link on another device to transfer your chat session</br></br><%link%>',
      InstructionsInvite: 'Share this link with another person to add them to this chat session</br></br><%link%>',
      InviteTitle: 'Need help?',
      InviteBody: 'Let us know if we can help out.',
      InviteReject: 'No thanks',
      InviteAccept: 'Start chat',
      ChatError: 'There was a problem starting the chat session. Please Retry.',
      ChatErrorButton: 'OK',
      RestoreTimeoutTitle: 'Chat ended',
      RestoreTimeoutBody: 'Your previous chat session has timed out. Would you like to start a new one?',
      RestoreTimeoutReject: 'No thanks',
      RestoreTimeoutAccept: 'Start chat',
      EndConfirmBody: 'Would you really like to end your chat session?',
      EndConfirmAccept: 'End chat',
      EndConfirmReject: 'Cancel',
      UnreadMessagesTitle: 'unread',
      AriaYouSaid: 'You said',
      AriaSaid: 'said',
      AriaSystemSaid: 'System said',
      AriaMinimize: 'Live Chat minimize',
      AriaMaximize: 'Live Chat Maximize',
      AriaClose: 'Live chat close',
      Errors: {
        ChatUnavailable: "We're sorry but we cannot start a new chat at this time. Please try again later",
        CriticalFault: 'Your chat session has ended unexpectedly due to an unknown issue. We apologize for the inconvenience',
        StartFailed:
          'There was an issue starting your chat session. Please verify your connection and that you submitted all required information properly, then try again',
        MessageFailed: 'Your message was not received successfully. Please try again',
        RestoreFailed: "We're sorry but we were unable to restore your chat session due to an unknown error",
        TransferFailed: 'Unable to transfer chat at this time. Please try again later',
        InviteFailed: 'Unable to generate invite at this time. Please try again later',
        ChatServerWentOffline: "Messages are currently taking longer than normal to get through. We're sorry for the delay",
        RestoredOffline: "Messages are currently taking longer than normal to get through. We're sorry for the delay",
        Disconnected: "<div style='text-align:center'>Connection Lost.<br/>Please check your connection</div>",
        Reconnected: "<div style='text-align:center'>Connection Restored</div>",
        102: 'First name is required',
        103: 'Last name is required',
        161: 'Please enter your name',
        201: 'Maximum number of files exceeded',
        202: 'Maximum filesize exceeded',
        203: 'Unsupported file submitted',
        364: 'Invalid email address',
      },
    },
  },
};
