var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormulateForm',{attrs:{"id":"car-details-form","errors":_vm.formErrors},on:{"submit":_vm.submitFormStep,"failed-validation":_vm.onFailedValidation},model:{value:(_vm.formValues),callback:function ($$v) {_vm.formValues=$$v},expression:"formValues"}},[_c('div',{staticClass:"step-content pt-4"},[(_vm.foundCarManually)?_c('FormulateInput',_vm._b({attrs:{"validation":"required","validation-messages":{
        required: _vm.validationMessages.defaultRequired,
      },"options":[
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No', id: _vm.motorPolicyModel.isVehicleRegistered + '_last-child' } ]}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.isVehicleRegistered),false)):_vm._e(),(_vm.formValues[_vm.motorPolicyModel.isVehicleRegistered] === 'true')?[_c('FormulateInput',_vm._b({attrs:{"validation":"required","validation-messages":{
          required: _vm.validationMessages.defaultRequired,
        }}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.vehicleRegistration),false))]:_vm._e(),_c('FormulateInput',_vm._b({attrs:{"validation":"required","validation-messages":{
        required: _vm.validationMessages.defaultRequired,
      },"options":_vm.vehicleUseTypeOptions}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.vehicleUseType),false)),_c('hr',{staticClass:"mb-4"}),(_vm.formValues[_vm.motorPolicyModel.vehicleUseType] === _vm.vehicleUseType.Business && _vm.prefillData[_vm.motorPolicyModel.businessUseType])?_c('FormulateInput',_vm._b({attrs:{"value":_vm.prefillData[_vm.motorPolicyModel.businessUseType],"type":"text","disabled":""}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.businessUseType),false)):_vm._e(),(_vm.formValues[_vm.motorPolicyModel.vehicleUseType] === _vm.vehicleUseType.Business && _vm.prefillData[_vm.motorPolicyModel.businessUseType])?_c('FormulateInput',_vm._b({attrs:{"validation":"required|number","validation-messages":{
        required: _vm.validationMessages.businessInputTaxCreditRequired,
        number: _vm.validationMessages.businessInputTaxCreditNumber,
      }}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.businessInputTaxCredit, true, '%'),false)):_vm._e(),_c('FormulateInput',_vm._b({class:_vm.motorPolicyModel.overnightParkingLocationCode,attrs:{"options":_vm.parkingLocations,"validation-messages":{
        required: _vm.validationMessages.defaultRequired,
      },"validation":"required"}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.overnightParkingLocationCode),false)),_c('FormulateInput',_vm._b({attrs:{"options":_vm.suburbListData,"validation-messages":{
        required: _vm.validationMessages.defaultRequired,
      },"validation":"required"},on:{"change":_vm.fetchSuburbsList}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.garagingLocation),false)),_c('hr',{staticClass:"mb-6 mt-0"}),_c('FormulateInput',_vm._b({attrs:{"options":[
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No', id: ((_vm.motorPolicyModel.hasFinance) + "_last-child") } ],"validation-messages":{
        required: _vm.validationMessages.defaultRequired,
      },"validation":"required"}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.hasFinance),false)),(_vm.formValues[_vm.motorPolicyModel.hasFinance] === 'true')?_c('FormulateInput',_vm._b({attrs:{"type":"selectbox","options":_vm.financeTypes,"validation-messages":{
        required: _vm.validationMessages.defaultRequired,
      },"validation":"required"}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.financeCode),false)):_vm._e(),(
        _vm.formValues[_vm.motorPolicyModel.hasFinance] === 'true' &&
        (_vm.formValues[_vm.motorPolicyModel.financeCode] === 'LEASE' || _vm.formValues[_vm.motorPolicyModel.financeCode] === 'SECURED')
      )?_c('FormulateInput',_vm._b({attrs:{"validation-messages":{
        required: _vm.validationMessages.defaultRequired,
      },"validation":"required"}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.financeProvider),false)):_vm._e(),(_vm.showAccessoriesFields(_vm.prefillData.coverageCode))?_c('FormulateInput',_vm._b({attrs:{"validation-messages":{ required: _vm.validationMessages.defaultRequired },"validation":"required","options":[
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No', id: ((_vm.motorPolicyModel.hasAftermarketAccessories) + "_last-child") } ]}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.hasAftermarketAccessories),false)):_vm._e(),_c('div',[(_vm.hasAlarmOrImmobiliser(_vm.formValues.vehicleAccessories))?_c('div',{staticClass:"custom-info custom-tag-icon"},[_c('img',{attrs:{"src":require("@/assets/img/icons/tag-icon.svg"),"alt":"An icon of a tag"}}),_vm._v(" Congratulations, you qualify for an alarm discount! ")]):_vm._e(),(_vm.formValues[_vm.motorPolicyModel.hasAftermarketAccessories] === 'true')?_c('div',[(_vm.showAftermarketAlarmMessage)?_c('p',{staticClass:"has-aftermarket-accessories-precontent"},[_vm._v(" "+_vm._s(_vm.formInputData(_vm.motorPolicyModel.hasAftermarketAccessories).preContent)+" ")]):_vm._e(),_c('AddAccessoriesInput',{staticClass:"accessories-repeatable-field",attrs:{"name":"vehicleAccessoryOption","accessoriesList":_vm.formValues.vehicleAccessories,"validation-messages":{
            required: _vm.validationMessages.defaultRequired,
          },"validation":"required","options":_vm.accessoriesList}})],1):_vm._e()]),(_vm.showVehicleModificationsField(_vm.prefillData.coverageCode))?_c('FormulateInput',_vm._b({attrs:{"validation-messages":{
        required: _vm.validationMessages.defaultRequired,
      },"validation":"required","options":[
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No', id: ((_vm.motorPolicyModel.vehicleHasModifications) + "_last-child") } ]}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.vehicleHasModifications),false)):_vm._e(),_c('div',{staticClass:"form-field-with-custom-help-text"},[_c('strong',{staticClass:"label"},[_vm._v("Do any of the following statements apply to your vehicle?")]),_c('div',{staticClass:"flex-col custom-help-text"},[_c('ul',{staticClass:"list-disc"},[_c('li',[_vm._v("Used for the delivery of flammable goods")]),_c('li',[_vm._v("Has over 2.5 tonnes carrying capacity")])]),_c('FormulateInput',{attrs:{"name":_vm.motorPolicyModel.vehicleUsedForUnacceptableBusinessUse,"type":"radio","validation-messages":{
            required: _vm.validationMessages.defaultRequired,
          },"validation":"required","options":[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No', id: ((_vm.motorPolicyModel.vehicleUsedForUnacceptableBusinessUse) + "_last-child") } ]}}),_c('br')],1)]),_c('hr',{staticClass:"mt-8 mb-12"}),_c('FormulateInput',_vm._b({attrs:{"validation-messages":{
        required: _vm.validationMessages.defaultRequired,
      },"validation":"required","options":[
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No', id: ((_vm.motorPolicyModel.unrepairedCollisionOrHailDamage) + "_last-child") } ]}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.unrepairedCollisionOrHailDamage),false)),_c('br')],2),_c('StepFooter',_vm._b({},'StepFooter',_vm.$props,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }