var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":_vm.formInputId}},[_c('h5',{staticClass:"repeatableAtFaultClaims-heading"},[_vm._v("Please provide details about your at fault claims in the last 3 years")]),_c('FormulateInput',{attrs:{"type":_vm.inputType,"name":_vm.groupInputFieldName,"repeatable":true,"label":"","add-label":_vm.addLabel,"minimum":1,"validation":"required"},scopedSlots:_vm._u([{key:"addmore",fn:function(ref){
var addMore = ref.addMore;
return [_c('button',{staticClass:"add-item-btn",attrs:{"type":"button"},domProps:{"textContent":_vm._s(_vm.addMoreButtonText)},on:{"click":function($event){return addMore()}}})]}},{key:"remove",fn:function(ref){
var removeItem = ref.removeItem;
return [_c('button',{staticClass:"remove-item-btn",attrs:{"type":"button"},on:{"click":function($event){return removeItem()}}})]}}])},[_c('div',{staticClass:"form-fields-flex-wrapper gap-4"},[_c('FormulateInput',{attrs:{"type":"selectbox","name":"month","options":_vm.months,"placeholder":"Month","validation":"required","validation-messages":{
          required: _vm.validationMessages.monthRequired,
        }}}),_c('FormulateInput',{attrs:{"type":"selectbox","name":"year","options":_vm.years,"placeholder":"Year","validation":"required","validation-messages":{
          required: _vm.validationMessages.yearRequired,
        }}}),_c('FormulateInput',{attrs:{"type":"selectbox","name":"lossType","options":_vm.lossTypes,"placeholder":"Loss type","validation":"required","validation-messages":{
          required: _vm.validationMessages.typeRequired,
        }}}),_c('FormulateInput',{attrs:{"type":"selectbox","name":"lossValue","options":_vm.lossValues,"placeholder":"Loss value","validation":"required","validation-messages":{
          required: _vm.validationMessages.selectRequired,
        }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }