<template>
  <div :id="formInputId">
    <h5 class="accessories-heading">Accessories / modifications</h5>
    <FormulateInput :type="inputType" :name="groupInputFieldName" :minimum="1" :repeatable="true" label="" :add-label="addLabel">
      <FormulateInput
        type="vue-select"
        class="what-type-of-accessory"
        :name="optionsFieldName"
        validation="customRequired"
        :validation-rules="{ customRequired: ({ value }) => customRequiredValidation(value) }"
        :validation-messages="{ customRequired: 'Please select an option' }"
        :options="options"
      />
      <template #addmore="{ addMore }">
        <button type="button" @click="addMore()" class="add-item-btn" v-if="shouldShowAddButton" v-text="addMoreButtonText"
      /></template>

      <template #remove="{ removeItem }">
        <button type="button" v-if="accessoriesList.length > 1" @click="removeItem()" class="remove-item-btn"></button
      ></template>
    </FormulateInput>
  </div>
</template>

<script>
export default {
  name: 'AddAccessoriesInput',

  props: {
    options: {
      type: Array,
    },
    accessoriesList: {
      type: Array,
      required: true,
      default: () => [],
    },
    groupInputFieldName: {
      type: String,
      default: 'vehicleAccessories',
    },
    optionsFieldName: {
      type: String,
      default: 'vehicleAccessoryOption',
    },
    inputType: {
      type: String,
      default: 'group',
    },
    addLabel: {
      type: String,
      default: '+ Add accessory or modification',
    },
    addMoreButtonText: {
      type: String,

      default: 'Add an accessory or modification',
    },
    formInputId: {
      type: String,

      default: 'add-accessories-input-group',
    },
  },
  computed: {
    shouldShowAddButton() {
      return this.accessoriesList?.[0]?.vehicleAccessoryOption;
    },
  },
  methods: {
    customRequiredValidation(value) {
      return value != '';
    },
  },
};
</script>

<style lang="scss">
#add-accessories-input-group {
  .accessories-heading {
    margin-bottom: -0.5rem;
  }

  [data-is-repeatable] {
    flex-direction: column;
    margin-left: 0;
    align-items: flex-start;

    .formulate-input-group-repeatable {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      padding-left: 0;
    }

    .formulate-input-errors {
      margin-left: 0 !important;
      padding-left: 0 !important;
    }

    .forms-input-wrapper {
      min-width: min-content !important;
      margin-bottom: 0.75rem;

      .formulate-input-wrapper {
        margin-top: 0;
      }
    }

    [name='vehicleAccessoryOption'] {
      margin-left: 0;
    }

    .add-item-btn,
    .remove-item-btn {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      text-align: left;
      background: transparent;
      border: none;
      padding-left: 0;
      outline: none;

      &:before {
        background-repeat: no-repeat;
        content: '';
      }
    }

    .remove-item-btn {
      position: relative;
      left: 1rem;

      &:before {
        width: 1.25rem;
        height: 2rem;
        background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20baseProfile%3D%22tiny%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2030%2030%22%3E%3Cpath%20fill%3D%22%23444%22%20d%3D%22M15%205.003C9.48%205.003%205.003%209.48%205.003%2015c0%205.52%204.476%209.997%209.997%209.997%205.52%200%209.997-4.476%209.997-9.997%200-5.52-4.476-9.997-9.997-9.997zm4.592%2013.02l-1.568%201.57-2.912-2.913-2.912%202.913-1.568-1.568%202.912-2.912-3.136-3.136%201.568-1.568%203.136%203.135%202.912-2.912%201.568%201.568-2.912%202.913%202.912%202.91z%22%2F%3E%3C%2Fsvg%3E');
      }

      &:hover,
      &:focus {
        &:before {
          background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20baseProfile%3D%22tiny%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2030%2030%22%3E%3Cpath%20fill%3D%22%23006eff%22%20d%3D%22M15%205.003C9.48%205.003%205.003%209.48%205.003%2015c0%205.52%204.476%209.997%209.997%209.997%205.52%200%209.997-4.476%209.997-9.997%200-5.52-4.476-9.997-9.997-9.997zm4.592%2013.02l-1.568%201.57-2.912-2.913-2.912%202.913-1.568-1.568%202.912-2.912-3.136-3.136%201.568-1.568%203.136%203.135%202.912-2.912%201.568%201.568-2.912%202.913%202.912%202.91z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
        }
      }
    }

    .add-item-btn {
      @include heading_font_family;
      color: $racq-navy;
      width: max-content;
      position: relative;
      right: 0.25rem;

      &:before {
        width: 1.85rem;
        height: 1.85rem;
        background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20baseProfile%3D%22tiny%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%220%200%2030%2030%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22%23003478%22%20d%3D%22M22%206H8c-1.104%200-2%20.896-2%202v14c0%201.104.896%202%202%202h14c1.105%200%202-.896%202-2V8c0-1.104-.895-2-2-2zm-2%209.882h-4.118V20h-1.765v-4.118H10v-1.765h4.118V10h1.765v4.117H20v1.765z%22%2F%3E%3C%2Fsvg%3E');
      }

      &:hover,
      &:focus {
        color: $racq-navy-hover;

        &:before {
          background-image: url('data:image/svg+xml;base64,PHN2ZyBiYXNlUHJvZmlsZT0idGlueSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9ImwwIDAgMzAgMzAiPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0iIzAwNmVmZiIgZD0iTTIyIDZIOGMtMS4xMDQgMC0yIC44OTYtMiAydjE0YzAgMS4xMDQuODk2IDIgMiAyaDE0YzEuMTA1IDAgMi0uODk2IDItMlY4YzAtMS4xMDQtLjg5NS0yLTItMnptLTIgOS44ODJoLTQuMTE4VjIwaC0xLjc2NXYtNC4xMThIMTB2LTEuNzY1aDQuMTE4VjEwaDEuNzY1djQuMTE3SDIwdjEuNzY1eiIvPjwvc3ZnPg==');
        }
      }
    }
  }

  // Due to limitations in Vue Formulate HTML structure
  // This is how we style the remove button if the
  // sibling dropdown input has an error
  .remove-item-btn:has(+ [data-is-showing-errors='true']) {
    bottom: 1.5rem;
  }
}
</style>
