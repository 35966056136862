/**
 * Custom scrollspy implementation.
 *
 * This will initially be used on the Add Vehicle Form ut can be extended to other forms if required.
 *
 * How this works:
 * 1. Add a data attribute to the element/s to observe (e.g) data-scrollspy-observe="elementId"
 * 2. Add a data attribute to the elements to change when the observed elements change (e.g.) getElementById(elementId)
 * 3. As the user scrolls, the observed elements will come into the viewport, and changes occur accordingly
 *
 * In this implementation, the step icons need to have an "active" colour when the "observed" step is in view.
 *
 */
export default {
  data() {
    return {
      observer: null,
    };
  },
  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      root: this.$el,
      // threshold: 0.1,
      // Reduce the observed area to intersect in the middle of the viewport
      rootMargin: '-50% 0px',
    });
  },
  mounted() {
    this.$el.querySelectorAll('[data-scrollspy-observe]').forEach((step) => {
      this.observer.observe(step);
    });
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    /**
     * Get all the entries from the observer and extract the
     * target and isIntersecting props from each entry
     *
     * @param { HTMLElement[] } entries
     */
    onElementObserved(entries) {
      const vm = this;

      entries.forEach(({ target, isIntersecting }) => {
        const id = target.getAttribute('data-scrollspy-observe');
        const elToChange = vm.$el.querySelector(`.scrollspy-step-item-id__${id}`);

        if (isIntersecting) {
          elToChange?.querySelector?.('.step-bar-icon')?.classList?.add('scrollspy-active-observed');
        } else {
          elToChange?.querySelector?.('.step-bar-icon')?.classList?.remove('scrollspy-active-observed');
        }
      });
    },
  },
};
