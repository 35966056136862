export function IsHomeAndContentInsurance(coverageType) {
  return coverageType === HomeAndContents || coverageType === Contents;
}

export function IsHomeInsurance(coverageType) {
  return coverageType === Home;
}

export function IsCarInsurance(coverageType) {
  return (
    coverageType === MotorComprehensive ||
    coverageType === MotorComprehensiveOther ||
    coverageType === MotorThirdPartyLiability ||
    coverageType === MotorFireTheftAndThirdPartyLiability ||
    coverageType === MotorCtp ||
    coverageType === MotorClassic ||
    coverageType === MotorUnique ||
    coverageType === Motorcycle ||
    coverageType === MotorThirdPartyLiabilityOther ||
    coverageType === MotorFireTheftAndThirdPartyLiabilityOther
  );
}

export function IsPetInsurance(coverageType) {
  return coverageType === Pet;
}

const HomeAndContents = 'HomeAndContents';
const Contents = 'Contents';
const Home = 'Home';
const MotorComprehensive = 'MotorComprehensive';
const MotorComprehensiveOther = 'MotorComprehensiveOther';
const MotorThirdPartyLiability = 'MotorThirdPartyLiability';
const MotorFireTheftAndThirdPartyLiability = 'MotorFireTheftAndThirdPartyLiability';
const MotorCtp = 'MotorCtp';
const MotorClassic = 'MotorClassic';
const MotorUnique = 'MotorUnique';
const Motorcycle = 'Motorcycle';
const MotorThirdPartyLiabilityOther = 'MotorThirdPartyLiabilityOther';
const MotorFireTheftAndThirdPartyLiabilityOther = 'MotorFireTheftAndThirdPartyLiabilityOther';
const Pet = 'Pet';
