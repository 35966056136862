<template>
  <div>
    <product-details-tile v-if="this.assistanceProduct" :assistanceProduct="this.assistanceProduct" :fields="fields" :hasBorder="true" />
    <div class="step-content">
      <sc-rich-text :field="fields.reasonForCancellationContent" />
    </div>
    <div class="form-elements-container">
      <FormulateForm v-model="step1Values" @submit="onSubmit">
        <racq-formulate-input
          :options="getDropDownListItems(firstDropDownOptions)"
          key="reasonForCancellation"
          name="reasonForCancellation"
          type="selectbox"
          placeholder="Please select"
          validation="required"
          inputclass=""
          :label="fields.reasonForCancellationLabel"
          inputhaserrorsclass="input-error"
          :validation-messages="{
            required: fields.reasonForCancellationRequiredErrorMessage,
          }"
          :experienceEditableValidationErrors="[fields.reasonForCancellationRequiredErrorMessage]"
        />

        <racq-formulate-input
          :options="getDropDownListItems(this.secondDropDownOptions.data)"
          name="subReasonForCancellation"
          key="subReasonForCancellation"
          v-if="this.step1Values.reasonForCancellation && this.secondDropDownOptions.data && this.secondDropDownOptions.data.length"
          :type="
            this.step1Values.reasonForCancellation == this.vehicleNotRegistered ||
            this.step1Values.reasonForCancellation == this.noLongerOwnVehicle ||
            this.step1Values.reasonForCancellation == this.noLongerRideVehicle //only show radio button if the reason is vehicle not registered or no longer own the vehicle
              ? 'radio'
              : 'selectbox'
          "
          placeholder="Please select"
          validation="required"
          :label="this.secondDropDownOptions.label"
          inputhaserrorsclass="input-error"
          :validation-messages="{
            required: this.secondDropDownOptions.requiredErrorMessage,
          }"
        />

        <racq-formulate-input
          :options="getDropDownListItems(this.thirdDropDownOptions.data)"
          name="roadsideProvider"
          key="roadsideProvider"
          v-if="this.step1Values.subReasonForCancellation && this.thirdDropDownOptions.data && this.thirdDropDownOptions.data.length"
          type="selectbox"
          placeholder="Please select"
          validation="required"
          :label="thirdDropDownOptions.label"
          inputhaserrorsclass="input-error"
          :validation-messages="{
            required: this.thirdDropDownOptions.requiredErrorMessage,
          }"
        />
        <racq-formulate-input
          name="additionalComments"
          v-if="showAdditionalComments()"
          type="textarea"
          inputhaserrorsclass="input-error"
          :label="this.step1Values.reasonForCancellation == this.otherCancellationReason ? fields.specifyDetailsLabel : fields.additionalCommentLabel"
          error-behavior="live"
          :validation="
            this.step1Values.reasonForCancellation == this.otherCancellationReason
              ? 'required|max:250,length|matches:/^[\\w\\-\\.\\s]*$/'
              : 'max:250,length|matches:/^[\\w\\-\\.\\s]*$/'
          "
          :validation-messages="{
            max: fields.additionalCommentMaxCharacterErrorMessage,
            matches: fields.additionalCommentAllowedCharactersErrorMessage,
            required: fields.specifyDetailsRequiredErrorMessage,
          }"
        />
        <div class="form-buttons" v-show="showButtons">
          <FormulateInput type="button" name="Cancel" input-class="btn btn-secondary" v-show="!hideButtons" @click="cancelForm" outer-class="" />

          <FormulateInput type="submit" name="Next" v-show="!hideButtons" input-class="btn btn-primary" outer-class="" />

          <FormulateInput
            type="button"
            name="Edit"
            style="margin-left: auto"
            v-show="hideButtons"
            @click="onEdit"
            input-class="btn btn-primary btn-edit"
            outer-class=""
          />
        </div>
      </FormulateForm>
      <change-vehicle-tile-vue v-if="!showButtons" :fields="fields" />
    </div>
  </div>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormsMixins from '../../../foundation/forms/mixins/RACQFormsMixins';
import ChangeVehicleTileVue from './shared/ChangeVehicleTile.vue';
import RacqFormulateInput from '../../../foundation/forms/controls/racqFormulateInput.vue';
import ProductDetailsTile from './shared/ProductDetailsTile.vue';
import { productApiService } from '../../../foundation/shared/apiservices/apiProductService';
import { jpCodes } from './shared/jpCodes';
import { reasonCodes } from './shared/reasonCodes';
import Vuex from 'vuex';
import CascadeDropDownMixin from '../../../foundation/forms/mixins/CascadeDropDownMixins';

export default {
  name: 'Step1CancelRoadside',
  components: {
    ScRichText: RichText,
    RacqFormulateInput,
    ProductDetailsTile,
    ChangeVehicleTileVue,
  },
  async mounted() {
    try {
      if (this.assistanceProduct.isCMOPolicy) {
        this.firstDropDownOptions = this.firstDropDownOptions.filter((item) => item.id != reasonCodes.priceReasonSitecoreId); //hide the price reason for cmo product
      }
      if (this.assistanceProduct.isLiteProduct) {
        this.firstDropDownOptions = this.firstDropDownOptions.filter((item) => item.fields.isForLiteProduct.value);
      } else {
        this.firstDropDownOptions = this.firstDropDownOptions.filter((item) => item.fields.isForAllProducts.value); //hide the price reason for cmo product
      }
      console.log('assistance product', JSON.stringify(this.assistanceProduct));
      if (!this.assistanceProduct.autoRenewFlag) {
        //if auto renew is already turned off then send user to jeopardy
        this.$root.$emit('show-jeopardy', {
          displayCode: jpCodes.genericError,
          jpCode: jpCodes.genericError,
          stepDetails: this.step.stepTitle,
          formName: 'Cancel Roadside Form',
        }); //policy is already marked as do not renew
      }
      this.addDataToAnalytics();
    } catch (error) {
      //in case of any exceptions
      console.error('Error occurred calling api', error);
      this.$root.$emit('show-jeopardy', {
        displayCode: jpCodes.genericError,
        jpCode: jpCodes.internalServerError,
        stepDetails: this.step.stepTitle,
        formName: 'Cancel Roadside Form',
      });
    }
  },
  computed: {
    ...Vuex.mapState({
      step1Data: (state) => state.cancelRoadsideModule.step1Data,
      assistanceProduct: (state) => state.cancelRoadsideModule.assistanceProduct,
    }),
  },
  methods: {
    getCancellationReasons() {
      var cancellationReason = '';
      if (this.step1Data && this.step1Data.length) {
        for (var i = 0; i < this.step1Data.length; i++) {
          cancellationReason += this.step1Data[i].label + ':' + this.step1Data[i].text;
          cancellationReason += '|';
        }
      }
      return cancellationReason;
    },
    /// this is fired when first step of the form is submitted
    async onSubmit() {
      try {
        this.commitDataToStore();
        if (this.isExperienceEditorRunning) {
          this.$emit('go-next');
        } else {
          const response = await productApiService.isEligibilityForCancellation(this.$route.query.policyId, this.getCancellationModel());
          if (response.isEligible) {
            this.$emit('go-next');
          } else {
            if (this.showAdditionalComments()) {
              //if additional comments to be passed to genesis
              this.$root.$emit('show-jeopardy', {
                displayCode: jpCodes.genericError,
                jpCode: response.jpCode,
                stepDetails: this.step.stepTitle,
                additionalComments: this.step1Values.additionalComments,
                cancellationReasons: this.getCancellationReasons(),
                formName: 'Cancel Roadside Form',
              });
            } else {
              this.$root.$emit('show-jeopardy', {
                displayCode: jpCodes.genericError,
                jpCode: response.jpCode,
                stepDetails: this.step.stepTitle,
                cancellationReasons: this.getCancellationReasons(),
                formName: 'Cancel Roadside Form',
              }); //not elgible to update
            }
          }
          this.addDataToAnalytics(this.step1Data);
        }
      } catch (error) {
        console.error('error occurred moving to next step', error);
        this.$root.$emit('show-jeopardy', {
          displayCode: jpCodes.genericError,
          jpCode: jpCodes.internalServerError,
          stepDetails: this.step.stepTitle,
          cancellationReasons: this.getCancellationReasons(),
          formName: 'Cancel Roadside Form',
        });
      }
    },
    //Get's the model for analytics
    getAnalyticsModel(cancelData) {
      var formAnalyticsModel = this.getAnalyticsBaseModel();
      formAnalyticsModel.form.productCategory = this.assistanceProduct.productCover;
      if (cancelData && cancelData.length) {
        formAnalyticsModel.form.cancelReason = '';
        cancelData.filter(function (elem) {
          formAnalyticsModel.form.cancelReason += `${elem.label}: ${elem.text}`;
          formAnalyticsModel.form.cancelReason += '|';
        });
      }
      return formAnalyticsModel;
    },
    //Raise an event to analytics
    addDataToAnalytics(cancelData) {
      const analyticsData = this.getAnalyticsModel(cancelData);
      analyticsData.event = cancelData ? 'form.step' : 'form.starts';
      this.$root.$emit('add-data-analytics', analyticsData);
    },
    onEdit() {
      this.$emit('visibility-requested');
    },
    //getting cancellation model
    getCancellationModel() {
      return {
        cancellationReasonId: this.step1Values.reasonForCancellation,
        subCancellationReasonId: this.step1Values.subReasonForCancellation,
      };
    },
    showAdditionalComments() {
      if (
        this.step1Values.reasonForCancellation &&
        this.getSelectedOption(this.firstDropDownOptions, this.step1Values.reasonForCancellation).fields.additionalCommentsRequired.value //if additional comments required is ticked
      ) {
        //if Other is selected then go straight to show comments
        return true;
      }
      if (this.step1Values.subReasonForCancellation) {
        return this.getSelectedOption(this.secondDropDownOptions.data, this.step1Values.subReasonForCancellation).fields.additionalCommentsRequired.value;
      } else {
        return false;
      }
    },
    commitDataToStore() {
      this.$store.dispatch('cancelRoadsideModule/clearStepData'); //clearing step data
      if (this.step1Values.reasonForCancellation) {
        var firstDropDownFieldValue = this.getFieldValue(
          this.fields.reasonForCancellationLabel.value,
          this.step1Values.reasonForCancellation,
          this.getSelectedOption(this.firstDropDownOptions, this.step1Values.reasonForCancellation).fields.listValue.value
        );
        this.$store.dispatch('cancelRoadsideModule/addStepDataToStore', firstDropDownFieldValue);
      }
      if (this.step1Values.subReasonForCancellation) {
        var secondDropDownFieldValue = this.getFieldValue(
          this.secondDropDownOptions.label.value,
          this.step1Values.subReasonForCancellation,
          this.getSelectedOption(this.secondDropDownOptions.data, this.step1Values.subReasonForCancellation).fields.listValue.value
        );
        this.$store.dispatch('cancelRoadsideModule/addStepDataToStore', secondDropDownFieldValue);
      }
      if (this.step1Values.roadsideProvider) {
        var thirdDropDownFieldValue = this.getFieldValue(
          this.thirdDropDownOptions.label.value,
          this.step1Values.roadsideProvider,
          this.getSelectedOption(this.thirdDropDownOptions.data, this.step1Values.roadsideProvider).fields.listValue.value
        );
        this.$store.dispatch('cancelRoadsideModule/addStepDataToStore', thirdDropDownFieldValue);
      }
      if (this.step1Values.additionalComments) {
        var additionalCommentValue = this.getFieldValue(
          this.fields.additionalCommentLabel.value,
          this.step1Values.additionalComments,
          this.step1Values.additionalComments
        );
        this.$store.dispatch('cancelRoadsideModule/addStepDataToStore', additionalCommentValue);
      }
    },
  },
  watch: {
    'step1Values.reasonForCancellation'() {
      // Populate the sub reason dropdown
      if (this.step1Values.reasonForCancellation) {
        this.getOptionsForSecondDropDown(); //setting options for second drop down
        this.thirdDropDownOptions = []; //reset options on another provider i.e. third dropdown
        this.step1Values.subReasonForCancellation = null; //reset value for second dropdown if 1st drop down value changes
      }
    },
    //fires when second dropdown value changes
    'step1Values.subReasonForCancellation'() {
      if (this.step1Values.subReasonForCancellation) {
        this.getOptionsForThirdDropDown(); //filter options for third dropdown
      }
    },
  },
  mixins: [RACQFormsMixins, CascadeDropDownMixin],
  data: function () {
    return {
      options: this.fields,
      firstDropDownOptions: this.cancellationReasons, //first dropdown options
      secondDropDownOptions: [], //to load second dropdown options
      thirdDropDownOptions: [], //to load third dropdown items
      step1Values: {},
      showButtons: true, //if change vehicle scenario is triggered. All buttons need to be hidden
      noLongerOwnVehicle: reasonCodes.noLongerOwnVehicleSitecoreId,
      noLongerRideVehicle: reasonCodes.noLongerRideSitecoreId,
      vehicleNotRegistered: reasonCodes.vehicleNotRegisteredSitecoreId,
      otherCancellationReason: reasonCodes.otherSitecoreId,
    };
  },
  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    stepNumber: String,
    stepTitle: String,
    formTitle: String,
    fields: {
      type: Object,
      default: () => ({}),
    },
    cancellationReasons: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
