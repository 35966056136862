<template>
  <div>
    <div class="collapse-container rounded">
      <!-- Start Header -->
      <div class="collapse-container-header d-md-flex pointer hover py-2 px-2" @click.stop="expanded = !expanded">
        <div class="d-flex px-3">
          <div class="svg-confirmation-car-bluegrey mt-2 px-4 py-4" style="width: 60px"></div>

          <i :class="expanded ? 'bi-chevron-up' : 'bi-chevron-down'" class="mt-4 ml-auto d-md-none"></i>
        </div>
        <div class="w-100 d-flex">
          <div class="d-inline-block d-md-none py-2 px-2"></div>
          <table class="w-100 my-0">
            <tbody>
              <tr>
                <td class="px-0" style="font-size: 17px">
                  <b>
                    {{ coverage.vehicle.formattedLine1 }}
                  </b>
                  <br />
                  <b>
                    {{ coverage.vehicle.formattedLine2 }}
                  </b>
                </td>
                <td class="text-right pt-3 pb-0 d-none d-md-table-cell">
                  <i :class="expanded ? 'bi-chevron-up' : 'bi-chevron-down'" class="ml-auto"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- End Header -->

      <!-- Start Body -->
      <div class="collapse-container-body px-2 py-2 border-top" v-show="expanded">
        <div class="d-flex">
          <div class="d-none d-md-inline-block py-2" style="width: 92px"></div>
          <table class="w-100 my-0">
            <tbody>
              <tr v-if="hasFreeHireCar">
                <td colspan="2" class="px-1">
                  <div class="border-dark d-flex rounded px-3 py-3" style="font-size: 14px">
                    <i class="icon-gold-member"></i>
                    <sc-rich-text :field="fields.memberFreeHireCarBenefit" />
                  </div>
                </td>
              </tr>
              <tr
                class="pointer hover"
                v-if="!hireCarAttached"
                @click.stop="
                  hireCarChecked = !hireCarChecked;
                  emitChange();
                "
              >
                <td style="vertical-align: top" class="pr-0 py-3">
                  <RacqCheckBox :value="hireCarChecked" />
                </td>
                <td class="py-3 pl-0">
                  <h6 class="text-bold font-arial">
                    <sc-text :field="fields.hireCarTitle" />
                  </h6>
                  <p class="mt-2">
                    <sc-text :field="fields.hireCarSubtitle" />
                  </p>
                </td>
              </tr>
              <tr v-else>
                <td colspan="2" class="px-1">
                  <div class="alert alert-secondary d-flex pl-2 mb-0 pt-2 pb-2">
                    <i class="bi-check mr-1"></i>
                    <div class="d-md-flex w-100">
                      <h6 class="my-0 text-bold text-right font-arial" style="line-height: 30px">
                        <sc-text :field="fields.hireCarTitle" />
                      </h6>
                      <div class="ml-auto text-right" style="font-size: 0.85em; line-height: 30px">
                        <b><sc-text :field="fields.currentlyInPolicy" /></b>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr
                class="pointer hover"
                v-if="!windscreenAttached"
                @click.stop="
                  windscreenChecked = !windscreenChecked;
                  emitChange();
                "
              >
                <td style="vertical-align: top" class="pr-0 py-3">
                  <RacqCheckBox :value="windscreenChecked" />
                </td>
                <td class="py-3 pl-0">
                  <h6 class="text-bold font-arial">
                    <sc-text :field="fields.windscreenTitle" />
                  </h6>
                  <p class="mt-2">
                    <sc-text :field="fields.windscreenSubtitle" />
                  </p>
                </td>
              </tr>
              <tr v-else>
                <td colspan="2" class="px-1">
                  <div class="alert alert-secondary d-flex pl-2 mb-0 pt-2 pb-2">
                    <i class="bi-check mr-1"></i>
                    <div class="d-md-flex w-100">
                      <h6 class="my-0 text-bold text-right font-arial" style="line-height: 28px">
                        <sc-text :field="fields.windscreenTitle" />
                      </h6>
                      <div class="ml-auto text-right" style="line-height: 28px">
                        <sc-text :field="fields.currentlyInPolicy" />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- End Body -->
    </div>
  </div>
</template>

<style>
.collapse-container {
  background-color: #f2f5f7;
}
.border-dark {
  border: 1px solid grey;
}
.text-bold {
  font-weight: bold !important;
}
.hover:hover {
  transition: 0.2s;
  background-color: rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.bi-check {
  background-position: center;
}
@media only screen and (max-width: 512px) {
  .bi-check {
    background-size: 20px;
  }
}
</style>

<script>
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';
import { mapState } from 'vuex';
import { Benefits } from '../constants/benefits.const';
import { Loyalty } from '../constants/loyalty.const';
import RacqCheckBox from './RacqCheckBox.vue';

export default {
  name: 'OptionalBenefits',
  components: {
    ScText: Text,
    ScRichText: RichText,
    RacqCheckBox,
  },
  data: function () {
    return {
      expanded: false,
      windscreenChecked: false,
      hireCarChecked: false,
    };
  },
  mounted() {
    if (this.index == 0) this.expanded = true;
  },
  methods: {
    emitChange() {
      this.$emit('update-optional-benefits', {
        windscreenChecked: this.windscreenChecked || this.windscreenAttached,
        hireCarChecked: this.hireCarChecked || this.hireCarAttached,
      });
    },
  },
  computed: {
    ...mapState({
      identity: (state) => state.identityModule.identity,
    }),
    hasFreeHireCar: function () {
      return this.identity && this.identity.membershipLoyalty && this.identity.membershipLoyalty.toLowerCase() == Loyalty.GOLD_50;
    },
    hireCarAttached: function () {
      return this.coverage.benefits.some((benefit) => benefit.toLowerCase() == Benefits.FREE_HIRE_CAR);
    },
    windscreenAttached: function () {
      return this.coverage.benefits.some((benefit) => benefit.toLowerCase() == Benefits.WINDSCREEN);
    },
  },
  props: {
    index: {
      type: Number,
      default: () => undefined,
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    coverage: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/css/components/forms/form-icons.scss';
</style>
