<template>
  <div>
    <FormulateForm v-model="formValues" @submit="gotoAccountPage">
      <div class="step-content pt-2">
        <h3 v-text="formInputData(motorPolicyModel.confirmationHeaderText).label" />

        <div v-html="confirmationIntroText"></div>

        <div v-html="paymentDetailsText"></div>

        <br />

        <h5 v-html="formInputData(motorPolicyModel.confirmationSummaryTitle).label" style="margin-bottom: -1rem"></h5>
        <PolicySummary :is-editable="false" :prefill-data="prefillData" />

        <ExcessDetails />
      </div>

      <StepFooter v-bind="$props" :show-back-button="false" />
    </FormulateForm>
  </div>
</template>

<script>
import StepFooter from '@/components/feature/newmotorinsuranceform/shared/StepFooter.vue';
import PolicySummary from '@/components/feature/newmotorinsuranceform/shared/PolicySummary.vue';
import ExcessDetails from '../excess-and-sum-insured/ExcessDetails.vue';

import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import { NEW_MOTOR_INSURANCE_MODULE, motorPolicyModel, validationMessages } from '@/components/feature/newmotorinsuranceform/services/mappings';
import { mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import AddVehicleFormMixin from '@/components/foundation/forms/mixins/AddVehicleFormMixin';

export default {
  name: 'ConfirmationForm',

  components: {
    StepFooter,
    ExcessDetails,
    PolicySummary,
  },

  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    onGoBack: {
      type: Function,
      required: true,
    },
    prefillData: {
      type: Object,
      required: false,
    },
  },

  mixins: [RACQFormsMixins, AddVehicleFormMixin],

  data() {
    return {
      validationMessages,
      motorPolicyModel,
      formValues: {},
    };
  },

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, ['']),

    gotoAccountPage() {
      this.getSelfServiceUrl()
        .then((url) => (window.location.href = url))
        .catch((e) => {
          this.UPDATE_JEOPARDY_ACTIVE({ jeopardyActive: true });
          console.error('Error fetching self service URL', e);
        });
    },
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, ['sitecoreContent', 'selectedPolicy', 'businessRulesResponse', 'paymentReceiptNumber']),

    confirmationIntroText() {
      return this.formInputData(motorPolicyModel.confirmationIntroText).label.replace('{loginEmail}', this.prefillData?.emailAddress);
    },

    paymentDetailsText() {
      const currentTermPaymentDetails = this.selectedPolicy?.currentTermPaymentDetails;

      if (this.directDebitType === 'ANNUAL') {
        return this.formInputData(this.motorPolicyModel.paymentDetailsDDAnnual).label.replace(
          '{amountDue}',
          this.formatCurrency(this.prefillData?.updatedPremium)
        );
      }

      if (this.directDebitType === 'MONTHLY') {
        const instalments = this.businessRulesResponse?.viewModel?.responseModel?.instalments;
        const nextMonthlyPayment =
          this.businessRulesResponse?.viewModel?.quotePremiumDetails?.monthlyNonResponsiveDeposit ||
          currentTermPaymentDetails.nextInvoiceAmountDue + this.prefillData?.updatedPremium;

        // KTD-598: if final instalment (instalments.length == 1) then we don't show the "next instalment text"
        if (instalments?.length === 1) {
          return this.formInputData(this.motorPolicyModel.paymentDetailsDDMonthlyFinalInstalment)
            .label.replace('{nextMonthlyPayment}', this.formatCurrency(nextMonthlyPayment))
            .replace('{paymentDueDate}', dayjs(currentTermPaymentDetails.nextInvoiceDueDate).format('DD MMM YYYY'));
        }

        return this.formInputData(this.motorPolicyModel.paymentDetailsDDMonthly)
          .label.replace('{nextMonthlyPayment}', this.formatCurrency(nextMonthlyPayment))
          .replace('{paymentDueDate}', dayjs(currentTermPaymentDetails.nextInvoiceDueDate).format('DD MMM YYYY'))
          .replace('{equalMonthlyPayments}', this.formatCurrency(this.prefillData?.instalmentAmount));
      }

      return this.formInputData(this.motorPolicyModel.paymentDetailsInvoice)
        .label.replace('{amountPaid}', this.formatCurrency(this.prefillData?.updatedPremium))
        .replace('{receiptNumber}', this.paymentReceiptNumber);
    },
  },
};
</script>
