<template>
  <div>
    <div class="form-elements-container confirmation-screen">
      <sc-rich-text :field="isExperienceEditorRunning ? fields.stepContent : stepContent" />
      <div class="forms-light-panel">
        <div class="form-confirmation-header">
          <div class="image-container">
            <sc-image :media="fields.confirmationClockIcon" />
          </div>
          <h3><sc-text :field="fields.subCancellationHeading" /></h3>
        </div>
        <div class="forms-confirmation-container">
          <sc-rich-text :field="isExperienceEditorRunning ? fields.cancellationDescription : cancellationDescription" />
        </div>
      </div>
      <div class="warning-container"></div>
      <div class="form-buttons">
        <FormulateInput type="button" name="Finish" input-class="btn btn-secondary" v-show="!hideButtons" @click="cancelForm" outer-class="" />
      </div>
    </div>
  </div>
</template>

<script>
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormsMixin from '../../../foundation/forms/mixins/RACQFormsMixins';
import { apiIdentityService } from '../../../foundation/shared/apiservices/apiIdentityService';
import Vuex from 'vuex';
export default {
  name: 'Step5CancelInsurance',
  components: {
    ScText: Text,
    ScImage: Image,
    ScRichText: RichText,
  },
  computed: {
    ...Vuex.mapState({
      insuranceProduct: (state) => state.cancelInsuranceModule.insuranceProduct,
    }),
  },
  async mounted() {
    try {
      if (!this.isExperienceEditorRunning) {
        const identityDetails = await apiIdentityService.getIdentityDetails();
        this.cancellationDescription = this.fields.cancellationDescription;
        this.stepContent = this.fields.stepContent;
        this.cancellationDescription.value = this.cancellationDescription.value.replace('${email}', identityDetails.email);
        this.cancellationDescription.value = this.cancellationDescription.value.replaceAll('${insuranceType}', this.insuranceProduct.insuranceType);
        this.stepContent.value = this.stepContent.value.replaceAll('${insuranceType}', this.insuranceProduct.insuranceType);
      }
    } catch (error) {
      console.warn('Error occurred getting profile data', error);
    }
  },
  data() {
    return {
      cancellationDescription: {},
      stepContent: {},
    };
  },
  mixins: [RACQFormsMixin],
  methods: {},
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
