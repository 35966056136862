<template>
  <FormulateForm v-model="formValues" @submit="submitForm">
    <div id="add-additonal-driver-form" class="step-content">
      <CustomCheckboxInput class="py-2 light-theme" :options="drivers" v-model="additionalDriverIds">
        <template #legend>
          <div v-html="additionalDriverInfoTitle"></div>
        </template>

        <template #label="{ option }">
          <div class="additional-driver-option-label">
            <strong class="px-2" v-text="option.label" />

            <span v-if="option.dateOfBirth" class="px-4" v-text="formattedDOB(option.dateOfBirth)" />
          </div>
        </template>
        <template #under-label="{ option }">
          <AdditionalDriverInfo :selected-driver="option" :step="step" />
        </template>
      </CustomCheckboxInput>
    </div>

    <StepFooter v-bind="$props" />
  </FormulateForm>
</template>

<script>
import CustomCheckboxInput from '@/components/feature/newmotorinsuranceform/shared/CustomCheckboxInput.vue';
import AdditionalDriverInfo from './AdditionalDriverInfo.vue';
import StepFooter from '@/components/feature/newmotorinsuranceform/shared/StepFooter.vue';

import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import AddVehicleFormMixin from '@/components/foundation/forms/mixins/AddVehicleFormMixin';
import { NEW_MOTOR_INSURANCE_MODULE } from '@/components/feature/newmotorinsuranceform/services/mappings';
import { motorPolicyModel } from '@/components/feature/newmotorinsuranceform/services/mappings';
import { mapGetters } from 'vuex';

export default {
  name: 'AdditionalDrivers',

  components: {
    CustomCheckboxInput,
    AdditionalDriverInfo,
    StepFooter,
  },

  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    onGoBack: {
      type: Function,
      required: true,
    },
    prefillData: {
      type: Object,
      required: false,
    },
    formErrors: {
      type: [Array, Object],
      required: false,
    },
  },

  mixins: [RACQFormsMixins, AddVehicleFormMixin],

  data() {
    return {
      motorPolicyModel,
      additionalDriverIds: [],
      formValues: {},
    };
  },

  methods: {
    submitForm() {
      this.onSubmit({ additionalDriverIds: this.additionalDriverIds });
    },
  },

  computed: {
    ...mapGetters(NEW_MOTOR_INSURANCE_MODULE, ['foundVehicleMakeModel']),

    drivers() {
      let computedAdditionalDriverIds = [];

      for (const driver of this.prefillData.drivers) {
        if (!driver.isPrimary) {
          computedAdditionalDriverIds.push({ ...driver, id: driver.externalId });
        }
      }

      return computedAdditionalDriverIds;
    },

    additionalDriverInfoTitle() {
      return this?.formInputData(motorPolicyModel.additionalDriversIntroText)?.label?.replace('{brandMake}', this.foundVehicleMakeModel);
    },
  },
};
</script>
<style lang="scss">
#add-additonal-driver-form {
  .additional-driver-option-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
</style>
