<template>
  <div style="display: none;" class="live-chat" :data-properties="JSON.stringify(fields.chatConfiguration)">
    <p>live chat</p>
    <button @click="startChat">start chat</button>
  </div>
</template>

<script>
import liveChatConfigProd from './data/config.prod';
import RACQFormsMixin from '../../foundation/forms/mixins/RACQFormsMixins';
import { liveChatApiService } from './../../foundation/shared/apiservices/liveChatApiService'; 

export default {
  name: 'LiveChat',
  components: {
    //LiveChat
  },
  mixins: [RACQFormsMixin],
  data: function () {
    return {
      chatData: '',
      liveChatConfig: {},
      liveChatModel: {
        defaults: {
          RACQPlugin: undefined,
          config: {},

          // Keep track of WebChat and WebChatService states
          chatOpen: false,
          chatReady: false,
          chatCategoryOnline: false,

          chatServiceReady: false,
          chatSessionActive: false,

          chatButtonEnabled: false,
          chatButtonVisible: false,
        },
      },
      RACQPlugin: {},
      config: {},
      instance: undefined,
      jpCode: '',
      jpDescription: '',
      additionalComments: undefined,
      cancellationReasons: undefined,
      liveChatApi: {},
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.$root.$on('initial-live-chat', () => {
      this.checkChat(this.fields?.Team?.name || liveChatConfigProd.checkChat.defaultCategory);
    });
    if (this.fields && this.fields.Enabled && this.fields.Enabled.value && !this.isExperienceEditorRunning) {
      this.liveChatConfig = liveChatConfigProd;
      this.liveChatApi = liveChatApiService;
      this.$nextTick(function () {
        this.init();
        this.$root.$on('start-chat', (jpcode, jpDescription, additionalComments, cancellationReasons) => {
          this.startChat(jpcode, jpDescription, additionalComments, cancellationReasons);
        });
      });
    }
  },
  methods: {
    addAnalyticsForLiveChat() {
      const analyticsData = {};
      analyticsData.event = 'form.chatstarted';
      this.$root.$emit('add-data-analytics', analyticsData);
    },
    startChat(jpCode, jpDescription, additionalComments, cancellationReasons) {
      this.jpCode = jpCode;
      this.jpDescription = jpDescription;
      this.additionalComments = additionalComments;
      this.cancellationReasons = cancellationReasons;
      this.addAnalyticsForLiveChat();
      this.RACQPlugin.command('WebChat.open', {});
    },
    
    checkChat(category) {
      const RACQPlugin = this.RACQPlugin;
      const selfForm = this;
      return new Promise(function (resolve, reject) {
        RACQPlugin.command('RACQPlugin.checkChat', {
          category: category,
        })
          .done((data) => { selfForm.$root.$emit('show-chat', true); resolve(data);  })
          .fail((errorMessage) => {selfForm.$root.$emit('show-chat', false); reject(errorMessage);  });
      });
    },
    addPrintButton() {
      const buttonContainer = $('.cx-webchat .cx-buttons-window-control');
      const printButton = $('<button class="cx-icon cx-print-transcript"></button>');

      buttonContainer.prepend(printButton);
      printButton.on('click', this.printTranscript.bind(this));
    },
    addMenuToggle() {
      const emojiButton = $('.cx-menu .cx-emoji');
      const uploadButton = $('.cx-menu .cx-upload');
      const cobrowseButton = $('.cx-menu .cx-cobrowse');

      // Check if at least one of the menu buttons is enabled
      if (
        emojiButton.css('display') === 'block' ||
        uploadButton.css('display') === 'block' ||
        cobrowseButton.css('display') === 'block'
      ) {
        const inputContainer = $('.cx-webchat .cx-input-container');
        const menuToggle = $("<button class='cx-menu-toggle'></button>");

        inputContainer.append(menuToggle);

        menuToggle.on('click', this.toggleMenu.bind(this));
      }
    },
    liveChatViewInit() {
      const RACQPlugin = this.RACQPlugin;
      const category = this.config.checkChat.defaultCategory;
      const self = this;
      if (!RACQPlugin?.before) return;
      // Prevent built-in showChatButton event
      RACQPlugin.before('WebChat.showChatButton', function (data) {
        if (data.customEvent) {
          self.liveChatModel.defaults.chatButtonVisible = true;
          return data;
        }

        // Returning nothing prevents the event
        return;
      });

      // Prevent built-in hideChatButton event
      RACQPlugin.before('WebChat.hideChatButton', function (data) {
        // Prevent every event that is not custom event
        if (data.customEvent) {
          self.liveChatModel.defaults.chatButtonVisible = false;
          return data;
        }

        return;
      });

      // Overwrite webchat.open event
      RACQPlugin.before('WebChat.open', function (data) {
        self.liveChatModel.defaults.chatOpen = true;

        // If restoring, allow it to pass through
        if (data.restoring || data.customEvent) {
          return data;
        }
        liveChatApiService.getLiveChatSessionData().then((response) => {
          const newChat = {
          customEvent: true,
          form: {
            autoSubmit: true,
            // @todo maybe move this to config / make server customizable?
            firstname: response.firstName,
            lastname: response.lastName,
            email: response.email,
          },
          userData: {
            // @todo: pick-up user data from the page data
            "MEMBERSHIP-CARD": response.membershipCard,
            "X-RACQ-CH-COMMENTS": self.additionalComments,
            "X-RACQ-CH-FORM-STEP": self.cancellationReasons,
            "X-RACQ-CH-PAGE-ERROR": self.jpCode + ': ' + self.jpDescription,
            'X-RACQ-CH-SOURCE-PAGE': document.title,
            'X-RACQ-CH-REFERRAL-URL': document.URL,
            'X-RACQ-CH-CATEGORY': category,
            // "X-RACQ-CH-WEBSITE": ''
          },
        };
        RACQPlugin.command('WebChat.open', newChat);
      }).catch(function(error){
        const newChat = {
          customEvent: true,
          form: {
            autoSubmit: true,
            // @todo maybe move this to config / make server customizable?
            firstname: 'Anonymous',
            lastname: 'Customer',
            email: 'anonymous@email.com',
          },
          userData: {
            // @todo: pick-up user data from the page data
            // "MEMBERSHIP-CARD": "123123123",
            // "X-RACQ-CH-COMMENTS": ''
            // "X-RACQ-CH-FORM-STEP": ''
            // "X-RACQ-CH-PAGE-ERROR": ''
            'X-RACQ-CH-SOURCE-PAGE': document.title,
            'X-RACQ-CH-REFERRAL-URL': document.URL,
            'X-RACQ-CH-CATEGORY': category,
            // "X-RACQ-CH-WEBSITE": ''
          },
        };
        RACQPlugin.command('WebChat.open', newChat);
      });
        return;
      });

      // Add custom layout elements to webchat
      RACQPlugin.subscribe('WebChat.opened', function () {
        this.addMenuToggle();
        this.addPrintButton();
      });
    },
    registerCheckChat() {
      const RACQPlugin = this.RACQPlugin;
      const config = this.config;
      if (!RACQPlugin?.registerCommand) return;
      RACQPlugin.registerCommand('checkChat', function (e) {
        const category = e.data?.category || config.checkChat.defaultCategory;
        const categoryUrl = `${config.checkChat.url}${category}`;

        $.ajax(categoryUrl)
          .then(function (data) {
            if (data && data.online) {
              e.deferred.resolve(data);
            } else {
              e.deferred.reject(data.message || 'Unknown Error Occured');
            }
          })
          .catch(function (jqXHR, textStatus) {
            e.deferred.reject(textStatus);
          });
      });
    },
    getSessionData() {
      const RACQPlugin = this.RACQPlugin;
      const promise = new Promise(function (resolve, reject) {
        if (!RACQPlugin) {
          reject({});
        }

        RACQPlugin.command('WebChatService.getSessionData').done(resolve).fail(reject);
      });

      return promise;
    },
    minimize() {
      const RACQPlugin = this.RACQPlugin;
      const config = this.config;
      const isMobile = _genesys.widgets.common.isMobileDevice();

      if (!config.webchat?.minimizeOnMobileRestore || !isMobile) {
        return;
      }

      const promise = new Promise(function (resolve, reject) {
        if (!RACQPlugin) {
          reject({});
        }

        RACQPlugin.command('WebChat.minimize').done(resolve).fail(reject);
      });

      return promise;
    },
    liveChatModelInit() {
      const RACQPlugin = this.RACQPlugin;
      const config = this.config;
      const self = this;
      // Create new plugin command to check if chat category is online on the server
      this.registerCheckChat();

      if (!config.main.enabled || !RACQPlugin?.subscribe) {
        // Live chat is disabled for this page and button will not be shown
        // It can only be restored from active chat session and all events logic below can be skipped.
        // We still init registerCheckChat above in case another script wants to use it.
        return;
      }

      // Track WebChat states:
      RACQPlugin.subscribe('WebChat.ready', function () {
        self.liveChatModel.defaults.chatReady = true; //self.set('chatReady', true);
      });

      RACQPlugin.subscribe('WebChat.closed', function () {
        self.liveChatModel.defaults.chatOpen = false;
        //self.set('chatOpen', false);
      });

      // Track WebChatService states:
      RACQPlugin.subscribe('WebChatService.ready', async function () {
        // Check if WebChatService has session data stored
        const sessionData = await self.getSessionData();
        self.liveChatModel.defaults.chatSessionActive = sessionData.sessionID ? true : false;
        self.liveChatModel.defaults.chatServiceReady = true;
        // Assuming that if sessionID exists - session will be restored
        //self.set('chatSessionActive', );
        //self.set('chatServiceReady', true);
      });

      RACQPlugin.subscribe('WebChatService.started', function () {
        self.liveChatModel.defaults.chatSessionActive = true;
      });

      RACQPlugin.subscribe('WebChatService.restored', function () {
        //self.set('chatSessionActive', true);
        self.liveChatModel.defaults.chatSessionActive = true;
        self.minimize();
      });

      RACQPlugin.subscribe('WebChatService.ended', function () {
        self.liveChatModel.defaults.chatSessionActive = true;
      });
      const category = this.config.checkChat.defaultCategory;

      this.checkChat(category)
        .then(function () {
          self.liveChatModel.defaults.chatCategoryOnline = true;
        })
        .catch(function (errorMessage) {
          self.liveChatModel.defaults.chatCategoryOnline = false;
        });
    },
    init() {
      if (this.instance) {
        console.warn('[Live Chat] Cannot re-initialize the widget.');
        return;
      }

      window._genesys = window._genesys || {};

      let config = this.liveChatConfig;

      if (this.fields.chatConfiguration) {
        const pageConfig = this.fields.chatConfiguration;

        config = $.extend(true, config, pageConfig);
      }

      _genesys.widgets = this.liveChatConfig;

      CXBus.configure({
        debug: config.main.debug,
        pluginsPath:  window.location.origin + '/scripts/genesis/js/',
      });

      CXBus.loadPlugin('widgets-core').done(function () {
        // Both plugins always need to load in case active session exists,
        // even if component is not present on the page
        CXBus.loadPlugin('webchat');
        CXBus.loadPlugin('webchatservice');
      });

      const RACQPlugin = CXBus.registerPlugin('RACQPlugin');
      this.RACQPlugin = RACQPlugin;
      this.config = config;
      this.liveChatViewInit();
      this.liveChatModelInit();
      

      this.instance = this.liveChatView;
    },
    stringToBoolean(value) {
      if (value == 'true')
        return true;
      else {
        return false;
      }
    }
  },
  watch: {
    chatProperties(value) {
      var [chatCategoryOnline, chatServiceReady, chatReady] = value.split('|');

      if (!this.stringToBoolean(chatReady) || !this.stringToBoolean(chatServiceReady) || !this.stringToBoolean(chatCategoryOnline)) {
        return;
      }
      this.liveChatModel.defaults.chatButtonEnabled = true;
      //self.set('chatButtonEnabled', true);
    },
    chatButtonProperties(value) {
      var [chatButtonEnabled, chatOpen, chatSessionActive] = value.split('|');

      if (!this.stringToBoolean(chatButtonEnabled) || this.stringToBoolean(chatOpen) || this.stringToBoolean(chatSessionActive)) {
        this.liveChatModel.defaults.chatButtonVisible = false;
      } else {
        this.liveChatModel.defaults.chatButtonVisible = true;
      }
    },
    'liveChatModel.defaults.chatButtonVisible' (value) {
      const isVisible = value;

      if (isVisible) {
        this.RACQPlugin.command('WebChat.showChatButton', { customEvent: true });
      } else {
        this.RACQPlugin.command('WebChat.hideChatButton', { customEvent: true });
      }
    },
  },
  computed: {
    chatProperties() {
      return `${this.liveChatModel.defaults.chatCategoryOnline}|${this.liveChatModel.defaults.chatServiceReady}|${this.liveChatModel.defaults.chatReady}`;
    },
    chatButtonProperties() {
      return `${this.liveChatModel.defaults.chatButtonEnabled}|${this.liveChatModel.defaults.chatOpen}|${this.liveChatModel.defaults.chatSessionActive}`;
    }
  }
};
</script>