const identityModule = {
  namespaced: true,
  state: () => ({
    identity: {},
  }),
  mutations: {
    clearIdentityData(state) {
      state.identity = {};
    },
    addIdentityData(state, identity) {
      state.identity = identity;
    },
  },
  actions: {
    addIdentityData({ commit }, data) {
      if (data) {
        commit('addIdentityData', data);
      }
    },
    clearIdentityData({ commit }) {
      commit('clearIdentityData');
    },
  },
};
export default identityModule;
