<template>
  <div>
    <FormulateForm v-model="formValues" @submit="handleSubmit" @validation="validation = $event">
      <div class="step-content">
        <LowVehicleValueWarning
          v-if="isLowValueVehicle"
          :secondary-btn-action="() => handleSubmit(formValues)"
          :initial-body-text="formInputData(motorPolicyModel.lvvInitialBodyText).label"
          :primary-btn-text="formInputData(motorPolicyModel.lvvPrimaryButton).label"
          :secondary-btn-text="formInputData(motorPolicyModel.lvvSecondaryButton).label"
          :accepted-body-text="formInputData(motorPolicyModel.lvvAcceptedBodyText).label"
          @lvvWarningAccepted="handleLvvWarningAccepted"
        />

        <ReviewPremiumHeader
          :title="premiumHeaderText"
          :review-text="formInputData(motorPolicyModel.premiumDetails).label"
          :primary-btn-text="formInputData(this.motorPolicyModel.headerPrimaryButton).label"
          :secondary-btn-text="formInputData(motorPolicyModel.headerSecondaryButton).label"
          :lvv-warning-accepted="lowValueVehicleWarningAccepted"
          :premium-amount="premiumAmount"
          :secondary-btn-action="showConfirmDialog"
          :primary-btn-action="() => handleSubmit(formValues)"
        />

        <hr class="" />

        <ExcessAndSumInsuredFormFields
          v-if="agreedValueRange"
          :prefill-data="{ agreedValueRange, ...prefillData }"
          @onAgreedValueChange="handleAgreedValueChange"
          @onFormValueChange="handleFormValueChange"
          :form-values="formValues"
          :step="step"
        />

        <hr class="" />

        <ExcessDetails />

        <PolicyDiscounts class="px-6 pt-4 pb-6" :title="formInputData(motorPolicyModel.policyDiscountsLabel).label" />

        <br />
      </div>

      <StepFooter v-bind="{ ...$props, step: stepFooterOptions }" />
    </FormulateForm>

    <ConfirmDialog
      :modal-name="modalName"
      :primary-btn-action="closeConfirmDialog"
      :secondary-btn-action="discardQuoteSession"
      :primary-btn-text="formInputData(motorPolicyModel.headerPrimaryButton).label"
      :secondary-btn-text="formInputData(motorPolicyModel.headerSecondaryButton).label"
      :modal-body-text="formInputData(motorPolicyModel.discardChangesModalBody).label"
      :modal-header-text="formInputData(motorPolicyModel.discardChangesModalTitle).label"
    />
  </div>
</template>

<script>
import StepFooter from '@/components/feature/newmotorinsuranceform/shared/StepFooter.vue';
import ConfirmDialog from '@/components/feature/newmotorinsuranceform/shared/ConfirmDialog.vue';
import ReviewPremiumHeader from '@/components/feature/newmotorinsuranceform/shared/ReviewPremiumHeader.vue';
import PolicyDiscounts from '@/components/feature/newmotorinsuranceform/shared/PolicyDiscounts.vue';

import ExcessAndSumInsuredFormFields from './ExcessAndSumInsuredFormFields.vue';
import LowVehicleValueWarning from './LowVehicleValueWarning.vue';
import ExcessDetails from './ExcessDetails.vue';

import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import { getVehicleAgreedValueRange } from '@/components/foundation/shared/apiservices/apiRefdataService';
import { NEW_MOTOR_INSURANCE_MODULE, motorPolicyModel } from '@/components/feature/newmotorinsuranceform/services/mappings';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { withdrawQuoteSession } from '@/components/foundation/shared/apiservices/apiPolicyService';
import { parseCurrencyToNumber } from '@/components/feature/newmotorinsuranceform/services/text-transforms';

export default {
  name: 'ExcessAndSumInsured',

  components: {
    StepFooter,
    ConfirmDialog,
    ExcessDetails,
    PolicyDiscounts,
    ReviewPremiumHeader,
    LowVehicleValueWarning,
    ExcessAndSumInsuredFormFields,
  },

  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    onGoBack: {
      type: Function,
      required: true,
    },
    prefillData: {
      type: Object,
      required: false,
    },
  },

  mixins: [RACQFormsMixins],

  async created() {
    this.formValues = {
      standardExcessCode: this.prefillData.standardExcessCode,
      includeNcdp: this.prefillData?.includeNcdp === 'true',
      includeCarHire: this.prefillData?.includeCarHire === 'true',
      includeWindscreenReplacement: this.prefillData?.includeWindscreenReplacement === 'true',
      howVehicleInsured: this.prefillData?.agreedVehicleValue ? 'AGREED' : 'MARKET',
    };

    this.agreedValueRange = await getVehicleAgreedValueRange(this.prefillData.glassesGuideVehicleDetails.marketValue, this.prefillData.coverageCode);
  },

  data() {
    return {
      motorPolicyModel,
      formValues: {},
      modalName: 'discard-changes-modal',
      lowValueVehicleWarningAccepted: false,
      agreedValueRange: null,
      validation: {
        hasErrors: false,
      },
    };
  },

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, ['UPDATE_JEOPARDY_ACTIVE']),

    handleAgreedValueChange(newValue) {
      if (this.validation?.hasErrors) return;
      this.onSubmit({
        ...this.formValues,
        agreedVehicleValue: newValue,
        metadata: { goNextStep: false },
      });
    },

    handleFormValueChange() {
      if (this.validation?.hasErrors) return;
      this.onSubmit({
        ...this.formValues,
        agreedVehicleValue: parseCurrencyToNumber(this.formValues.agreedVehicleValue), // When change market value radio, agreed value will become string

        metadata: { goNextStep: false },
      });
    },

    handleLvvWarningAccepted() {
      this.lowValueVehicleWarningAccepted = true;
      this.onSubmit({
        ...this.formValues,
        lowValueVehicleWarningAccepted: true,
        agreedVehicleValue: parseCurrencyToNumber(this.formValues.agreedVehicleValue), // When change market value radio, agreed value will become string

        metadata: { goNextStep: false },
      });
    },

    showConfirmDialog() {
      this.$vfm.show(this.modalName);
    },

    closeConfirmDialog() {
      this.$vfm.hide(this.modalName);
    },

    handleSubmit(formValues) {
      this.onSubmit({
        ...formValues,
        agreedVehicleValue: parseCurrencyToNumber(formValues.agreedVehicleValue), // When change market value radio, agreed value will become string
      });
    },

    async discardQuoteSession() {
      await withdrawQuoteSession(this.$route.query.quoteSessionId);

      this.getSelfServiceUrl()
        .then((url) => (window.location.href = url))
        .catch((e) => {
          this.UPDATE_JEOPARDY_ACTIVE({ jeopardyActive: true });
          console.error('Error fetching self service URL', e);
        });
    },
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, ['businessRulesResponse']),
    ...mapGetters(NEW_MOTOR_INSURANCE_MODULE, ['isLowValueVehicle']),

    premiumHeaderText() {
      const annualHeaderText = this.formInputData(motorPolicyModel.headerTextAnnual).label;
      const monthlyHeaderText = this.formInputData(motorPolicyModel.headerTextMonthly).label;

      return this.prefillData.isAnnual === 'true' ? annualHeaderText : monthlyHeaderText;
    },

    premiumAmount() {
      return this.prefillData?.updatedPremium || 0;
    },

    stepFooterOptions() {
      if (this.lowValueVehicleWarningAccepted) {
        return {
          ...this.step,
          btnNext: this.step.btnNext,
        };
      }

      if (this.isLowValueVehicle) {
        return {
          ...this.step,
          btnNext: false,
        };
      }

      return {
        ...this.step,
      };
    },
  },
};
</script>
