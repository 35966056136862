<template>
  <div :class="tooltipClasses">
    <RacqFormTooltip v-if="context.attributes.tooltip && context.attributes.tooltip.content" v-bind="context.attributes.tooltip">
      <template #tooltip-content>
        <div v-if="context.attributes.tooltip.title" v-html="context.attributes.tooltip.title"></div>
        <div v-if="context.attributes.tooltip.content" v-html="context.attributes.tooltip.content"></div>
      </template>
    </RacqFormTooltip>

    <slot v-else />
  </div>
</template>

<script>
import RacqFormTooltip from './RacqFormTooltip.vue';

export default {
  components: {
    RacqFormTooltip,
  },

  props: {
    context: {
      type: Object,
      required: true,
    },
    tooltip: {
      type: [String, Boolean],
      default: false,
    },
  },

  computed: {
    tooltipClasses() {
      return this.context.attributes?.tooltip ? `d-flex items-center px-2 ${this.context.attributes?.tooltip?.classes || ''}` : '';
    },
  },
};
</script>

<style lang="scss">
.formulate-input-element {
  height: min-content;
}
</style>
