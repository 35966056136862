<template>
  <div>
    <FormulateForm v-model="formValues" @submit="onSubmit">
      <ReviewPremiumHeader title="Payment required due to changes" :show-buttons="false" :premium-amount="premiumAmount" />

      <div class="step-content">
        <p class="pb-4" v-html="formInputData(motorPolicyModel.paymentTermsAndConditions).label"></p>

        <FormulateInput type="checkbox" name="paymentConfirmCheckbox" label="">
          <template #label>
            <div class="pb-2" v-html="formInputData(motorPolicyModel.paymentConfirmCheckbox).postContent"></div>
          </template>
        </FormulateInput>

        <FatZebraPayment
          v-if="fatZebraIframeUrl"
          :policy-number="selectedPolicy.policyNumber"
          :amount="premiumAmount"
          :form-disabled="formValues['paymentConfirmCheckbox'] !== true"
          :fat-zebra-iframe-url="fatZebraIframeUrl"
          :step="step"
        />
      </div>

      <StepFooter v-bind="$props" :step="{ ...step, btnNext: false }" />
    </FormulateForm>
  </div>
</template>

<script>
import StepFooter from '@/components/feature/newmotorinsuranceform/shared/StepFooter.vue';
import FatZebraPayment from './FatZebraPayment.vue';
import ReviewPremiumHeader from '../../../shared/ReviewPremiumHeader.vue';

import { getPaymentIframeConfiguration } from '@/components/foundation/shared/apiservices/paymentService';
import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import { NEW_MOTOR_INSURANCE_MODULE, motorPolicyModel, validationMessages } from '@/components/feature/newmotorinsuranceform/services/mappings';
import { mapState } from 'vuex';
import { nanoid } from 'nanoid';

export default {
  name: 'PaymentForm',

  components: {
    StepFooter,
    FatZebraPayment,
    ReviewPremiumHeader,
  },

  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    onSubmit: {
      type: Function,
      required: false,
    },
    onGoBack: {
      type: Function,
      required: true,
    },
    prefillData: {
      type: Object,
      required: false,
    },
  },

  mixins: [RACQFormsMixins],

  async created() {
    const iframeConfig = await getPaymentIframeConfiguration({
      reference: nanoid(),
      buttonText: this.formInputData(this.motorPolicyModel.submitPayment).label,
      amount: String(this.prefillData?.updatedPremium),
    });

    this.fatZebraIframeUrl = iframeConfig?.url;
  },

  data() {
    return {
      validationMessages,
      motorPolicyModel,
      formValues: {},
      fatZebraIframeUrl: '',
    };
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, ['selectedPolicy']),

    premiumAmount() {
      return this.prefillData?.updatedPremium || 0;
    },
  },
};
</script>

<style scoped lang="scss"></style>
