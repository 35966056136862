<template>
  <div class="form-step-container" v-show="stepvisibility != 'notSeen'">
    <div class="form-step">
      <step-title :step-number="stepNumber" :step-title="stepTitle" :with-sitecore-data="withSitecoreData" />
      <div class="step-side-icon"></div>
      <div class="form-step-content">
        <div v-show="stepvisibility == 'seen'" id="curtain" class="form-step-curtain"></div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { StepVisibility } from '../../../models/foundation/forms/enums';
import StepTitle from './StepTitle.vue';

export default {
  name: 'RacqFormStep',
  components: {
    StepTitle,
  },
  props: {
    stepNumber: {
      required: true,
      type: [String, Number, Object],
    },
    stepTitle: {
      type: [String, Object],
      required: true,
    },
    withSitecoreData: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      stepvisibility: StepVisibility.NOTSEEN,
    };
  },
  methods: {
    moveAway(hidePreviousSteps) {
      if (hidePreviousSteps) {
        this.stepvisibility = StepVisibility.NOTSEEN;
      } else {
        this.stepvisibility = StepVisibility.SEEN;
      }
    },
    moveInto() {
      this.stepvisibility = StepVisibility.VISIBLE;
    },
  },
};
</script>
