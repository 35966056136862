export const NEW_MOTOR_INSURANCE_MODULE = 'newMotorInsuranceModule';

export const policyCoverageTypes = Object.freeze({
  MC: 'Comprehensive Car Insurance',
  MCOther: 'Comprehensive Insurance',
  Motorcycle: 'Comprehensive Motorcycle Insurance',
  MT: 'Third Party Liability Car Insurance',
  MF: 'Fire, Theft and Third Party Insurance',
  Caravan: 'Caravan & Trailer Insurance',
});

export const policyIconSrc = Object.freeze({
  MCOther: require('@/assets/img/icons/vehicle.svg'),
  MC: require('@/assets/img/icons/vehicle.svg'),
  Motorcycle: require('@/assets/img/icons/motorcycle-icon.svg'),
  MT: require('@/assets/img/icons/motor-third-party-damage-icon.svg'),
  MF: require('@/assets/img/icons/motor-fire-theft-icon.svg'),
  Caravan: require('@/assets/img/icons/caravan-icon.svg'),
});

export const coverageTypeCodes = Object.freeze({
  MotorComprehensive: 'MC',
  MotorThirdParty: 'MT',
  MotorThirdPartyFireAndTheft: 'MF',
  MotorComprehensiveOther: 'MCOther',
  Caravan: 'Caravan',
  Motorcycle: 'Motorcycle',
});

export const driverFormStates = Object.freeze({
  NOT_DONE: 'NOT_DONE',
  EXISTING_DRIVER_SELECTED: 'EXISTING_DRIVER_SELECTED',
  NEW_DRIVER_CREATED: 'NEW_DRIVER_CREATED',
  EDITING_NEW_DRIVER: 'EDITING_NEW_DRIVER',
  NEW_DRIVER_SELECTED: 'NEW_DRIVER_SELECTED',
});

/**
 * An enum-like list of all relevant policy data model keys
 * to be used in the front end UI form templates.
 */
export const motorPolicyModel = Object.freeze({
  carNotFoundMessage: 'carNotFoundMessage',
  isVehicleRegistered: 'isVehicleRegistered',
  vehicleRegistration: 'vehicleRegistration',
  regoLookupErrorMessage: 'regoLookupErrorMessage',
  makeSelect: 'makeSelect',
  seriesSelect: 'seriesSelect',
  yearSelect: 'yearSelect',
  modelSelect: 'modelSelect',
  bodyTypeSelect: 'bodyTypeSelect',
  findCarManually: 'findCarManually',
  policyNumber: 'policyNumber',
  termStart: 'termStart',
  termEnd: 'termEnd',
  basicExcessCode: 'basicExcessCode',
  hasListedDriverUnder25: 'hasListedDriverUnder25',
  isMarketValue: 'isMarketValue',
  marketValue: 'marketValue',
  overnightParkingLocationCode: 'overnightParkingLocationCode',
  garagingLocation: 'garagingLocation',
  vehicleUseType: 'vehicleUseType',
  businessUseType: 'businessUseType',
  businessInputTaxCredit: 'businessInputTaxCredit',
  hasMotorEngineImmobiliser: 'hasMotorEngineImmobiliser',
  hasAftermarketAlarm: 'hasAftermarketAlarm',
  vehicleHasModifications: 'vehicleHasModifications',
  hasAdHocCondition: 'hasAdHocCondition',
  hasDriverExcessCondition: 'hasDriverExcessCondition',
  coverageType: 'coverageType',
  coverageCode: 'coverageCode',
  hasFinance: 'hasFinance',
  financeCode: 'financeCode',
  financeProvider: 'financeProvider',
  aftermarketAccessories: 'aftermarketAccessories',
  vehicleAccessories: 'vehicleAccessories',
  hasAftermarketAccessories: 'hasAftermarketAccessories',
  changeEffectiveDate: 'changeEffectiveDate',
  vehicleUsedForUnacceptableBusinessUse: 'vehicleUsedForUnacceptableBusinessUse',
  unrepairedCollisionOrHailDamage: 'unrepairedCollisionOrHailDamage',
  isRatingOne: 'isRatingOne',
  yearsRatingOneHeld: 'yearsRatingOneHeld',
  longTermClaims: 'longTermClaims',
  shortTermClaims: 'shortTermClaims',
  yearsLicenced: 'yearsLicenced',
  confirmPrimaryDriver: 'confirmPrimaryDriver',
  primaryDriverIntroText: 'primaryDriverIntroText',
  primaryDriverFormTitle: 'primaryDriverFormTitle',
  primaryDriverFormHelpText: 'primaryDriverFormHelpText',
  additionalDriverFormTitle: 'additionalDriverFormTitle',
  additionalDriversIntroText: 'additionalDriversIntroText',
  additionalDriversFormHelpText: 'additionalDriversFormHelpText',
  confirmAdditionalDrivers: 'confirmAdditionalDrivers',
  additionalDriverConfirmHelpText: 'additionalDriverConfirmHelpText',
  standardExcessCode: 'standardExcessCode',
  howVehicleInsured: 'howVehicleInsured',
  agreedVehicleValue: 'agreedVehicleValue',
  includeCarHire: 'includeCarHire',
  includeWindscreenReplacement: 'includeWindscreenReplacement',
  includeNcdp: 'includeNcdp',
  lvvInitialBodyText: 'lvvInitialBodyText',
  lvvPrimaryButton: 'lvvPrimaryButton',
  lvvSecondaryButton: 'lvvSecondaryButton',
  lvvAcceptedBodyText: 'lvvAcceptedBodyText',
  lvvLevelOfCoverTooltip: 'lvvLevelOfCoverTooltip',
  headerTextAnnual: 'headerTextAnnual',
  headerTextMonthly: 'headerTextMonthly',
  headerPrimaryButton: 'headerPrimaryButton',
  headerSecondaryButton: 'headerSecondaryButton',
  excessesSectionHeader: 'excessesSectionHeader',
  excessesSectionIntroText: 'excessesSectionIntroText',
  highPerformanceExcess: 'highPerformanceExcess',
  listedDriverAgeExcess: 'listedDriverAgeExcess',
  listedDriverAgeExcess_OLD: 'listedDriverAgeExcess_OLD',
  inexperiencedDriverExcess_OLD: 'inexperiencedDriverExcess_OLD',
  inexperiencedDriverExcess: 'inexperiencedDriverExcess',
  unlistedDriverAgeExcess_OLD: 'unlistedDriverAgeExcess_OLD',
  unlistedDriverAgeExcess: 'unlistedDriverAgeExcess',
  paymentTermsAndConditions: 'paymentTermsAndConditions',
  paymentConfirmCheckbox: 'paymentConfirmCheckbox',
  premiumDetails: 'premiumDetails',
  policyDiscountsLabel: 'policyDiscountsLabel',
  modalHeaderText: 'modalHeaderText',
  excessFormHeader: 'excessFormHeader',
  discardChangesModalTitle: 'discardChangesModalTitle',
  discardChangesModalBody: 'discardChangesModalBody',
  premiumDetailsMonthlyDD: 'premiumDetailsMonthlyDD',
  premiumDetailsInvoice: 'premiumDetailsInvoice',
  premiumDetailsAnnualDD: 'premiumDetailsAnnualDD',
  confirmationHeaderText: 'confirmationHeaderText',
  confirmationIntroText: 'confirmationIntroText',
  paymentDetailsInvoice: 'paymentDetailsInvoice',
  paymentDetailsDDMonthly: 'paymentDetailsDDMonthly',
  paymentDetailsDDMonthlyFinalInstalment: 'paymentDetailsDDMonthlyFinalInstalment',
  paymentDetailsDDAnnual: 'paymentDetailsDDAnnual',
  confirmationNextButton: 'confirmationNextButton',
  headerPrimaryButtonAnnualDD: 'headerPrimaryButtonAnnualDD',
  headerPrimaryButtonInvoice: 'headerPrimaryButtonInvoice',
  headerTextAnnualDD: 'headerTextAnnualDD',
  headerTextMonthlyDD: 'headerTextMonthlyDD',
  headerTextInvoice: 'headerTextInvoice',
  submitPayment: 'submitPayment',
  declinedCardMessage: 'declinedCardMessage',
  confirmationSummaryTitle: 'confirmationSummaryTitle',
});

/**
 * Vue Formulate Validation Messages
 */
export const validationMessages = Object.freeze({
  defaultRequired: 'This field is required.',
  yearRequired: 'Please select a year',
  monthRequired: 'Please select a month',
  dobRequired: 'Please enter your date of birth',
  genderRequired: 'Please select your gender',
  selectRequired: 'Please select a value',
  typeRequired: 'Please select a type',
  businessInputTaxCreditRequired: 'Please enter the input tax credits for your vehicle',
  businessInputTaxCreditNumber: 'Please enter a valid percentage i.e. numbers only.',
  primaryDriverDetailsConfirm: 'Please confirm that the primary driver details are correct.',
  additionalDriverDetailsConfirm: 'Please confirm that the additional driver details are correct.',
  duiOffencesRequired: 'Please select an option for DUI offences',
  dateThirtyDaysFromToday: 'The selected date must be within 30 days from today.',
  firstNameAlpha: 'Only letters, numbers, spaces and hyphens are allowed on the first name',
  lastNameAlpha: 'Only letters, numbers, spaces and hyphens are allowed on the last name',
  atFaultClaimsRequired: 'Please select the number of at fault claims you have had in the last 3 years',
  licenceHistoryRequired: 'Please select the number of licence history issues in the last 3 years',
  yearsRatingOneHeldRequired: 'Please select the number of years you have held rating 1 continuously',
  withinAcceptableRange: 'Please enter a value within the acceptable range.',
  requiredAgreedValue: 'Please enter a valid agreed value',
  requiredRegoNumber: 'Registration number is required',
  requiredVehicleYear: 'Please select a vehicle year',
  requiredVehicleSeries: 'Please select a vehicle series',
  requiredVehicleBodyType: 'Please select a body type',
  requiredVehicle: 'Please select your car',
  requiredMake: 'Please select a make',
  requiredModel: 'Please select a model',
});

export const vehicleUseType = Object.freeze({
  Private: '1',
  Business: '2',
});

/**
 * Convert quote model data into a format that will be parsed by Vue Formulate.
 *
 * @param {*} obj
 */
export function remapViewModelToFormulateData(obj) {
  const isObj = (val) => typeof val === 'object' && !Array.isArray(val) && val !== null;

  if (isObj(obj)) {
    for (const keys in obj) {
      if (isObj(obj[keys])) {
        // if so then again calling the same function
        remapViewModelToFormulateData(obj[keys]);
      } else {
        // else getting the value and replacing or updating accordingly
        if (obj[keys] === true) {
          obj[keys] = 'true';
        } else if (obj[keys] === false) {
          obj[keys] = 'false';
        }
      }
    }
  }

  return obj;
}
