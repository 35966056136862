<template>
  <div class="change-cta-container">
    <div class="cta-container">
      <div class="image-container">
        <sc-image :media="fields.carIcon"></sc-image>
      </div>
      <div class="text-container">
        <sc-rich-text :field="fields.changeVehicleDescription" />
      </div>
      <div class="btn-container">
        <button @click="goChangeVehicleLink" class="btn btn-primary"><sc-text :field="fields.changeVehicleButtonText" /></button>
      </div>
    </div>
  </div>
</template>

<script>
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-vue';
import { apiIdentityService } from '../../../../foundation/shared/apiservices/apiIdentityService';
import Vuex from 'vuex';
export default {
  name: 'ChangeVehicleTileInsurance',
  components: {
    ScText: Text,
    ScRichText: RichText,
    ScImage: Image,
  },
  methods: {
    async goChangeVehicleLink() {
      const changeVehicleUrl = await apiIdentityService.getSelfServiceChangeVehiclePage(true);
      window.location.href = changeVehicleUrl.replace('{0}', this.insuranceProduct.insuranceIndexNumber);
    },
  },
  computed: {
    ...Vuex.mapState({
      insuranceProduct: (state) => state.cancelInsuranceModule.insuranceProduct,
    }),
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
