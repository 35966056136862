<template>
  <FormulateForm v-model="stepValues" @submit="onSubmit">
    <div class="step-content">
      <h4>Generic Step</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis esse saepe ipsam, itaque aperiam sint. Quae, dolorum numquam! Reiciendis
        asperiores voluptatibus sunt minima id hic quis culpa rem nam quo.
      </p>
    </div>

    <StepFooter v-bind="$props" />
  </FormulateForm>
</template>

<script>
import StepFooter from '@/components/feature/newmotorinsuranceform/shared/StepFooter.vue';
import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';

export default {
  name: 'GenericStep',

  components: {
    StepFooter,
  },

  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    onGoBack: {
      type: Function,
      required: true,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    prefillData: {
      type: Object,
      required: false,
    },
    formErrors: {
      type: [Array, Object],
      required: false,
    },
  },

  mixins: [RACQFormsMixins],

  data() {
    return {
      stepValues: {},
    };
  },
};
</script>
