var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"step-content"},[_c('sc-rich-text',{attrs:{"field":_vm.fields.stepContent}})],1),_c('div',{staticClass:"form-elements-container"},[_c('FormulateForm',{on:{"submit":_vm.onSubmit},model:{value:(_vm.step1Values),callback:function ($$v) {_vm.step1Values=$$v},expression:"step1Values"}},[_c('div',{staticClass:"call-back-form-container"},[_c('h4',[_c('sc-text',{attrs:{"field":_vm.fields.personalDetailsHeading}})],1),_c('racq-formulate-input',{key:"firstName",attrs:{"type":"text","name":"firstName","label":_vm.fields.firstNameLabel,"validation":"required","validation-messages":{
            required: _vm.fields.firstNameRequiredErrorMessage,
          },"readonly":true,"experienceEditableValidationErrors":[_vm.fields.firstNameRequiredErrorMessage]}}),_c('racq-formulate-input',{attrs:{"type":"text","name":"lastName","label":_vm.fields.lastNameLabel,"validation":"required","validation-messages":{
            required: _vm.fields.lastNameRequiredErrorMessage,
          },"readonly":true,"experienceEditableValidationErrors":[_vm.fields.lastNameRequiredErrorMessage]}}),_c('racq-formulate-input',{attrs:{"type":"text","name":"membershipCardNumber","label":_vm.fields.membershipCardNumberLabel,"validation":"required","validation-messages":{
            required: _vm.fields.memberCardNumberRequiredErrorMessage,
          },"readonly":true,"experienceEditableValidationErrors":[_vm.fields.memberCardNumberRequiredErrorMessage]}}),((_vm.firstCoverage && _vm.firstCoverage.isMotor) || _vm.isExperienceEditorRunning)?_c('h4',[_c('sc-text',{attrs:{"field":_vm.fields.carDetailsHeading}})],1):_vm._e(),((_vm.firstCoverage && _vm.firstCoverage.isHome) || _vm.isExperienceEditorRunning)?_c('h4',[_c('sc-text',{attrs:{"field":_vm.fields.homeDetailsHeading}})],1):_vm._e(),((_vm.firstCoverage && _vm.firstCoverage.isPet) || _vm.isExperienceEditorRunning)?_c('h4',[_c('sc-text',{attrs:{"field":_vm.fields.petDetailsHeading}})],1):_vm._e(),((_vm.firstCoverage && _vm.firstCoverage.isBoat) || _vm.isExperienceEditorRunning)?_c('h4',[_c('sc-text',{attrs:{"field":_vm.fields.boatDetailsHeading}})],1):_vm._e(),(!_vm.isMultipleCoveragePolicy && !_vm.isLiteProduct)?_c('racq-formulate-input',{attrs:{"type":"text","name":"coverageDetail","label":_vm.getCoverageLabel().label,"validation":"required","readonly":true,"validation-messages":{
            required: _vm.getCoverageLabel().errorMessage,
          },"experienceEditableValidationErrors":[_vm.getCoverageLabel().errorMessage]}}):_vm._e(),(_vm.isMultipleCoveragePolicy)?_c('racq-formulate-input',{key:"selectbox",attrs:{"type":"selectbox","name":"multipleCoverables","label":_vm.getCoverageLabel().label,"options":_vm.getMultipleCoveragesOptions(),"placeholder":"Please select","validation":"required","validation-messages":{
            required: _vm.getCoverageLabel().errorMessage,
          },"experienceEditableValidationErrors":[_vm.getCoverageLabel().errorMessage]}}):_vm._e(),(_vm.insuranceProduct)?_c('racq-formulate-input',{key:"cancellationDate",attrs:{"disabled-dates":_vm.disabledDates,"label":_vm.fields.cancellationDateLabel,"validation":_vm.getDateValidation(),"name":"cancellationDate","type":"datepicker"},model:{value:(_vm.step1Values.cancellationDate),callback:function ($$v) {_vm.$set(_vm.step1Values, "cancellationDate", $$v)},expression:"step1Values.cancellationDate"}}):_vm._e(),_c('h4',[_c('sc-text',{attrs:{"field":_vm.fields.callBackDetailsHeading}})],1),_c('racq-formulate-input',{key:"preferredTime",attrs:{"options":_vm.getDropDownListItems(_vm.timePreferenceOptions),"name":"preferredTime","type":"selectbox","placeholder":"Please select","validation":"required","inputclass":"","label":_vm.fields.preferredTimeLabel,"inputhaserrorsclass":"input-error","validation-messages":{
            required: _vm.fields.preferredTimeRequiredErrorMessage,
          },"experienceEditableValidationErrors":[_vm.fields.preferredTimeRequiredErrorMessage]}}),_c('racq-formulate-input',{attrs:{"type":"text","name":"contactNumber","label":_vm.fields.contactNumberLabel,"validation":[
            ['required'],
            ['matches', /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/] ],"validation-messages":{
            required: _vm.fields.contactNumberRequiredErrorMessage,
            matches: _vm.fields.contactNumberInvalidErrorMessage,
          },"experienceEditableValidationErrors":[_vm.fields.contactNumberRequiredErrorMessage]}}),_c('div',{staticClass:"form-buttons"},[_c('FormulateInput',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideButtons),expression:"!hideButtons"}],attrs:{"type":"button","name":"Cancel","input-class":"btn btn-secondary","outer-class":""},on:{"click":_vm.cancelForm}}),_c('FormulateInput',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideButtons),expression:"!hideButtons"}],attrs:{"type":"submit","name":"Submit","input-class":"btn btn-primary","outer-class":""}})],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }