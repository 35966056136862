/**
 * DPO-90 Digital Solution Analysis
 */
module.exports = {
  // 8.1
  FormLoaded: {
    event: 'pageLoaded',
    web: {
      webPageDetails: {
        name: 'Optional benefits',
        server: 'forms.racq.com.au',
        URL: 'https://www.racq.com.au/',
        browserURL: '',
        pageViews: {
          value: 1,
        },
      },
      pageInfo: {
        previousPage: '',
        pageType: 'Form',
        pageId: '{D64A8DEF-27FF-48B2-892A-654C517F3F7A}', //Sitecore Page ID
        versionID: '',
      },
    },
  },
  // 8.2
  PolicyLoaded: {
    event: 'form.starts',
    form: {
      name: 'Optional benefits',
      type: 'SS',
      stepName: 'Start: Add optional benefits: 1',
    },
  },
  // 8.3
  SelectBenefitsClickedNext: {
    event: 'form.steps',
    form: {
      name: 'Optional benefits',
      type: 'SS',
      stepName: 'Start: Add optional benefits: 2',
    },
  },
  // 8.4
  FormComplete: {
    event: 'form.completes',
    form: {
      name: 'Optional Benefits',
      type: 'form',
      stepName: 'Complete: Optional benefits',
      Amount: null,
    },
  },
  //8.5
  MoveToReview: {
    event: 'form.steps',
    form: {
      amount: null,
      details: [],
    },
  },
  //8.6
  ReviewStepOnLoad: {
    event: 'form.steps',
    form: {
      details: [],
    },
  },
  //8.7
  ReviewStepOnNext: {
    event: 'form.steps',
    form: {
      amount: null,
    },
  },
  //8.8
  NavigationLinkClick: {
    event: 'navigation.linkClick',
    eventInfo: {
      navigation: {
        linkClicks: 1,
        name: 'Renew' || 'Submit' || 'click to chat' || 'Continue', //etc, //text name on button/link
        type: '', //Name of component (e.g Banner), Rendering ID",
      },
      promoInfo: {
        pageRegion: '', //Region of page the button is located
      },
    },
    form: {
      name: 'Optional benefits', //
      stepName: 'Add Optional Benefits' || 'Review', //what the step name is
    },
  },
};
