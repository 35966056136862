<template>
  <ul class="policy-select-list">
    <li v-for="policy in policies" :key="policy.policyNumber">
      <button :class="['policy-select-list-item', { selected: isSelected(policy.policyNumber) }]" @click="() => onPolicySelect(policy)">
        <div class="policy-select-list-item__icon">
          <img :src="policyIcon(policy)" alt="An icon of a vehicle." width="50" :class="selectedIconClasses(policy)" />
        </div>

        <div class="policy-select-list-item__general-details" ref="generalDetails">
          <div>
            <p class="general-details-title">{{ policy.policyNumber }}</p>

            <p class="no-margin" v-for="coverage in policy.coverages" :key="coverage.id">
              <strong v-if="coverage.registrationNumber" v-text="coverage.registrationNumber.toUpperCase()" /> &nbsp;
              <span class="text-uppercase" v-if="coverage.vehicleSummary" v-text="coverage.vehicleSummary" /> &nbsp;
              <span v-text="formattedPolicyCoverage(coverage)" />
            </p>
          </div>

          <div class="policy-select-list-item__payment-and-selection">
            <div class="policy-select-list-item__selection">
              <img v-if="isSelected(policy.policyNumber)" src="@/assets/img/icons/tick.svg" alt="A checkmark icon" />
            </div>

            <div class="policy-select-list-item__payment-details">
              <span v-if="policy.paymentFrequency" v-text="policy.paymentFrequency" />
              <span v-if="policy.paymentMethod" v-text="policy.paymentMethod" />
              <span v-if="policy.termEnd">Expires {{ formattedTermEnd(policy.termEnd) }}</span>
              <span v-if="policy.autoRenewal" v-text="'Auto renewal: ' + policy.autoRenewal" />
            </div>
          </div>
        </div>
      </button>
    </li>
  </ul>
</template>

<script>
import dayjs from 'dayjs';
import { policyCoverageTypes, coverageTypeCodes, policyIconSrc } from '@/components/feature/newmotorinsuranceform/services/mappings';
import { STANDARD_DATE_FORMAT } from '@/components/foundation/shared/utils/constants';

export default {
  name: 'PolicySelectList',

  props: {
    policies: {
      type: Array,
      required: true,
    },
    selectedPolicyNumber: {
      type: String,
      required: false,
    },
    onPolicySelect: {
      type: Function,
      required: true,
    },
  },

  computed: {
    isSelected() {
      return (policyNum) => this.selectedPolicyNumber === policyNum;
    },

    formattedTermEnd() {
      return (date) => dayjs(new Date(date)).format(STANDARD_DATE_FORMAT);
    },

    formattedPolicyCoverage() {
      // keep blank if coverageCode not found
      return (coverage) => (policyCoverageTypes[coverage.coverageCode] ? `(${policyCoverageTypes[coverage.coverageCode]})` : '');
    },

    policyIcon() {
      return (policy) => policyIconSrc[policy.coverages[0].coverageCode];
    },

    selectedIconClasses() {
      return (policy) => {
        if (this.isSelected(policy.policyNumber)) {
          // When selected, icons need to change color in-line with the selected BG color
          // Not all icons need additional styling when selected
          if (
            [coverageTypeCodes.Caravan, coverageTypeCodes.MotorComprehensive, coverageTypeCodes.MotorComprehensiveOther].includes(
              policy.coverages[0].coverageCode
            )
          )
            return '';

          return 'policy-select-list-item__icon--selected';
        }

        return '';
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.text-uppercase {
  text-transform: uppercase;
}

.policy-select-list {
  padding: 0;
}

.policy-select-list-item {
  background: #fff;
  border: 2px solid $racq-navy;
  color: $racq-navy;
  margin: 1.5rem 0;
  display: flex;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  position: relative;

  &.selected {
    background: $racq-navy;
    color: #fff;
  }

  // SVGs are tricky beasts to style, so to get around this, we can use the CSS filter property
  // ref: https://blog.union.io/code/2017/08/10/img-svg-fill/
  &__icon--selected {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(305deg) brightness(102%) contrast(102%);
  }

  &__general-details {
    flex-grow: 1;
    text-align: left;
    display: flex;
    justify-content: space-between;

    .general-details-title {
      font-size: 1.25rem;
      line-height: 1.5;
      @include heading-regular-font-family;
    }
  }

  &__payment-and-selection {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__selection {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 50%;
    margin-bottom: 1rem;
    text-align: right;
    border: 2px solid $racq-navy;
    width: 2rem;
    height: 2rem;
  }

  &__payment-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
    font-size: 0.75rem;
    font-weight: 600;
  }
}
</style>
