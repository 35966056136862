<template>
  <div v-if="transactionCost <= 0">
    <div class="step5-header__container">
      <div :class="showNoChange ? 'step5-header__long-content' : 'step5-header__content'">
        <sc-rich-text :field="fields.stepContent" />

        <div v-if="!showNoChange" v-html="reducedPremiumText"></div>
        <div v-if="showNoChange" v-html="noChangePremiumText"></div>
      </div>

      <div class="step5-header__amount-panel">
        <div v-if="!showNoChange" class="step5-header__amount-title-box">
          <sc-text :field="fields.annualPremiumReducedLabel" />
        </div>
        <div v-if="!showNoChange" class="step5-header__amount-box">
          ${{ totalCostWhole }}<span>.{{ totalCostFraction }}</span>
        </div>
        <button class="step5-header__amount-button btn btn-primary" @click="cancelForm">{{ fields.nextButtonText.value }}</button>
      </div>
    </div>

    <div>
      <sc-rich-text :field="fields.summaryHeader" />
      <summary-panel :items-to-display="itemsToReview">
        <!-- put some custom content in the default slot -->
        <sc-rich-text :field="fields.reviewHeader" />

        <div class="step5__review-columns">
          <div class="step5__property-icon">
            <sc-image :media="fields.houseIcon"></sc-image>
          </div>
          <div class="step5__property-details">
            <div class="step5__property-street">{{ insuranceStreetAddress }}</div>
            <div>{{ insuranceSuburbState }}</div>
          </div>
        </div>
      </summary-panel>

      <div class="form-buttons">
        &nbsp;
        <FormulateInput
          type="submit"
          :name="fields.nextButtonText.value"
          input-class="btn btn-primary step5-header__done-button"
          outer-class=""
          @click="cancelForm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RichText, Image, Text } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormsMixins from '../../../foundation/forms/mixins/RACQFormsMixins';
import { mapState, mapGetters } from 'vuex';
import SummaryPanel from './shared/SummaryPanel.vue';

export default {
  name: 'Step5CycloneInsurance',
  components: {
    ScRichText: RichText,
    ScText: Text,
    ScImage: Image,
    SummaryPanel,
  },
  computed: {
    ...mapState({
      insuranceProduct: (state) => state.cycloneInsuranceModule.insuranceProduct,
      step3FormValues: (state) => state.cycloneInsuranceModule.formState['step3'],
      transactionCost: (state) => state.cycloneInsuranceModule.transactionCost,
    }),
    ...mapGetters({
      insuranceStreetAddress: 'cycloneInsuranceModule/insuranceStreetAddress',
      insuranceSuburbState: 'cycloneInsuranceModule/insuranceSuburbState',
      buildingTypeDescription: 'cycloneInsuranceModule/buildingTypeDescription',
    }),
    itemsToReview() {
      // display the property type at the top
      let finalItemsToReview = [{ value: this.buildingTypeDescription, enableEditValue: false, label: this.getLabel('propertyType') }];

      // add additional properties for the items in the specified order
      // so we know how to display the review items
      if (this.step3FormValues) {
        this.step3FormValues.forEach((item) => {
          const label = this.getLabel(item.key);
          if (item.value) {
            finalItemsToReview.push({ value: item.displayValue, label: label, enableEditValue: true });
          }
        });
      }

      return finalItemsToReview;
    },
    showNoChange() {
      return this.transactionCost === 0;
    },
    isInRenewal() {
      return this.insuranceProduct?.insurancePolicy?.state?.isInRenewal === true;
    },
    isAnnual() {
      return (
        this.insuranceProduct?.insurancePolicy?.currentTermPaymentDetails?.isAnnual === true ||
        this.insuranceProduct?.insurancePolicy?.currentTermPaymentDetails?.discriminator === 'ResponsivePaymentDetails'
      );

      //responsive means it's a annual payment policy
    },
    isManualPayment() {
      return this.insuranceProduct?.insurancePolicy?.currentTermPaymentDetails?.discriminator === 'ResponsivePaymentDetails';
    },
    totalCostWhole() {
      return Math.trunc(Math.abs(this.transactionCost)).toLocaleString('en-AU');
    },
    totalCostFraction() {
      return this.formatCurrency(this.transactionCost).toString().split('.')[1];
    },
    reducedPremiumText() {
      let text = {};

      // use the mid term policy change text
      if (this.isAnnual) {
        text = this.fields.reducedPremiumAnnual.value;
      } else {
        text = this.fields.reducedPremiumMonthly.value;
      }

      // policy is in renewal use the renewal policy change text
      if (this.isInRenewal) {
        if (this.isManualPayment) {
          text = this.fields.reducedPremiumRenewalManual.value;
        } else {
          text = this.fields.reducedPremiumRenewalAuto.value;
        }
      }
      text = text.replace('${changeAmount}', this.formatCurrency(Math.abs(this.transactionCost)));
      return text;
    },
    noChangePremiumText() {
      let text = this.fields.noChangePremium.value; //start with mid term text
      if (this.isInRenewal) {
        text = this.fields.noChangePremiumRenewal.value;
      }

      const termEnd = new Date(this.insuranceProduct?.insurancePolicy?.termEnd?.substring(0, 10)).toLocaleDateString('en-AU');
      text = text.replace('${termEndDate}', termEnd);
      return text;
    },
  },
  methods: {
    getLabel(propertyKey) {
      const fieldName = `${propertyKey}Label`;
      return this.fields[fieldName]?.value;
    },
  },
  mixins: [RACQFormsMixins],
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
.step5-header__container {
  display: flex;
}

.step5-header__content {
  width: 60%;
}

.step5-header__long-content {
  width: 80%;
}

.step5-header__amount-panel {
  margin-top: 20px;
  margin-left: auto;
  font-size: 1rem;
  color: $racq-true-white;
}

.step5-header__amount-title-box {
  padding: 12px 18px;
  background-color: $racq-navy;
}

.step5-header__amount-box {
  padding: 12px 18px;
  border: 1px solid $racq-navy;
  height: 70px;
  margin-bottom: 16px;
  color: $racq-dark-grey;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;

  > span {
    font-size: 1.25rem;
  }
}

.btn.btn-primary.step5-header__amount-button {
  width: 100%;
  text-align: center;
}

.step5__property-icon {
  display: flex;
  width: 25%;
  flex-direction: row-reverse;
  margin-right: 50px;
  padding-right: 5px;
}

.step5__property-details {
  display: flex;
  flex-direction: column;
}

.step5__property-street {
  font-weight: bold;
}

.step5__review-columns {
  border-bottom: 1px solid $racq-navy-30p;
  padding: 16px;
  display: flex;
}

.btn.btn-primary.step5-header__done-button {
  margin-left: auto;
}
</style>
