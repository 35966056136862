import { render, staticRenderFns } from "./ExcessDetails.vue?vue&type=template&id=7fa7952a&scoped=true&"
import script from "./ExcessDetails.vue?vue&type=script&lang=js&"
export * from "./ExcessDetails.vue?vue&type=script&lang=js&"
import style0 from "./ExcessDetails.vue?vue&type=style&index=0&id=7fa7952a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fa7952a",
  null
  
)

export default component.exports