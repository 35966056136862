var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"py-4",attrs:{"id":"additional-driver-form"}},[_c('h3',[_vm._v("Add new Primary Driver")]),_c('TitleFirstLastName',{attrs:{"form-values":_vm.formValues,"id":"new-additional-driver-title-first-last-name"}}),_c('FormulateInput',{attrs:{"label":"Date of birth","placeholder":"DD/MM/YYYY","validation":"required|isValidDate","error-behaviour":"submit","validation-name":"Date of birth","validation-rules":_vm.validationRules,"disabled-dates":_vm.disabledFutureDates,"name":"dateOfBirth","type":"datepicker","format":"dd/MM/yyyy","tooltip":{
      content: 'tooltipContent',
      tooltipId: "dateOfBirth-id",
    },"validation-messages":{
      required: _vm.validationMessages.dobRequired,
    }}}),_c('FormulateInput',{attrs:{"label":"Your gender","name":"gender","type":"radio","options":[
      { value: 'MALE', label: 'Male' },
      { value: 'FEMALE', label: 'Female', id: 'gender_last-child' } ],"validation":"required","validation-messages":{
      required: _vm.validationMessages.genderRequired,
    }}}),_c('br'),_c('FormulateInput',{attrs:{"label":"Number of years fully licensed","name":"licenseYearsCount","validation":"required|number","tooltip":{
      title: '<h5>Why are we asking this?</h5>',
      content: '<span>Hello I am a tooltip with content applied.</span>',
      tooltipId: 'licenseYearsCount-tooltip-id',
    },"validation-messages":{
      required: 'Please select the number of years licenced',
      number: 'Please enter a valid number',
    }}}),_c('FormulateInput',{attrs:{"label":"In the last 3 years, how many at fault claims have you had?","name":"atFaultClaimsCount","type":"button-group","options":[
      { value: '0', label: '0' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5+', label: '5+', id: 'atFaultClaimsCount_last-child' } ],"tooltip":{
      title: '<h5>Why are we asking this?</h5>',
      content: '<span>Hello I am a tooltip with content applied.</span>',
      tooltipId: 'atFaultClaimsCount-tooltip-id',
    },"validation":"required","validation-messages":{
      required: _vm.validationMessages.atFaultClaimsRequired,
    }}}),(_vm.formValues['atFaultClaimsCount'] === '0')?_c('div',{staticClass:"custom-info custom-tag-icon"},[_c('img',{attrs:{"src":require("@/assets/img/icons/tag-icon.svg"),"alt":"An icon of a tag"}}),_vm._v(" Congratulations, you qualify for a No Claim discount! "),_vm._m(0)]):_vm._e(),(_vm.hasMoreThanOne('atFaultClaimsCount'))?_c('RepeatableAtFaultClaims',{attrs:{"name":"repeatableAtFaultClaims","id":"repeatableAtFaultClaims"}}):_vm._e(),_c('div',{staticClass:"form-field-with-custom-help-text licenceRestrictionCount-wrapper"},[_c('strong',{staticClass:"label"},[_vm._v("In the last 3 years, how many of the following have you had?")]),_c('div',{staticClass:"flex-col custom-help-text"},[_vm._m(1),_c('FormulateInput',{attrs:{"name":"licenceRestrictionCount","type":"button-group","options":[
          { value: '0', label: '0' },
          { value: '1', label: '1' },
          { value: '2', label: '2' },
          { value: '3', label: '3' },
          { value: '4', label: '4' },
          { value: '5+', label: '5+', id: 'licenceRestrictionCount_last-child' } ],"tooltip":{
          title: '<h5>Why are we asking this?</h5>',
          content: '<span>Hello I am a tooltip with content applied.</span>',
          tooltipId: 'licenceRestrictionCount-tooltip-id',
        },"validation":"required","validation-messages":{
          required: _vm.validationMessages.licenceHistoryRequired,
        }}})],1)]),(_vm.hasMoreThanOne('licenceRestrictionCount'))?_c('RepeatableOccurrenceInputGroup',{attrs:{"name":"repeatableOccurrenceCount","id":"repeatableOccurrenceCount","count":_vm.licenceRestrictionCount}}):_vm._e(),_c('FormulateInput',{attrs:{"label":"Has this driver had any driving under the influence of drug or alcohol offences in the last 3 years?","name":"hasAlcoholOffences","type":"radio","options":[
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No', id: 'hasAlcoholOffences_last-child' } ],"validation":"required","validation-messages":{
      required: _vm.validationMessages.duiOffencesRequired,
    }}}),_c('FormulateInput',{attrs:{"type":"checkbox","name":"confirmDriverDetails","label":"I confirm the primary driver details are correct","validation":"required","validation-messages":{
      required: 'Please confirm that the additional driver details are correct',
    }}}),_c('div',{staticClass:"flex-center"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"submit"},on:{"click":_vm.onSave}},[_vm._v("Save additional driver")])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info-box--basic"},[_c('p',[_vm._v("The No Claim Discount you are offered is based on:")]),_c('ul',{staticClass:"list-disc"},[_c('li',[_vm._v("The number of at fault claims the primary driver has had in the last three (3) years; and")]),_c('li',[_vm._v("The number of years the primary driver has held a driver’s license (excluding the time on a learner’s license).")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"list-disc"},[_c('li',[_vm._v("Any loss, cancellation, disqualification, or suspension of licence")]),_c('li',[_vm._v("Any altered or restricted licence conditions or Good Driver Behaviour period imposed")])])}]

export { render, staticRenderFns }