<template>
  <div class="form-step-container">
    <div class="form-step center-aligned">
      <div class="step-title">
        <h3 class="text-white">
          <sc-text :field="step.stepTitle" />
        </h3>
      </div>

      <div class="step-side-icon"></div>
      <div class="form-step-content">
        <div>
          <div class="step-content">
            <div class="bottom-margin">
              <div class="text-container">
                <sc-rich-text :field="step.stepContent" />
              </div>
            </div>
          </div>
        </div>

        <div class="form-elements-container" v-if="!jeopardyActive">
          <div class="form-buttons">
            <FormulateInput type="button" :label="step.btnPrev.value" name="no-thanks" input-class="btn btn-secondary" outer-class="" @click="onCancelForm" />

            <div>
              <FormulateInput
                type="submit"
                :disabled="jeopardyActive"
                :label="step.btnNext.value"
                name="proceed"
                input-class="btn btn-primary"
                outer-class=""
                @click="submitGeneralConditions"
              />
              <FormulateInput type="button" name="I do not agree" input-class="disagree-button" @click="showJeopardy" outer-class="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormsMixin from '@/components/foundation/forms/mixins/RACQFormsMixins';

import { scrollToElement } from '@/components/foundation/shared/utils/dom-helpers';
import { mapState, mapMutations } from 'vuex';
import { NEW_MOTOR_INSURANCE_MODULE } from '@/components/feature/newmotorinsuranceform/services/mappings';
import { JP_DOD_DISAGREE_KEY } from '@/components/foundation/shared/utils/constants';

export default {
  name: 'GeneralConditions',

  components: {
    ScText: Text,
    ScRichText: RichText,
  },

  mixins: [RACQFormsMixin],

  props: {
    step: {
      type: Object,
      default: () => ({
        fields: [],
      }),
    },
    onSubmitForm: {
      type: Function,
      default: () => undefined,
    },
    onCancelForm: {
      type: Function,
      default: () => undefined,
    },
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, ['jeopardyActive', 'viewModelData']),
  },

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, ['UPDATE_JEOPARDY_ACTIVE']),

    showJeopardy() {
      // For complete JP code reference, go to dictionary, go to:
      // https://racqcm.localhost/sitecore/api/jss/dictionary/RACQForms/en?sc_apikey=584FD054-6FD5-4E3F-8FD4-5F698355E25E
      this.UPDATE_JEOPARDY_ACTIVE({ jeopardyActive: true, activeJeopardyCode: JP_DOD_DISAGREE_KEY });
      scrollToElement('#jeopardy-container', 100);
    },

    submitGeneralConditions() {
      this.onSubmitForm({
        metadata: { stepId: 'GeneralConditions' },
        formData: this.viewModelData,
      });
    },
  },
};
</script>
