<template>
  <div class="form-step-container">
    <div class="form-step center-aligned">
      <div class="step-title no--padding">
        <h3>
          <span><sc-text :field="fields.formHeading" /></span>
        </h3>
      </div>
      <div class="step-side-icon"></div>
      <div class="form-step-content">
        <div>
          <div class="step-content">
            <div v-if="fields.rawHtmlDescription && fields.rawHtmlDescription.value">
              <sc-rich-text :field="fields.formDescription" />
            </div>
            <div v-else class="warning-note bottom-margin" :class="this.hasMultipleCoverables ? 'align-items-center' : ''">
              <sc-image :media="fields.warningIcon" />
              <div class="text-container">
                <sc-rich-text
                  :field="
                    isExperienceEditorRunning ? fields.formDescription : params.formType == 'CancelInsurance' ? this.formDescription : fields.formDescription
                  "
                />
                <sc-rich-text v-if="this.isPet" :field="fields.formDescriptionPetInsurance" />
              </div>
            </div>
          </div>
        </div>
        <div class="form-elements-container pt-4 pb-3">
          <div class="d-flex w-100">
            <FormulateInput
              type="button"
              :name="(fields.btnCancelText && fields.btnCancelText.value) || 'No, thanks'"
              input-class="btn btn-secondary"
              outer-class=""
              @click="cancelForm"
            />

            <FormulateInput
              type="submit"
              :name="(fields.btnAgreeText && fields.btnAgreeText.value) || 'Proceed'"
              input-class="btn btn-primary"
              outer-class="ml-auto"
              @click="proceedToForm"
            />
          </div>
          <div class="d-flex pt-2" v-if="fields.btnDisagreeText && fields.btnDisagreeText.value">
            <FormulateInput
              type="button"
              :name="fields.btnDisagreeText.value"
              input-class="btn-link bg-white border-0"
              outer-class="ml-auto"
              @click="disagreeForm"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-vue';
import { jpCodes } from '../../feature/cancelroadsideform/steps/shared/jpCodes';
import RACQFormsMixin from './mixins/RACQFormsMixins';
import Vuex from 'vuex';

export default {
  name: 'FormDisclaimer',
  components: {
    ScText: Text,
    ScRichText: RichText,
    ScImage: Image,
  },
  mixins: [RACQFormsMixin],
  computed: {
    ...Vuex.mapState({
      insuranceProduct: (state) => state.cancelInsuranceModule.insuranceProduct,
    }),
  },
  async mounted() {
    try {
      if (this.params.formType == 'CancelInsurance' && !this.isExperienceEditorRunning) {
        this.isPet = this.insuranceProduct && this.insuranceProduct.insurancePolicy.coverages[0].isPet;
        const isHome = this.insuranceProduct && this.insuranceProduct.insurancePolicy.coverages[0].isHome;
        this.hasMultipleCoverables =
          this.insuranceProduct && this.insuranceProduct.insurancePolicy && this.insuranceProduct.insurancePolicy.coverages.length > 1;
        this.formDescription = this.hasMultipleCoverables ? this.fields.formDescriptionMultipleCoverages : this.fields.formDescription;
        if (isHome && this.hasMultipleCoverables) {
          this.formDescription.value = this.formDescription.value.replaceAll('${insuranceType}', 'home and /or contents'); //only multiple coverable have specific text
        }
        this.formDescription.value = this.formDescription.value.replaceAll('${insuranceType}', this.insuranceProduct.insuranceType);
        this.formDescription.value = this.formDescription.value.replaceAll('${coverableDetails}', this.insuranceProduct.coverageDetails);
      }
    } catch (error) {
      console.error('error occurred', error);
      this.$root.$emit('show-jeopardy', {
        displayCode: jpCodes.genericError,
        jpCode: jpCodes.internalServerError,
        formName: 'Cancel Insurance Form',
      });
    }
  },
  methods: {
    proceedToForm() {
      this.$root.$emit('proceed-to-form');
    },
    /**
     * Throw JP31 if member disagree terms and conditions
     */
    disagreeForm() {
      this.$root.$emit('disagree-form');
    },
  },
  data() {
    return {
      formDescription: {},
      hasMultipleCoverables: false,
      isPet: false,
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
