<template>
  <div class="d-flex items-center gap-4">
    <veeno
      :class="`formulate-input-element formulate-input-element--${context.type}`"
      :data-type="context.type"
      v-model="context.model"
      :options="context.options"
      v-bind="context.attributes"
      v-on="$listeners"
      label="label"
      :placeholder="context.attributes.placeholder || 'Please select'"
    />

    <div v-if="context.attributes.tooltip && tooltipContent">
      <RacqFormTooltip :tooltip-id="context.attributes.tooltip.tooltipId">
        <template #tooltip-trigger>
          <button :class="[triggerIcon]" :id="context.attributes.tooltip.tooltipId" type="button" name="tooltip-trigger" />
        </template>
        <template #tooltip-content>
          <p v-if="tooltipTitle"><strong v-text="tooltipTitle" /></p>
          <div v-html="tooltipContent" v-if="tooltipContent"></div>
        </template>
      </RacqFormTooltip>
    </div>
  </div>
</template>

<script>
import RacqFormTooltip from '@/components/foundation/forms/controls/RacqFormTooltip.vue';
import veeno from 'veeno';
import 'nouislider/distribute/nouislider.min.css';

export default {
  components: {
    RacqFormTooltip,
    veeno,
  },

  mixins: [RacqFormTooltip],

  props: {
    context: {
      type: Object,
      required: true,
    },
    searchFn: {
      type: Function,
      required: false,
    },
  },

  methods: {
    onClose: function () {
      this.context.blurHandler();
    },

    async onSearch(search, loading) {
      if (this.$listeners.change) {
        loading(true);

        const data = await this.$listeners.change(search);

        this.$emit('on-search', data);

        loading(false);
      }
    },
  },

  computed: {
    tooltipContent() {
      const { tooltip } = this.context.attributes;

      if (tooltip && tooltip.content) {
        return tooltip.content;
      }

      return '';
    },

    tooltipTitle() {
      const { tooltip } = this.context.attributes;

      if (tooltip && tooltip.title) {
        return tooltip.title;
      }

      return '';
    },
  },
};
</script>
<style lang="scss">
.noUi-handle.noUi-handle-lower {
  border-radius: 50%;
  width: 30px;
  border: 1px solid #000;
}

.noUi-horizontal {
  height: 0.25rem;
  background-color: #c4c8ce;

  .noUi-handle {
    top: -15px;
    height: 30px;
    width: 30px;

    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
}

.noUi-base {
  background-color: #c4c8ce;
}

.noUi-handle:after,
.noUi-handle:before {
  display: none;
}

.noUi-marker.noUi-marker-horizontal.noUi-marker-large {
  display: none;
}

.noUi-value.noUi-value-horizontal.noUi-value-large {
  bottom: 3.25rem;
}
</style>
