var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"existing-primary-driver-details"}},[_c('p',{staticClass:"font-bold",domProps:{"textContent":_vm._s(_vm.formInputData(_vm.motorPolicyModel.primaryDriverFormTitle).label)}}),_c('div',{staticClass:"form-field-wrapper"},[_c('p',{staticClass:"form-field-label",domProps:{"textContent":_vm._s(_vm.formInputData(_vm.motorPolicyModel.longTermClaims).label)}}),_c('p',{staticClass:"form-field-value",domProps:{"textContent":_vm._s(_vm.formattedLongTermClaims)}})]),_c('div',{staticClass:"form-field-wrapper"},[_c('p',{staticClass:"form-field-label",domProps:{"textContent":_vm._s(_vm.formInputData(_vm.motorPolicyModel.shortTermClaims).label)}}),_c('p',{staticClass:"form-field-value",domProps:{"textContent":_vm._s(_vm.formattedShortTermClaims)}})]),_c('div',{staticClass:"form-field-wrapper"},[_c('p',{staticClass:"form-field-label",domProps:{"textContent":_vm._s(_vm.formInputData(_vm.motorPolicyModel.yearsLicenced).label)}}),_c('p',{staticClass:"form-field-value"},[_vm._v(_vm._s(_vm.selectedDriver.yearsLicenced)+" years")])]),(_vm.isMotorComprehensive(_vm.prefillData.coverageCode))?_c('div',{staticClass:"form-field-wrapper"},[_c('p',{staticClass:"form-field-label"},[_vm._v(_vm._s(_vm.formInputData(_vm.motorPolicyModel.isRatingOne).label))]),_c('div',{staticClass:"form-field-value"},[_c('FormulateInput',_vm._b({attrs:{"label":"","options":[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No', id: ((_vm.motorPolicyModel.isRatingOne) + "_last-child") } ],"validation":"required","validation-messages":{
          required: _vm.validationMessages.defaultRequired,
        }}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.isRatingOne),false))],1)]):_vm._e(),(_vm.formValues.isRatingOne === 'true')?_c('div',{staticClass:"form-field-wrapper"},[_c('p',{staticClass:"form-field-label"},[_vm._v(_vm._s(_vm.formInputData(_vm.motorPolicyModel.yearsRatingOneHeld).label))]),_c('div',{staticClass:"form-field-value"},[(_vm.formValues.isRatingOne === 'true')?_c('FormulateInput',_vm._b({attrs:{"label":"","options":_vm.yearsRatingOneHeldOptions,"validation":"required","validation-messages":{
          required: _vm.validationMessages.yearsRatingOneHeldRequired,
        }}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.yearsRatingOneHeld),false)):_vm._e()],1),_c('hr')]):_vm._e(),_c('p',{staticClass:"py-2",domProps:{"innerHTML":_vm._s(_vm.formInputData(_vm.motorPolicyModel.primaryDriverFormHelpText).label)}}),_c('FormulateInput',_vm._b({attrs:{"validation":"required","validation-messages":{
      required: _vm.validationMessages.primaryDriverDetailsConfirm,
    }}},'FormulateInput',_vm.formInputData(_vm.motorPolicyModel.confirmPrimaryDriver),false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }