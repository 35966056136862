import { render, staticRenderFns } from "./PolicyDiscounts.vue?vue&type=template&id=1e1ce1ea&scoped=true&"
import script from "./PolicyDiscounts.vue?vue&type=script&lang=js&"
export * from "./PolicyDiscounts.vue?vue&type=script&lang=js&"
import style0 from "./PolicyDiscounts.vue?vue&type=style&index=0&id=1e1ce1ea&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e1ce1ea",
  null
  
)

export default component.exports