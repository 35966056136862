<template>
  <div>
    <div class="step-content">
      <sc-rich-text :field="fields.reasonForCancellationContent" />
    </div>
    <div class="form-elements-container">
      <FormulateForm v-model="step1Values" @submit="onSubmit">
        <racq-formulate-input
          :options="getDropDownListItems(this.firstDropDownOptions)"
          key="reasonForCancellation"
          name="reasonForCancellation"
          type="selectbox"
          placeholder="Please select"
          validation="required"
          inputclass=""
          :label="fields.reasonForCancellationLabel"
          inputhaserrorsclass="input-error"
          :validation-messages="{
            required: fields.reasonForCancellationRequiredErrorMessage,
          }"
          :experienceEditableValidationErrors="[fields.reasonForCancellationRequiredErrorMessage]"
        />

        <racq-formulate-input
          :options="getDropDownListItems(this.secondDropDownOptions.data)"
          name="subReasonForCancellation"
          key="subReasonForCancellation"
          v-if="this.step1Values.reasonForCancellation && this.secondDropDownOptions.data && this.secondDropDownOptions.data.length"
          :type="
            this.step1Values.reasonForCancellation == this.vehicleNotRegistered || this.step1Values.reasonForCancellation == this.noLongerOwnVehicle //only show radio button if the reason is vehicle not registered or no longer own the vehicle
              ? 'radio'
              : 'selectbox'
          "
          placeholder="Please select"
          validation="required"
          :label="this.secondDropDownOptions.label"
          inputhaserrorsclass="input-error"
          :validation-messages="{
            required: this.secondDropDownOptions.requiredErrorMessage,
          }"
        />
        <racq-formulate-input
          :options="getDropDownListItems(this.thirdDropDownOptions.data)"
          name="anotherVehicle"
          key="anotherVehicle"
          v-if="this.step1Values.subReasonForCancellation && this.thirdDropDownOptions.data && this.thirdDropDownOptions.data.length"
          type="radio"
          placeholder="Please select"
          validation="required"
          :label="this.thirdDropDownOptions.label"
          inputhaserrorsclass="input-error"
          :validation-messages="{
            required: this.thirdDropDownOptions.requiredErrorMessage,
          }"
        />

        <racq-formulate-input
          :options="getDropDownListItems(this.fourthDropDownOptions.data)"
          name="changeVehicle"
          key="changeVehicle"
          v-if="this.step1Values.anotherVehicle && this.fourthDropDownOptions.data && this.fourthDropDownOptions.data.length"
          :type="this.fourthDropDownOptions.data.length > 2 ? 'selectbox' : 'radio'"
          placeholder="Please select"
          validation="required"
          :label="this.fourthDropDownOptions.label"
          inputhaserrorsclass="input-error"
          :validation-messages="{
            required: this.fourthDropDownOptions.requiredErrorMessage,
          }"
        />

        <racq-formulate-input
          name="additionalComments"
          v-if="showAdditionalComments()"
          type="textarea"
          inputhaserrorsclass="input-error"
          :label="fields.additionalCommentLabel"
          error-behavior="live"
          :validation="
            this.step1Values.reasonForCancellation == this.otherCancellationReason
              ? 'required|max:250,length|matches:/^[\\w\\-\\.\\s]*$/'
              : 'max:250,length|matches:/^[\\w\\-\\.\\s]*$/'
          "
        />
        <div class="form-buttons" v-show="showButtons">
          <FormulateInput type="button" name="Cancel" @click="cancelForm" input-class="btn btn-secondary" v-show="!hideButtons" outer-class="" />

          <FormulateInput type="submit" name="Next" v-show="!hideButtons" input-class="btn btn-primary" outer-class="" />

          <FormulateInput
            type="button"
            name="Edit"
            style="margin-left: auto"
            v-show="hideButtons"
            @click="onEdit"
            input-class="btn btn-primary btn-edit"
            outer-class=""
          />
        </div>
      </FormulateForm>
      <change-vehicle-tile-insurance v-if="!showButtons" :fields="fields" />
    </div>
  </div>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import RacqFormulateInput from '../../../foundation/forms/controls/racqFormulateInput.vue';
import RACQFormsMixins from '../../../foundation/forms/mixins/RACQFormsMixins';
import CascadeDropDownMixin from '../../../foundation/forms/mixins/CascadeDropDownMixins';
import { reasonCodes } from './shared/reasonCodes';
import { productApiService } from '../../../foundation/shared/apiservices/apiProductService';
import { jpCodes } from '../../cancelroadsideform/steps/shared/jpCodes';
import ChangeVehicleTileInsurance from './shared/ChangeVehicleTileInsurance.vue';
import Vuex from 'vuex';

export default {
  name: 'Step1CancelInsurance',
  components: {
    ScRichText: RichText,
    RacqFormulateInput,
    ChangeVehicleTileInsurance,
  },
  mounted() {
    if (this.insuranceProduct) {
      this.firstDropDownOptions = this.firstDropDownOptions.filter(
        (item) => item.fields.insuranceType.value == this.insuranceProduct.insurancePolicyType || item.fields.insuranceType.value == 'All'
      ); //filter options for selected insurance type and all
    }
  },
  methods: {
    commitDataToStore() {
      this.$store.dispatch('cancelInsuranceModule/clearStepData', 'All'); //clearing all steps data
      if (this.step1Values.reasonForCancellation) {
        var firstDropDownFieldValue = this.getFieldValue(
          this.fields.reasonForCancellationLabel.value,
          this.step1Values.reasonForCancellation,
          this.getSelectedOption(this.firstDropDownOptions, this.step1Values.reasonForCancellation).fields.listValue.value
        );
        this.$store.dispatch('cancelInsuranceModule/addStep1DataToStore', firstDropDownFieldValue);
      }
      if (this.step1Values.subReasonForCancellation) {
        var secondDropDownFieldValue = this.getFieldValue(
          this.secondDropDownOptions.label.value,
          this.step1Values.subReasonForCancellation,
          this.getSelectedOption(this.secondDropDownOptions.data, this.step1Values.subReasonForCancellation).fields.listValue.value
        );
        this.$store.dispatch('cancelInsuranceModule/addStep1DataToStore', secondDropDownFieldValue);
      }
      if (this.step1Values.anotherVehicle) {
        var thirdDropDownFieldValue = this.getFieldValue(
          this.thirdDropDownOptions.label.value,
          this.step1Values.anotherVehicle,
          this.getSelectedOption(this.thirdDropDownOptions.data, this.step1Values.anotherVehicle).fields.listValue.value
        );
        this.$store.dispatch('cancelInsuranceModule/addStep1DataToStore', thirdDropDownFieldValue);
      }
      if (this.step1Values.changeVehicle) {
        var fourthDropDownFieldValue = this.getFieldValue(
          this.fourthDropDownOptions.label.value,
          this.step1Values.changeVehicle,
          this.getSelectedOption(this.fourthDropDownOptions.data, this.step1Values.changeVehicle).fields.listValue.value
        );
        this.$store.dispatch('cancelInsuranceModule/addStep1DataToStore', fourthDropDownFieldValue);
      }
      if (this.step1Values.additionalComments) {
        var additionalCommentValue = this.getFieldValue(
          this.fields.additionalCommentLabel.value,
          this.step1Values.additionalComments,
          this.step1Values.additionalComments
        );
        this.$store.dispatch('cancelInsuranceModule/addStep1DataToStore', additionalCommentValue);
      }
    },
    getSubCancellationReason(model) {
      if (model.changeVehicle) {
        return model.changeVehicle;
      }
      if (model.anotherVehicle) {
        return model.anotherVehicle;
      }
      if (model.subReasonForCancellation) {
        return model.subReasonForCancellation;
      }
    },
    getCancellationReasons() {
      var cancellationReason = '';
      if (this.step1Data && this.step1Data.length) {
        for (var i = 0; i < this.step1Data.length; i++) {
          cancellationReason += this.step1Data[i].label + ':' + this.step1Data[i].text;
          cancellationReason += '|';
        }
      }
      return cancellationReason;
    },

    async onSubmit(model) {
      try {
        if (this.isExperienceEditorRunning) {
          this.$emit('go-next');
        } else {
          this.commitDataToStore();
          var response = await productApiService.isInsuranceEligibleForCancellation(this.$route.query.policyNumber, {
            subCancellationReasonId: this.getSubCancellationReason(model),
            cancellationReasonId: model.reasonForCancellation,
          });
          this.addAnalyticsFormData(this.step1Data, this.insuranceProduct.insuranceType);
          if (response.isEligible) {
            this.$emit('go-next');
          } else {
            this.$root.$emit('show-jeopardy', {
              displayCode: jpCodes.genericError,
              jpCode: response.jpCode,
              stepDetails: this.step.stepTitle,
              formName: 'Cancel Insurance Form',
              cancellationReasons: this.getCancellationReasons(),
            });
          }
        }
      } catch (error) {
        console.error('error occurred on api call', error);
        this.$root.$emit('show-jeopardy', {
          displayCode: jpCodes.genericError,
          jpCode: jpCodes.internalServerError,
          stepDetails: this.step.stepTitle,
          formName: 'Cancel Insurance Form',
          cancellationReasons: this.getCancellationReasons(),
        });
      }
    },
    onEdit() {
      this.$emit('visibility-requested');
    },
  },
  computed: {
    ...Vuex.mapState({
      insuranceProduct: (state) => state.cancelInsuranceModule.insuranceProduct,
      step1Data: (state) => state.cancelInsuranceModule.step1Data,
    }),
  },
  data() {
    return {
      showButtons: true, //if change vehicle scenario is triggered. All buttons need to be hidden
      firstDropDownOptions: this.cancellationReasons,
      secondDropDownOptions: [], //to load second dropdown options
      thirdDropDownOptions: [],
      fourthDropDownOptions: [],
      step1Values: {},
      vehicleNotRegistered: reasonCodes.vehicleNotRegisteredSitecoreId,
      noLongerOwnVehicle: reasonCodes.noLongerOwnVehicleSitecoreId,
      otherCancellationReason: reasonCodes.otherReasonSitecoreId,
    };
  },
  watch: {
    'step1Values.reasonForCancellation'() {
      // Populate the sub reason dropdown
      if (this.step1Values.reasonForCancellation) {
        this.getOptionsForSecondDropDown(); //setting options for second drop down
        this.thirdDropDownOptions = []; //reset options on another provider i.e. third dropdown
        this.step1Values.subReasonForCancellation = null; //reset value for second dropdown if 1st drop down value changes
      }
    },
    //fires when second dropdown value changes
    'step1Values.subReasonForCancellation'() {
      if (this.step1Values.subReasonForCancellation) {
        this.getOptionsForThirdDropDown(); //filter options for third dropdown
      }
    },
    //fires when third dropdown value changes
    'step1Values.anotherVehicle'() {
      if (this.step1Values.anotherVehicle) {
        this.getOptionsForFourthDropDown(); //filter options for third dropdown
      }
    },
    'step1Values.changeVehicle'() {
      if (this.step1Values.changeVehicle && this.step1Values.changeVehicle == '3a5e77a2-e6c6-4506-aad0-84407b7e8969') {
        this.showButtons = false;
      } else {
        if (this.step1Values.changeVehicle == 'ee074af0-939c-4752-92da-b47dbe75a49e') this.showButtons = true;
      }
    },
  },
  mixins: [RACQFormsMixins, CascadeDropDownMixin],
  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    cancellationReasons: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
