<template>
  <div>
    <sc-rich-text :field="fields.stepContent" />
    <div class="form-elements-container">
      <FormulateForm @submit="onSubmit">
        <div class="forms-light-panel">
          <h5>
            <sc-text :field="fields.reasonForCancellationHeading" />
          </h5>
          <product-details-tile
            :isReviewStep="true"
            :assistanceProduct="this.$store.state.cancelRoadsideModule.assistanceProduct"
            :fields="fields"
            :hasBorder="false"
          />
          <div class="review-table-content">
            <div class="header-content-title">
              <h5>
                <sc-text :field="fields.reasonForCancellationSubHeading" />
              </h5>
            </div>
            <div v-if="this.step1Data && this.step1Data">
              <div v-for="(stepValue, index) in this.step1Data" :key="index" class="review-table-row">
                <div class="review-table-column">
                  <div class="review-table-label">
                    {{ stepValue.label }}
                  </div>
                  <div class="review-table-value">
                    {{ stepValue.text }}
                  </div>
                  <div class="review-edit">
                    <a href="#"> Edit </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="warning-note">
          <sc-image :media="fields.warningNoteImage"></sc-image>
          <div class="text-container">
            <sc-rich-text :field="fields.noteDescription"></sc-rich-text>
          </div>
        </div>
        <div class="form-buttons">
          <FormulateInput type="button" name="Back" input-class="btn btn-secondary" @click="goBack" v-show="!hideButtons" outer-class="" />

          <FormulateInput type="submit" name="Confirm" v-show="!hideButtons" input-class="btn btn-primary" outer-class="" />
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { RichText, Text, Image } from '@sitecore-jss/sitecore-jss-vue';
import ProductDetailsTile from './shared/ProductDetailsTile.vue';
import { productApiService } from '../../../foundation/shared/apiservices/apiProductService';
import { jpCodes } from './shared/jpCodes';
import RACQFormsMixin from '../../../foundation/forms/mixins/RACQFormsMixins';
import Vuex from 'vuex';

export default {
  name: 'Step2CancelRoadside',
  components: {
    ScRichText: RichText,
    ScText: Text,
    ProductDetailsTile,
    ScImage: Image,
  },
  mixins: [RACQFormsMixin],
  mounted() {
    console.log('store data');
  },
  computed: {
    ...Vuex.mapState({
      step1Data: (state) => state.cancelRoadsideModule.step1Data,
    }),
  },
  methods: {
    goBack() {
      this.$emit('go-back');
    },
    getCancellationModel() {
      var data = {};
      data.cancellationReason = this.step1Data[0].text;
      data.cancellationReasonId = this.step1Data[0].value;
      data.subCancellationReasonId = this.step1Data[1].value;
      data.subCancellationReason = this.step1Data[1].text;
      if (this.step1Data.length == 4) {
        data.anotherProvider = this.step1Data[2].text;
      }
      return data;
    },
    async onSubmit() {
      try {
        if (this.isExperienceEditorRunning) {
          this.$emit('go-next');
        } else {
          await productApiService.setAssistanceAutoRenewFlag(this.$route.query.policyId, this.getCancellationModel());
          this.$emit('go-next');
        }
      } catch (error) {
        console.error('Error updating the auto renew flag', error);
        this.$root.$emit('show-jeopardy', {
          displayCode: jpCodes.genericError,
          jpCode: jpCodes.internalServerError,
          stepDetails: this.step.stepTitle,
          formName: 'Cancel Roadside Form',
        });
      }
    },
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    step: {
      type: Object,
    },
  },
};
</script>
