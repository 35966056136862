var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"py-4",attrs:{"id":"add-primary-driver-form"}},[_c('h3',[_vm._v("Add new Primary Driver")]),_c('TitleFirstLastName',{attrs:{"form-values":_vm.formValues,"id":"add-primary-driver-title-first-last-name"}}),_c('FormulateInput',{attrs:{"label":"Date of birth","placeholder":"DD/MM/YYYY","validation":"required|isValidDate","error-behaviour":"submit","validation-name":"Date of birth","validation-rules":_vm.validationRules,"disabled-dates":_vm.disabledFutureDates,"name":"dateOfBirth","type":"datepicker","format":"dd/MM/yyyy","tooltip":{
      content: 'tooltipContent',
      tooltipId: "dateOfBirth-id",
    },"validation-messages":{
      required: _vm.validationMessages.dobRequired,
    }}}),_c('FormulateInput',{attrs:{"label":"Your gender","name":"gender","type":"radio","validation":"required","options":[
      { value: 'MALE', label: 'Male' },
      { value: 'FEMALE', label: 'Female', id: 'gender_last-child' } ],"validation-messages":{
      required: _vm.validationMessages.genderRequired,
    }}}),_c('br'),_c('FormulateInput',{attrs:{"label":"Have you held your drivers licence for 10 years or more?","name":"hasLicenceMoreThan10Years","type":"radio","options":[
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No', id: 'hasLicenceMoreThan10Years_last-child' } ],"tooltip":{
      title: '<h5>Why are we asking this?</h5>',
      content: '<span>Hello I am a tooltip with content applied.</span>',
      tooltipId: 'hasLicenceMoreThan10Years-tooltip-id',
    },"validation":"required","validation-messages":{
      required: 'Please select if you have held your licence for 10 years or more',
    }}}),(_vm.formValues['hasLicenceMoreThan10Years'] === 'false')?_c('FormulateInput',{attrs:{"label":"How many years have you held your licence?","name":"yearsLicenced","type":"selectbox","placeholder":"Select item","options":_vm.licenceHeldYearOptions}}):_vm._e(),_c('FormulateInput',{attrs:{"label":"In the last 3 years, how many at fault claims have you had?","name":"longTermClaims","type":"button-group","options":[
      { value: '0', label: '0' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5+', label: '5+', id: 'longTermClaims_last-child' } ],"tooltip":{
      content: '<h5>Why are we asking this?</h5> <span>Hello I am a tooltip with content applied.</span>',
      tooltipId: 'longTermClaims-tooltip-id',
    },"validation":"required","validation-messages":{
      required: 'Please select the number of at fault claims you have had in the last 3 years',
    }}}),(_vm.formValues['longTermClaims'] === '0')?_c('div',{staticClass:"custom-info custom-tag-icon"},[_c('img',{attrs:{"src":require("@/assets/img/icons/tag-icon.svg"),"alt":"An icon of a tag"}}),_vm._v(" Congratulations, you qualify for a No Claim discount! "),_vm._m(0)]):_vm._e(),(_vm.hasMoreThanOne('longTermClaims'))?_c('RepeatableAtFaultClaims',{attrs:{"name":"repeatableAtFaultClaims","id":"repeatableAtFaultClaims"}}):_vm._e(),(_vm.hasMoreThanOne('longTermClaims'))?_c('FormulateInput',{attrs:{"label":"How many of these at fault claims were in the last 12 months?","name":"shortTermClaims","type":"button-group","options":_vm.numAtFaultClaimsOptions,"validation":"required","validation-messages":{
      required: 'Please select the number of at fault claims you have had in the last 12 months',
    }}}):_vm._e(),_c('FormulateInput',{attrs:{"label":"Do you hold a rating 1 with your current insurance provider?","name":"hasRatingOne","type":"radio","options":[
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No', id: 'hasRatingOne_last-child' } ],"tooltip":{
      title: '<h5>Why are we asking this?</h5>',
      content: '<span>Hello I am a tooltip with content applied.</span>',
      tooltipId: 'hasRatingOne-tooltip-id',
    }}}),(_vm.formValues['hasRatingOne'] === 'true')?_c('FormulateInput',{attrs:{"label":"How many years have you held your rating 1?","name":"yearsRatingOneHeld","type":"selectbox","placeholder":"Select item","searchable":false,"options":_vm.yearsHeldRatingOneOptions}}):_vm._e(),_c('FormulateInput',{attrs:{"label":"In the last 3 years, how many of the following have you had?","name":"licenceRestrictionCount","type":"button-group","options":[
      { value: '0', label: '0' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5+', label: '5+', id: 'licenceRestrictionCount_last-child' } ],"tooltip":{
      title: '<h5>Why are we asking this?</h5>',
      content: '<span>Hello I am a tooltip with content applied.</span>',
      tooltipId: 'licenceRestrictionCount-tooltip-id',
    }}}),(_vm.licenceRestrictionCount > 0)?_c('RepeatableOccurrenceRadioGroup',{key:_vm.licenceRestrictionCount,attrs:{"count":_vm.licenceRestrictionCount,"id":"licenceRestrictionCount"}}):_vm._e(),_c('div',{staticClass:"form-field-with-custom-help-text"},[_c('strong',{staticClass:"label"},[_vm._v("Have you, any policyholders or any other drivers had any of the following?")]),_c('div',{staticClass:"flex-col custom-help-text"},[_vm._m(1),_c('FormulateInput',{attrs:{"name":"hasCriminalOffence","type":"radio","options":[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No', id: 'hasCriminalOffence_last-child' } ],"tooltip":{
          title: '<h5>Why are we asking this?</h5>',
          content: '<span>Hello I am a tooltip with content applied.</span>',
          tooltipId: 'hasCriminalOffence-tooltip-id',
        }}}),_c('br')],1)]),_c('FormulateInput',{attrs:{"type":"checkbox","name":"confirm-primary-driver-details","label":"I confirm the primary driver details are correct","validation-messages":{
      required: 'Please confirm that the primary driver details are correct',
    },"validation":"required"}}),_vm._m(2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info-box--basic"},[_c('p',[_vm._v("The No Claim Discount you are offered is based on:")]),_c('ul',{staticClass:"list-disc"},[_c('li',[_vm._v("The number of at fault claims the primary driver has had in the last three (3) years; and")]),_c('li',[_vm._v("The number of years the primary driver has held a driver’s license (excluding the time on a learner’s license).")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"list-disc"},[_c('li',[_vm._v("In the last 5 years, been convicted of any criminal offence?")]),_c('li',[_vm._v("In the last 3 years, had any insurance refused, cancelled or voided?")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-center"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"submit"}},[_vm._v("Save Primary Driver")])])}]

export { render, staticRenderFns }