var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":_vm.formInputId}},[_c('h5',{staticClass:"accessories-heading"},[_vm._v("Accessories / modifications")]),_c('FormulateInput',{attrs:{"type":_vm.inputType,"name":_vm.groupInputFieldName,"minimum":1,"repeatable":true,"label":"","add-label":_vm.addLabel},scopedSlots:_vm._u([{key:"addmore",fn:function(ref){
var addMore = ref.addMore;
return [(_vm.shouldShowAddButton)?_c('button',{staticClass:"add-item-btn",attrs:{"type":"button"},domProps:{"textContent":_vm._s(_vm.addMoreButtonText)},on:{"click":function($event){return addMore()}}}):_vm._e()]}},{key:"remove",fn:function(ref){
var removeItem = ref.removeItem;
return [(_vm.accessoriesList.length > 1)?_c('button',{staticClass:"remove-item-btn",attrs:{"type":"button"},on:{"click":function($event){return removeItem()}}}):_vm._e()]}}])},[_c('FormulateInput',{staticClass:"what-type-of-accessory",attrs:{"type":"vue-select","name":_vm.optionsFieldName,"validation":"customRequired","validation-rules":{ customRequired: function (ref) {
	var value = ref.value;

	return _vm.customRequiredValidation(value);
} },"validation-messages":{ customRequired: 'Please select an option' },"options":_vm.options}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }