<template>
  <div v-if="isExperienceEditorRunning || this.insurancePolicy">
    <div class="form-elements-container">
      <FormulateForm v-model="step2Values" @submit="onSubmit">
        <div>
          <span v-show="this.isCancelledPolicy || isExperienceEditorRunning">
            <sc-rich-text :field="isExperienceEditorRunning ? fields.cancelledPolicyRenewalDetailsMessage : cancelledPolicyRenewalDetailsMessage" />
          </span>
          <span v-show="!this.isCancelledPolicy || isExperienceEditorRunning">
            <sc-rich-text :field="isExperienceEditorRunning ? fields.renewalDetailsMessage : renewalDetailsMessage" />
          </span>
          <div class="forms-review-content form-review-content-border space-around-content">
            <div class="half-width-content">
              <sc-image class="top-left-icon" :media="fields.homeContentInsuranceIcon" v-if="this.isHomeAndContent(coverageType)" />
              <sc-image class="top-left-icon" :media="fields.homeInsuranceIcon" v-else-if="this.isHome(coverageType)" />
              <sc-image class="top-left-icon" :media="fields.petInsuranceIcon" v-else-if="this.isPet(coverageType)" />
              <sc-image class="top-left-icon" :media="fields.motorInsuranceIcon" v-else />
              <sc-rich-text :field="isExperienceEditorRunning ? fields.productOverviewPolicyInfo : productOverviewPolicyInfo" />
            </div>
            <div class="secondary-text-color">
              <sc-rich-text :field="isExperienceEditorRunning ? fields.productOverviewPricingInfo : productOverviewPricingInfo" />
              <div v-if="isExperienceEditorRunning || !this.isAnnualPayment">
                <sc-rich-text :field="fields.adminFeeInfo" />
                <sc-rich-text :field="isExperienceEditorRunning ? fields.totalAnnualCostInfo : totalAnnualCostInfo" />
              </div>
            </div>
          </div>
          <span v-show="!this.isCancelledPolicy || isExperienceEditorRunning">
            <sc-rich-text :field="fields.policyDetailsMessage" />
          </span>
          <span v-show="this.isCancelledPolicy || isExperienceEditorRunning">
            <sc-rich-text :field="fields.cancelledPolicyPolicyDetailsMessage" />
          </span>

          <span v-show="!this.isCancelledPolicy || isExperienceEditorRunning">
            <sc-rich-text
              v-if="this.step2Values.renewalOfferAccepted == null"
              :field="isExperienceEditorRunning ? fields.renewalOfferMessage : renewalOfferMessage"
            />
          </span>
          <span v-show="this.isCancelledPolicy || isExperienceEditorRunning">
            <sc-rich-text
              v-if="this.step2Values.renewalOfferAccepted == null"
              :field="isExperienceEditorRunning ? fields.cancelledPolicyRenewalOfferMessage : cancelledPolicyRenewalOfferMessage"
            />
          </span>
          <racq-formulate-input
            type="radio"
            :label="fields.renewalOfferQuestionLabel"
            :options="{ true: 'true', false: 'false' }"
            name="renewalOfferAccepted"
          ></racq-formulate-input>

          <!-- user has accepted the renewal offer -->
          <div v-show="this.step2Values.renewalOfferAccepted === 'true'">
            <div v-show="!this.isCancelledPolicy">
              <hr />
              <sc-rich-text :field="fields.autoRenewalDefaultMessage" />
              <br />
              <racq-formulate-input
                name="autoRenewalOptedIn"
                type="radio"
                :label="fields.autoRenewalNextTimeLabel"
                :options="{ true: 'true', false: 'false' }"
              ></racq-formulate-input>
              <div class="forms-review-content form-review-content-border secondary-notice-box" v-if="this.step2Values.autoRenewalOptedIn === 'true'">
                <sc-rich-text :field="fields.autoRenewalOptInMessage" />
              </div>
              <div class="forms-review-content form-review-content-border secondary-notice-box" v-if="this.step2Values.autoRenewalOptedIn === 'false'">
                <sc-rich-text :field="fields.autoRenewalOptOutMessage" />
              </div>
            </div>
            <hr />
            <span v-show="!this.isCancelledPolicy || isExperienceEditorRunning">
              <sc-rich-text :field="isExperienceEditorRunning ? fields.nextDebitDueDateMessage : nextDebitDueDateMessage" />
            </span>
            <span v-show="this.isCancelledPolicy || isExperienceEditorRunning">
              <sc-rich-text :field="fields.cancelledPolicyNextDebitDueDateMessage" />
            </span>
            <racq-formulate-input
              v-if="this.step2Values.renewalOfferAccepted === 'true'"
              name="tAndCChecked"
              type="checkbox"
              :label="fields.tAndCCheckboxMessage"
              validation="accepted"
              :validation-messages="{
                accepted: this.fields.mustAcceptTandCMessage,
              }"
            />
            <racq-formulate-input name="emailRenewalReceipt" type="checkbox" :label="this.emailReceiptCheckboxMessage" />
          </div>

          <!-- user has rejected renewal Offer -->
          <div v-show="this.step2Values.renewalOfferAccepted === 'false'">
            <div v-show="!this.isCancelledPolicy || isExperienceEditorRunning">
              <div class="forms-review-content form-review-content-border secondary-notice-box">
                <sc-rich-text :field="isExperienceEditorRunning ? fields.renewalOfferDeclineMessage : renewalOfferDeclineMessage" />
              </div>
              <hr />
              <div>
                <sc-rich-text :field="fields.renewalOfferDeclineReasonMessage" />
              </div>
              <racq-formulate-input
                name="offerRejectionReason"
                type="selectbox"
                :label="fields.renewalOfferDeclineReasonQuestionLabel"
                :options="getDropDownListItems(this.fields.RejectionReasons, true)"
                placeholder="Please select"
              ></racq-formulate-input>
            </div>
            <div v-show="this.isCancelledPolicy || isExperienceEditorRunning">
              <hr />
              <div class="forms-review-content form-review-content-border secondary-notice-box">
                <sc-rich-text :field="fields.cancelledPolicyRenewalOfferDeclineMessage" />
              </div>
            </div>
          </div>
        </div>
        <div class="form-buttons" v-show="showButtons">
          <FormulateInput type="button" name="Back" input-class="btn btn-secondary" v-show="!hideButtons" outer-class="" @click="goBack" />
          <FormulateInput type="submit" name="Submit" v-show="!hideButtons" :input-class="submitButtonClass" outer-class="" />
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { RichText, Image } from '@sitecore-jss/sitecore-jss-vue';
import RacqFormulateInput from '../../../foundation/forms/controls/racqFormulateInput.vue';
import RACQFormsMixins from '../../../foundation/forms/mixins/RACQFormsMixins';
import { renewalOfferApiService } from '../../../foundation/shared/apiservices/apiRenewalOfferService';
import { jpCodes } from '../../renewalofferform/steps/shared/jpCodes';
import { scPlaceholders } from '../../renewalofferform/steps/shared/sitecorePlaceholders';
import { IsHomeAndContentInsurance, IsHomeInsurance, IsPetInsurance } from '../../renewalofferform/steps/shared/coverageTypes';

import { mapActions, mapState } from 'vuex';

export default {
  name: 'RenewalOfferFormStep2',
  components: {
    ScRichText: RichText,
    RacqFormulateInput,
    ScImage: Image,
  },
  methods: {
    ...mapActions('renewalOffer', ['setRenewalOfferPreference', 'sendRenewalReceiptEmail', 'reinstatePolicy']),
    isHomeAndContent(coverageType) {
      return IsHomeAndContentInsurance(coverageType);
    },
    isHome(coverageType) {
      return IsHomeInsurance(coverageType);
    },
    isPet(coverageType) {
      return IsPetInsurance(coverageType);
    },
    goBack() {
      this.$emit('go-back');
    },
    async onSubmit(model) {
      if (this.isExperienceEditorRunning) {
        this.$emit('go-next');
        return;
      }

      this.$emit('show-loading-mask');
      let updateRequest = {
        policyNumber: this.$route.query.policyId || this.$route.query.policyNumber,
        renewalPreference: model.autoRenewalOptedIn,
        offerAccepted: model.renewalOfferAccepted,
        offerRejectionReason: model.offerRejectionReason,
      };

      // Reinstate a policy if it was cancelled, otherwise renew it
      if (this.isCancelledPolicy) {
        if (model.renewalOfferAccepted === 'false') {
          this.$store.commit('updateInsurancePolicy', updateRequest);
          this.$emit('go-next');
        } else {
          try {
            await this.reinstatePolicy(updateRequest);

            if (model.emailRenewalReceipt) {
              try {
                await renewalOfferApiService.sendRenewalReceiptEmail({
                  isReinstatement: true,
                  isAnnual: this.insurancePolicy?.renewalTermPaymentDetails.isAnnual,
                  subject: 'renewal offer receipt',
                  toAddresses: [this.email],
                  firstname: this.insurancePolicy.policyHolders[0].firstName,
                  effectiveDate: new Date(this.insurancePolicy.renewalTermStart).toLocaleDateString('en-AU'),
                  PremiumAmount: this.insurancePolicy?.renewalTermPaymentDetails.nextInvoiceAmountDue,
                });
              } catch (error) {
                // no action on unsuccessful email delivery
              }
            }

            this.$emit('go-next');
          } catch (error) {
            this.$root.$emit('show-jeopardy', {
              displayCode: jpCodes.genericError,
              jpCode: jpCodes.genericError,
              stepDetails: this.step.stepTitle,
              formName: 'Renewal Offer Form',
            });
          }
        }
      } else {
        try {
          //Ensure renewalPreference is false whenever offer was rejected
          if (model.renewalOfferAccepted === 'false') updateRequest.renewalPreference = false;

          await this.setRenewalOfferPreference(updateRequest);

          if (model.emailRenewalReceipt) {
            try {
              await renewalOfferApiService.sendRenewalReceiptEmail({
                isAnnual: this.insurancePolicy?.renewalTermPaymentDetails.isAnnual,
                subject: 'renewal offer receipt',
                toAddresses: [this.email],
                firstname: this.insurancePolicy.policyHolders[0].firstName,
                effectiveDate: new Date(this.insurancePolicy.termEnd).toLocaleDateString('en-AU'),
                PremiumAmount: this.insurancePolicy?.renewalTermPaymentDetails.nextInvoiceAmountDue,
              });
            } catch (error) {
              // no action on unsuccessful email delivery
            }
          }

          this.$emit('go-next');
        } catch (error) {
          this.$root.$emit('show-jeopardy', {
            displayCode: jpCodes.genericError,
            jpCode: jpCodes.genericError,
            stepDetails: this.step.stepTitle,
            formName: 'Renewal Offer Form',
          });
        }
      }
      this.$emit('hide-loading-mask');
    },
  },
  data: function () {
    return {
      showButtons: true,
      rejectReasonSelected: 'elsewhere',
      emailRenewalReceipt: false,
      step2Values: {
        renewalOfferAccepted: null,
        autoRenewalOptedIn: null,
      },
      renewalDetailsMessage: {},
      renewalOfferMessage: {},
      cancelledPolicyRenewalDetailsMessage: {},
      cancelledPolicyRenewalOfferMessage: {},
      productOverviewPolicyInfo: {},
      productOverviewPricingInfo: {},
      totalAnnualCostInfo: {},
      renewalOfferDeclineMessage: {},
      cancelledPolicyRenewalOfferDeclineMessage: {},
      nextDebitDueDateMessage: {},
      emailReceiptCheckboxMessage: {},
    };
  },
  mounted() {
    this.cancelledPolicyRenewalDetailsMessage = this.fields.cancelledPolicyRenewalDetailsMessage;
    this.cancelledPolicyRenewalDetailsMessage.value = this.fields.cancelledPolicyRenewalDetailsMessage.value
      .replace(scPlaceholders.insuranceType, this.coverageCodeToLabel(this.insurancePolicy?.coverages[0].coverageCode))
      .replace(scPlaceholders.daysToExpiry, this.datesToExpire)
      .replace(
        scPlaceholders.expiryDate,
        this.isExperienceEditorRunning
          ? new Date(2099, 12, 31).toLocaleDateString('en-AU')
          : new Date(this.insurancePolicy.renewalTermStart).toLocaleDateString('en-AU')
      );

    this.renewalDetailsMessage = this.fields.renewalDetailsMessage;
    this.renewalDetailsMessage.value = this.fields.renewalDetailsMessage.value
      .replace(scPlaceholders.insuranceType, this.coverageCodeToLabel(this.insurancePolicy?.coverages[0].coverageCode))
      .replace(scPlaceholders.daysToExpiry, this.datesToExpire)
      .replace(
        scPlaceholders.expiryDate,
        this.isExperienceEditorRunning ? new Date(2099, 12, 31).toLocaleDateString('en-AU') : new Date(this.insurancePolicy.termEnd).toLocaleDateString('en-AU')
      );

    this.renewalOfferMessage = this.fields.renewalOfferMessage;
    this.renewalOfferMessage.value = this.fields.renewalOfferMessage.value
      .replace(scPlaceholders.insuranceType, this.coverageCodeToLabel(this.insurancePolicy?.coverages[0].coverageCode))
      .replace(scPlaceholders.daysToExpiry, this.datesToExpire)
      .replace(
        scPlaceholders.expiryDate,
        this.isExperienceEditorRunning ? new Date(2099, 12, 31).toLocaleDateString('en-AU') : new Date(this.insurancePolicy.termEnd).toLocaleDateString('en-AU')
      );

    this.cancelledPolicyRenewalOfferMessage = this.fields.cancelledPolicyRenewalOfferMessage;
    this.cancelledPolicyRenewalOfferMessage.value = this.fields.cancelledPolicyRenewalOfferMessage.value
      .replace(scPlaceholders.insuranceType, this.coverageCodeToLabel(this.insurancePolicy?.coverages[0].coverageCode))
      .replace(scPlaceholders.daysToExpiry, this.datesToExpire)
      .replace(
        scPlaceholders.expiryDate,
        this.isExperienceEditorRunning
          ? new Date(2099, 12, 31).toLocaleDateString('en-AU')
          : new Date(this.insurancePolicy.renewalTermStart).toLocaleDateString('en-AU')
      );

    var nameLabel = '';
    nameLabel = this.insurancePolicy?.policyHolders.reduce(
      (previousValue, currentValue) => previousValue + currentValue.firstName + '&nbsp;' + currentValue.lastName + ', ',
      nameLabel
    );

    this.productOverviewPolicyInfo = this.fields.productOverviewPolicyInfo;
    this.productOverviewPolicyInfo.value = this.fields.productOverviewPolicyInfo.value
      .replace(scPlaceholders.insuranceType, this.coverageCodeToLabel(this.insurancePolicy?.coverages[0].coverageCode))
      .replace(scPlaceholders.policyNumber, this.insurancePolicy?.policyNumber)
      .replace(
        scPlaceholders.termStart,
        this.isExperienceEditorRunning
          ? new Date(2099, 12, 31).toLocaleDateString('en-AU')
          : new Date(this.insurancePolicy.renewalTermStart).toLocaleDateString('en-AU')
      )
      .replace(
        scPlaceholders.termEnd,
        this.isExperienceEditorRunning
          ? new Date(2099, 12, 31).toLocaleDateString('en-AU')
          : new Date(this.insurancePolicy.renewalTermEnd).toLocaleDateString('en-AU')
      )
      .replace(scPlaceholders.policyHolderFullname, nameLabel.substring(0, nameLabel.length - 2));

    this.productOverviewPricingInfo = this.fields.productOverviewPricingInfo;
    this.productOverviewPricingInfo.value = this.fields.productOverviewPricingInfo.value
      .replace(scPlaceholders.costPerPaymentPeriod, this.formatCurrency(this.insurancePolicy?.renewalTermPaymentDetails.nextInvoiceAmountDue))
      .replace(scPlaceholders.paymentPeriod, this.isAnnualPayment ? 'annually' : 'per month');

    this.totalAnnualCostInfo = this.fields.totalAnnualCostInfo;
    this.totalAnnualCostInfo.value = this.fields.totalAnnualCostInfo.value.replace(
      scPlaceholders.totalAnnualPrice,
      this.formatCurrency(this.insurancePolicy?.renewalTermPaymentDetails.totalAmountDue)
    );

    this.renewalOfferDeclineMessage = this.fields.renewalOfferDeclineMessage;
    this.renewalOfferDeclineMessage.value = this.fields.renewalOfferDeclineMessage.value.replace(
      scPlaceholders.expiryDate,
      this.isExperienceEditorRunning ? new Date(2099, 12, 31).toLocaleDateString('en-AU') : new Date(this.insurancePolicy.termEnd).toLocaleDateString('en-AU')
    );

    this.cancelledPolicyRenewalOfferDeclineMessage = this.fields.cancelledPolicyRenewalOfferDeclineMessage;
    this.cancelledPolicyRenewalOfferDeclineMessage.value = this.fields.cancelledPolicyRenewalOfferDeclineMessage.value.replace(
      scPlaceholders.expiryDate,
      this.isExperienceEditorRunning
        ? new Date(2099, 12, 31).toLocaleDateString('en-AU')
        : new Date(this.insurancePolicy.renewalTermStart).toLocaleDateString('en-AU')
    );

    this.nextDebitDueDateMessage = this.fields.nextDebitDueDateMessage;
    this.nextDebitDueDateMessage.value = this.fields.nextDebitDueDateMessage.value.replace(
      scPlaceholders.nextDebitDate,
      new Date(this.insurancePolicy?.renewalTermPaymentDetails.nextInvoiceDueDate).toLocaleDateString('en-AU')
    );

    this.emailReceiptCheckboxMessage = this.fields.emailReceiptCheckboxMessage;
    this.emailReceiptCheckboxMessage.value = this.fields.emailReceiptCheckboxMessage.value.replace(scPlaceholders.email, this.email);
  },
  computed: {
    ...mapState('renewalOffer', ['email', 'insurancePolicy']),
    coverageType() {
      return this.insurancePolicy?.coverages[0].coverageType;
    },
    datesToExpire() {
      return (
        Math.floor((this.isExperienceEditorRunning ? new Date(2099, 12, 31) : new Date(this.insurancePolicy.termEnd) - Date.now()) / (24 * 60 * 60 * 1000)) + 1
      );
    },
    isAnnualPayment() {
      return this.insurancePolicy?.renewalTermPaymentDetails.isAnnual === true;
    },
    submitButtonClass() {
      if (!this.isCancelledPolicy) {
        if (
          (this.step2Values.renewalOfferAccepted === 'true' && this.step2Values.autoRenewalOptedIn != null && this.step2Values.tAndCChecked === true) ||
          (this.step2Values.renewalOfferAccepted === 'false' && this.step2Values.offerRejectionReason != null)
        ) {
          return 'btn btn-primary';
        } else {
          return 'btn btn-primary disabled';
        }
      } else {
        if ((this.step2Values.renewalOfferAccepted === 'true' && this.step2Values.tAndCChecked === true) || this.step2Values.renewalOfferAccepted === 'false') {
          return 'btn btn-primary';
        } else {
          return 'btn btn-primary disabled';
        }
      }
    },
    isCancelledPolicy() {
      return this.insurancePolicy?.status === 'Cancelled';
    },
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    step: {
      type: Object,
    },
  },
  mixins: [RACQFormsMixins],
};
</script>
