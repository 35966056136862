<template>
  <fieldset :id="id">
    <slot name="legend">
      <legend class="legend" v-text="legendTitle" />
    </slot>

    <ul class="options-list">
      <li
        :class="['radio-block', { 'radio-block--checked': isChecked(option) }]"
        v-for="option in options"
        :key="option.value"
        @click="() => checkItem(option)"
      >
        <input :name="id" :id="option.id" :value="option.value" type="radio" :checked="isChecked(option)" @click="() => checkItem(option)" />

        <label :class="['radio-block-label']" :for="option.id">
          <slot name="label" :option="option" :is-checked="isChecked(option)">
            <span v-text="option.label" />
          </slot>
        </label>
      </li>
    </ul>

    <slot name="footer"> </slot>
  </fieldset>
</template>

<script>
export default {
  name: 'CustomRadioInput',

  emits: ['on-checked'],

  props: {
    legendTitle: {
      type: String,
      default: 'Select your car',
    },

    id: {
      type: String,
      default: 'selection-radio-group',
    },

    options: {
      type: Array,
      default: () => [
        {
          label: 'TONALE Ti MHEV MY22 Turbo - 4 Door WAGON - 7 Speed Auto FWD - T4 1.469L',
          value: '0D3J23',
          id: 'car-id-1',
          selected: false,
        },
        {
          label: 'SPECTRE TiD MY22 Turbo - 4 Door WAGON - 7 Speed Auto FWD - T6 2.49L',
          value: '0D3JXX',
          id: 'car-id-2',
          selected: false,
        },
      ],
    },

    defaultSelected: {
      type: Object,
      required: false,
    },
  },

  methods: {
    checkItem(item) {
      this.$emit('on-checked', item);
    },
  },

  computed: {
    checkedItem() {
      return this.defaultSelected || this.options.find((item) => item.selected);
    },

    isChecked() {
      return (item) => {
        if (item?.defaultSelected || item?.selected) return true;

        if (item?.selected === false || !this?.checkedItem?.value) return false;

        return this.checkedItem?.value === item?.value;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.options-list {
  padding: 0;
}

.legend {
  font-size: 1rem;
  color: $racq-navy;
  margin-bottom: 0;
  padding-top: 0.75rem;
  @include heading-regular-font-family;
}

li.radio-block {
  background: $racq-pale-white;
  border: 1px solid $racq-pale-white;
  border-radius: 3px;
  margin: 14px 0;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #b8cad6;
    border-color: $racq-pale-white;
  }

  .radio-block-label {
    margin: 0;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;

    padding: 2px 10px 2px 1.5em;
    border: 1px solid transparent;
    padding: 1rem 1.5rem 1rem 2.25rem;
    width: 100%;
  }

  input[type='radio'] {
    position: absolute;
    left: 1rem;
  }

  input[type='radio']:checked + label {
    background: $racq-navy;
    border-color: $racq-pale-white;
    color: #fff;
  }
}

.light-theme {
  li.radio-block {
    input[type='radio']:checked + label {
      background: $racq-pale-white;
      color: #000;
      border-color: $racq-navy;
    }
  }
}
</style>
