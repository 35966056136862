var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('racq-form',[_c('step-tracker',{key:_vm.reloadSteps,attrs:{"steps":_vm.steps}}),_c('div',[_c('racq-form-step',{ref:"step1",attrs:{"stepNumber":_vm.fields.childItems[0].fields.stepNumber,"stepTitle":_vm.fields.childItems[0].fields.stepTitle}},[(_vm.isExperienceEditorRunning || _vm.insurancePolicy)?_c('renewal-offer-form-step-1',{ref:"step1-child",attrs:{"formTitle":_vm.params.formTitle,"fields":_vm.fields.childItems[0].fields,"step":_vm.steps[0]},on:{"go-jeopardy":_vm.showJeopardy,"go-next":function($event){return _vm.moveToStep('step2')},"visibility-requested":function($event){return _vm.moveToStep('step1')}}}):_vm._e()],1),_c('racq-form-step',{ref:"step2",attrs:{"stepNumber":_vm.fields.childItems[1].fields.stepNumber,"stepTitle":_vm.fields.childItems[1].fields.stepTitle}},[(_vm.isExperienceEditorRunning || _vm.insurancePolicy)?_c('renewal-offer-form-step-2',{ref:"step2-child",attrs:{"fields":_vm.fields.childItems[1].fields,"step":_vm.steps[1]},on:{"go-jeopardy":function($event){return _vm.moveToStep('stepjeopardy')},"go-back":function($event){return _vm.moveToStep('step1')},"go-next":function($event){return _vm.moveToStep('step3')}}}):_vm._e()],1),_c('racq-form-step',{ref:"step3",attrs:{"stepNumber":_vm.fields.childItems[2].fields.stepNumber,"stepTitle":_vm.fields.childItems[2].fields.stepTitle}},[(_vm.isExperienceEditorRunning || _vm.insurancePolicy)?_c('renewal-offer-form-step-3',{ref:"step3-child",attrs:{"fields":_vm.fields.childItems[2].fields,"step":_vm.steps[2]},on:{"go-jeopardy":function($event){return _vm.moveToStep('stepjeopardy')},"go-back":function($event){return _vm.moveToStep('step1')}}}):_vm._e()],1),_c('racq-form-step',{ref:"stepjeopardy",attrs:{"stepTitle":_vm.fields.jeopardyFolder.childItems[0].fields.renewalFormJeopardyTitle}},[_c('placeholder',{ref:"jeopardy-child",attrs:{"step":_vm.steps[3],"name":"jeopardy","rendering":_vm.rendering,"params":{
          displayCode: _vm.displayCode,
          jpCode: _vm.jpcode,
          formName: _vm.formName,
          additionalComments: _vm.additionalComments,
          stepDetails: _vm.stepDetails,
          callUsVisible: true,
          callbackVisible: false,
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }