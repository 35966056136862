<template>
  <div
    :class="`formulate-input-element d-flex items-center gap-2 ${context.class ? `formulate-input-element--${context.class}` : ''}`"
    :data-type="context.type"
  >
    <ul v-if="context.options.length" class="d-flex no-padding">
      <li v-for="(option, index) in context.options" :key="option.value" @mouseenter="selectedIndex = index" :id="option.id" class="racq-formulate-button-list">
        <button
          type="button"
          v-text="option.label"
          @click="selectItem(option)"
          :class="['racq-formulate-button', { 'is-selected': isSelected(option) }]"
          :style="{
            border: '2px solid #003478',
            borderRight: option.id.includes('last-child') ? '2px solid #003478' : '0',
            borderBottomRightRadius: option.id.includes('last-child') ? '4px' : '0',
            borderTopRightRadius: option.id.includes('last-child') ? '4px' : '0',
            borderBottomLeftRadius: index === 0 ? '4px' : '0',
            borderTopLeftRadius: index === 0 ? '4px' : '0',
          }"
        />
      </li>
    </ul>

    <div v-if="context.attributes.tooltip && tooltipContent" style="position: relative; bottom: 0.5rem">
      <RacqFormTooltip :tooltip-id="context.attributes.tooltip.tooltipId">
        <template #tooltip-trigger>
          <button :class="[triggerIcon]" :id="context.attributes.tooltip.tooltipId" type="button" name="tooltip-trigger" />
        </template>
        <template #tooltip-content>
          <p v-if="tooltipTitle"><strong v-text="tooltipTitle" /></p>
          <div v-html="tooltipContent" v-if="tooltipContent"></div>
        </template>
      </RacqFormTooltip>
    </div>
  </div>
</template>

<script>
import RacqFormTooltip from '@/components/foundation/forms/controls/RacqFormTooltip.vue';

export default {
  components: {
    RacqFormTooltip,
  },

  mixins: [RacqFormTooltip],

  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  methods: {
    isSelected(option) {
      return this.context.model === option.value;
    },

    selectItem(option) {
      this.context.model = option.value;
    },
  },
  computed: {
    tooltipContent() {
      const { tooltip } = this.context.attributes;

      if (tooltip && tooltip.content) {
        return tooltip.content;
      }

      return '';
    },

    tooltipTitle() {
      const { tooltip } = this.context.attributes;

      if (tooltip && tooltip.title) {
        return tooltip.title;
      }

      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
.formulate-input-element {
  margin-left: 67px;
}

.racq-formulate-button {
  background-color: #ffffff;
  outline: none !important;
  color: $racq-navy;
  font-weight: 700;
  height: 41px;
  line-height: 39px;
  margin: 0;
  padding: 0px 1rem;
  text-align: center;
  cursor: pointer;

  &.is-selected {
    background: $racq-navy;
    color: #ffffff;
  }
}
</style>
