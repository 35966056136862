import { NEW_MOTOR_INSURANCE_MODULE } from '@/components/feature/newmotorinsuranceform/services/mappings';
import { mapMutations, mapActions } from 'vuex';
import { processPaymentResponse } from '../../shared/apiservices/apiPolicyService';

export default {
  data() {
    return {
      fatZebraPaymentResponse: null,
    };
  },

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, ['GO_CONFIRMATION_PAGE', 'SET_PAYMENT_RECEIPT_NUMBER', 'UPDATE_JEOPARDY_ACTIVE']),
    ...mapActions(NEW_MOTOR_INSURANCE_MODULE, ['handleApiError']),

    async messageListener(e) {
      // I'm not sure if we need this code? - 22.11.2023
      // if (!window.paymentHost.startsWith(e.origin)) {
      //   throw new Error(`Incorrect payment host configuration. Payment host: ${window?.paymentHost}. Origin: ${e.origin}`);
      // }

      // Older browsers will have a query-string style data payload
      // Whereas newer browsers will have an object
      let payload = e.data;

      if (typeof e.data == 'string') {
        if (/\[object/i.test(e.data)) {
          alert('Sorry, it looks like there has been a problem communicating with your browser...'); // Raised if the serialization failed
        }

        // Deserialize into an object
        let pairs = payload.split('&');
        payload = {};

        for (let i = 0; i < pairs.length; i++) {
          let element = pairs[i];
          let kv = element.split('=');
          payload[kv[0]] = kv[1];
        }
      }

      switch (e.data.message) {
        case 'transaction.complete': {
          // Handle the transaction complete message.
          // Payload will be in e.data.data (eg. payload.data.r == 1)
          if (payload.data !== null && payload.data.r == 1 && payload.data?.token !== null) {
            this.showIFrame = false;

            try {
              const paymentResponse = await processPaymentResponse({
                quoteSessionId: this.$route.query.quoteSessionId,
                policyNumber: this.policyNumber,
                cardToken: payload.data?.token,
                amount: this.amount,
              });

              this.fatZebraPaymentResponse = paymentResponse;

              if (paymentResponse?.result && paymentResponse?.receiptNumber) {
                // check payment response status and reload iframe so user can try again.
                if (paymentResponse.result.toUpperCase() === 'DECLINED') {
                  this.fatZebraIframeKey += 1;

                  return false;
                }

                // if success store receipt number
                this.SET_PAYMENT_RECEIPT_NUMBER(paymentResponse.receiptNumber);
                // then go to confirmation page
                this.GO_CONFIRMATION_PAGE();

                return true;
              }
            } catch (error) {
              this.handleApiError(error);
              this.fatZebraIframeKey += 1;
              return false;
            }
          }

          return true;
        }

        case 'transaction.processing': {
          // Handle the processing of the transaction - implementation optional.
          return true;
        }

        case 'transaction.cancelled': {
          // Handle the transaction being cancelled (i.e. show message, re-display the window etc).
          return true;
        }
      }
    },
  },
};
