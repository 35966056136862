<template>
  <div>
    <sc-rich-text :field="fields.stepContent" />
    <sc-rich-text :field="fields.introduction" />
    <sc-rich-text :field="modifiedRenewalText" v-if="isInRenewal" />
    <sc-rich-text :field="fields.conditionalMidTermText" v-else />
    <sc-rich-text :field="fields.learnMore" />
    <product-tile :productIcon="fields.houseIcon" :productTitle="insuranceStreetAddress" :productBody="insuranceSuburbState" />

    <div class="form-elements-container">
      <div class="form-buttons">
        <FormulateInput type="button" :name="fields.previousButtonText.value" input-class="btn btn-secondary" outer-class="" @click="onCancel" />

        <FormulateInput type="submit" :name="fields.nextButtonText.value" input-class="btn btn-primary" outer-class="" @click="onSubmit" />
      </div>
    </div>
  </div>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormsMixins from '../../../foundation/forms/mixins/RACQFormsMixins';
import ProductTile from '../../../foundation/forms/ProductTile.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Step1CycloneInsurance',
  components: {
    ScRichText: RichText,
    ProductTile,
  },
  computed: {
    ...mapState({
      insuranceProduct: (state) => state.cycloneInsuranceModule.insuranceProduct,
    }),
    ...mapGetters({
      insuranceStreetAddress: 'cycloneInsuranceModule/insuranceStreetAddress',
      insuranceSuburbState: 'cycloneInsuranceModule/insuranceSuburbState',
    }),
    modifiedRenewalText() {
      const renewalText = this.fields.conditionalRenewalText;
      renewalText.value = this.fields.conditionalRenewalText.value.replace(
        '${renewalDate}',
        new Date(this.insuranceProduct?.insurancePolicy?.termEnd).toLocaleDateString('en-AU')
      );
      return renewalText;
    },
    isInRenewal() {
      return this.isExperienceEditorRunning ? true : this.insuranceProduct?.insurancePolicy?.state?.isInRenewal;
    },
    renewalText() {
      return this.fields.conditionalRenewalText.value;
    },
  },
  methods: {
    async onSubmit() {
      this.$emit('go-next');
    },
    onCancel() {
      window.history.back();
    },
  },
  mixins: [RACQFormsMixins],
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
