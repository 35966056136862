<template>
  <MotorInsuranceRacqForm data-tooltip-wrapper>
    <MotorInsuranceQuoteStepTracker :steps="steps" :form-title="rendering.formTitle" />

    <div v-if="steps && steps.length" class="stacked-steps">
      <MotorInsuranceQuoteStep v-for="(step, index) in steps" :key="step.stepTitle" :step="step" :step-visibility="setStepVisibility(index)">
        <component
          v-if="step.stepState"
          :is="step.component"
          :step="stepData(step, index)"
          :on-submit="(formStepValues) => submitForm(formStepValues, { step, stepIndex: index })"
          :on-go-back="index === 0 ? onGoBack : goPrevStep"
          :prefill-data="prefillData"
          :form-errors="formErrors"
        />
      </MotorInsuranceQuoteStep>

      <MotorInsuranceQuoteStep v-show="jeopardyActive" :step="jeopardyStep" step-visibility="visible" :show-step-no="false">
        <JeopardyV2 class="jeopardy-active" :step-data="currentActiveStep" :jeopardy-data="jeopardyData" :on-cancel="cancelJeopardyForm" />
      </MotorInsuranceQuoteStep>
    </div>
  </MotorInsuranceRacqForm>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import MotorInsuranceRacqForm from '@/components/feature/newmotorinsuranceform/shared/MotorInsuranceRacqForm.vue';
import MotorInsuranceQuoteStepTracker from '@/components/feature/newmotorinsuranceform/shared/MotorInsuranceQuoteStepTracker.vue';
import MotorInsuranceQuoteStep from '@/components/feature/newmotorinsuranceform/shared/MotorInsuranceQuoteStep.vue';
import GenericStep from '@/components/feature/newmotorinsuranceform/shared/GenericStep.vue';
import JeopardyV2 from '@/components/foundation/forms/JeopardyV2.vue';
import FindYourCar from '@/components/feature/newmotorinsuranceform/steps/quote/find-your-car/FindYourCar.vue';
import CarDetails from '@/components/feature/newmotorinsuranceform/steps/quote/car-details/CarDetails.vue';
import PrimaryDriverDetails from '@/components/feature/newmotorinsuranceform/steps/quote/add-primary-driver/PrimaryDriverDetails.vue';
import AdditionalDrivers from '@/components/feature/newmotorinsuranceform/steps/quote/additional-drivers/AdditionalDrivers.vue';
import ExcessAndSumInsured from '@/components/feature/newmotorinsuranceform/steps/quote/excess-and-sum-insured/ExcessAndSumInsured';
import ReviewQuote from '@/components/feature/newmotorinsuranceform/steps/quote/review/ReviewQuote';
import PaymentForm from '@/components/feature/newmotorinsuranceform/steps/quote/payment/PaymentForm';
import ConfirmationForm from '@/components/feature/newmotorinsuranceform/steps/quote/confirmation/ConfirmationForm';

import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import scrollspyMixin from '@/components/foundation/forms/mixins/scrollspyMixin';
import { StepVisibility } from '@/models/foundation/forms/enums';
import { StepStates } from '@/models/foundation/forms/enums/stepStates';
import { scrollToElement } from '@/components/foundation/shared/utils/dom-helpers';
import ApiStatus from '@/models/foundation/forms/enums/apiStatus';
import { NEW_MOTOR_INSURANCE_MODULE } from '@/components/feature/newmotorinsuranceform/services/mappings';
import { applyBusinessRules, processPaymentResponse } from '@/components/foundation/shared/apiservices/apiPolicyService';
import { loginApiService } from '@/components/foundation/shared/apiservices/loginApiService';
import { JP_LOGGED_OUT_CODE } from '@/components/foundation/shared/utils/constants';

export default {
  name: 'StepsContainer',

  components: {
    MotorInsuranceQuoteStepTracker,
    MotorInsuranceQuoteStep,
    MotorInsuranceRacqForm,
    PrimaryDriverDetails,
    ExcessAndSumInsured,
    AdditionalDrivers,
    ConfirmationForm,
    ReviewQuote,
    GenericStep,
    FindYourCar,
    PaymentForm,
    CarDetails,
    JeopardyV2,
  },

  mixins: [RACQFormsMixins, scrollspyMixin],

  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: [Array, Object],
      required: true,
    },
    onGoBack: {
      type: Function,
      required: false,
      default: () => {},
    },
    jeopardyData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      insuranceProduct: true,
      businessRulesResponse: null,
      formErrors: null,
      selfServiceLoginUrl: '',
    };
  },

  async mounted() {
    this.selfServiceLoginUrl = await loginApiService.getLoginUrl(window.location.href);
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, [
      'steps',
      'stepNumber',
      'jeopardyStep',
      'viewModelData',
      'jeopardyActive',
      'selectedPolicy',
      'allInsurancePolicies',
      'sitecoreContent',
      'activeJeopardyCode',
    ]),
    ...mapGetters(NEW_MOTOR_INSURANCE_MODULE, ['currentActiveStep', 'foundVehicle', 'prefillData']),

    stepData() {
      return (step, index) => ({
        ...step,
        stepIndex: index,
        content: this.sitecoreContent[step.id],
        ...this.params,
      });
    },

    isAdditionalDriversCompleted() {
      const additionalDriverStep = this.steps.find((step) => step.id === 'additionalDrivers');
      return additionalDriverStep.stepCompleted;
    },
  },

  methods: {
    ...mapActions(NEW_MOTOR_INSURANCE_MODULE, ['formatFormDataToApplyBusinessRules', 'handleApiError']),
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, [
      'GO_NEXT_STEP',
      'GO_PREV_STEP',
      'SET_ACTIVE_STEP',
      'UPDATE_STEP_STATUS',
      'UPDATE_JEOPARDY_ACTIVE',
      'UPDATE_VIEW_MODEL_DATA',
      'UPDATE_PRELIMINARY_STEP',
      'UPDATE_BUSINESS_RULES_RESPONSE',
      'UPDATE_PRIMARY_DRIVER_FORM_STATE',
      'UPDATE_FOUND_CAR_MANUALLY',
      'GO_CONFIRMATION_PAGE',
    ]),

    /**
     * Upon form step submission, the business rules engine is used to validate the form values.
     * Any field specific validations are passed back into the form step component and shown.
     * If jeopardy is triggered then the Jeopardy component is shown.
     *
     * @param {*} formStepValues
     * @param {*} stepData
     */
    async submitForm(formStepValues, { step, stepIndex }) {
      this.UPDATE_STEP_STATUS({ stepIndex, status: ApiStatus.LOADING });

      const isAuthenticated = await loginApiService.isAuthenticated();

      if (!isAuthenticated) {
        return (window.location.href = this.selfServiceLoginUrl);
      }

      if (formStepValues?.metadata) {
        this.UPDATE_FOUND_CAR_MANUALLY(formStepValues.metadata?.foundVehicleManually);

        if (formStepValues.metadata?.processPaymentResponse) {
          try {
            const paymentResponse = await processPaymentResponse(formStepValues?.metadata.processPaymentPayload);

            if (paymentResponse?.receiptNumber === null && paymentResponse?.result === null) {
              return this.GO_CONFIRMATION_PAGE(); // no receipt and response/result when direct debit
            }

            return this.GO_NEXT_STEP(step);
          } catch (error) {
            console.error({ error });

            this.handleApiError(error);
          }
        }
      }

      const payload = await this.formatFormDataToApplyBusinessRules({
        formData: { ...this.prefillData, ...formStepValues },
        metadata: {
          currentPolicy: this.selectedPolicy,
          stepId: step.id,
          quoteSessionId: this.$route.query.quoteSessionId.toLowerCase(),
          requestQuote: this.isAdditionalDriversCompleted || step.id === 'additionalDrivers',
        },
      });

      return applyBusinessRules(payload)
        .then((res) => {
          this.UPDATE_STEP_STATUS({ stepIndex, status: ApiStatus.LOADING });
          this.businessRulesResponse = res;

          if (res?.success && res?.data) {
            this.UPDATE_VIEW_MODEL_DATA(res.data?.viewModel?.responseModel);
            this.UPDATE_BUSINESS_RULES_RESPONSE(res.data);
            this.UPDATE_JEOPARDY_ACTIVE({ jeopardyActive: false });

            if (formStepValues?.metadata?.goNextStep === false) {
              this.SET_ACTIVE_STEP({ stepIndex: this.stepNumber - 1 });
            } else {
              this.GO_NEXT_STEP();
            }
          } else if (res?.data?.jeopardies) {
            this.UPDATE_JEOPARDY_ACTIVE({
              jeopardyActive: true,
              activeJeopardyCode: res.data.jeopardies[0].code,
            });
          }
        })
        .catch((error) => {
          if (!isAuthenticated) {
            return (window.location.href = this.selfServiceLoginUrl);
          }

          this.handleApiError(error);
        })
        .finally(() => {
          this.UPDATE_STEP_STATUS({ stepIndex, status: ApiStatus.IDLE });
        });
    },

    goPrevStep() {
      this.GO_PREV_STEP();
      this.addDataToAnalytics(this.steps[this.stepNumber - 1]);
      scrollToElement(`#${this.steps[this.stepNumber - 1].id}`);
    },

    setStepVisibility(stepIndex) {
      if ([StepStates.ACTIVE, StepStates.ACTIVE_JEOPARDY, StepStates.ACTIVE_COMPLETED].includes(this.steps[stepIndex].stepState)) {
        return StepVisibility.VISIBLE;
      }

      if ([StepStates.COMPLETED, StepStates.DISABLED, StepStates.DISABLED_UNEDITABLE].includes(this.steps[stepIndex].stepState)) {
        return StepVisibility.SEEN;
      }

      return StepVisibility.NOTSEEN;
    },

    cancelJeopardyForm() {
      if (this.activeJeopardyCode === JP_LOGGED_OUT_CODE) {
        return (window.location.href = this.selfServiceLoginUrl);
      }

      this.UPDATE_JEOPARDY_ACTIVE({ jeopardyActive: false });
      this.SET_ACTIVE_STEP({ stepIndex: this.stepNumber - 1 });
      scrollToElement(`#${this.steps[this.stepNumber - 1].id}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.stacked-steps {
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
}
</style>
