<template>
  <vue-final-modal
    :name="modalName"
    v-model="showModal"
    :ssr="false"
    classes="vue-modal-container"
    content-class="vue-modal-content"
    :esc-to-close="true"
    :focus-trap="true"
  >
    <div class="modal-container">
      <div>
        <slot name="modal-header">
          <div class="modal-header">
            <h2 class="modal-header-text" v-text="modalHeaderText" />
          </div>
        </slot>

        <slot name="modal-content">
          <div class="modal-content">
            <p v-html="modalBodyText"></p>
          </div>
        </slot>
      </div>

      <slot name="modal-footer">
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="secondaryBtnAction" v-text="secondaryBtnText" />
          <button type="button" class="btn btn-primary" @click="primaryBtnAction" v-text="primaryBtnText" />
        </div>
      </slot>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  props: {
    modalName: {
      type: String,
      required: true,
    },
    modalHeaderText: {
      type: String,
      default: 'Cancel changes',
    },
    modalBodyText: {
      type: String,
      default: 'Are you sure you want to discard all changes and leave this page?',
    },
    secondaryBtnText: {
      type: String,
      default: 'Discard changes',
    },
    primaryBtnText: {
      type: String,
      default: 'Continue',
    },
    primaryBtnAction: {
      type: Function,
      default: () => {},
    },
    secondaryBtnAction: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      showModal: false,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .vue-modal-content {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  max-width: 400px;
}

.modal-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.modal-header {
  border: none;
  padding: 0 0 0.5rem;
  .modal-header-text {
    font-size: 1.5rem;
  }
}

.modal-content {
  border: none;
  padding: 0;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #f1f1f1;
  padding: 1rem 0 0;
}
</style>
