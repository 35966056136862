<template>
  <div :id="formInputId" class="pb-4">
    <h5 class="repeatableOccurrenceInputGroup-heading">Please provide details about this driver's licence cancellations, suspensions or special conditions</h5>

    <div v-for="(item, index) in count" :key="`${item}__${index}`">
      <div>
        <label class="font-bold">Occurrence {{ item }}</label>
      </div>
      <div class="form-fields-flex-wrapper gap-4">
        <FormulateInput
          type="selectbox"
          :name="`month__occurrence-${index}`"
          :options="months"
          placeholder="Month"
          validation="required"
          error-behaviour="live"
          :validation-messages="{
            required: validationMessages.monthRequired,
          }"
        />
        <FormulateInput
          type="selectbox"
          :name="`year__occurrence-${index}`"
          :options="years"
          placeholder="Year"
          validation="required"
          error-behaviour="live"
          :validation-messages="{
            required: validationMessages.yearRequired,
          }"
        />
        <FormulateInput
          type="selectbox"
          :name="`lossType__occurrence-${index}`"
          :options="lossTypes"
          placeholder="Loss type"
          validation="required"
          error-behaviour="live"
          :validation-messages="{
            required: validationMessages.typeRequired,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { validationMessages } from '@/components/feature/newmotorinsuranceform/services/mappings';

export default {
  name: 'RepeatableOccurrenceInputGroup',

  props: {
    options: {
      type: Array,
      default: () => [{ label: 'hello', value: 'hello' }],
    },
    groupInputFieldName: {
      type: String,
      default: 'addClaims',
    },
    addLabel: {
      type: String,
      default: '+ Add claim',
    },
    addMoreButtonText: {
      type: String,

      default: 'Add claim',
    },
    formInputId: {
      type: String,
      default: 'repeatableOccurrenceCount',
    },
    count: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      validationMessages,
      months: [{ value: 'January', label: 'January' }],
      years: [{ value: 2023, label: '2023' }],
      lossTypes: [{ value: 'Damage', label: 'Damage' }],
      lossValues: [{ value: 50000, label: '$50,000' }],
    };
  },
};
</script>

<style lang="scss">
#repeatableOccurrenceCount {
  margin-left: 0;

  @media screen and (min-width: $breakpoint-lg) {
    // margin-left: 15.5rem;
  }

  .formulate-input-errors {
    margin-left: 0;
    padding: 0;
  }

  .forms-input-wrapper {
    width: 150px !important;
    margin-bottom: 0.5rem;

    .formulate-input-wrapper {
      margin-top: 0;
      width: 160px !important;

      .repeatableOccurrenceInputGroup-heading {
        margin-bottom: 1rem;
      }

      .formulate-input-element {
        width: 150px !important;
      }
    }
  }

  .formulate-input-group {
    margin-left: 0;
  }

  .formulate-input-element--select {
    width: 150px !important;
  }

  .formulate-input-element--selectbox {
    margin-left: 0;
  }

  [data-is-repeatable] {
    flex-direction: column;
    margin-left: 0;
    align-items: flex-start;

    .formulate-input-group-repeatable {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      padding-left: 0;
    }

    .forms-input-wrapper {
      min-width: min-content !important;
      margin-bottom: 0.25rem;

      .formulate-input-wrapper {
        margin-top: 0;
      }
    }
  }
}
</style>
