<template>
  <div class="new-motor-insurance-multi-step-form">
    <div class="container form-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RacqForm',
  components: {},
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss">
.new-motor-insurance-multi-step-form {
  background-color: $racq-grey-background;
  height: 100%;
  min-height: 100vh;

  // if we want to center align forms, uncomment below
  // display: flex;

  .form-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    @include respond-to(all-mobile) {
      flex-direction: column;
    }
  }

  .form-step-container {
    display: flex;
    flex-direction: column;

    .call-back-form-container {
      margin-top: 50px;
    }

    .form-step {
      border: 2px solid $racq-navy;
      margin: 40px 0 20px 0;
      background: $racq-true-white;
      max-width: 789px;
      width: 100%;
      height: 100%;

      &.center-aligned {
        @include respond-to(desktop) {
          margin-left: 165px;
        }
      }

      .form-step-curtain {
        background: $curtain-color;
        width: 780px !important;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 20;

        // display: flex;
        // justify-content: center;
        // align-items: center;
      }

      @include respond-to(all-mobile) {
        margin: 20px 0 20px 0px;
      }

      .form-step-content {
        padding: 10px 30px 6px;
        position: relative;

        .bank-container {
          p {
            font-style: italic;
          }
        }
        .tile-container {
          display: flex;

          @include respond-to(all-mobile) {
            flex-direction: column;
          }

          .content-container {
            margin-left: 40px;

            @include respond-to(all-mobile) {
              margin-left: 0;
              margin-top: 10px;
            }
          }
        }

        .forms-review-content {
          display: flex;
          margin-top: 25px;
          align-items: center;

          img {
            width: 50;
            height: 50;
          }

          &.form-review-content-border {
            border: 1px solid $racq-navy;
            margin: 20px 0;
            padding: 16px 30px 16px;

            &.space-around-content {
              justify-content: space-around;
              flex-wrap: wrap;

              :first-child {
                flex-grow: 1;

                &.half-width-content {
                  max-width: 360px;
                }
              }

              .top-left-icon {
                float: left;
                margin: 10px;
              }

              .secondary-text-color {
                color: $racq-navy;
              }
            }

            &.secondary-notice-box {
              background-color: $racq-pale-blue;
              border: 1px solid $racq-navy-30p;
              border-radius: 3px;
              color: $racq-navy;
            }
          }
          .review-title {
            margin-left: 25px;

            h5 {
              margin-bottom: 0px;
            }
          }
        }

        .step-content {
          margin-top: 0;

          a {
            color: $racq-navy;

            &:focus {
              outline: none;
              border-bottom: 2px dotted;
            }
          }

          .forms-input-wrapper[data-is-showing-errors='true'] {
            [data-type='datepicker'] input,
            input[type='text'] {
              background-color: $formfield-error-background;
              border: 1px solid #000;
            }
          }
        }

        .form-elements-container {
          margin: 10px 0;

          .bank-details {
            margin-top: 30px;
          }

          &.confirmation-screen {
            border-top: none;
            padding-top: 12px;
          }

          .forms-light-panel {
            background: $racq-white-background;
            border-radius: 5px;
            margin: 25px 0;
            padding: 20px 30px 6px;

            .form-confirmation-header {
              display: flex;
              align-items: center;

              h3 {
                font-size: 21px;
                margin: 0;
                margin-left: 15px;
              }
            }

            .product-details-container {
              display: flex;

              .product-details-content {
                margin-left: 26px;
              }
            }

            .forms-confirmation-container {
              margin-top: 20px;

              ul {
                padding: 0;
                margin-left: 21px;
                list-style: disc;

                li::marker {
                  color: $racq-navy;
                }
              }
              .text-wrapper {
                font-weight: bold;
                font-size: 14px;
              }
            }

            .review-table-content {
              margin-top: 30px;

              .header-content-title {
                margin-top: 10px;
              }

              .review-table-row {
                .review-table-column {
                  display: flex;
                  align-items: normal;
                  justify-content: space-between;
                  width: 100%;
                  border-bottom: 1px solid #d5d5d5;
                  padding-bottom: 8px;
                  padding-top: 8px;

                  .review-table-label,
                  .review-table-value {
                    color: $racq-navy;
                    font-weight: 400;
                    width: 49%;
                    text-align: right;
                  }

                  .review-table-label {
                    color: $dark-grey-text;
                  }

                  .review-table-value {
                    text-align: left;
                    margin-left: 15px;
                  }

                  .review-edit {
                    @include respond-to(all-mobile) {
                      display: none;
                    }
                  }
                }
              }

              .review-table-row:last-child {
                margin-bottom: 35px;
              }
            }
          }

          // jeopardy screen designs
          .form-jeopardy-wrapper {
            display: flex;

            @include respond-to(all-mobile) {
              flex-direction: column;
            }

            .chat-container,
            .phone-container {
              border: 1px solid $light-grey;
              padding: 45px 20px 6px;
              width: 100%;
              text-align: center;

              &.chat--unavailable {
                display: none;
              }

              h3 {
                font-size: 24px;
              }

              .chat-text-content,
              .phone-text-content {
                margin: 14px 0;
                font-size: 18px;
                font-weight: 400;
                line-height: 1.7777777778;
              }

              .text-wrapper {
                margin: 14px 0;
              }

              .phone-text {
                font-size: 32px;
              }
            }
          }

          .change-cta-container {
            padding: 20px;
            background: $racq-white-background 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 3px $racq-black-border;
            border-radius: 3px;
            padding: 30px 20px 30px 20px;

            .cta-container {
              display: flex;
              @include respond-to(all-mobile) {
                flex-direction: column;
              }

              .text-container {
                p {
                  margin-bottom: 0px;
                  margin-left: 30px;

                  @include respond-to(all-mobile) {
                    margin-left: 0px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                  }
                }
              }
            }

            .btn-container {
              margin-left: 10px;

              @include respond-to(all-mobile) {
                margin-left: 0px;
              }

              button {
                font-size: 17px;
                width: 158px;
              }
            }
          }
        }
      }
    }
  }

  .step-title {
    display: flex;
    flex-direction: row;
    background-color: $racq-navy;
    padding: 20px 60px 6px 30px;
    margin: -1px;

    &.no--padding {
      padding-left: 0;
    }

    .text-white {
      font-size: 1.25rem;
      line-height: 1.2;
    }

    .step-title-with-icon-container {
      color: $white;
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 1.2;
      position: relative;

      .step-icon {
        background-position: 50% 50%;
        color: $racq-navy;
        display: inline-block;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-size: 1rem;
        font-weight: 700;
        height: 30px;
        left: 3px;
        line-height: 30px;
        margin-left: -7px;
        margin-top: -14px;
        position: absolute;
        text-align: center;
        top: 50%;
        width: 30px;
        background-image: url(~@/assets/img/forms/form-step-ico-active.colors-white.svg);
        background-repeat: no-repeat;
        text-indent: initial;
        font-family: $firasans-regular-font;
      }

      .step-icon-white {
        background-image: url(~@/assets/img/forms/form-step-ico-complete-active-white.svg);
      }
    }
  }
  .step-side-icon {
    border-color: $racq-navy transparent transparent;
    border-style: solid;
    border-width: 15px 15px 0;
    display: block;
    height: 0;
    width: 0;
    margin-left: 25px;
  }
}
</style>
