import { productApiService } from './../../shared/apiservices/apiProductService';
const cancelRoadsideModule = {
  namespaced: true,
  state: () => ({
    step1Data: [],
    assistanceProduct: {},
  }),
  mutations: {
    clearStepData(state) {
      state.step1Data = [];
    },
    addStepData(state, stepData) {
      state.step1Data.push(stepData);
    },
    addAssistanceProductData(state, assistanceProduct) {
      state.assistanceProduct = assistanceProduct;
    },
  },
  actions: {
    addStepDataToStore({ commit }, data) {
      if (data) {
        commit('addStepData', data);
      }
    },
    clearStepData({ commit }) {
      commit('clearStepData');
    },
    async addRoadsideProductData({ commit }, policyId) {
      const assistanceProduct = await productApiService.getAssistanceProduct(policyId);
      commit('addAssistanceProductData', assistanceProduct);
    },
  },
};
export default cancelRoadsideModule;
