<template>
  <FormulateForm id="car-details-form" v-model="formValues" @submit="submitFormStep" @failed-validation="onFailedValidation" :errors="formErrors">
    <div class="step-content pt-4">
      <FormulateInput
        v-if="foundCarManually"
        v-bind="formInputData(motorPolicyModel.isVehicleRegistered)"
        validation="required"
        :validation-messages="{
          required: validationMessages.defaultRequired,
        }"
        :options="[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No', id: motorPolicyModel.isVehicleRegistered + '_last-child' },
        ]"
      />

      <template v-if="formValues[motorPolicyModel.isVehicleRegistered] === 'true'">
        <FormulateInput
          v-bind="formInputData(motorPolicyModel.vehicleRegistration)"
          validation="required"
          :validation-messages="{
            required: validationMessages.defaultRequired,
          }"
        />
      </template>

      <FormulateInput
        v-bind="formInputData(motorPolicyModel.vehicleUseType)"
        validation="required"
        :validation-messages="{
          required: validationMessages.defaultRequired,
        }"
        :options="vehicleUseTypeOptions"
      />

      <hr class="mb-4" />

      <!-- Read-only if prefilling business type from quote -->
      <FormulateInput
        v-if="formValues[motorPolicyModel.vehicleUseType] === vehicleUseType.Business && prefillData[motorPolicyModel.businessUseType]"
        v-bind="formInputData(motorPolicyModel.businessUseType)"
        :value="prefillData[motorPolicyModel.businessUseType]"
        type="text"
        disabled
      />

      <FormulateInput
        v-if="formValues[motorPolicyModel.vehicleUseType] === vehicleUseType.Business && prefillData[motorPolicyModel.businessUseType]"
        v-bind="formInputData(motorPolicyModel.businessInputTaxCredit, true, '%')"
        validation="required|number"
        :validation-messages="{
          required: validationMessages.businessInputTaxCreditRequired,
          number: validationMessages.businessInputTaxCreditNumber,
        }"
      />

      <FormulateInput
        v-bind="formInputData(motorPolicyModel.overnightParkingLocationCode)"
        :class="motorPolicyModel.overnightParkingLocationCode"
        :options="parkingLocations"
        :validation-messages="{
          required: validationMessages.defaultRequired,
        }"
        validation="required"
      />

      <FormulateInput
        v-bind="formInputData(motorPolicyModel.garagingLocation)"
        @change="fetchSuburbsList"
        :options="suburbListData"
        :validation-messages="{
          required: validationMessages.defaultRequired,
        }"
        validation="required"
      />

      <hr class="mb-6 mt-0" />

      <FormulateInput
        v-bind="formInputData(motorPolicyModel.hasFinance)"
        :options="[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No', id: `${motorPolicyModel.hasFinance}_last-child` },
        ]"
        :validation-messages="{
          required: validationMessages.defaultRequired,
        }"
        validation="required"
      />

      <FormulateInput
        v-if="formValues[motorPolicyModel.hasFinance] === 'true'"
        v-bind="formInputData(motorPolicyModel.financeCode)"
        type="selectbox"
        :options="financeTypes"
        :validation-messages="{
          required: validationMessages.defaultRequired,
        }"
        validation="required"
      />

      <FormulateInput
        v-if="
          formValues[motorPolicyModel.hasFinance] === 'true' &&
          (formValues[motorPolicyModel.financeCode] === 'LEASE' || formValues[motorPolicyModel.financeCode] === 'SECURED')
        "
        v-bind="formInputData(motorPolicyModel.financeProvider)"
        :validation-messages="{
          required: validationMessages.defaultRequired,
        }"
        validation="required"
      />

      <!-- V - IF policy is comprehensive OR Motor 3rd party fire and theft-->
      <FormulateInput
        v-if="showAccessoriesFields(prefillData.coverageCode)"
        v-bind="formInputData(motorPolicyModel.hasAftermarketAccessories)"
        :validation-messages="{ required: validationMessages.defaultRequired }"
        validation="required"
        :options="[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No', id: `${motorPolicyModel.hasAftermarketAccessories}_last-child` },
        ]"
      />

      <div>
        <div class="custom-info custom-tag-icon" v-if="hasAlarmOrImmobiliser(formValues.vehicleAccessories)">
          <img src="@/assets/img/icons/tag-icon.svg" alt="An icon of a tag" /> Congratulations, you qualify for an alarm discount!
        </div>

        <div v-if="formValues[motorPolicyModel.hasAftermarketAccessories] === 'true'">
          <p v-if="showAftermarketAlarmMessage" class="has-aftermarket-accessories-precontent">
            {{ formInputData(motorPolicyModel.hasAftermarketAccessories).preContent }}
          </p>

          <AddAccessoriesInput
            class="accessories-repeatable-field"
            name="vehicleAccessoryOption"
            :accessoriesList="formValues.vehicleAccessories"
            :validation-messages="{
              required: validationMessages.defaultRequired,
            }"
            validation="required"
            :options="accessoriesList"
          />
        </div>
      </div>

      <FormulateInput
        v-if="showVehicleModificationsField(prefillData.coverageCode)"
        v-bind="formInputData(motorPolicyModel.vehicleHasModifications)"
        :validation-messages="{
          required: validationMessages.defaultRequired,
        }"
        validation="required"
        :options="[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No', id: `${motorPolicyModel.vehicleHasModifications}_last-child` },
        ]"
      />

      <div class="form-field-with-custom-help-text">
        <strong class="label">Do any of the following statements apply to your vehicle?</strong>

        <div class="flex-col custom-help-text">
          <ul class="list-disc">
            <li>Used for the delivery of flammable goods</li>
            <li>Has over 2.5 tonnes carrying capacity</li>
          </ul>

          <FormulateInput
            :name="motorPolicyModel.vehicleUsedForUnacceptableBusinessUse"
            type="radio"
            :validation-messages="{
              required: validationMessages.defaultRequired,
            }"
            validation="required"
            :options="[
              { value: 'true', label: 'Yes' },
              { value: 'false', label: 'No', id: `${motorPolicyModel.vehicleUsedForUnacceptableBusinessUse}_last-child` },
            ]"
          />
          <br />
        </div>
      </div>

      <hr class="mt-8 mb-12" />

      <FormulateInput
        v-bind="formInputData(motorPolicyModel.unrepairedCollisionOrHailDamage)"
        :validation-messages="{
          required: validationMessages.defaultRequired,
        }"
        validation="required"
        :options="[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No', id: `${motorPolicyModel.unrepairedCollisionOrHailDamage}_last-child` },
        ]"
      />

      <br />
    </div>

    <StepFooter v-bind="$props" />
  </FormulateForm>
</template>

<script>
import AddAccessoriesInput from './AddAccessoriesInput.vue';
import StepFooter from '@/components/feature/newmotorinsuranceform/shared/StepFooter.vue';
import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import VueFormulateMixins from '@/components/foundation/forms/mixins/VueFormulateMixins';
import {
  getCombinedAccessoriesListData,
  getParkingLocationRefData,
  getFinanceTypesRefData,
  getVehicleBusinessUseTypes,
  getSuburbList,
} from '@/components/foundation/shared/apiservices/apiRefdataService';
import { NEW_MOTOR_INSURANCE_MODULE, motorPolicyModel, validationMessages, vehicleUseType } from '@/components/feature/newmotorinsuranceform/services/mappings';
import { mapMutations, mapState } from 'vuex';
import { coverageTypeCodes } from '@/components/feature/newmotorinsuranceform/services/mappings';

export default {
  name: 'CarDetails',

  components: {
    StepFooter,
    AddAccessoriesInput,
  },

  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    onGoBack: {
      type: Function,
      required: true,
    },
    prefillData: {
      type: Object,
      required: false,
    },
    formErrors: {
      type: [Array, Object],
      required: false,
    },
  },

  mixins: [RACQFormsMixins, VueFormulateMixins],

  async created() {
    // Prefill data is injected via Vuex store form data.
    // Fetching happens before the VueFormulate form mounts.

    // Below, we explicitly set the values for just the form fields in this component
    // We don't want to include all prefillData, just what we need for this form.
    this.formValues = {
      [motorPolicyModel.isVehicleRegistered]: this.prefillData[motorPolicyModel.isVehicleRegistered],
      [motorPolicyModel.vehicleRegistration]: this.prefillData[motorPolicyModel.vehicleRegistration],
      [motorPolicyModel.vehicleUseType]: this.prefillData[motorPolicyModel.vehicleUseType],
      [motorPolicyModel.businessUseType]: this.prefillData[motorPolicyModel.businessUseType],
      [motorPolicyModel.businessInputTaxCredit]: this.prefillData[motorPolicyModel.businessInputTaxCredit],
      [motorPolicyModel.overnightParkingLocationCode]: this.prefillData[motorPolicyModel.overnightParkingLocationCode],
      [motorPolicyModel.garagingLocation]: this.prefillData[motorPolicyModel.garagingLocation],
      [motorPolicyModel.hasFinance]: this.prefillData[motorPolicyModel.hasFinance],
      [motorPolicyModel.financeCode]: this.prefillData[motorPolicyModel.financeCode],
      [motorPolicyModel.financeProvider]: this.prefillData[motorPolicyModel.financeProvider],
      [motorPolicyModel.hasAftermarketAccessories]: this.prefillData[motorPolicyModel.hasAftermarketAccessories],
      [motorPolicyModel.vehicleAccessories]: this.prefillData[motorPolicyModel.vehicleAccessories],
      [motorPolicyModel.vehicleUsedForUnacceptableBusinessUse]: this.prefillData[motorPolicyModel.vehicleUsedForUnacceptableBusinessUse],
      [motorPolicyModel.unrepairedCollisionOrHailDamage]: this.prefillData[motorPolicyModel.unrepairedCollisionOrHailDamage],

      vehicleHasModifications: null,
    };

    // remove glasses data from the prefilled formValues because it's not required
    delete this.formValues.glassesGuideVehicleDetails;

    this.formValues.vehicleUseType = this.formValues.vehicleUseType ? this.formValues.vehicleUseType.toString() : vehicleUseType.Private;
    this.vehicleUseTypeOptions = Object.keys(vehicleUseType).map((label) => ({ label, value: vehicleUseType[label] }));

    await this.fetchParkingLocationsRefData();
    await this.fetchFinanceTypesRefData();
    await this.fetchCombinedAccessoriesRefData();
    await this.fetchVehicleBusinessUseTypes();
  },

  data() {
    return {
      formValues: {},
      financeTypes: null,
      parkingLocations: null,
      accessoriesList: null,
      businessUseTypesList: null,
      suburbListData: [],
      motorPolicyModel,
      validationMessages,
      vehicleUseType,
      vehicleUseTypeOptions: [],
      showAftermarketAlarmMessage: false,
    };
  },

  watch: {
    formValues(val) {
      if (!this.hasAlarmOrImmobiliser(val.vehicleAccessories) || val[motorPolicyModel.hasAftermarketAccessories] === 'false') {
        this.showAftermarketAlarmMessage = false;
      }
    },
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, ['foundCarManually']),

    showAccessoriesFields() {
      return (coverageCode) => coverageCode === coverageTypeCodes.MotorComprehensive || coverageCode === coverageTypeCodes.MotorThirdPartyFireAndTheft;
    },

    hasAlarmOrImmobiliser() {
      return (accessories) => {
        if (accessories?.length) {
          return accessories.filter((a) => a.vehicleAccessoryOption === 'A-Alarm' || a.vehicleAccessoryOption === 'A-Immobilizer').length > 0;
        }

        return false;
      };
    },

    showVehicleModificationsField() {
      return (coverageCode) => coverageCode === coverageTypeCodes.MotorThirdParty;
    },
  },

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, ['UPDATE_ACCESSORIES_REFDATA']),

    async fetchParkingLocationsRefData() {
      const parkingLocations = await getParkingLocationRefData();
      this.parkingLocations = parkingLocations.map(this.formatInputOptions);
    },

    async fetchFinanceTypesRefData() {
      const financeTypes = await getFinanceTypesRefData();
      this.financeTypes = financeTypes.map(this.formatInputOptions);
    },

    async fetchCombinedAccessoriesRefData() {
      const accessoriesList = await getCombinedAccessoriesListData();
      this.accessoriesList = accessoriesList.map(this.formatInputOptions);
      this.UPDATE_ACCESSORIES_REFDATA(this.accessoriesList);

      if (this.formValues[motorPolicyModel.hasAftermarketAccessories] === 'true') {
        this.showAftermarketAlarmMessage = true;
      }
    },

    async fetchVehicleBusinessUseTypes() {
      const businessUseTypesList = await getVehicleBusinessUseTypes();
      this.businessUseTypesList = businessUseTypesList.map(this.formatInputOptions);
    },

    async fetchSuburbsList(val) {
      if (!val || val.length < 3) return;

      const data = await getSuburbList(val);

      if (data.length) {
        this.suburbListData = data.map((d) => ({
          label: `${d.postcode}, ${d.suburb}`,
          value: `${d.postcode}, ${d.suburb}, ${d.state}`,
        }));
      }
    },

    submitFormStep(formValues) {
      formValues.vehicleAccessories = formValues.vehicleAccessories ?? [];
      this.onSubmit(formValues);
    },

    /**
     * Format API data to be used in Vue Formulate.
     * Note: we add the id for the last child when
     * dealing with select and radio input's so
     * that the tooltip suffix is placed at the
     * end of the input.
     *
     * @param {{Value: string; Name:string}} item
     * @param {number} index
     * @param {*[]} arr
     */
    formatInputOptions(item, index, arr) {
      const options = {
        value: item?.Value || item.value,
        label: item?.Name || item.name,
      };

      if (index + 1 === arr.length) {
        options.id = item.value + '_last-child';
      }

      return options;
    },
  },
};
</script>
<style lang="scss">
#car-details-form {
  .overnightParkingLocationCode .formulate-input-label--after {
    padding: 0;
    width: 100px;
  }

  .formulate-input-element--vue-select {
    margin-left: 4.5rem;
    min-width: 268px;
  }

  .formulate-input-element.formulate-input-element--selectbox {
    min-width: 245px !important;
    width: 245px;

    .dropdown-label {
      width: 245px;
    }
  }

  .custom-info {
    margin-left: 0;
    margin-top: 1rem;

    @media screen and (min-width: $breakpoint-lg) {
      margin-left: 247px;
    }
  }

  .custom-tag-icon {
    margin-top: -54px;
  }

  .has-aftermarket-accessories-precontent {
    @media screen and (min-width: $breakpoint-lg) {
      margin-left: 15.5rem;
    }
  }

  .accessories-repeatable-field {
    margin-left: 0;
    margin-bottom: 3rem;

    @media screen and (min-width: $breakpoint-lg) {
      margin-left: 15.5rem;
    }
  }

  .form-field-with-custom-help-text {
    display: flex;

    .formulate-input-errors {
      margin-left: 0;
      padding-left: 1.25rem;
    }

    .label {
      max-width: 180px;
      margin-right: 45px;
    }

    .custom-help-text {
      max-width: 445px;
    }

    .formulate-input-element.formulate-input-element--group.formulate-input-group {
      margin-left: 1.4rem;
    }
  }
}
</style>
