export default [
  {
    stepNo: 1,
    stepTitle: '',
    stepState: 'active',
    stepControlName: 'step1',
    stepChildControlName: 'step1-child',
  },
  {
    stepNo: 2,
    stepTitle: '',
    stepState: '',
    stepControlName: 'step2',
    stepChildControlName: 'step2-child',
    hideFromNavigation: true,
  },
  {
    stepNo: 3,
    stepTitle: '',
    stepState: '',
    stepControlName: 'step3',
    stepChildControlName: 'step3-child',
    hideFromNavigation: true,
  },
  {
    stepNo: 4,
    stepTitle: '',
    stepState: '',
    stepControlName: 'renewstep1',
    stepChildControlName: 'renewstep1-child',
  },
  {
    stepNo: 5,
    stepTitle: '',
    stepState: '',
    stepControlName: 'renewstep2',
    stepChildControlName: 'renewstep2-child',
    hideFromNavigation: true,
  },
  {
    stepNo: 6,
    stepTitle: 'Jeopardy',
    stepState: '',
    stepControlName: 'stepjeopardy',
    stepChildControlName: 'jeopardy-child',
    hideFromNavigation: true,
  },
];
