<template>
  <div v-if="isExperienceEditorRunning || insurancePolicy">
    <div class="form-elements-container">
      <FormulateForm @submit="onFinish">
        <div>
          <div v-if="isExperienceEditorRunning || renewalTermAccepted">
            <span v-show="this.isCancelledPolicy || isExperienceEditorRunning">
              <sc-rich-text :field="fields.cancelledPolicyAcceptThankYou" />
            </span>
            <span v-show="!this.isCancelledPolicy || isExperienceEditorRunning">
              <sc-rich-text :field="fields.acceptThankYou" />
            </span>

            <span v-show="this.isCancelledPolicy || isExperienceEditorRunning">
              <sc-rich-text :field="isExperienceEditorRunning ? fields.cancelledPolicyAcceptRenewalDate : cancelledPolicyAcceptRenewalDate" />
            </span>
            <span v-show="!this.isCancelledPolicy || isExperienceEditorRunning">
              <sc-rich-text :field="isExperienceEditorRunning ? fields.acceptRenewalDate : acceptRenewalDate" />
            </span>

            <div v-show="!this.isCancelledPolicy" class="forms-review-content form-review-content-border">
              <div v-show="autoRenewalOptIn">
                <sc-rich-text :field="fields.acceptAutoRenewalOn" />
              </div>
              <div v-show="!autoRenewalOptIn">
                <sc-rich-text :field="fields.acceptAutoRenewalOff" />
              </div>
            </div>
          </div>
          <div v-if="isExperienceEditorRunning || !renewalTermAccepted">
            <span v-show="this.isCancelledPolicy || isExperienceEditorRunning">
              <sc-rich-text :field="isExperienceEditorRunning ? fields.cancelledPolicyDeclineYourCoverWillEnd : cancelledPolicyDeclineYourCoverWillEnd" />
            </span>
            <span v-show="!this.isCancelledPolicy || isExperienceEditorRunning">
              <sc-rich-text :field="isExperienceEditorRunning ? fields.declineYourCoverWillEnd : declineYourCoverWillEnd" />
            </span>
            <span v-show="this.isCancelledPolicy || isExperienceEditorRunning">
              <sc-rich-text :field="fields.cancelledPolicyDeclineYourCanChangeYourMind" />
            </span>
            <span v-show="!this.isCancelledPolicy || isExperienceEditorRunning">
              <sc-rich-text :field="fields.declineYourCanChangeYourMind" />
            </span>
            <div v-show="!this.isCancelledPolicy || isExperienceEditorRunning">
              <hr />
              <sc-rich-text :field="fields.declineReasonProvided" />
            </div>
          </div>
        </div>
        <div class="form-buttons flow-reverse" v-show="showButtons">
          <FormulateInput type="submit" name="Finish" v-show="!hideButtons" input-class="btn btn-primary" outer-class="" @click="cancelForm" />
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { scPlaceholders } from '../../renewalofferform/steps/shared/sitecorePlaceholders';
import { mapState } from 'vuex';
import RACQFormsMixin from '../../../foundation/forms/mixins/RACQFormsMixins';

export default {
  name: 'RenewalOfferFormStep3',
  components: {
    ScRichText: RichText,
  },
  data: function () {
    return {
      showButtons: true,
      acceptRenewalDate: {},
      cancelledPolicyAcceptRenewalDate: {},
      declineYourCoverWillEnd: {},
      cancelledPolicyDeclineYourCoverWillEnd: {},
    };
  },
  mounted() {
    this.acceptRenewalDate = this.fields.acceptRenewalDate;
    this.acceptRenewalDate.value = this.fields.acceptRenewalDate.value
      .replace(scPlaceholders.coverType, this.coverageCodeToLabel(this.insurancePolicy?.coverages[0].coverageCode))
      .replace(scPlaceholders.futureTermEffectiveDate, new Date(this.insurancePolicy?.renewalTermStart).toLocaleDateString('en-AU'));

    this.cancelledPolicyAcceptRenewalDate = this.fields.cancelledPolicyAcceptRenewalDate;
    this.cancelledPolicyAcceptRenewalDate.value = this.fields.cancelledPolicyAcceptRenewalDate.value
      .replace(scPlaceholders.coverType, this.coverageCodeToLabel(this.insurancePolicy?.coverages[0].coverageCode))
      .replace(scPlaceholders.futureTermEffectiveDate, new Date(this.insurancePolicy?.renewalTermStart).toLocaleDateString('en-AU'));

    this.declineYourCoverWillEnd = this.fields.declineYourCoverWillEnd;
    this.declineYourCoverWillEnd.value = this.fields.declineYourCoverWillEnd.value.replace(
      scPlaceholders.currentTermExpiryDate,
      this.isExperienceEditorRunning ? new Date(2099, 12, 31).toLocaleDateString('en-AU') : new Date(this.insurancePolicy.termEnd).toLocaleDateString('en-AU')
    );

    this.cancelledPolicyDeclineYourCoverWillEnd = this.fields.cancelledPolicyDeclineYourCoverWillEnd;
    this.cancelledPolicyDeclineYourCoverWillEnd.value = this.fields.cancelledPolicyDeclineYourCoverWillEnd.value.replace(
      scPlaceholders.currentTermExpiryDate,
      this.isExperienceEditorRunning ? new Date(2099, 12, 31).toLocaleDateString('en-AU') : new Date(this.insurancePolicy.termEnd).toLocaleDateString('en-AU')
    );
  },
  computed: {
    ...mapState('renewalOffer', ['email', 'insurancePolicy']),
    renewalTermAccepted() {
      return this.insurancePolicy?.renewalTermAcceptance === true || this.insurancePolicy?.renewalTermAcceptance === 'true' ? true : false;
    },
    autoRenewalOptIn() {
      return this.insurancePolicy?.renewalPreference === true || this.insurancePolicy?.renewalPreference === 'true' ? true : false;
    },
    isCancelledPolicy() {
      return this.insurancePolicy?.status === 'Cancelled';
    },
  },
  methods: {
    onFinish() {
      console.log('next button click');
    },
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [RACQFormsMixin],
};
</script>
