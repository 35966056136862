import * as ApiConstant from './apiConstant';
import * as apiServiceBase from './apiServiceBase';

/**
 * Fetch the config for the Fat Zebra payment iFrame
 *
 * @param {*} reference
 * @param {*} amount
 * @param {*} buttonText
 * @param {*} tokenizeOnly
 *
 * @returns
 */
export async function getPaymentIframeConfiguration({ reference = '', amount = '0', buttonText = 'Submit payment', tokenizeOnly = true, googlePay = false }) {
  try {
    return await apiServiceBase.HttpGet(
      `${ApiConstant.getPaymentConfiguration}?reference=${reference}&amount=${amount}&buttonText=${buttonText}&tokenizeOnly=${tokenizeOnly}&googlepay=${googlePay}`
    );
  } catch (e) {
    throw new Error(e);
  }
}
