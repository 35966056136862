<template>
  <div id="jeopardy-container" class="form-step-container">
    <div class="form-step center-aligned">
      <div class="step-title">
        <h3 class="text-white">
          <span v-text="jeopardyData.fields.jeopardyTitle.value" />
        </h3>
      </div>
      <div class="step-side-icon"></div>
      <div class="form-step-content">
        <div>
          <div class="step-content">
            <div class="bottom-margin">
              <div class="text-container">
                <JeopardyV2 :step-data="stepData" :jeopardy-data="jeopardyData" :on-cancel="onCancel" />
              </div>
            </div>
          </div>
        </div>
        <div class="form-elements-container">
          <div class="form-buttons"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JeopardyV2 from '@/components/foundation/forms/JeopardyV2.vue';

export default {
  components: {
    JeopardyV2,
  },

  props: {
    jeopardyData: {
      type: Object,
      required: true,
    },
    stepData: {
      type: Object,
      requied: true,
    },
    onCancel: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
