<template>
  <MotorInsuranceRacqForm class="new-motor-insurance-form">
    <transition name="component-fade" mode="out-in">
      <!-- Preliminary steps -->
      <div v-if="preliminarySteps.length && !prelimStepsComplete" class="w-full preliminary-steps">
        <component
          :is="preliminarySteps[prelimStepIndex].component.value"
          :step="prelimStepData(prelimStepIndex)"
          :on-submit-form="submitPrelimStep"
          :on-cancel-form="goBackOrCancel"
          :go-next-step="goNextStep"
        />
        <MotorInsuranceJeopardy
          v-show="jeopardyActive"
          :jeopardy-data="jeopardyData"
          :step-data="preliminarySteps[prelimStepIndex]"
          :on-cancel="removeJeopardy"
        />
      </div>

      <!-- Begin new motor insurance form wizard with it's own series of steps -->
      <StepsContainer
        v-else-if="prelimStepsComplete"
        :params="params"
        :rendering="postPrelimStepRendering"
        :on-go-back="goBackToPrelimSteps"
        :jeopardy-data="jeopardyData"
      />
    </transition>
  </MotorInsuranceRacqForm>
</template>

<script>
import MotorInsuranceRacqForm from './shared/MotorInsuranceRacqForm.vue';
import StepsContainer from './steps/quote/StepsContainer.vue';
import SelectYourPolicy from './steps/preliminary/SelectYourPolicy.vue';
import ChangeEffectiveStartDate from './steps/preliminary/ChangeEffectiveStartDate.vue';
import GeneralConditions from './steps/preliminary/GeneralConditions.vue';

import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import { mapMutations, mapState, mapActions } from 'vuex';
import { scrollToElement } from '@/components/foundation/shared/utils/dom-helpers';
import { applyBusinessRules } from '@/components/foundation/shared/apiservices/apiPolicyService';
import MotorInsuranceJeopardy from './shared/MotorInsuranceJeopardy.vue';
import { NEW_MOTOR_INSURANCE_MODULE, policyCoverageTypes } from './services/mappings';
import { JP_LOGGED_OUT_CODE, JP_CHANGE_NOT_ALLOWED_CODE } from '@/components/foundation/shared/utils/constants';

export default {
  name: 'NewMotorInsuranceForm',

  components: {
    MotorInsuranceRacqForm,
    SelectYourPolicy,
    StepsContainer,
    ChangeEffectiveStartDate,
    GeneralConditions,
    MotorInsuranceJeopardy,
  },

  mixins: [RACQFormsMixins],

  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
    },
  },

  data() {
    return {
      prelimStepIndex: 0,
      prelimStepsComplete: false,
      businessRulesResponse: null,
      formTitle: 'Combine Roadside', // form name for analysis
    };
  },

  created() {
    // fetch all sitecore data
    this.INITIALISE_SITECORE_CONTENT(this.fields.data.datasource.steps);

    // Format steps data from Sitecore to be used in Vue
    this.INITIALISE_STEPS(this.fields.data.datasource.steps.filter((step) => !step.isPrelimStep.value));
  },

  mounted() {
    // Push form start event
    this.addDataToAnalytics(this.stepAnalytics);
  },

  watch: {
    prelimStepsComplete: function () {
      this.triggerAnalyticsEvent();
    },
    stepNumber: function () {
      this.triggerAnalyticsEvent();
    },
    prelimStepIndex: function () {
      this.triggerAnalyticsEvent();
    },
    formCompleted: function () {
      this.triggerAnalyticsEvent();
    },
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, [
      'newQuoteStepValue',
      'selectedPolicy',
      'jeopardyActive',
      'allInsurancePolicies',
      'viewModelData',
      'sitecoreContent',
      'stepNumber',
      'formCompleted',
      'activeJeopardyCode',
    ]),

    isConfirmationStep() {
      return this.fields.data.datasource.steps.filter((step) => step.stepId.value === 'confirmation');
    },

    postPrelimSteps() {
      return this.fields.data.datasource.steps.filter((step) => !step.isPrelimStep.value && step.stepId.value !== 'confirmation');
    },

    preliminarySteps() {
      return this.fields.data.datasource.steps.filter((step) => step.isPrelimStep.value);
    },

    isGeneralConditionsStep() {
      return this.preliminarySteps[this.prelimStepIndex].stepId.value === 'generalConditions';
    },

    postPrelimStepRendering() {
      return {
        ...this.rendering,
        formTitle: this.params?.formTitle || 'Add new vehicle',
        prelimStepsCount: this.preliminarySteps?.length,
      };
    },

    jeopardyData() {
      const sitecoreJeopardyContent = this.rendering.placeholders.jeopardy[0];

      const DEFAULT_JEOPARDY_CODE = [{ code: this.activeJeopardyCode || JP_CHANGE_NOT_ALLOWED_CODE }];

      return {
        ...sitecoreJeopardyContent,
        jpCodes: this.activeJeopardyCode ? DEFAULT_JEOPARDY_CODE : this.businessRulesResponse?.data?.jeopardies,
        params: {
          callUsVisible: true,
          callbackVisible: false,
        },
        jeopardyActive: this.jeopardyActive,
        cancelBtnText: this.allInsurancePolicies.length === 0 || this.activeJeopardyCode === JP_LOGGED_OUT_CODE ? 'Back to account' : 'Back',
      };
    },

    stepAnalytics() {
      let stepData;
      if (!this.prelimStepsComplete) {
        stepData = this.preliminarySteps[this.prelimStepIndex];
      } else if (!this.formCompleted) {
        stepData = this.postPrelimSteps[this.stepNumber - 1] || {};
      } else {
        stepData = this.isConfirmationStep[0];
      }

      const stepDataForAnalytics = {
        ...stepData,
        stepTitle: stepData.stepTitle?.value,
        stepNo: stepData.stepNumber?.value,
        stepId: stepData.stepId?.value,
        productCategory: 'Insurance',
        productName: '',
      };

      return stepDataForAnalytics;
    },

    prelimStepData() {
      return (index) => {
        return {
          ...this.preliminarySteps[index],
          content: this.sitecoreContent[this.preliminarySteps[index].stepId.value],
        };
      };
    },

    isFirstStep() {
      return this.prelimStepIndex === 0;
    },
  },

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, [
      'INITIALISE_SITECORE_CONTENT',
      'UPDATE_JEOPARDY_ACTIVE',
      'INITIALISE_STEPS',
      'UPDATE_PRELIMINARY_STEP',
      'UPDATE_VIEW_MODEL_DATA',
    ]),
    ...mapActions(NEW_MOTOR_INSURANCE_MODULE, ['formatFormDataToApplyBusinessRules']),

    goBackToPrelimSteps() {
      this.prelimStepsComplete = false;
      this.UPDATE_PRELIMINARY_STEP('CONFIRM_GENERAL_CONDITIONS');
      scrollToElement('body');
    },
    /**
     * Submit a preliminary step.
     *
     * @param {any} formData The form data Object
     * @param {any} metadata Any data not associated with the form fields
     */
    async submitPrelimStep({ metadata, formData }) {
      const payload = await this.formatFormDataToApplyBusinessRules({
        formData: {
          ...formData,
          changeEffectiveDate: formData?.changeEffectiveDate || this.viewModelData?.changeEffectiveDate || null,
        },
        metadata: {
          currentPolicy: metadata.selectedPolicy,
          stepId: metadata.stepId,
          quoteSessionId: this.$route.query.quoteSessionId.toLowerCase(),
        },
      });

      return applyBusinessRules(payload)
        .then((response) => {
          this.businessRulesResponse = response;

          this.UPDATE_VIEW_MODEL_DATA(response?.data?.viewModel?.responseModel);

          return response;
        })
        .catch((e) => {
          this.UPDATE_JEOPARDY_ACTIVE({ jeopardyActive: true });
          console.error('Apply Business Rules Error: ', e);
        })
        .finally(() => {
          if (metadata?.stepId === 'GeneralConditions') {
            // bypass business rules validation on general conditions step
            return this.goNextStep();
          }
        });
    },

    /**
     * Trigger analytics event upon successful business rules response
     * Note: none of the analytics code is mine, I am still not 100%
     * sure how it all works (21.11.2023)
     */
    triggerAnalyticsEvent() {
      const productName = policyCoverageTypes[this.businessRulesResponse?.data?.viewModel?.responseModel?.coverageCode] || '';

      this.addAnalyticsFormData(null, 'Insurance', productName, this.stepAnalytics, (this.formCompleted && 'form.complete') || '');
    },

    goNextStep() {
      if (this.prelimStepIndex === this.preliminarySteps.length - 1) {
        this.prelimStepsComplete = true;
      } else {
        this.prelimStepIndex += 1;
      }

      scrollToElement('body');
    },

    gotoSelfService() {
      return this.getSelfServiceUrl()
        .then((url) => (window.location.href = url))
        .catch((e) => {
          this.UPDATE_JEOPARDY_ACTIVE({ jeopardyActive: true });
          console.error('Error fetching self service URL', e);
        });
    },

    hideJeopardy() {
      if (this.jeopardyActive) {
        this.UPDATE_JEOPARDY_ACTIVE({ jeopardyActive: false });
      }

      scrollToElement('body');
    },

    removeJeopardy() {
      if (this.isFirstStep) {
        return this.gotoSelfService();
      }

      this.hideJeopardy();
    },

    goBackOrCancel() {
      if (this.isFirstStep || this.isGeneralConditionsStep) {
        return this.gotoSelfService();
      }

      this.hideJeopardy();

      this.prelimStepIndex -= 1;
    },
  },
};
</script>
<style lang="scss">
.new-motor-insurance-form {
  .form-container {
    position: relative;
    left: -4rem;

    @include respond-to(all-mobile) {
      left: 0;
    }

    .preliminary-steps {
      position: relative;
      left: 4rem;

      @include respond-to(all-mobile) {
        left: 0;
      }

      .form-step.center-aligned {
        @include respond-to(desktop) {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .component-fade-enter-active,
  .component-fade-leave-active {
    transition: opacity 0.15s ease;
  }

  .component-fade-enter,
  .component-fade-leave-to {
    opacity: 0;
  }

  .formulate-input-label {
    min-width: 100px !important;
  }

  .formulate-input-group-item.forms-input-wrapper {
    margin-bottom: 0 !important;

    .formulate-input-label {
      border-right: 2px solid $racq-navy;

      :not([id$='last-child_label']) {
        border-right: none !important;
      }
    }
  }

  .custom-tag-icon {
    font-size: 0.8rem;
    padding-top: 0.5rem;
    padding-bottom: 1.6rem;
  }

  [data-type='checkbox'] {
    label {
      cursor: pointer;
    }

    .formulate-input-element--checkbox {
      input[type='checkbox'] {
        font: inherit;
        margin-right: 1rem;
        width: 24px;
        height: 24px;
        transform: translateY(-1px);

        &::before {
          content: ' ';
          display: inline-block;
          border: 2px solid $racq-navy;
          width: 24px;
          height: 24px;
          border-radius: 4px;
        }

        &:checked {
          &::before {
            background-image: url('~@/assets/img/forms/bi-check-white.svg');
            background-size: 14px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $racq-navy;
          }
        }
      }
    }
  }

  .btn-primary,
  .btn-secondary {
    font-weight: bold;
    font-family: 'FiraSans-Regular', Arial, Helvetica, sans-serif !important;
  }
}
</style>
