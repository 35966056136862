<template>
  <div id="excess-and-sum-insured-form-fields">
    <h2 class="pt-4 pb-2" v-text="formInputData(motorPolicyModel.excessFormHeader).label" />

    <div v-if="!isReadOnlyExcessAmount && isMotorComprehensive">
      <FormulateInput
        v-bind="formInputData(motorPolicyModel.standardExcessCode)"
        type="button-group"
        :options="excessAmounts"
        :validation-messages="{
          required: validationMessages.defaultRequired,
        }"
        validation="required"
      />

      <div class="standard-excess-post-content" v-html="formInputData(motorPolicyModel.standardExcessCode).postContent"></div>
    </div>

    <div v-if="isReadOnlyExcessAmount">
      <FormulateInput v-bind="formInputData(motorPolicyModel.standardExcessCode)" type="hidden">
        <template #suffix>
          <p v-text="readOnlyExcessAmount" style="margin-left: 4rem" />
        </template>
      </FormulateInput>
      <div class="standard-excess-post-content pt-4" v-html="formInputData(motorPolicyModel.standardExcessCode).postContent"></div>
    </div>

    <FormulateInput
      v-bind="formInputData(motorPolicyModel.howVehicleInsured)"
      v-if="isMotorComprehensive"
      type="button-group"
      :options="vehicleValueOptions"
      validation="required"
      :validation-messages="{ required: validationMessages.defaultRequired }"
    />
    <p
      v-if="formValues.howVehicleInsured !== 'AGREED'"
      v-html="formInputData(motorPolicyModel.howVehicleInsured).postContent"
      class="how-vehicle-insured-initial-disclaimer"
    ></p>

    <div class="agreed-value-slider-wrapper" v-show="showAgreedValueSlider">
      <FormulateInput
        v-bind="formInputData(motorPolicyModel.agreedVehicleValue)"
        :name="motorPolicyModel.agreedVehicleValueInput"
        :id="motorPolicyModel.agreedVehicleValueInput"
        v-model="agreedValueRange"
        validation="required|rangeValidation"
        :validation-messages="{
          required: validationMessages.requiredAgreedValue,
          rangeValidation: validationMessages.withinAcceptableRange,
        }"
        :validation-rules="validationRules"
        @blur="onFormValueChange"
      >
        <template #suffix>
          <div class="pl-4">
            <FormulateInput
              @end="sliderEnd"
              label=""
              type="rangeslider"
              :handles="100"
              :pipsy="rangeSliderPipsOpts"
              v-bind="formInputData(motorPolicyModel.agreedVehicleValue)"
              :set="setAgreedValueRange"
              :tooltip="formInputData(motorPolicyModel.agreedVehicleValue).tooltip"
              :range="{
                min: prefillData.agreedValueRange.minValue,
                max: prefillData.agreedValueRange.maxValue,
              }"
            />
          </div>
        </template>
      </FormulateInput>

      <p class="pb-4" v-if="isFTTP" v-html="formInputData(motorPolicyModel.agreedVehicleValue).postContent"></p>
    </div>

    <FormulateInput v-if="isMotorComprehensive && isAllowedToSelectCarHire" type="hidden" :label="formInputData(motorPolicyModel.includeCarHire).label">
      <template #suffix>
        <FormulateInput type="checkbox" label="Yes" v-bind="formInputData(motorPolicyModel.includeCarHire)" :tooltip="{}" @change="onFormValueChange">
          <template #label>
            <label>Yes</label>
            <!-- Overriding tooltip in a hidden input because checkbox 'suffix' slot tooltip creates a difficult HTML structure -->
            <FormulateInput type="hidden" :tooltip="formInputData(motorPolicyModel.includeCarHire).tooltip" />
          </template>
        </FormulateInput>
      </template>
    </FormulateInput>

    <!-- TODO: Calculate v-if based on years driving against claim count -->
    <FormulateInput v-if="isMotorComprehensive && isAllowedToSelectNcdp" type="hidden" :label="formInputData(motorPolicyModel.includeNcdp).label">
      <template #suffix>
        <FormulateInput type="checkbox" label="Yes" v-bind="formInputData(motorPolicyModel.includeNcdp)" :tooltip="{}" @change="onFormValueChange">
          <template #label>
            <label>Yes</label>
            <!-- Overriding tooltip in a hidden input because checkbox 'suffix' slot tooltip creates a difficult HTML structure -->
            <FormulateInput type="hidden" :tooltip="formInputData(motorPolicyModel.includeNcdp).tooltip" />
          </template>
        </FormulateInput>
      </template>
    </FormulateInput>

    <FormulateInput
      v-if="isMotorComprehensive && isAllowedToSelectWindscreenReplacement"
      type="hidden"
      :label="formInputData(motorPolicyModel.includeWindscreenReplacement).label"
    >
      <template #suffix>
        <FormulateInput
          type="checkbox"
          label="Yes"
          v-bind="formInputData(motorPolicyModel.includeWindscreenReplacement)"
          :tooltip="{}"
          @change="onFormValueChange"
        >
          <template #label>
            <label>Yes</label>
            <!-- Overriding tooltip in a hidden input because checkbox 'suffix' slot tooltip creates a difficult HTML structure -->
            <FormulateInput type="hidden" :tooltip="formInputData(motorPolicyModel.includeWindscreenReplacement).tooltip" />
          </template>
        </FormulateInput>
      </template>
    </FormulateInput>
  </div>
</template>

<script>
import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import { NEW_MOTOR_INSURANCE_MODULE, motorPolicyModel } from '@/components/feature/newmotorinsuranceform/services/mappings';
import { getExcessAmounts } from '@/components/foundation/shared/apiservices/apiRefdataService';
import { coverageTypeCodes } from '@/components/feature/newmotorinsuranceform/services/mappings';

import wNumb from 'wnumb';
import { mapMutations, mapState } from 'vuex';

const sliderFormatOpts = wNumb({
  prefix: '$',
  thousand: ',',
  decimals: 0,
});

export default {
  name: 'ExcessAndSumInsuredFormFields',

  props: {
    step: {
      type: Object,
      default: () => ({}),
    },

    formValues: {
      type: Object,
      default: () => ({}),
    },

    prefillData: {
      type: Object,
      required: false,
    },
  },

  mixins: [RACQFormsMixins],

  emits: ['onAgreedValueChange'],

  async created() {
    await this.setExcessAmountValues();
    this.prefillAgreedValueRange();
  },

  watch: {
    'formValues.standardExcessCode': function (newVal, oldVal) {
      newVal != oldVal && this.onFormValueChange();
    },
    'formValues.howVehicleInsured': function (newVal, oldVal) {
      newVal != oldVal && this.onFormValueChange();
    },
  },

  data() {
    return {
      highMarketVale: 100_000,
      motorPolicyModel,
      agreedValueRange: '',
      excessAmounts: [],
      sliderFormatOpts,
      vehicleValueOptions: [
        {
          label: 'Market value',
          value: 'MARKET',
        },
        {
          label: 'Agreed value',
          value: 'AGREED',
          id: 'car-value_last-child',
        },
      ],
    };
  },

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, ['SET_EXCESS_AMOUNTS_REFDATA']),

    sliderEnd(newVal) {
      const valueAsString = newVal?.values?.[0];
      const valueAsNumber = newVal?.unencoded?.[0];

      if (valueAsString) {
        this.agreedValueRange = valueAsString;
      }

      if (valueAsNumber) {
        this.$emit('onAgreedValueChange', Math.trunc(valueAsNumber));
      }
    },

    async setExcessAmountValues() {
      const excessAmounts = await getExcessAmounts(this.prefillData.coverageCode, this.prefillData.changeEffectiveDate);

      this.excessAmounts = excessAmounts.map((amount, index, arr) => {
        return {
          value: amount.code,
          label: '$' + amount.name,
          id: index === arr.length - 1 ? 'excess-amount_last-child' : amount.code,
        };
      });

      this.SET_EXCESS_AMOUNTS_REFDATA(this.excessAmounts);
    },

    prefillAgreedValueRange() {
      if (this.agreedValueRange) {
        return;
      }

      if (this.prefillData?.agreedVehicleValue) {
        this.agreedValueRange = sliderFormatOpts.to(this.prefillData?.agreedVehicleValue);
      } else {
        this.agreedValueRange = sliderFormatOpts.to(this.prefillData.glassesGuideVehicleDetails.marketValue);
      }
    },

    onFormValueChange() {
      this.$emit('onFormValueChange');
    },
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, ['businessRulesResponse']),

    isReadOnlyExcessAmount() {
      return (
        this.isHighMarketValue || [coverageTypeCodes.MotorThirdParty, coverageTypeCodes.MotorThirdPartyFireAndTheft].includes(this.prefillData.coverageCode)
      );
    },

    showAgreedValueSlider() {
      const { Motorcycle, Caravan, MotorComprehensiveOther, MotorThirdParty } = coverageTypeCodes;

      if ([Motorcycle, Caravan, MotorComprehensiveOther, MotorThirdParty].includes(this.coverageCode)) {
        return false;
      }

      if (this.formValues.howVehicleInsured !== 'AGREED') {
        return false;
      }

      return this.isFTTP || this.isMotorComprehensive;
    },

    isFTTP() {
      return this.prefillData.coverageCode == coverageTypeCodes.MotorThirdPartyFireAndTheft;
    },

    isMotorComprehensive() {
      return this.prefillData.coverageCode == coverageTypeCodes.MotorComprehensive;
    },

    isHighMarketValue() {
      return this.prefillData?.glassesGuideVehicleDetails?.marketValue > this.highMarketVale;
    },

    isAllowedToSelectNcdp() {
      return this.businessRulesResponse.viewModel.isAllowedToSelectNcdp;
    },

    isAllowedToSelectCarHire() {
      return this.businessRulesResponse.viewModel.isAllowedToSelectCarHire;
    },

    isAllowedToSelectWindscreenReplacement() {
      return this.businessRulesResponse.viewModel.isAllowedToSelectWindscreenReplacement;
    },

    rangeSliderPipsOpts() {
      return {
        mode: 'steps',
        density: 1000,
        format: sliderFormatOpts,
      };
    },

    readOnlyExcessAmount() {
      if (this.businessRulesResponse?.viewModel?.highValueBasicExcess) {
        return new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(this.businessRulesResponse.viewModel.highValueBasicExcess);
      }

      const standardExcessCode = this.prefillData.standardExcessCode;
      return this?.excessAmounts.find((amount) => amount.value == standardExcessCode)?.label;
    },

    validationRules() {
      return {
        rangeValidation: ({ value }) => {
          const { minValue, maxValue } = this.prefillData.agreedValueRange;
          const valueAsNumber = sliderFormatOpts.from(value);

          return valueAsNumber >= minValue && valueAsNumber <= maxValue;
        },
      };
    },

    setAgreedValueRange() {
      return sliderFormatOpts.from(this.agreedValueRange) || this.prefillData.marketValue;
    },
  },
};
</script>

<style lang="scss">
#excess-and-sum-insured-form-fields {
  .agreed-value-slider-wrapper {
    [name='agreedVehicleValueInput'] {
      max-width: 150px;
    }
  }

  [data-type='checkbox'] input[type='checkbox'] {
    cursor: pointer;
  }

  [data-type='rangeslider'],
  [data-type='checkbox'] {
    .formulate-input-wrapper {
      margin-top: 0 !important;
    }
  }

  [data-type='rangeslider'] {
    margin-left: 1rem;
    max-width: 200px;

    .formulate-input-element--rangeslider[name='agreedValue'] {
      max-width: 200px;
    }
  }

  [data-type='hidden'].forms-input-wrapper {
    position: relative;
    bottom: 0.25rem;
    margin-bottom: 0 !important;
  }

  [data-type='checkbox'].forms-input-wrapper {
    margin-left: 4rem;
    margin-bottom: 0 !important;
  }

  #agreedVehicleValue {
    max-width: 150px;
  }

  .standard-excess-post-content {
    position: relative;
    bottom: 2.5rem;
    left: 15.5rem;
  }

  .how-vehicle-insured-initial-disclaimer {
    position: relative;
    left: 15.5rem;
    bottom: 2rem;
    width: 370px;
  }
}
</style>
