import animateScrollTo from 'animated-scroll-to';

/**
 * Smooth scroll to an element in the form wizard.
 *
 * @param {String} el The element to scroll to
 * @param {Number} timeout Begin scrolling after element has attached to the DOM
 * @param {Number} verticalOffset The amount of space to show before the element
 *
 */
export function scrollToElement(el = 'body', timeout = 100, verticalOffset = 0) {
  const elementToScrollTo = document.querySelector(el);

  if (elementToScrollTo) {
    setTimeout(() => {
      animateScrollTo(elementToScrollTo, {
        minDuration: 400,
        maxDuration: 600,
        verticalOffset,
      });
    }, timeout);
  }
}

export function isDevEnv() {
  return process.env.NODE_ENV === 'development';
}
