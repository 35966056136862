<template>
  <div v-show="amountValue && decimalValue">
    <h1 :style="{ fontSize: fontSize + 'px', fontFamily: 'Arial' }">
      <b>
        <sup :style="{ fontSize: fontSize / 1.8 + 'px', marginRight: '4px' }">$</sup>{{ amountValue
        }}<sub :style="{ fontSize: fontSize / 2 + 'px', bottom: '0px' }"
          ><span style="margin: 0 2px">.</span>{{ decimalValue }}<sup v-if="showStarSign">*</sup></sub
        >
      </b>
    </h1>
  </div>
</template>

<style></style>

<script>
export default {
  name: 'DollarAmount',
  computed: {
    amountValue: function () {
      try {
        if (!this.amount) return '';
        const val = new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(this.amount);
        return val.slice(1, val.lastIndexOf('.'));
      } catch (error) {
        return '';
      }
    },
    decimalValue: function () {
      try {
        if (!this.amount) return '';
        const val = new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(this.amount);
        return val.slice(val.lastIndexOf('.') + 1, val.length);
      } catch (error) {
        return '';
      }
    },
  },
  props: {
    fontSize: {
      type: [Number],
      default: () => 40,
    },
    showStarSign: {
      type: [Boolean],
      default: () => false,
    },
    amount: {
      type: [Number, String],
      default: () => '',
    },
  },
};
</script>
