<template>
  <div v-if="loginVisible" class="login-screen">
    <p>Logging you in. Please wait.</p>
  </div>
</template>

<script>
import { loginApiService } from './../../foundation/shared/apiservices/loginApiService';
import * as ApiConstant from './../../foundation/shared/apiservices/apiConstant';
export default {
  name: 'Login',
  async created() {
    if (this.$route.fullPath.toLowerCase().includes('logout')) {
      const logoutUrl = await loginApiService.logOut();
      if (window && window.location) window.location.href = logoutUrl;
    } else {
      const isAuthenticated = await loginApiService.isAuthenticated();
      this.loginVisible = !isAuthenticated;
      if (!isAuthenticated) {
        if (this.$route.query.code) {
          const response = await loginApiService.authenticate(this.$route.query.code);
          if (response.success) {
            this.loginVisible = false;
            const url = this.$route.query.state.split(';')[1]; //redirect back to form
            window.location.href = url;
          }
        } else {
          var response = await loginApiService.getLoginUrl(window.location.toString());
          if (process.env.NODE_ENV != 'production') {
            response = this.urlUpdateForLocal(response);
          }
          window.location.href = response;
        }
      } else {
        if (this.$route.query && this.$route.query.state) {
          const url = this.$route.query.state.split(';')[1]; //redirect back to form
          window.location.href = url;
        }
      }
    }
  },
  data: function () {
    return {
      loginVisible: false,
    };
  },
  computed: {},
  methods: {
    urlUpdateForLocal(url) {
      var urlObject = new URL(url);
      var search_params = urlObject.searchParams;

      search_params.set('redirect_uri', ApiConstant.localURL);
      urlObject.search = search_params.toString();
      return urlObject.toString();
    },
  },
  mounted() {},
  components: {},
  props: {},
};
</script>
<style lang="scss" scoped>
.login-screen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
