<template>
  <div>
    <p>PageContent Component Arun</p>
    <p>John</p>
    <label for="" v-if="IsExp"><sc-text :field="fields.heading" /></label>

    <FormulateInput type="text" :label="fields.heading.value" />
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss';

export default {
  name: 'PageContent',
  components: {
    ScText: Text,
  },
  props: {
    IsExp: isExperienceEditorActive(),
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
