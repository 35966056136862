const firstname = '${firstname}';
const insuranceType = '${insuranceType}';
const daysToExpiry = '${daysToExpiry}';
const expiryDate = '${expiryDate}';
const policyNumber = '${policyNumber}';
const termStart = '${termStart}';
const termEnd = '${termEnd}';
const policyHolderFullname = '${policyHolderFullname}';
const costPerPaymentPeriod = '${costPerPaymentPeriod}';
const paymentPeriod = '${paymentPeriod}';
const totalAnnualPrice = '${totalAnnualPrice}';
const nextDebitDate = '${nextDebitDate}';
const email = '${email}';
const coverType = '${coverType}';
const futureTermEffectiveDate = '${futureTermEffectiveDate}';
const nextDebitAmount = '${nextDebitAmount}';
const currentTermExpiryDate = '${currentTermExpiryDate}';

export const scPlaceholders = {
  firstname,
  insuranceType,
  daysToExpiry,
  expiryDate,
  policyNumber,
  termStart,
  termEnd,
  policyHolderFullname,
  costPerPaymentPeriod,
  paymentPeriod,
  totalAnnualPrice,
  nextDebitDate,
  email,
  coverType,
  futureTermEffectiveDate,
  nextDebitAmount,
  currentTermExpiryDate,
};
