<template>
  <div class="form-elements-container confirmation-screen">
    <sc-rich-text :field="fields.stepContent" />
    <div class="forms-light-panel">
      <div class="form-confirmation-header">
        <div class="image-container">
          <sc-image :media="fields.confirmationClockIcon" />
        </div>
        <h3><sc-text :field="fields.subCallBackHeading" /></h3>
      </div>
      <div class="forms-confirmation-container">
        <sc-rich-text :field="fields.confirmationMessageContent" />
      </div>
    </div>
    <div class="form-buttons">
      <FormulateInput type="button" name="Finish" input-class="btn btn-secondary" v-show="!hideButtons" @click="cancelForm" outer-class="" />
    </div>
  </div>
</template>

<script>
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormsMixin from '../../../foundation/forms/mixins/RACQFormsMixins';

export default {
  name: 'Step2CallBackForm',
  components: {
    ScText: Text,
    ScImage: Image,
    ScRichText: RichText,
  },
  methods: {},
  mixins: [RACQFormsMixin],
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
