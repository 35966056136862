<template>
  <div class="header-container">
    <div class="forms-header container">
      <div class="header-logo" role="banner">
        <sc-link style="font-size: 0" :field="fields.logoLink ? fields.logoLink : {}">
          <sc-image :media="fields.racqLogo" />
        </sc-link>
      </div>
      <h1 v-if="title" class="form-title" v-text="title" />
      <div class="help-content">
        <sc-link :field="fields.HelpContentLink">
          <sc-image :media="fields.helpLogo" />
        </sc-link>
      </div>
    </div>
  </div>
</template>

<script>
import { Image, Link } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'Header',
  components: {
    ScImage: Image,
    ScLink: Link,
  },
  props: {
    // TODO: PJ-325 - Refactor approach to header. 04/10/23
    // because of the way rendering and fields data was set up
    // in sitecore, we are not getting the correct route data
    // which is why we are using the "title" prop.
    // This needs to be addressed at the sitecore level.
    rendering: {
      type: Object,
      default: () => ({}),
    },
    pageTitle: {
      type: String,
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
