var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"policy-summary-container"},[_c('PolicyDiscounts'),_c('hr'),_c('h3',{staticClass:"policy-summary-table-heading"},[_vm._v("Your vehicle details")]),_vm._l((_vm.vehicleDetails),function(ref){
var label = ref[0];
var ref_1 = ref[1];
var editable = ref_1.editable;
var value = ref_1.value;
return _c('table',{key:label,staticClass:"policy-summary-table"},[_c('tr',{staticClass:"policy-summary-table-row"},[(label === 'car-icon')?_c('td',{staticClass:"policy-summary-table-data data-label"},[_c('div',{staticClass:"car-icon"})]):_c('td',{staticClass:"policy-summary-table-data data-label",domProps:{"textContent":_vm._s(label)}}),_c('td',{staticClass:"policy-summary-table-data data-value"},[(label === 'car-icon')?_c('span',{domProps:{"innerHTML":_vm._s(value)}}):_c('span',{domProps:{"textContent":_vm._s(value)}}),(_vm.isEditable && editable)?_c('button',{staticClass:"button-as-link",attrs:{"type":"button"},on:{"click":_vm.gotoCarDetailsStep}},[_vm._v("Edit")]):_vm._e()])])])}),_c('h3',{staticClass:"policy-summary-table-heading"},[_vm._v("Your Policy details")]),_vm._l((_vm.policyDetails),function(ref){
var label = ref[0];
var ref_1 = ref[1];
var editable = ref_1.editable;
var value = ref_1.value;
return _c('table',{key:label,staticClass:"policy-summary-table"},[_c('tr',{staticClass:"policy-summary-table-row"},[_c('td',{staticClass:"policy-summary-table-data data-label",domProps:{"textContent":_vm._s(label)}}),_c('td',{staticClass:"policy-summary-table-data data-value"},[_c('span',{domProps:{"textContent":_vm._s(value)}}),(_vm.isEditable && editable)?_c('button',{staticClass:"button-as-link",attrs:{"type":"button"},on:{"click":_vm.gotoExcessStep}},[_vm._v("Edit")]):_vm._e()])])])}),_c('h3',{staticClass:"policy-summary-table-heading"},[_vm._v("Primary driver")]),_vm._l((_vm.primaryDriverDetails),function(ref){
var label = ref[0];
var ref_1 = ref[1];
var editable = ref_1.editable;
var value = ref_1.value;
return _c('table',{key:label,staticClass:"policy-summary-table"},[_c('tr',{staticClass:"policy-summary-table-row"},[_c('td',{staticClass:"policy-summary-table-data data-label",domProps:{"textContent":_vm._s(label)}}),_c('td',{staticClass:"policy-summary-table-data data-value"},[_c('span',{domProps:{"textContent":_vm._s(value)}}),(_vm.isEditable && editable)?_c('button',{staticClass:"button-as-link",attrs:{"type":"button"},on:{"click":_vm.gotoPrimaryDriverStep}},[_vm._v("Edit")]):_vm._e()])])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }