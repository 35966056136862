<template>
  <Datepicker
    :class="`formulate-input-element formulate-input-element--${context.type}`"
    :input-class="context.attributes.class"
    :data-type="context.type"
    v-model="date"
    :options="context.options"
    v-bind="context.attributes"
    :language="language"
    @blur="context.blurHandler"
    typeable
  >
    <template #prevIntervalBtn> <span class="calendar-arrow arrow-left"> &nbsp; </span> </template>
    <template #nextIntervalBtn> <span class="calendar-arrow arrow-right"> &nbsp; </span> </template>

    <template #afterDateInput v-if="context.attributes.tooltip && tooltipContent">
      <RacqFormTooltip :tooltip-id="tooltipId">
        <template #tooltip-trigger>
          <button :class="[triggerIcon]" :id="tooltipId" type="button" name="tooltip-trigger" />
        </template>
        <template #tooltip-content>
          <p v-if="tooltipTitle"><strong v-text="tooltipTitle" /></p>
          <div v-html="tooltipContent" v-if="tooltipContent"></div>
        </template>
      </RacqFormTooltip>
    </template>
  </Datepicker>
</template>

<script>
import RacqFormTooltip from '@/components/foundation/forms/controls/RacqFormTooltip.vue';
import Datepicker from '@sum.cumo/vue-datepicker';
import * as lang from '@sum.cumo/vue-datepicker/dist/locale/index.esm';
import { nanoid } from 'nanoid';

export default {
  name: 'RacqFormDatepicker',

  components: { Datepicker, RacqFormTooltip },

  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      language: lang[this.context.attributes.language],
      triggerActive: false,
    };
  },

  watch: {
    'context.model': {
      handler(val) {
        this.context.model = this.formatDate(val);
        this.context.rootEmit('input', this.formatDate(val));
      },
    },
    'context.value': {
      handler(val) {
        this.context.model = this.formatDate(val);
        this.context.rootEmit('input', this.formatDate(val));
      },
    },
  },

  computed: {
    date: {
      get() {
        if (this.context.model) {
          return this.formatDate(this.context.model);
        } else return null;
      },
      set(val) {
        this.context.rootEmit('input', this.formatDate(val));
        this.context.model = this.formatDate(val);
      },
    },

    tooltipId() {
      const { tooltip } = this.context.attributes;

      if (tooltip && tooltip.tooltipId) {
        return tooltip.tooltipId;
      }

      return `tooltip-${nanoid()}`;
    },

    tooltipContent() {
      const { tooltip } = this.context.attributes;

      if (tooltip && tooltip.content) {
        return tooltip.content;
      }

      return '';
    },

    tooltipTitle() {
      const { tooltip } = this.context.attributes;

      if (tooltip && tooltip.title) {
        return tooltip.title;
      }

      return '';
    },

    triggerIcon() {
      const { tooltip } = this.context.attributes;

      if (tooltip && tooltip.triggerIcon) {
        return tooltip.triggerIcon;
      }

      return 'questionmark-icon';
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      let d = new Date(date);
      let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
      let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
      return `${year}-${month}-${day}`;
    },
  },
};
</script>

<style lang="scss">
.flex {
  display: flex;
}

[tooltip] {
  > div:first-child {
    display: flex;
    gap: 0.25rem;
  }

  .v-popper--theme-racq-form-tooltip {
    display: flex;
    justify-content: center;
  }

  .questionmark-icon {
    align-self: center;
    background: transparent;
    background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20baseProfile%3D%22tiny%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%220%200%2030%2030%22%3E%3Cpath%20fill%3D%22%23003478%22%20d%3D%22M14.86%204.5C9.06%204.58%204.424%209.344%204.5%2015.14c.08%205.797%204.842%2010.436%2010.64%2010.36%205.798-.08%2010.437-4.843%2010.36-10.64C25.42%209.06%2020.657%204.423%2014.86%204.5zm1.187%2016h-2.283v-2.24h2.283v2.24zm2.35-7.473c-.122.3-.272.558-.452.77-.18.215-.378.396-.595.546-.22.15-.425.3-.62.45-.193.15-.365.323-.515.517-.15.194-.245.436-.284.727v.553h-1.96v-.655c.027-.417.108-.766.238-1.047.13-.28.284-.52.458-.72.175-.2.36-.37.553-.517.193-.144.373-.29.538-.435.164-.146.298-.305.4-.48.102-.175.148-.392.138-.655%200-.446-.11-.775-.328-.99-.218-.212-.52-.32-.91-.32-.26%200-.485.052-.675.154-.19.102-.344.238-.465.408-.122.17-.212.368-.27.596-.058.228-.086.473-.086.734h-2.14c.01-.524.1-1.004.27-1.44.17-.436.408-.814.713-1.134.305-.32.673-.57%201.105-.748.43-.18.914-.27%201.446-.27.69%200%201.263.095%201.723.284.46.19.83.424%201.112.705.282.28.483.583.604.908.122.325.182.628.182.91%200%20.466-.06.848-.182%201.15z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    border: none;
    display: block;
    outline: none;
    width: 2rem;
    height: 2rem;

    &:hover,
    &.active {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBiYXNlUHJvZmlsZT0idGlueSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCI+PHBhdGggZmlsbD0iIzAwNmVmZiIgZD0iTTE0Ljg2IDQuNUM5LjA2IDQuNTggNC40MjQgOS4zNDQgNC41IDE1LjE0Yy4wOCA1Ljc5NyA0Ljg0MiAxMC40MzYgMTAuNjQgMTAuMzYgNS43OTgtLjA4IDEwLjQzNy00Ljg0MyAxMC4zNi0xMC42NEMyNS40MiA5LjA2IDIwLjY1NyA0LjQyMyAxNC44NiA0LjV6bTEuMTg3IDE2aC0yLjI4M3YtMi4yNGgyLjI4M3YyLjI0em0yLjM1LTcuNDczYy0uMTIyLjMtLjI3Mi41NTgtLjQ1Mi43Ny0uMTguMjE1LS4zNzguMzk2LS41OTUuNTQ2LS4yMi4xNS0uNDI1LjMtLjYyLjQ1LS4xOTMuMTUtLjM2NS4zMjMtLjUxNS41MTctLjE1LjE5NC0uMjQ1LjQzNi0uMjg0LjcyN3YuNTUzaC0xLjk2di0uNjU1Yy4wMjctLjQxNy4xMDgtLjc2Ni4yMzgtMS4wNDcuMTMtLjI4LjI4NC0uNTIuNDU4LS43Mi4xNzUtLjIuMzYtLjM3LjU1My0uNTE3LjE5My0uMTQ0LjM3My0uMjkuNTM4LS40MzUuMTY0LS4xNDYuMjk4LS4zMDUuNC0uNDguMTAyLS4xNzUuMTQ4LS4zOTIuMTM4LS42NTUgMC0uNDQ2LS4xMS0uNzc1LS4zMjgtLjk5LS4yMTgtLjIxMi0uNTItLjMyLS45MS0uMzItLjI2IDAtLjQ4NS4wNTItLjY3NS4xNTQtLjE5LjEwMi0uMzQ0LjIzOC0uNDY1LjQwOC0uMTIyLjE3LS4yMTIuMzY4LS4yNy41OTYtLjA1OC4yMjgtLjA4Ni40NzMtLjA4Ni43MzRoLTIuMTRjLjAxLS41MjQuMS0xLjAwNC4yNy0xLjQ0LjE3LS40MzYuNDA4LS44MTQuNzEzLTEuMTM0LjMwNS0uMzIuNjczLS41NyAxLjEwNS0uNzQ4LjQzLS4xOC45MTQtLjI3IDEuNDQ2LS4yNy42OSAwIDEuMjYzLjA5NSAxLjcyMy4yODQuNDYuMTkuODMuNDI0IDEuMTEyLjcwNS4yODIuMjguNDgzLjU4My42MDQuOTA4LjEyMi4zMjUuMTgyLjYyOC4xODIuOTEgMCAuNDY2LS4wNi44NDgtLjE4MiAxLjE1eiIvPjwvc3ZnPg==');
    }
  }

  .v-popper--theme-racq-form-tooltip.v-popper--shown .questionmark-icon {
    background-image: url('data:image/svg+xml;base64,PHN2ZyBiYXNlUHJvZmlsZT0idGlueSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCI+PHBhdGggZmlsbD0iIzAwNmVmZiIgZD0iTTE0Ljg2IDQuNUM5LjA2IDQuNTggNC40MjQgOS4zNDQgNC41IDE1LjE0Yy4wOCA1Ljc5NyA0Ljg0MiAxMC40MzYgMTAuNjQgMTAuMzYgNS43OTgtLjA4IDEwLjQzNy00Ljg0MyAxMC4zNi0xMC42NEMyNS40MiA5LjA2IDIwLjY1NyA0LjQyMyAxNC44NiA0LjV6bTEuMTg3IDE2aC0yLjI4M3YtMi4yNGgyLjI4M3YyLjI0em0yLjM1LTcuNDczYy0uMTIyLjMtLjI3Mi41NTgtLjQ1Mi43Ny0uMTguMjE1LS4zNzguMzk2LS41OTUuNTQ2LS4yMi4xNS0uNDI1LjMtLjYyLjQ1LS4xOTMuMTUtLjM2NS4zMjMtLjUxNS41MTctLjE1LjE5NC0uMjQ1LjQzNi0uMjg0LjcyN3YuNTUzaC0xLjk2di0uNjU1Yy4wMjctLjQxNy4xMDgtLjc2Ni4yMzgtMS4wNDcuMTMtLjI4LjI4NC0uNTIuNDU4LS43Mi4xNzUtLjIuMzYtLjM3LjU1My0uNTE3LjE5My0uMTQ0LjM3My0uMjkuNTM4LS40MzUuMTY0LS4xNDYuMjk4LS4zMDUuNC0uNDguMTAyLS4xNzUuMTQ4LS4zOTIuMTM4LS42NTUgMC0uNDQ2LS4xMS0uNzc1LS4zMjgtLjk5LS4yMTgtLjIxMi0uNTItLjMyLS45MS0uMzItLjI2IDAtLjQ4NS4wNTItLjY3NS4xNTQtLjE5LjEwMi0uMzQ0LjIzOC0uNDY1LjQwOC0uMTIyLjE3LS4yMTIuMzY4LS4yNy41OTYtLjA1OC4yMjgtLjA4Ni40NzMtLjA4Ni43MzRoLTIuMTRjLjAxLS41MjQuMS0xLjAwNC4yNy0xLjQ0LjE3LS40MzYuNDA4LS44MTQuNzEzLTEuMTM0LjMwNS0uMzIuNjczLS41NyAxLjEwNS0uNzQ4LjQzLS4xOC45MTQtLjI3IDEuNDQ2LS4yNy42OSAwIDEuMjYzLjA5NSAxLjcyMy4yODQuNDYuMTkuODMuNDI0IDEuMTEyLjcwNS4yODIuMjguNDgzLjU4My42MDQuOTA4LjEyMi4zMjUuMTgyLjYyOC4xODIuOTEgMCAuNDY2LS4wNi44NDgtLjE4MiAxLjE1eiIvPjwvc3ZnPg==');
  }
}

.vdp-datepicker__calendar {
  .day-header {
    background: $racq-blue-grey;
    color: #fff;
  }

  header {
    .prev,
    .next,
    .day__month_btn,
    .month__year_btn,
    > span:not(.prev),
    > span:not(.next) {
      background: $racq-blue-grey !important;
      color: #fff;
    }

    .day__month_btn {
      @include heading-regular-font-family;
      font-weight: bold;
      font-size: 1.25rem;
      text-align: center;
      font-style: italic;

      &:hover {
        background: $racq-blue-grey !important;
      }
    }
  }

  .arrow-left,
  .arrow-right {
    background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M12.88%209l-5.776%205.53-1.975-1.897L8.92%209%205.13%205.367h-.01L7.104%203.47%2012.88%209z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    position: relative;
  }

  .arrow-right {
    top: 0.75rem;
    left: 0.5rem;
  }

  .arrow-left {
    bottom: 0.75rem;
    transform: rotate(180deg);
  }

  .picker-view .day-header {
    font-size: 1rem;
    font-weight: bold;
  }

  .cell:not(.blank).month,
  .cell:not(.blank).year,
  .cell:not(.blank).day {
    &.selected,
    &:hover {
      background-color: $racq-navy-30p;
      border: 1px solid $racq-navy-30p !important;
    }

    &.today,
    &.selected:hover {
      color: #fff;
      font-weight: bold;
      background-color: $racq-navy;
    }
  }
}
</style>
