/**
 * Viewport mixin to handle resize events and
 * other viewport related interactions.
 */
export default {
  data() {
    return {
      viewportHeight: 0,
      viewportWidth: 0,
    };
  },

  methods: {
    resizeHandler() {
      if (typeof window !== 'undefined') {
        this.viewportHeight = window.innerHeight;
        this.viewportWidth = window.innerWidth;
      }
    },
  },

  computed: {
    isLargeScreen() {
      return this.viewportWidth > 1400;
    },
    isSmallLaptopAndBelow() {
      return this.viewportWidth <= 1200;
    },
    isTabletAndBelow() {
      return this.viewportWidth <= 992;
    },
  },

  mounted() {
    this.viewportHeight = window.innerHeight;
    this.viewportWidth = window.innerWidth;
  },

  created() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.resizeHandler);
    }
  },

  destroyed() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.resizeHandler);
    }
  },
};
