<template>
  <div>
    <div v-if="isDeclinedCard" class="alert alert-danger my-2">
      <span v-text="formInputData(motorPolicyModel.declinedCardMessage).label" />
    </div>

    <div class="p-relative pb-4">
      <div className="py-4 my-4">
        <img src="@/assets/img/icons/visa.svg" alt="Visa logo" />
        &nbsp;
        <img src="@/assets/img/icons/mastercard.svg" alt="Mastercard logo" />
        &nbsp;
        <img src="@/assets/img/icons/amex.svg" alt="American Express logo" />
      </div>

      <br />

      <FatZebraIframe
        v-if="fatZebraIframeUrl"
        v-bind="$props"
        :fat-zebra-card-token="fatZebraCardToken"
        :fat-zebra-iframe-url="fatZebraIframeUrl"
        :policy-number="policyNumber"
        :form-disabled="formDisabled"
        :key="fatZebraIframeKey"
        button-text="Submit Payment"
      />
    </div>
  </div>
</template>

<script>
import FatZebraServiceMixin from '@/components/foundation/forms/mixins/FatZebraServiceMixin';
import FatZebraIframe from './FatZebraIframe.vue';
import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import { NEW_MOTOR_INSURANCE_MODULE, motorPolicyModel } from '@/components/feature/newmotorinsuranceform/services/mappings';

import { mapMutations } from 'vuex';

// ------------------------------------------------------------------------------------ //
// Note: All the code you see below has been ported across from the React codebase.
// ------------------------------------------------------------------------------------ //

export default {
  name: 'FatZebraPayment',

  components: {
    FatZebraIframe,
  },

  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    fatZebraIframeUrl: {
      type: String,
      required: true,
    },
    formDisabled: {
      type: Boolean,
      default: true,
    },
    policyNumber: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
  },

  mixins: [RACQFormsMixins, FatZebraServiceMixin],

  data() {
    return {
      motorPolicyModel,
      fatZebraIframeKey: 1,
      fatZebraCardToken: '',
      paymentError: null,
      paymentStatus: '',
      submitting: false,
      quoteSessionId: this.$route.query.quoteSessionId,
      fatZebraPaymentResponse: {
        result: null,
      },
    };
  },

  mounted() {
    window.paymentHost = this.fatZebraIframeUrl;

    if (window.addEventListener) {
      window.addEventListener('message', this.messageListener);
    } else {
      window.attachEvent('onmessage', this.messageListener);
    }
  },

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, ['UPDATE_JEOPARDY_ACTIVE']),

    handlePaymentError(data) {
      this.paymentError = true;
      this.paymentStatus = data;
      this.submitting = false;
    },

    submitStage() {
      this.paymentError = false;
      this.paymentStatus = null;
    },
  },

  computed: {
    isDeclinedCard() {
      return this.fatZebraPaymentResponse?.result?.toUpperCase() === 'DECLINED';
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  border-radius: 4px;
  color: #c09853;
}

.alert-danger {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
}
</style>
