<template>
  <div>
    <sc-rich-text :field="fields.stepContent" />

    <div class="form-elements-container">
      <div class="form-buttons">
        <FormulateInput type="button" :name="fields.previousButtonText.value" input-class="btn btn-secondary" outer-class="" @click="goBack" />

        <div class="step-2__next-button-section">
          <FormulateInput type="submit" :name="fields.nextButtonText.value" input-class="btn btn-primary" outer-class="" @click="onSubmit" />
          <a href="#" @click="iDontAgree">{{ fields.iDontAgreeText.value }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormsMixins from '../../../foundation/forms/mixins/RACQFormsMixins';
import { jpCodes } from './shared/jpCodes';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Step2CycloneInsurance',
  components: {
    ScRichText: RichText,
  },
  data() {
    return {
      updatedRenewalText: {},
    };
  },
  computed: {
    ...mapState({
      insuranceProduct: (state) => state.cycloneInsuranceModule.insuranceProduct,
      step3Data: (state) => state.cycloneInsuranceModule.step3Data,
    }),
  },
  methods: {
    ...mapActions('cycloneInsuranceModule', ['loadReferenceData', 'createQuoteSession']),
    async onSubmit() {
      if (!this.isExperienceEditorRunning) {
        await this.loadReferenceData(this.insuranceProduct?.insurancePolicy?.coverages[0]);
        await this.createQuoteSession({ policyId: this.$route.query.policyNumber, coverageId: this.$route.query.coverageId });
      }

      this.$emit('go-next');
    },
    goBack() {
      this.$emit('go-back');
    },
    iDontAgree() {
      this.$root.$emit('show-jeopardy', {
        displayCode: jpCodes.genericError,
        jpCode: jpCodes.genericError,
        stepDetails: this.fields.stepTitle.value,
        formName: 'Cyclone Insurance Form',
      }); // user did not agree to terms and conditions
      return false; //stop normal anchor behaviour
    },
  },
  mixins: [RACQFormsMixins],
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
.step-2__next-button-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
}
</style>
