import * as ApiConstant from './apiConstant';
import * as apiServiceBase from './apiServiceBase';

const getQuoteSessionId = async function (policyNumber, quoteSessionType) {
  const response = await apiServiceBase.HttpGet(ApiConstant.getQuoteSessionId.replace('{0}', policyNumber).replace('{1}', quoteSessionType));
  return response;
};

const getQuoteSessionIdByCoverage = async function (policyNumber, quoteSessionType, coverageId) {
  const response = await apiServiceBase.HttpGet(
    ApiConstant.getQuoteSessionIdByCoverage.replace('{0}', policyNumber).replace('{1}', quoteSessionType).replace('{2}', coverageId)
  );
  return response;
};

export const quoteSessionApiService = {
  getQuoteSessionId,
  getQuoteSessionIdByCoverage,
};
