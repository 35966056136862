<template>
  <label class="racq-checkbox-container" aria-disabled="true">
    <input type="checkbox" disabled :checked="value ? 'checked' : ''" />
    <span class="checkmark"></span>
  </label>
</template>

<style>
/* The racq-checkbox-container */
.racq-checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.racq-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.racq-checkbox-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 2px;
}

/* When the checkbox is checked, add a blue background */
.racq-checkbox-container input:checked ~ .checkmark {
  background-color: #003478;
}

/* Create the checkmark/indicator (hidden when not checked) */
.racq-checkbox-container .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.racq-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.racq-checkbox-container .checkmark:after {
  left: 7px;
  top: 3.5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>

<script>
export default {
  name: 'RacqCheckBox',
  data: function () {
    return {
      showWarning: false,
    };
  },
  methods: {},
  props: {
    value: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>
