<template>
  <div>
    <FormulateForm v-model="formValues" @submit="primaryBtnAction">
      <div class="step-content">
        <ReviewPremiumHeader
          :title="premiumHeaderText"
          :review-text="premiumHeaderReviewText"
          :primary-btn-text="premiumHeaderPrimaryBtnText"
          :secondary-btn-text="formInputData(motorPolicyModel.headerSecondaryButton).label"
          :premium-amount="premiumAmount"
          :secondary-btn-action="showConfirmDialog"
          :primary-btn-action="primaryBtnAction"
        />

        <PolicySummary :prefill-data="prefillData" />

        <ExcessDetails />
      </div>

      <StepFooter v-bind="{ ...$props, step: stepFooterOptions }" />
    </FormulateForm>
  </div>
</template>

<script>
import StepFooter from '@/components/feature/newmotorinsuranceform/shared/StepFooter.vue';
import ExcessDetails from '../excess-and-sum-insured/ExcessDetails.vue';
import ReviewPremiumHeader from '@/components/feature/newmotorinsuranceform/shared/ReviewPremiumHeader.vue';
import PolicySummary from '@/components/feature/newmotorinsuranceform/shared/PolicySummary.vue';

import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import AddVehicleFormMixin from '@/components/foundation/forms/mixins/AddVehicleFormMixin';
import { NEW_MOTOR_INSURANCE_MODULE, motorPolicyModel } from '@/components/feature/newmotorinsuranceform/services/mappings';

import { mapGetters, mapState, mapMutations } from 'vuex';

export default {
  name: 'ReviewQuote',

  components: {
    StepFooter,
    ExcessDetails,
    PolicySummary,
    ReviewPremiumHeader,
  },

  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    onGoBack: {
      type: Function,
      required: true,
    },
    prefillData: {
      type: Object,
      required: false,
    },
  },

  mixins: [RACQFormsMixins, AddVehicleFormMixin],

  data() {
    return {
      motorPolicyModel,
      formValues: {},
      modalName: 'discard-changes-modal',
    };
  },

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, ['SET_ACTIVE_STEP', 'UPDATE_STEP_STATUS', 'GO_NEXT_STEP']),

    showConfirmDialog() {
      this.$vfm.show(this.modalName);
    },

    async primaryBtnAction() {
      if (this.isDirectDebit) {
        return this.onSubmit({
          metadata: {
            processPaymentResponse: true,
            processPaymentPayload: {
              quoteSessionId: this.$route.query.quoteSessionId,
              policyNumber: this.selectedPolicy.policyNumber,
              amount: this.premiumAmount,
              cardToken: null,
            },
          },
        });
      }

      this.onSubmit(this.formValues);
    },
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, ['accessoriesMap', 'excessAmounts', 'businessRulesResponse', 'selectedPolicy']),
    ...mapGetters(NEW_MOTOR_INSURANCE_MODULE, ['foundVehicleMakeModel', 'foundVehicle']),

    premiumHeaderText() {
      const annualHeaderText = this.formInputData(motorPolicyModel.headerTextAnnualDD).label;
      const monthlyHeaderText = this.formInputData(motorPolicyModel.headerTextMonthlyDD).label;
      const invoiceHeaderText = this.formInputData(motorPolicyModel.headerTextInvoice).label;

      if (this.directDebitType === 'ANNUAL') {
        return annualHeaderText;
      }

      if (this.directDebitType === 'MONTHLY') {
        return monthlyHeaderText;
      }

      return invoiceHeaderText;
    },

    premiumHeaderPrimaryBtnText() {
      if (this.directDebitType === 'ANNUAL') {
        return this.formInputData(this.motorPolicyModel.headerPrimaryButtonAnnualDD).label;
      }

      if (this.directDebitType === 'INVOICE') {
        return this.formInputData(this.motorPolicyModel.headerPrimaryButtonInvoice).label;
      }

      return this.formInputData(this.motorPolicyModel.headerPrimaryButton).label;
    },

    premiumHeaderReviewText() {
      if (this.directDebitType === 'ANNUAL') {
        return this.formInputData(this.motorPolicyModel.premiumDetailsAnnualDD).label;
      }

      if (this.directDebitType === 'MONTHLY') {
        return this.formInputData(this.motorPolicyModel.premiumDetailsMonthlyDD).label.replace(
          '{newMonthlyPremium}',
          this.formatCurrency(this.prefillData?.instalmentAmount)
        );
      }

      return this.formInputData(this.motorPolicyModel.premiumDetailsInvoice).label;
    },

    stepFooterOptions() {
      return {
        ...this.step,
        btnNext: this.premiumHeaderPrimaryBtnText,
        content: {
          ...this.step.content,
          btnNext: { value: this.premiumHeaderPrimaryBtnText },
        },
      };
    },
  },
};
</script>

<style scoped lang="scss"></style>
