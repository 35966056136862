import * as ApiConstant from './apiConstant';
import * as apiServiceBase from './apiServiceBase';

const getIdentityDetails = async function () {
  const response = await apiServiceBase.HttpGet(`${ApiConstant.getIdentityDetails}`);
  return response;
};

const getSelfServiceAccountOverviewPage = async function () {
  const response = await apiServiceBase.HttpGet(`${ApiConstant.getSelfServiceAccountOverviewUrl}`);
  return response;
};

const getSelfServiceChangeVehiclePage = async function (isInsuranceProduct) {
  const response = await apiServiceBase.HttpGet(`${ApiConstant.getSelfServiceChangeVehicleUrl.replace('{0}', isInsuranceProduct)}`);
  return response;
};

const getSelfServiceMakePaymentUrl = async function () {
  const response = await apiServiceBase.HttpGet(ApiConstant.getSelfServiceMakePaymentUrl);
  return response;
};

export const apiIdentityService = {
  getIdentityDetails,
  getSelfServiceAccountOverviewPage,
  getSelfServiceChangeVehiclePage,
  getSelfServiceMakePaymentUrl,
};
