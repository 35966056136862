<template>
  <div id="review-quote-premium-header">
    <div class="d-flex space-between">
      <div class="d-flex flex-col">
        <h1 class="title d-flex items-center" v-text="title" />

        <h2 class="premium-amount">
          <span class="currency">$</span><span v-text="splitPremiumAmount.dollars" />
          <span class="decimals">.<span v-text="splitPremiumAmount.cents" /></span>
        </h2>
      </div>

      <div class="d-flex flex-col" v-if="showButtons && (lvvWarningAccepted || !isLowValueVehicle)">
        <button type="button" class="btn btn-primary premium-btn" @click="primaryBtnAction" v-text="primaryBtnText" />
        <button type="button" class="btn btn-secondary premium-btn" @click="secondaryBtnAction" v-text="secondaryBtnText" />
      </div>
    </div>

    <p class="py-4" v-html="reviewText"></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { NEW_MOTOR_INSURANCE_MODULE } from '@/components/feature/newmotorinsuranceform/services/mappings';

export default {
  name: 'ReviewPremiumHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    premiumAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    reviewText: {
      type: String,
      required: false,
      default: '',
    },
    primaryBtnText: {
      type: String,
      default: 'Continue',
    },
    secondaryBtnText: {
      type: String,
      default: 'Discard changes',
    },
    primaryBtnAction: {
      type: Function,
      default: () => {},
    },
    secondaryBtnAction: {
      type: Function,
      default: () => {},
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    lvvWarningAccepted: {
      type: Boolean,
      deafult: false,
    },
  },

  computed: {
    ...mapGetters(NEW_MOTOR_INSURANCE_MODULE, ['isLowValueVehicle']),

    splitPremiumAmount() {
      const premiumAmountArray = this.premiumAmount.toLocaleString('en-US', { maximumFractionDigits: 2 }).split('.');

      // If only dollars, add 0 cents
      if (premiumAmountArray.length === 1) {
        premiumAmountArray.push('00');
      }

      // If single cent amount, add trailing zero
      if (premiumAmountArray[1].length === 1) {
        premiumAmountArray[1] += '0';
      }

      return {
        dollars: premiumAmountArray[0],
        cents: premiumAmountArray[1],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#review-quote-premium-header {
  .title {
    font-size: 1.5rem;
    font-family: Arial, Helvetica, sans-serif;
    align-items: baseline;
    line-height: 1.5;

    .title-tooltip {
      position: relative;
      top: 0.5rem;
    }
  }

  .premium-btn {
    text-align: center;
    padding: 0.5rem;
    margin: 0 0 0.75em 0;
    min-width: 10rem;
    max-width: 15rem;
  }

  .quote-premium {
    font-size: 3.75rem;
    font-weight: bold;
  }

  .premium-amount {
    font-size: 3.4375rem;
    font-weight: 700;
    line-height: 0.8181818182;

    .currency {
      font-size: 1.75rem;
      line-height: 1.125;
      vertical-align: top;
    }

    .decimals {
      font-size: 2rem;
      line-height: 1.125;
      vertical-align: top;
    }
  }
}
</style>
