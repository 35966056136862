import FormulateVueSelect from '@/components/foundation/forms/controls/FormulateVueSelect';
import RacqFormDatepicker from '@/components/foundation/forms/controls/RacqFormDatepicker.vue';
import RacqFormulateRangeInput from '@/components/foundation/forms/controls/RacqFormulateRangeInput';
import RacqSelectFormulateInput from '@/components/foundation/forms/controls/RacqSelectFormulateInput';
import RacqFormulateButtonGroup from '@/components/foundation/forms/controls/RacqFormulateButtonGroup.vue';

export default function RacqFormDatepickerPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      RacqFormDatepicker,
      FormulateVueSelect,
      RacqFormulateRangeInput,
      RacqFormulateButtonGroup,
      RacqSelectFormulateInput,
    },
    library: {
      datepicker: {
        classification: 'text',
        component: 'RacqFormDatepicker',
        slotProps: {
          labelAfter: ['tooltipButton'],
        },
      },
      'vue-select': {
        classification: 'search',
        component: 'FormulateVueSelect',
      },
      'button-group': {
        classification: 'radio',
        component: 'RacqFormulateButtonGroup',
      },
      selectbox: {
        classification: 'select',
        component: 'RacqSelectFormulateInput',
      },
      rangeslider: {
        classification: 'range',
        component: 'RacqFormulateRangeInput',
      },
    },
  });
}
