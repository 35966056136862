import * as axios from 'axios';
import config from './../../../../temp/config';
import * as ApiConstant from './apiConstant';

export async function HttpGet(endpoint = '', params = {}) {
  try {
    params.params = {
      sc_apikey: ApiConstant.isProduction ? config.sitecoreApiKey : ApiConstant.jssApiKey,
    };

    if (!ApiConstant.isProduction) {
      params.withCredentials = true;
    }

    const response = await axios.get(`${endpoint}`, params);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function HttpPost(endpoint = '', body = {}, params = {}) {
  try {
    params.params = {
      sc_apikey: ApiConstant.isProduction ? config.sitecoreApiKey : ApiConstant.jssApiKey,
    };

    if (!ApiConstant.isProduction) {
      params.withCredentials = true;
    }

    const response = await axios.post(`${endpoint}`, body, params);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function HttpPut(endpoint = '', body = {}, params = {}) {
  try {
    params.params = {
      sc_apikey: ApiConstant.isProduction ? config.sitecoreApiKey : '{8F768A35-1B88-47A4-B271-7A1F1CF03F78}',
    };

    if (!ApiConstant.isProduction) {
      params.withCredentials = true;
    }

    const response = await axios.put(`${endpoint}`, body, params);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
