var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("formulate-input-element d-flex items-center gap-2 " + (_vm.context.class ? ("formulate-input-element--" + (_vm.context.class)) : '')),attrs:{"data-type":_vm.context.type}},[(_vm.context.options.length)?_c('ul',{staticClass:"d-flex no-padding"},_vm._l((_vm.context.options),function(option,index){return _c('li',{key:option.value,staticClass:"racq-formulate-button-list",attrs:{"id":option.id},on:{"mouseenter":function($event){_vm.selectedIndex = index}}},[_c('button',{class:['racq-formulate-button', { 'is-selected': _vm.isSelected(option) }],style:({
          border: '2px solid #003478',
          borderRight: option.id.includes('last-child') ? '2px solid #003478' : '0',
          borderBottomRightRadius: option.id.includes('last-child') ? '4px' : '0',
          borderTopRightRadius: option.id.includes('last-child') ? '4px' : '0',
          borderBottomLeftRadius: index === 0 ? '4px' : '0',
          borderTopLeftRadius: index === 0 ? '4px' : '0',
        }),attrs:{"type":"button"},domProps:{"textContent":_vm._s(option.label)},on:{"click":function($event){return _vm.selectItem(option)}}})])}),0):_vm._e(),(_vm.context.attributes.tooltip && _vm.tooltipContent)?_c('div',{staticStyle:{"position":"relative","bottom":"0.5rem"}},[_c('RacqFormTooltip',{attrs:{"tooltip-id":_vm.context.attributes.tooltip.tooltipId},scopedSlots:_vm._u([{key:"tooltip-trigger",fn:function(){return [_c('button',{class:[_vm.triggerIcon],attrs:{"id":_vm.context.attributes.tooltip.tooltipId,"type":"button","name":"tooltip-trigger"}})]},proxy:true},{key:"tooltip-content",fn:function(){return [(_vm.tooltipTitle)?_c('p',[_c('strong',{domProps:{"textContent":_vm._s(_vm.tooltipTitle)}})]):_vm._e(),(_vm.tooltipContent)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.tooltipContent)}}):_vm._e()]},proxy:true}],null,false,2829545873)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }