<template>
  <div>
    <div class="step-content">
      <sc-rich-text :field="fields.stepContent" />
    </div>
    <div class="form-elements-container">
      <FormulateForm v-model="step1Values" @submit="onSubmit">
        <div class="call-back-form-container">
          <h4><sc-text :field="fields.personalDetailsHeading" /></h4>
          <racq-formulate-input
            type="text"
            name="firstName"
            key="firstName"
            :label="fields.firstNameLabel"
            validation="required"
            :validation-messages="{
              required: fields.firstNameRequiredErrorMessage,
            }"
            :readonly="true"
            :experienceEditableValidationErrors="[fields.firstNameRequiredErrorMessage]"
          />
          <racq-formulate-input
            type="text"
            name="lastName"
            :label="fields.lastNameLabel"
            validation="required"
            :validation-messages="{
              required: fields.lastNameRequiredErrorMessage,
            }"
            :readonly="true"
            :experienceEditableValidationErrors="[fields.lastNameRequiredErrorMessage]"
          />
          <racq-formulate-input
            type="text"
            name="membershipCardNumber"
            :label="fields.membershipCardNumberLabel"
            validation="required"
            :validation-messages="{
              required: fields.memberCardNumberRequiredErrorMessage,
            }"
            :readonly="true"
            :experienceEditableValidationErrors="[fields.memberCardNumberRequiredErrorMessage]"
          />
          <h4 v-if="(firstCoverage && firstCoverage.isMotor) || isExperienceEditorRunning"><sc-text :field="fields.carDetailsHeading" /></h4>
          <h4 v-if="(firstCoverage && firstCoverage.isHome) || isExperienceEditorRunning"><sc-text :field="fields.homeDetailsHeading" /></h4>
          <h4 v-if="(firstCoverage && firstCoverage.isPet) || isExperienceEditorRunning"><sc-text :field="fields.petDetailsHeading" /></h4>
          <h4 v-if="(firstCoverage && firstCoverage.isBoat) || isExperienceEditorRunning"><sc-text :field="fields.boatDetailsHeading" /></h4>
          <racq-formulate-input
            type="text"
            name="coverageDetail"
            :label="getCoverageLabel().label"
            v-if="!isMultipleCoveragePolicy && !isLiteProduct"
            validation="required"
            :readonly="true"
            :validation-messages="{
              required: getCoverageLabel().errorMessage,
            }"
            :experienceEditableValidationErrors="[getCoverageLabel().errorMessage]"
          />
          <racq-formulate-input
            key="selectbox"
            type="selectbox"
            name="multipleCoverables"
            :label="getCoverageLabel().label"
            v-if="isMultipleCoveragePolicy"
            :options="getMultipleCoveragesOptions()"
            placeholder="Please select"
            validation="required"
            :validation-messages="{
              required: getCoverageLabel().errorMessage,
            }"
            :experienceEditableValidationErrors="[getCoverageLabel().errorMessage]"
          />
          <racq-formulate-input
            :disabled-dates="disabledDates"
            :label="fields.cancellationDateLabel"
            :validation="getDateValidation()"
            v-if="insuranceProduct"
            name="cancellationDate"
            key="cancellationDate"
            type="datepicker"
            v-model="step1Values.cancellationDate"
          />
          <h4><sc-text :field="fields.callBackDetailsHeading" /></h4>
          <racq-formulate-input
            :options="getDropDownListItems(timePreferenceOptions)"
            key="preferredTime"
            name="preferredTime"
            type="selectbox"
            placeholder="Please select"
            validation="required"
            inputclass=""
            :label="fields.preferredTimeLabel"
            inputhaserrorsclass="input-error"
            :validation-messages="{
              required: fields.preferredTimeRequiredErrorMessage,
            }"
            :experienceEditableValidationErrors="[fields.preferredTimeRequiredErrorMessage]"
          />

          <racq-formulate-input
            type="text"
            name="contactNumber"
            :label="fields.contactNumberLabel"
            :validation="[
              ['required'],
              ['matches', /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/],
            ]"
            :validation-messages="{
              required: fields.contactNumberRequiredErrorMessage,
              matches: fields.contactNumberInvalidErrorMessage,
            }"
            :experienceEditableValidationErrors="[fields.contactNumberRequiredErrorMessage]"
          />

          <div class="form-buttons">
            <FormulateInput @click="cancelForm" type="button" name="Cancel" input-class="btn btn-secondary" v-show="!hideButtons" outer-class="" />

            <FormulateInput type="submit" name="Submit" v-show="!hideButtons" input-class="btn btn-primary" outer-class="" />
          </div>
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';
import RacqFormulateInput from '@/components/foundation/forms/controls/racqFormulateInput.vue';
import RACQFormsMixin from '@/components/foundation/forms/mixins/RACQFormsMixins';
import { apiIdentityService } from '@/components/foundation/shared/apiservices/apiIdentityService';
import { productApiService } from '@/components/foundation/shared/apiservices/apiProductService';
import CascadeDropDownMixin from '@/components/foundation/forms/mixins/CascadeDropDownMixins';
import { jpCodes } from './shared/jpCodes';
import Vuex from 'vuex';

export default {
  name: 'Step1CallBackForm',
  components: {
    ScRichText: RichText,
    ScText: Text,
    RacqFormulateInput,
  },
  async mounted() {
    try {
      var identity = await apiIdentityService.getIdentityDetails();

      console.log({ identity });
      if (identity) {
        this.$store.dispatch('identityModule/addIdentityData', identity);
      }
      this.parsedJeopardyData = JSON.parse(this.jeopardyData);
      if (this.parsedJeopardyData.formName == 'Cancel Roadside Form') {
        this.assistanceProduct = await productApiService.getAssistanceProduct(this.$route.query.policyid);
        this.isLiteProduct = this.assistanceProduct.isLiteProduct;
        if (this.assistanceProduct.vehicle) this.step1Values.coverageDetail = this.assistanceProduct.vehicle.registrationNumber;
      } else {
        this.insuranceProduct = await productApiService.getInsuranceProduct(this.$route.query.policyid);
        this.isMultipleCoveragePolicy =
          this.insuranceProduct && this.insuranceProduct.insurancePolicy && this.insuranceProduct.insurancePolicy.coverages.length > 1;
        this.firstCoverage =
          this.insuranceProduct && this.insuranceProduct.insurancePolicy && this.insuranceProduct.insurancePolicy.coverages
            ? this.insuranceProduct.insurancePolicy.coverages[0]
            : null;
      }
      this.step1Values.firstName = identity.firstName;
      this.step1Values.lastName = identity.lastName;
      this.step1Values.membershipCardNumber = identity.membershipCardNumber;
      this.step1Values.contactNumber = identity.phoneNumber;
      if (this.insuranceProduct) this.step1Values.coverageDetail = this.getCoverageIdentifierValue();
    } catch (error) {
      console.log('error occurred on callback form', error);
      this.$root.$emit('show-jeopardy', {
        displayCode: jpCodes.genericError,
        jpCode: jpCodes.internalServerError,
        stepDetails: this.step.stepTitle,
        formName: 'Cancel Insurance Form',
      });
    }
  },
  updated() {
    if (this.insuranceProduct && this.insuranceProduct.defaultCancellationDate && !this.defaultDateSet) {
      var toDate = new Date(this.getMaxDate());
      toDate.setHours(0, 0, 0, 0); //set hours to zero so that date comparison works correctly
      var fromDate = new Date(this.getCurrentDate());
      fromDate.setHours(0, 0, 0, 0);
      this.disabledDates = {
        customPredictor: function (date) {
          date.setHours(0, 0, 0, 0);
          if (date < fromDate || date > toDate) {
            //if date is between min and max dates
            return true;
          }
        },
      };
      this.step1Values.cancellationDate = new Date(this.insuranceProduct.defaultCancellationDate);
      this.defaultDateSet = true; //once default date is set then let user change the date
    }
  },
  created() {},
  mixins: [RACQFormsMixin, CascadeDropDownMixin],
  methods: {
    getModel(model) {
      return {
        formName: this.parsedJeopardyData.formName,
        cancellationReasons: this.parsedJeopardyData.cancellationReasons,
        cancellationDate: model.cancellationDate,
        jeopardyReason: this.parsedJeopardyData.jpCode + ' | ' + this.parsedJeopardyData.jpDescription,
        phoneNumber: model.contactNumber,
        preferredTime: this.getSelectedOption(this.timePreferenceOptions, model.preferredTime).fields.listValue.value,
        coverageLabel: this.getCoverageLabel().label.value,
        CoverageDetails: this.isMultipleCoveragePolicy ? this.step1Values.multipleCoverables : this.step1Values.coverageDetail,
      };
    },
    async onSubmit(model) {
      await productApiService.submitCallBackDetails(this.$route.query.policyid, this.getModel(model));
      this.$store.commit('callBackFormModule/clearCallBackFormJeopardyData');
      this.$emit('go-next');
    },
    getCoverageLabel() {
      if (this.firstCoverage) {
        if (this.firstCoverage.isBoat) {
          return { label: this.fields.boatRegoLabel, errorMessage: this.fields.boatRegoRequiredMessage };
        }
        if (this.firstCoverage.isHome) {
          return { label: this.fields.homeAddressLabel, errorMessage: this.fields.homeAddressRequiredMessage };
        }
        if (this.firstCoverage.isPet) {
          return { label: this.fields.petNameLabel, errorMessage: this.fields.petNameRequiredMessage };
        }
        if (this.firstCoverage.isMotor || this.firstCoverage.drivers) {
          // For some reason isMotor is false for motorbike & caravan
          return { label: this.fields.carRegoLabel, errorMessage: this.fields.coverageDetailRequiredMessage };
        }
      } else if (this.assistanceProduct) {
        return { label: this.fields.carRegoLabel, errorMessage: this.fields.coverageDetailRequiredMessage };
      }
      return { label: '', errorMessage: '' };
    },
    getCoverageIdentifierValue() {
      if (this.firstCoverage) {
        const isMotor = this.firstCoverage.isMotor || this.firstCoverage.drivers;
        var value =
          isMotor || this.firstCoverage.isBoat
            ? `${this.firstCoverage.vehicle.formattedSummary} (${this.firstCoverage.vehicle.registrationNumber})`
            : this.firstCoverage.isHome
            ? this.firstCoverage.riskAddress.formattedStreet
            : this.firstCoverage.isPet
            ? `${this.firstCoverage.petName} (${this.firstCoverage.petBreed})`
            : '';
        return value;
      }
    },
    getDateValidation() {
      return 'required';
    },
    getMaxDate() {
      if (this.isExperienceEditorRunning) {
        return null;
      } else {
        return this.insuranceProduct.maxCancellationDate;
      }
    },
    getCurrentDate() {
      if (this.isExperienceEditorRunning) {
        return null;
      } else {
        return this.insuranceProduct.defaultCancellationDate;
      }
    },
    getMultipleCoveragesOptions() {
      var options = [];
      if (this.insuranceProduct && this.insuranceProduct.insurancePolicy && this.insuranceProduct.insurancePolicy.coverages.length > 1) {
        //only if there are multiple coverages
        this.insuranceProduct.insurancePolicy.coverages.forEach((coverage) => {
          var value =
            coverage.isMotor || coverage.isBoat
              ? `${coverage.vehicle.formattedSummary} (${coverage.vehicle.registrationNumber})`
              : coverage.isHome
              ? coverage.riskAddress.formattedStreet
              : coverage.isPet
              ? `${coverage.petName} (${coverage.petBreed})`
              : '';
          var option = {
            value: value,
            label: value,
          };
          options.push(option);
        });
        options.push({ label: 'All products on this policy', value: 'All products on this policy' });
        return options;
      }
      return options;
    },
  },
  computed: {
    ...Vuex.mapState({
      identity: (state) => state.identityModule.identity,
    }),
    ...Vuex.mapGetters({
      jeopardyData: 'callBackFormModule/getCallBackJeopardyData',
    }),
  },
  data() {
    return {
      timePreferenceOptions: this.fields.preferredTimeOptions,
      step1Values: {},
      parsedJeopardyData: null,
      insuranceProduct: null,
      isMultipleCoveragePolicy: false,
      firstCoverage: null,
      assistanceProduct: null,
      defaultDateSet: false,
      isLiteProduct: false,
    };
  },
  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
