<template>
  <div class="contentBlock">
    <sc-text tag="h2" class="display-4" :field="fields.heading" />
    <sc-rich-text class="contentDescription" :field="fields.content" />
  </div>
</template>

<script>
import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'ContentBlock',
  created() {
    window.location.href =
      'https://my-nonprod.racq.com.au/oauth2/aus4c53snTtcCYJNN3l6/v1/authorize?response_type=code&scope=openid&state=67f2e391-7b88-46bd-9b51-794f8a934ef4&nonce=67f2e391-7b88-46bd-9b51-794f8a934ef4&client_id=racq_web&redirect_uri=https://digital-test-racq.racqgroup.local/login';
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ScText: Text,
    ScRichText: RichText,
  },
};
</script>
