<template>
  <div>
    <div class="tile-container">
      <div class="image-container">
        <sc-image :media="fields.refundIcon" />
      </div>
      <div class="content-container">
        <h5>
          <sc-text :field="fields.refundTitle"></sc-text>
        </h5>
        <sc-rich-text :field="fields.refundContent"></sc-rich-text>
      </div>
    </div>
    <div class="bank-container">
      <div class="form-elements-container">
        <FormulateForm v-model="step3Values" @submit="onSubmit">
          <div class="bank-details">
            <h5>
              <sc-text :field="fields.bankHeading"></sc-text>
            </h5>
            <racq-formulate-input
              type="text"
              name="accountName"
              :label="fields.accountNameLabel"
              :validation-messages="{
                required: fields.accountNameRequiredMessage,
                matches: fields.accountNameInvalidMessage,
              }"
              :validation="[['required'], ['matches', /^[a-zA-Z0-9 -]{1,40}$/]]"
            />
            <racq-formulate-input
              type="text"
              name="BSB"
              validation="required|bsbValid|number|max:6,length"
              :validation-messages="{
                required: fields.bsbRequiredMessage,
                bsbValid: fields.bsbNumberInvalidMessage,
              }"
              :validation-rules="{
                bsbValid: debounceVue(bsbValid, 2000),
              }"
              :label="fields.bsbLabel"
              :help="bsbDetails"
              :hasSpinner="true"
              :showSpinner="showSpinner"
            >
            </racq-formulate-input>
            <racq-formulate-input
              type="text"
              name="accountNumber"
              :label="fields.accountNumberLabel"
              :validation-messages="{
                required: fields.accountNumberRequiredMessage,
                matches: fields.accountNumberInvalidMessage,
              }"
              :validation="[['required'], ['matches', /^(\d{6,9})$/]]"
            />
            <sc-rich-text :field="fields.bankDetailsNote" />

            <div class="form-buttons">
              <FormulateInput type="button" name="Back" input-class="btn btn-secondary" v-show="!hideButtons" outer-class="" @click="goBack" />

              <FormulateInput type="submit" name="Next" v-show="!hideButtons" input-class="btn btn-primary" outer-class="" :disabled="this.showSpinner" />

              <FormulateInput
                type="button"
                name="Edit"
                style="margin-left: auto"
                v-show="hideButtons"
                @click="onEdit"
                input-class="btn btn-primary btn-edit"
                outer-class=""
              />
            </div>
          </div>
        </FormulateForm>
      </div>
    </div>
  </div>
</template>

<script>
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-vue';
import RacqFormulateInput from '../../../foundation/forms/controls/racqFormulateInput.vue';
import { productApiService } from '../../../foundation/shared/apiservices/apiProductService';
import pDebounce from 'p-debounce';
import RACQFormsMixin from '../../../foundation/forms/mixins/RACQFormsMixins';
export default {
  name: 'Step3CancelInsurance',
  components: {
    ScText: Text,
    ScImage: Image,
    ScRichText: RichText,
    RacqFormulateInput,
  },
  mixins: [RACQFormsMixin],
  methods: {
    goBack() {
      this.$emit('go-back');
    },
    onSubmit(model) {
      this.$store.dispatch('cancelInsuranceModule/clearStepData', '3');
      this.$store.dispatch('cancelInsuranceModule/addStep3DataToStore', {
        label: this.fields.accountNameLabel.value,
        value: model.accountName,
      });
      this.$store.dispatch('cancelInsuranceModule/addStep3DataToStore', {
        label: this.fields.bsbLabel.value,
        value: model.BSB,
      });
      this.$store.dispatch('cancelInsuranceModule/addStep3DataToStore', {
        label: this.fields.accountNumberLabel.value,
        value: model.accountNumber,
      });
      this.$emit('go-next');
    },
    debounceVue(bsbValid, wait) {
      var response = pDebounce(bsbValid, wait);
      return response;
    },
    async bsbValid() {
      if (this.bsbNumber == '') {
        if (!this.step3Values.BSB || this.step3Values.BSB == '') {
          //if bsb is blank return false
          return false;
        }
        this.bsbNumber = this.step3Values.BSB; //store bsb value in data
        this.showSpinner = true;
        this.lastBsbResponse = await productApiService.getFinancialInstitutionFromBsb(this.step3Values.BSB);
        this.showSpinner = false;
        if (this.lastBsbResponse && this.lastBsbResponse.branch)
          //f response is valid
          this.bsbDetails = `${this.lastBsbResponse.branch}, ${this.lastBsbResponse.name}`;
        //show bank and branch to customer
        else {
          this.bsbDetails = '';
        }
        return this.lastBsbResponse;
      } else {
        if (this.bsbNumber != this.step3Values.BSB) {
          //if user has entered different bsb than last time
          this.bsbNumber = this.step3Values.BSB;
          this.showSpinner = true;
          try {
            this.lastBsbResponse = await productApiService.getFinancialInstitutionFromBsb(this.step3Values.BSB);
            this.showSpinner = false;
            if (this.lastBsbResponse && this.lastBsbResponse.branch) this.bsbDetails = `${this.lastBsbResponse.branch}, ${this.lastBsbResponse.name}`;
            else this.bsbDetails = '';
            return this.lastBsbResponse;
          } catch (error) {
            console.error('error getting bsb details', error);
            this.showSpinner = false;
            return false;
          }
        } else {
          //if same bsb is entered then don't fire the request again .. to avoid additional calls
          if (this.lastBsbResponse && this.lastBsbResponse.branch) this.bsbDetails = `${this.lastBsbResponse.branch}, ${this.lastBsbResponse.name}`;
          return this.lastBsbResponse;
        }
      }
    },
    onEdit() {
      this.$emit('visibility-requested');
    },
  },
  data() {
    return {
      step3Values: {},
      bsbNumber: '',
      lastBsbResponse: {},
      bsbDetails: '',
      showSpinner: false,
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    step: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
