var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-title-first-last-name",attrs:{"id":_vm.id}},[_c('div',{staticClass:"form-fields-flex-wrapper"},[_c('FormulateInput',{staticClass:"flex-field-with-label title",attrs:{"id":"title","name":"title","options":_vm.personTitles,"type":"selectbox","placeholder":"Title","label":"Your name","validation":"required","validation-messages":{
        required: 'Please select a title',
      },"validation-name":"Title","error-behavior":"live"},on:{"validation":function($event){_vm.titleValidation = $event}}}),_c('FormulateInput',{staticClass:"firstName",attrs:{"validation-name":"First name","type":"text","name":"firstName","label":"","placeholder":"First name","validation":"bail|required|alpha","validation-messages":{
        required: 'Please enter your first name',
        alpha: _vm.validationMessages.firstNameAlpha,
      }},on:{"validation":function($event){_vm.firstNameValidation = $event}}}),_c('FormulateInput',{staticClass:"lastName",attrs:{"validation-name":"Last name","type":"text","name":"lastName","label":"","placeholder":"Last name","validation":"bail|required|alpha","validation-messages":{
        required: 'Please enter your last name',
        alpha: _vm.validationMessages.lastNameAlpha,
      }},on:{"validation":function($event){_vm.lastNameValidation = $event}}})],1),(_vm.titleValidation.hasErrors)?_c('ul',{staticClass:"formulate-input-errors"},_vm._l((_vm.titleValidation.errors),function(error,index){return _c('li',{key:(error + "_" + index),staticClass:"formulate-input-error",attrs:{"role":"status","aria-live":"polite"},domProps:{"textContent":_vm._s(error)}})}),0):_vm._e(),(_vm.firstNameValidation.hasErrors)?_c('ul',{staticClass:"formulate-input-errors"},_vm._l((_vm.firstNameValidation.errors),function(error,index){return _c('li',{key:(error + "_" + index),staticClass:"formulate-input-error",attrs:{"role":"status","aria-live":"polite"},domProps:{"textContent":_vm._s(error)}})}),0):_vm._e(),(_vm.lastNameValidation.hasErrors)?_c('ul',{staticClass:"formulate-input-errors"},_vm._l((_vm.lastNameValidation.errors),function(error,index){return _c('li',{key:(error + "_" + index),staticClass:"formulate-input-error",attrs:{"role":"status","aria-live":"polite"},domProps:{"textContent":_vm._s(error)}})}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }