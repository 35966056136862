import * as ApiConstant from './apiConstant';
import * as apiServiceBase from './apiServiceBase';

const getLiveChatSessionData = async function () {
  const response = await apiServiceBase.HttpGet(`${ApiConstant.getLiveChatSessionData}`);
  return response;
};

export const liveChatApiService = {
  getLiveChatSessionData,
};
