<template>
  <div class="form-elements-container">
    <div class="form-buttons" v-if="!isDisabledAndUneditable">
      <FormulateInput
        name="Back"
        :label="buttonPrevText"
        @click="onGoBack"
        type="button"
        :disabled="!showBackButton"
        input-class="btn btn-secondary"
        :style="backButtonStyles"
        outer-class=""
      />

      <FormulateInput
        name="Submit"
        v-if="step.btnNext !== false && buttonNextText"
        :label="buttonNextText"
        @click="prelimStep ? onSubmit() : () => {}"
        :type="prelimStep ? 'button' : 'submit'"
        :disabled="disableNext"
        v-show="formStatus === 'FETCHING' || !isDisabled || formStatus === 'EDITING'"
        :input-class="`btn btn-primary ${disableNext && 'disabled'}`"
        outer-class=""
      />

      <FormulateInput
        @click="onEdit"
        type="button"
        name="Edit"
        style="margin-left: auto"
        v-show="formStatus !== 'FETCHING' && isDisabled && formStatus !== 'EDITING'"
        input-class="btn btn-primary btn-edit"
        outer-class=""
      />
    </div>
  </div>
</template>

<script>
import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import { StepStates } from '@/models/foundation/forms/enums/stepStates';

import { mapMutations, mapState } from 'vuex';
import { scrollToElement } from '@/components/foundation/shared/utils/dom-helpers';
import { NEW_MOTOR_INSURANCE_MODULE } from '@/components/feature/newmotorinsuranceform/services/mappings';

export default {
  name: 'StepFooter',

  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    onGoBack: {
      type: Function,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
    disableNext: {
      type: Boolean,
      default: false,
      required: false,
    },
    prelimStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    formStatus: {
      type: String,
      default: 'IDLE',
    },
  },

  emits: ['on-edit'],

  mixins: [RACQFormsMixins],

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, ['UPDATE_JEOPARDY_ACTIVE', 'SET_ACTIVE_STEP']),

    onEdit() {
      this.UPDATE_JEOPARDY_ACTIVE({ jeopardyActive: false });
      this.$emit('on-edit');

      if (!this.prelimStep) {
        this.SET_ACTIVE_STEP({ stepIndex: this.step.stepNo - 1 });
        scrollToElement(`#${this.step.id}`);
      }
    },
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, ['steps', 'jeopardyActive']),

    isDisabled() {
      if (this.prelimStep && this.jeopardyActive) return true;

      const disabledStepStates = this.step.stepState === StepStates.DISABLED || this.step.stepState === StepStates.COMPLETED;

      return this.formStatus === 'FETCHING' || disabledStepStates;
    },

    isDisabledAndUneditable() {
      return this.step.stepState === StepStates.DISABLED_UNEDITABLE;
    },

    backButtonStyles() {
      const isButtonDisabled = this.isDisabled && this.formStatus !== 'FETCHING' && this.formStatus !== 'EDITING';
      const isButtonHidden = !this.showBackButton || isButtonDisabled;

      return {
        visibility: isButtonHidden ? 'hidden' : 'visible',
      };
    },

    buttonPrevText() {
      return this.step?.content?.btnPrev?.value;
    },

    buttonNextText() {
      return this.step?.content?.btnNext?.value;
    },
  },
};
</script>
