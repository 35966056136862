<template>
  <div>
    <label v-if="isExperienceEditorRunning">
      <sc-text :field="label" />
    </label>
    <FormulateInput
      @input="input"
      :options="options"
      v-model="dataModel"
      :name="name"
      :type="type"
      :min="min"
      :max="max"
      :help="help"
      :help-position="helpPosition"
      :placeholder="placeholder"
      :validation="validation"
      :input-class="type == 'select' ? 'input-select' : ''"
      :label="!isExperienceEditorRunning ? label.value : undefined"
      :input-has-errors-class="inputhaserrorsclass"
      :validation-messages="getErrorMessagesValues"
      :validationRules="validationRules"
      :readonly="readonly"
      :disabled-dates="disabledDates"
    >
      <template v-if="hasSpinner" #suffix>
        <div v-if="showSpinner" class="formulate-field-loader"></div>
      </template>
    </FormulateInput>
    <div class="formulate-input-errors" v-if="validationMessages && isExperienceEditorRunning">
      <div v-for="(currentError, index) in validationMessages" :key="index">
        <sc-text :field="currentError" />
      </div>
    </div>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormsMixins from '../mixins/RACQFormsMixins';
export default {
  name: 'racqFormulateInput',
  components: {
    ScText: Text,
  },
  methods: {
    input(event) {
      this.$emit('racqInput', { event: event });
    },
  },
  data() {
    return {
      dataModel: this.value ? { label: this.label, value: this.value } : null,
    };
  },
  computed: {
    getErrorMessagesValues() {
      ///this method get's all the validation fields passed as sitecore fields and set them to values only so formulate can understand validation message
      ///Also than we can have those validation fields working in experience editor
      const validationMessagesUpdated = JSON.parse(JSON.stringify(this.validationMessages));
      Object.keys(validationMessagesUpdated).forEach(function (key) {
        validationMessagesUpdated[key] = validationMessagesUpdated[key].value;
      });
      return validationMessagesUpdated;
    },
  },
  mixins: [RACQFormsMixins],
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    min: {
      type: [String, Boolean],
      default: true,
    },
    max: {
      type: [String, Boolean],
      default: true,
    },
    //experience editor custom fiedlds starts here
    experienceEditablelabel: {
      type: Object,
      default: () => ({}),
    },
    experienceEditableValidationErros: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: [String, Boolean],
      default: true,
    },
    value: {
      default: false,
    },
    help: {
      type: [String, Boolean],
      default: false,
    },
    helpPosition: {
      type: [String, Boolean],
      default: false,
    },
    /* eslint-enable */
    options: {
      type: [Object, Array, Boolean],
      default: false,
    },
    optionGroups: {
      type: [Object, Boolean],
      default: false,
    },
    id: {
      type: [String, Boolean, Number],
      default: false,
    },
    label: {
      type: [Object, Boolean],
      default: false,
    },
    labelPosition: {
      type: [String, Boolean],
      default: false,
    },
    errors: {
      type: [String, Array, Boolean],
      default: false,
    },
    validation: {
      type: [String, Boolean, Array],
      default: false,
    },
    inputhaserrorsclass: {
      type: [String, Boolean],
      default: false,
    },
    validationName: {
      type: [String, Boolean],
      default: false,
    },
    error: {
      type: [String, Boolean],
      default: false,
    },
    errorBehavior: {
      type: String,
      default: 'blur',
      validator: function (value) {
        return ['blur', 'live', 'submit', 'value'].includes(value);
      },
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    groupErrors: {
      type: Object,
      default: () => ({}),
      validator: (value) => {
        const isK = /^\d+\./;
        return !Object.keys(value).some((k) => !isK.test(k));
      },
    },
    validationMessages: {
      type: Object,
      default: () => ({}),
    },
    disabledDates: {
      type: [Object, Boolean],
      default: false,
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    hasSpinner: {
      type: Boolean,
      default: false,
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
