<template>
  <div>
    <racq-form>
      <step-tracker :steps="steps" :key="reloadSteps" />
      <div>
        <racq-form-step ref="step1" :stepNumber="fields.childItems[0].fields.stepNumber" :stepTitle="fields.childItems[0].fields.stepTitle">
          <step-1
            v-if="isExperienceEditorRunning || insuranceProduct"
            ref="step1-child"
            :formTitle="params.formTitle"
            @go-next="moveToStep('step2')"
            :fields="fields.childItems[0].fields"
            :step="steps[0]"
          />
        </racq-form-step>

        <racq-form-step ref="step2" :stepNumber="fields.childItems[1].fields.stepNumber" :stepTitle="fields.childItems[1].fields.stepTitle">
          <step-2
            v-if="isExperienceEditorRunning || insuranceProduct"
            ref="step2-child"
            :formTitle="params.formTitle"
            @go-next="preProcessStep3"
            @go-back="moveToStep('step1')"
            :fields="fields.childItems[1].fields"
            :step="steps[1]"
          />
        </racq-form-step>

        <racq-form-step
          ref="step3"
          :stepNumber="fields.childItems[2].fields.stepNumber"
          :stepTitle="fields.childItems[2].fields.stepTitle"
          data-tooltip-wrapper
        >
          <step-3
            v-if="isExperienceEditorRunning || insuranceProduct"
            ref="step3-child"
            :formTitle="params.formTitle"
            @go-next="moveToStep('step4')"
            @go-back="moveToStep('step2')"
            :fields="fields.childItems[2].fields"
            :step="steps[2]"
          />
        </racq-form-step>

        <racq-form-step ref="step4" :stepNumber="fields.childItems[3].fields.stepNumber" :stepTitle="fields.childItems[3].fields.stepTitle">
          <step-4
            v-if="isExperienceEditorRunning || insuranceProduct"
            ref="step4-child"
            :formTitle="params.formTitle"
            @go-next="moveToStep('step5')"
            @go-back="moveToStep('step3')"
            :fields="fields.childItems[3].fields"
            :step="steps[3]"
          />
        </racq-form-step>

        <racq-form-step ref="step5" :stepNumber="fields.childItems[4].fields.stepNumber" :stepTitle="fields.childItems[4].fields.stepTitle">
          <step-5
            v-if="isExperienceEditorRunning || insuranceProduct"
            ref="step5-child"
            :formTitle="params.formTitle"
            @go-next="moveToStep('step6')"
            @go-back="moveToStep('step4')"
            :fields="fields.childItems[4].fields"
            :step="steps[4]"
          />
        </racq-form-step>

        <racq-form-step ref="stepjeopardy" stepNumber="" :stepTitle="fields.jeopardyFolder.childItems[0].fields.jeopardyTitle">
          <placeholder
            ref="jeopardy-child"
            :step="steps[5]"
            name="jeopardy"
            :rendering="rendering"
            :params="{
              displayCode: displayCode,
              jpCode: jpcode,
              stepDetails: stepDetails,
              callUsVisible: true,
              chatVisible: true,
              formName: formName,
              btnBackText: fields.backButtonText.value,
            }"
          />
        </racq-form-step>
      </div>
    </racq-form>
    <div v-show="showLoader" class="w-100 h-100 form-curtain">
      <Loader />
    </div>
  </div>
</template>

<script>
import StepTracker from '../../foundation/forms/StepTracker.vue';
import Step1 from './steps/Step1CycloneInsurance.vue';
import Step2 from './steps/Step2CycloneInsurance.vue';
import Step3 from './steps/Step3CycloneInsurance.vue';
import Step4 from './steps/Step4CycloneInsurance.vue';
import Step5 from './steps/Step5CycloneInsurance.vue';
import RacqForm from '../../foundation/forms/RACQForm.vue';
import RacqFormStep from '../../foundation/forms/RACQFormStep.vue';
import RACQFormsMixins from '../../foundation/forms/mixins/RACQFormsMixins';
import { mapActions, mapState } from 'vuex';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import { jpCodes } from './steps/shared/jpCodes';
import Loader from '@/components/foundation/forms/Loader.vue';

export default {
  name: 'CycloneInsuranceForm',
  components: {
    StepTracker,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    RacqForm,
    RacqFormStep,
    Placeholder,
    Loader,
  },
  mixins: [RACQFormsMixins],
  data: function () {
    return {
      reloadSteps: 0,
      jpcode: undefined,
      displayCode: undefined,
      stepDetails: undefined,
      formName: undefined,
      steps: [
        //defaults
        {
          stepNo: 1,
          stepTitle: '',
          stepState: 'active',
          stepControlName: 'step1',
          stepChildControlName: 'step1-child',
        },
        {
          stepNo: 2,
          stepTitle: '',
          stepState: '',
          stepControlName: 'step2',
          stepChildControlName: 'step2-child',
        },
        {
          stepNo: 3,
          stepTitle: '',
          stepState: '',
          stepControlName: 'step3',
          stepChildControlName: 'step3-child',
        },
        {
          stepNo: 4,
          stepTitle: '',
          stepState: '',
          stepControlName: 'step4',
          stepChildControlName: 'step4-child',
        },
        {
          stepNo: 5,
          stepTitle: '',
          stepState: '',
          stepControlName: 'step5',
          stepChildControlName: 'step5-child',
        },
        {
          stepNo: 6,
          stepTitle: 'Jeopardy',
          stepState: '',
          stepControlName: 'stepjeopardy',
          stepChildControlName: 'jeopardy-child',
          hideFromNavigation: true,
        },
      ],
      currentStep: {},
    };
  },
  computed: {
    ...mapState('cycloneInsuranceModule', ['insuranceProduct', 'jeopardy', 'showLoader']),
  },
  async mounted() {
    if (!this.isExperienceEditorRunning) {
      await this.loadInsuranceData({ policyNumber: this.$route.query.policyNumber, coverageId: this.$route.query.coverageId });
    }

    this.currentStep = this.steps[0];
    this.$refs[this.currentStep.stepControlName].moveInto();

    for (var i = 0; i < this.steps.length - 1; i++) {
      //exclude the jeopardy step
      // Set the step details from sitecore content
      this.steps[i].stepTitle = this.fields.childItems[i].fields.stepTitle.value;

      // Add data to the store
      this.addStepData({ stepData: this.fields.childItems[i].fields, stepNumber: i + 1 });
    }

    //global event to go redirect to account overview
    this.addCancelFormEvent();

    //global event to show jeopardy on any step
    this.$root.$on('show-jeopardy', (jpData) => {
      this.showJeopardy(jpData);
    });
  },
  methods: {
    ...mapActions('cycloneInsuranceModule', ['loadInsuranceData', 'addStepData']),
    preProcessStep3() {
      this.$refs['step3-child'].performPreProcessing();
      this.moveToStep('step3');
    },
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
    },
  },
  watch: {
    // Watch jeopardy state from Vuex store
    jeopardy(jeopardyCode) {
      if (jeopardyCode) {
        setTimeout(() => {
          this.$root.$emit('show-jeopardy', {
            displayCode: jeopardyCode !== null ? jeopardyCode : jpCodes.genericError,
            jpCode: jeopardyCode !== null ? jeopardyCode : jpCodes.genericError,
            formName: 'Cyclone Insurance Form',
          });
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss">
.form-curtain {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 999;
}
</style>
