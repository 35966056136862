<template>
  <div class="form-elements-container confirmation-screen">
    <sc-rich-text :field="fields.stepContent" />
    <div class="forms-light-panel">
      <div class="form-confirmation-header">
        <div class="image-container">
          <sc-image :media="fields.confirmationClockIcon" />
        </div>
        <h3><sc-text :field="fields.subCancellationHeading" /></h3>
      </div>
      <div class="forms-confirmation-container">
        <sc-rich-text :field="isExperienceEditorRunning ? fields.cancellationDescription : cancellationDescription" />
      </div>
    </div>
    <div class="warning-note information-background bottom-margin">
      <sc-image :media="fields.policyNoteIcon"></sc-image>
      <div class="text-container">
        <sc-rich-text :field="fields.policyNoteDescription"></sc-rich-text>
      </div>
    </div>
    <div class="form-buttons">
      <FormulateInput type="button" name="Finish" input-class="btn btn-secondary" v-show="!hideButtons" @click="cancelForm" outer-class="" />
    </div>
  </div>
</template>

<script>
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormsMixin from '../../../foundation/forms/mixins/RACQFormsMixins';
import { apiIdentityService } from '../../../foundation/shared/apiservices/apiIdentityService';
export default {
  name: 'Step3CancelRoadside',
  components: {
    ScRichText: RichText,
    ScImage: Image,
    ScText: Text,
  },
  data() {
    return {
      cancellationDescription: {},
    };
  },
  async mounted() {
    try {
      const identityDetails = await apiIdentityService.getIdentityDetails();
      this.cancellationDescription = this.fields.cancellationDescription;
      this.cancellationDescription.value = this.cancellationDescription.value.replace('${email}', identityDetails.email);
    } catch (error) {
      console.warn('Error occurred getting profile data', error);
    }
  },
  mixins: [RACQFormsMixin],
  methods: {
    goNext() {
      this.$emit('go-next');
    },
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    step: {
      type: Object,
    },
  },
};
</script>
