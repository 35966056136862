<template>
  <div id="add-to-cover-form-step3">
    <!-- Start Updated Paragraph -->
    <h4 class="mt-3">
      <b>
        <sc-text :field="fields.Step_Title" />
      </b>
    </h4>
    <p>
      <sc-rich-text :field="fields.Step_SubTitle" />
    </p>
    <div class="my-3 border-racq py-3 px-0 row rounded">
      <div class="col-12 col-md-6 d-md-flex">
        <span v-if="notPaid && !showAsAdditional" class="pr-2 text-16">
          <ScText :field="fields.PremiumTitle_Annual" v-if="showAnnual" />
          <ScText :field="fields.PremiumTitle_Monthly" v-else />
        </span>
        <span v-else class="pr-2 text-16">
          <ScText :field="fields.PremiumTitle_Additional" v-if="isManualPaid && !isDD" />
          <ScText :field="fields.PremiumTitle_AdditionalAnnual" v-else-if="showAnnual" />
          <ScText :field="fields.PremiumTitle_AdditionalMonthly" v-else />
        </span>
        <h6 class="mb-0 line-height-24 font-arial" v-if="newTotalPremium">
          <b>
            {{ newPremium }}
          </b>
        </h6>
      </div>
      <div class="col-12 py-2 d-md-none"></div>
      <div class="col-12 col-md-6 d-md-flex">
        <span class="pr-2 text-16">
          <sc-text :field="isManualPaid ? fields.RenewalEffective_Text : fields.RenewalDue_Text" />
        </span>
        <h6 class="mb-0 line-height-24 font-arial">
          <b>
            {{ termStart }}
          </b>
        </h6>
      </div>
    </div>
    <div class="alert my-4" style="background-color: #f2f5f7">
      <!-- Manual -->
      <sc-rich-text :field="fields.Instructions_Paid" v-if="isManualPaid" />
      <sc-rich-text :field="fields.Instructions_NotPaid" v-else-if="!isDD" />
      <!-- DD Opted In -->
      <sc-rich-text :field="fields.Instructions_Auto" v-else-if="acceptedRenewal" />
      <!-- DD Opted Out -->
      <sc-rich-text :field="fields.Instructions_AutoDD" v-else />
    </div>
    <div class="alert mt-3 mb-5" v-if="!isDD" style="background: #fdfbe7; border: 1px solid #feef69; font-size: 16px; color: #333333">
      <sc-rich-text :field="fields.Alert_NotPaid" />
    </div>
    <!-- End Updated Paragraph -->

    <!-- Start Bottom Buttons -->
    <div class="form-elements-container mt-5">
      <FormulateForm @submit="onSubmit">
        <div class="form-buttons d-flex w-100 pt-5">
          <FormulateInput
            type="submit"
            :name="(fields.nextButtonText && fields.nextButtonText.value) || 'Done'"
            input-class="btn btn-primary"
            outer-class="ml-auto"
          />
        </div>
      </FormulateForm>
    </div>
    <!-- End Bottom Buttons -->
  </div>
</template>

<style>
.text-16 {
  font-size: 16px;
}
.line-height-24 {
  line-height: 23.5px;
}
@media (min-width: 960px) {
  #add-to-cover-form-step3 {
    width: calc(785px - 60px);
  }
}
</style>

<script>
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormsMixin from '../../../foundation/forms/mixins/RACQFormsMixins';
import { mapState } from 'vuex';

export default {
  name: 'Step3AddToCoverForm',
  components: {
    ScRichText: RichText,
    ScText: Text,
  },
  mixins: [RACQFormsMixin],
  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    isDD: {
      type: Boolean,
      default: () => false,
    },
    isManualPaid: {
      type: Boolean,
      default: () => false,
    },
    showAsAdditional: {
      type: Boolean,
      default: () => false,
    },
    notPaid: {
      type: Boolean,
      default: () => false,
    },
    isDDOptedOut: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapState('renewalOffer', ['insurancePolicy']),
    acceptedRenewal: function () {
      return this.insurancePolicy && this.insurancePolicy.futureRenewalPreference;
    },
    termStart: function () {
      if (!this.insurancePolicy) return '';
      try {
        const d = new Date(this.insurancePolicy.renewalTermStart || this.insurancePolicy.termStart);
        const dString = new Intl.DateTimeFormat('en-AU', {
          dateStyle: 'long',
        }).format(d);
        return dString;
      } catch (error) {
        return '';
      }
    },
    ...mapState({
      newTotalPremium: (state) => state.addToCoverFormModule.newTotalPremium,
    }),
    showAnnual: function () {
      return (
        (this.insurancePolicy && this.insurancePolicy.renewalTermPaymentDetails && this.insurancePolicy.renewalTermPaymentDetails.isAnnual != false) ||
        !this.notPaid
      );
    },
    newPremium: function () {
      try {
        return new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(
          this.showAnnual ? this.newTotalPremium : this.newTotalPremium / 12
        );
      } catch (error) {
        return '';
      }
    },
  },
  data() {
    return {
      step3Values: {},
    };
  },
  mounted() {
    if (this.acceptedRenewal && this.fields.Instructions_Auto) {
      this.fields.Instructions_Auto.value = this.fields.Instructions_Auto.value.split('{0}').join(this.showAnnual ? 'annual' : 'monthly');
    }
  },
  methods: {
    /**
     * Move to next step
     */
    onSubmit: function () {
      this.getSelfServiceUrl().then((url) => {
        window.location.href = url;
      });
    },
    /**
     * Move back to Step 1
     */
    back: function () {},
  },
};
</script>
