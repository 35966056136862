// @ts-check

import { StepStates } from '@/models/foundation/forms/enums/stepStates';
import ApiStatus from '@/models/foundation/forms/enums/apiStatus';
import { coverageTypeCodes, driverFormStates, remapViewModelToFormulateData } from '@/components/feature/newmotorinsuranceform/services/mappings';
import { scrollToElement } from '../../shared/utils/dom-helpers';
import { JP_GENERIC_CODE, JP_LOGGED_OUT_CODE } from '../../shared/utils/constants';

function resetSteps(state, stepIndex, options = {}) {
  return state.steps.map((step, index) => {
    const stepIsActive = step.stepState === StepStates.ACTIVE;
    const stepIsUntouched = step.stepState === '';

    const stepCopy = {
      ...step,
      stepState: stepIsUntouched ? StepStates.UNTOUCHED : step.stepCompleted ? StepStates.COMPLETED : StepStates.DISABLED,
    };

    if (state.jeopardyActive) {
      stepCopy.stepState = stepIsActive ? StepStates.DISABLED : step.stepState;
    }

    if (stepIndex === index && step.stepCompleted) {
      stepCopy.stepState = StepStates.ACTIVE_COMPLETED; // : StepStates.ACTIVE;
    }

    if (options.disableNextSteps) {
      if (index > stepIndex && step.stepState !== '') {
        stepCopy.stepState = StepStates.DISABLED_UNEDITABLE;
      }
    }

    if (stepIndex === index) {
      stepCopy.stepState = StepStates.ACTIVE;
    }

    return stepCopy;
  });
}

const newMotorInsuranceModule = {
  namespaced: true,

  state() {
    return {
      activeJeopardyCode: '',
      jeopardyActive: false,
      stepNumber: 1,
      selectedPolicy: null,
      preliminaryStep: null,
      primaryDriverFormState: driverFormStates.NOT_DONE,
      allInsurancePolicies: [],
      selectedPolicyNumber: null,
      formCompleted: false,
      accessoriesMap: [],

      foundCarManually: false,
      // All steps data from sitecore
      initialSteps: [],
      // Computed steps data for Vue UI
      steps: [],
      // This is the data that comes from the Quote Session model
      // Which is part of the business rules engine response.
      // This data is used for prefill.
      viewModelData: null, // {}
      // All content from sitecore
      sitecoreContent: null,
      // Entire response payload from business rules engine
      businessRulesResponse: null,
      // Different excess amounts are available for different policies
      excessAmounts: null,
      // If payment is successful, keep the receipt no. for the user
      paymentReceiptNumber: null,

      // This is code copied across from the previous solution
      // It needs work to integrate the jeopardy step data
      // with the step no from the current motor form flow.
      // TODO: Make this a getter
      jeopardyStep: {
        stepNo: 1,
        stepTitle: `Let's chat to discuss your quote`,
        stepState: '',
        stepStatus: ApiStatus.IDLE,
        id: 'jeopardy-container',
      },
    };
  },
  mutations: {
    /**
     * Update the store with entire business rules engine response.
     *
     * @param {*} state
     * @param {*} payload
     */
    UPDATE_BUSINESS_RULES_RESPONSE(state, payload) {
      state.businessRulesResponse = payload;
    },
    /**
     * Update the viewModel data from the business rules engine.
     *
     * @param {*} state
     * @param {*} payload The business rules response viewModel payoad
     */
    UPDATE_VIEW_MODEL_DATA(state, payload) {
      let newModelData = remapViewModelToFormulateData(payload);

      state.viewModelData = {
        ...payload,
        ...newModelData,
        vehicleRegistration: payload.vehicleRegistration === 'TBA' ? '' : payload.vehicleRegistration,
        drivers:
          payload?.drivers?.map((driver) => ({
            ...driver,
            value: driver.name,
            label: driver.name,
          })) || [],
      };

      state.viewModelData.overnightParkingLocationCode = payload?.overnightParkingLocationCode?.toUpperCase();
      state.viewModelData.garagingLocation = `${payload?.overnightParkingLocationPostcode}, ${payload?.overnightParkingLocationSuburb}`;
      state.viewModelData.vehicleAccessories = payload?.vehicleAccessories?.map((accessory) => {
        return {
          vehicleAccessoryOption: accessory,
        };
      });
    },

    /**
     * Format steps data from Sitecore for easier use in Vue UI.
     *
     * @param {*} state
     * @param {any[]} payload The 'steps' payload from Sitecore
     */
    INITIALISE_STEPS(state, payload) {
      const stepsData = payload.map((step) => ({
        id: step.stepId.value,
        stepStatus: ApiStatus.IDLE,
        stepNo: step.stepNumber.value,
        stepTitle: step.stepTitle.value,
        component: step.component.value || 'GenericStep',
        content: state.sitecoreContent[step.stepId.value],
        stepState: step.stepNumber.value == 1 ? StepStates.ACTIVE : '',
        stepCompleted: false,
      }));

      state.initialSteps = [...stepsData];
      // Don't show the confirmation page until user successfully gets thru all steps
      state.steps = [...stepsData].filter((step) => step.id !== 'confirmation');
    },

    /**
     * Initialise all sitecore content.
     *
     * Convert Array of sitecore step data into an Object for easy lookup.
     *
     * @param {*} state
     * @param {*} payload
     */
    INITIALISE_SITECORE_CONTENT(state, payload = []) {
      const siteCoreContentObject = {};

      payload.forEach((step) => {
        if (step?.stepId?.value) {
          siteCoreContentObject[step?.stepId?.value] = step;
        }
      });

      state.sitecoreContent = siteCoreContentObject;
    },

    /**
     * Update the prelimary step.
     *
     * @param {*} state
     * @param {string} payload
     */
    UPDATE_PRELIMINARY_STEP(state, payload) {
      state.preliminaryStep = payload;
    },

    /**
     * Update step status based on API events.
     *
     * @param {*} state
     * @param {{ stepIndex: number, status: keyof ApiStatus }} payload
     */
    UPDATE_STEP_STATUS(state, { stepIndex, status }) {
      state.steps[stepIndex].stepStatus = status;
    },

    /**
     * Update jeopardy state (if active, show jeopardy UI)
     *
     * @param {*} state
     * @param {{ jeopardyActive: boolean; activeJeopardyCode: string; }} payload
     */
    UPDATE_JEOPARDY_ACTIVE(state, payload) {
      state.jeopardyActive = payload.jeopardyActive;
      state.activeJeopardyCode = '';

      if (payload.activeJeopardyCode) {
        state.activeJeopardyCode = payload.activeJeopardyCode;
      }

      state.jeopardyStep.stepState = StepStates.ACTIVE;
      state.steps = resetSteps(state);

      if (payload) {
        scrollToElement('#jeopardy-container', 100);
      }
    },

    /**
     * Set the active step.
     *
     * This can be achieved by clicking the "Edit" button.
     * Switches all other steps to either completed or disabled.
     *
     * @param {*} state
     * @param { {stepIndex: Number} } stepIndex
     */
    SET_ACTIVE_STEP(state, { stepIndex }) {
      state.steps = resetSteps(state, stepIndex, { disableNextSteps: true });

      state.stepNumber = stepIndex + 1;

      if (state.steps[stepIndex].stepState === StepStates.COMPLETED) {
        state.steps[stepIndex].stepState = StepStates.ACTIVE_COMPLETED;
      }
    },

    GO_PREV_STEP(state) {
      if (state.stepNumber > 0) {
        state.stepNumber = state.stepNumber - 1;
      }

      // Set current step as disabled
      state.steps[state.stepNumber].stepState = StepStates.DISABLED;
      // Set decremented step number and set to active
      state.steps[state.stepNumber - 1].stepState = StepStates.ACTIVE;
      // Scroll to step
      scrollToElement(`#${state.steps[state.stepNumber - 1].id}`);
    },

    GO_NEXT_STEP(state) {
      // Complete current step
      state.steps[state.stepNumber - 1].stepState = StepStates.COMPLETED;
      state.steps[state.stepNumber - 1].stepCompleted = true;
      // Increment step number
      state.stepNumber = state.stepNumber + 1;
      // Set new step number as active
      state.steps[state.stepNumber - 1].stepState = StepStates.ACTIVE;

      // if steps beyond current/next step are disabled, they need to be uneditable
      state.steps = state.steps.map((step, index) => {
        if (index > state.stepNumber - 1 && step.stepState !== '') {
          step.stepState = StepStates.DISABLED_UNEDITABLE;
        }

        return step;
      });

      // Scroll to step
      scrollToElement(`#${state.steps[state.stepNumber - 1].id}`, 100, -100);
    },

    GO_CONFIRMATION_PAGE(state) {
      // Filter out steps to only show the confirmation
      // Set confirmation step to active-complete
      state.steps = state.initialSteps
        .filter((step) => step.id === 'confirmation')
        .map((step) => ({
          ...step,
          stepNumber: 1,
          stepNo: 1,
          stepState: StepStates.ACTIVE_COMPLETED,
        }));
      state.formCompleted = true;
      scrollToElement(`body`, 300);
    },

    SET_SELECTED_POLICY(state, payload) {
      state.selectedPolicy = payload;
    },

    UPDATE_PRIMARY_DRIVER_FORM_STATE(state, payload) {
      state.primaryDriverFormState = payload;
    },

    UPDATE_ALL_INSURANCE_POLICIES(state, payload) {
      state.allInsurancePolicies = payload;
    },

    UPDATE_SELECTED_POLICY_NUMBER(state, payload) {
      state.selectedPolicyNumber = payload;
    },

    UPDATE_ACCESSORIES_REFDATA(state, payload) {
      const accessoriesMap = new Map();

      payload.forEach((item) => {
        accessoriesMap.set(item.value, item.label);
      });

      state.accessoriesMap = accessoriesMap;
    },

    SET_EXCESS_AMOUNTS_REFDATA(state, payload = []) {
      const excessAmounts = {};

      payload.forEach((item) => {
        if (item.value) excessAmounts[item.value] = item;
      });

      state.excessAmounts = excessAmounts;
    },

    SET_PAYMENT_RECEIPT_NUMBER(state, payload = '') {
      if (payload) {
        state.paymentReceiptNumber = payload;
      }
    },

    /**
     * Remove the payment step when payment method is direct debit.
     *
     * @param {*} state
     */
    REMOVE_PAYMENT_STEP(state) {
      state.steps = state.steps
        .filter((step) => (step.id === 'payment' ? false : true))
        .map((step) => {
          if (step.id === 'confirmation') step.stepNo -= 1;

          return step;
        });

      // TODO: Hide confirmation step
    },

    UPDATE_FOUND_CAR_MANUALLY(state, payload) {
      state.foundCarManually = payload;
    },
  },

  getters: {
    prefillData(state) {
      return { ...state.viewModelData, ...(state.viewModelData?.responseModel || {}) };
    },

    currentActiveStep(state) {
      return state.steps.find((step) => step.stepState === StepStates.ACTIVE);
    },

    currentInsurancePolicies(state) {
      return state.allInsurancePolicies;
    },

    foundVehicle(state) {
      return state.viewModelData?.glassesGuideVehicleDetails;
    },

    foundVehicleMakeModel(state, getters) {
      return `${getters?.foundVehicle?.make?.description || ''} ${getters?.foundVehicle?.model?.description || ''}`;
    },

    isLowValueVehicle(state) {
      return (
        ([coverageTypeCodes.MotorComprehensive, coverageTypeCodes.MotorThirdPartyFireAndTheft].includes(state.viewModelData?.coverageCode) &&
          state.businessRulesResponse?.viewModel?.isLowValueVehicle) ||
        false
      );
    },

    coverageCode(state) {
      return state.viewModelData?.coverageCode;
    },

    /**
     * Return only the content information relating to "excess".
     * The reason this is done like so is because the "excess" content
     * is not generic, but sits inside the "excess" step content.
     *
     * Time permitting, we break out the "excess" content into something
     * generic in Sitecore, so it can be rendered on any page.
     *
     * @param {*} state
     * @returns
     */
    quoteExcessInfo(state) {
      const excessInfoObject = {};

      if (state?.sitecoreContent?.excess.formInputs) {
        state.sitecoreContent.excess.formInputs
          .filter((item) => item?.formInputId?.value?.toLowerCase()?.includes('excess'))
          .forEach((item) => {
            excessInfoObject[item.formInputId.value] = item;
          });

        return excessInfoObject;
      }

      return null;
    },
  },

  actions: {
    handleApiError({ commit }, error) {
      if (error?.response?.status) {
        if (error.response.status >= 500) {
          commit('UPDATE_JEOPARDY_ACTIVE', { jeopardyActive: true, activeJeopardyCode: JP_GENERIC_CODE });
        } else if (error.response.status >= 400) {
          commit('UPDATE_JEOPARDY_ACTIVE', { jeopardyActive: true, activeJeopardyCode: JP_LOGGED_OUT_CODE });
        }
      }
    },

    /**
     * Formatted form payload to send to Business Rules engine.
     *
     * @param {*} formData
     */
    formatFormDataToApplyBusinessRules({ state }, { formData, metadata }) {
      return {
        stepId: metadata?.stepId,
        currentPolicy: state.selectedPolicy,
        quoteSessionId: metadata?.quoteSessionId?.toLowerCase(),
        requestQuote: metadata?.requestQuote,

        model: {
          ...formData,
          overnightParkingLocationSuburb: formData?.garagingLocation?.split(',')?.[1]?.trim(),
          overnightParkingLocationPostcode: formData?.garagingLocation?.split(',')?.[0]?.trim(),
          overnightParkingLocationState: formData?.garagingLocation?.split(',')?.[2]?.trim(),
          vehicleAccessories: formData?.vehicleAccessories?.map((item) => item?.vehicleAccessoryOption || item),
          vehicleHasModifications: formData?.aftermarketAccessories,
          glassesGuideVehicleDetails: formData?.glassesGuideVehicleDetails || null,
        },
      };
    },
  },
};

export default newMotorInsuranceModule;
