<template>
  <section>
    <CarFoundBox v-if="carHasBeenFound" :on-change-car="gotoFindYourCarStep" />

    <article class="form-step-container" v-show="stepVisibility !== 'notSeen'" :id="step.id" :data-scrollspy-observe="step.id">
      <div class="form-step">
        <step-title :show-step-no="showStepNo" :step-number="step.stepNo" :step-title="step.stepTitle" :step-id="step.id" />
        <div class="step-side-icon"></div>
        <div class="form-step-content">
          <div v-show="formCurtainIsVisible" class="form-step-curtain">
            <div class="form-loader" v-if="step.stepStatus === ApiStatus.LOADING"></div>
          </div>
          <slot></slot>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import StepTitle from '@/components/foundation/forms/StepTitle.vue';
import CarFoundBox from '@/components/feature/newmotorinsuranceform/steps/quote/find-your-car/CarFoundBox.vue';

import { StepVisibility } from '@/models/foundation/forms/enums';
import { StepStates } from '@/models/foundation/forms/enums/stepStates';
import ApiStatus from '@/models/foundation/forms/enums/apiStatus';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { NEW_MOTOR_INSURANCE_MODULE } from '@/components/feature/newmotorinsuranceform/services/mappings';

export default {
  name: 'MotorInsuranceQuoteStep',

  components: {
    StepTitle,
    CarFoundBox,
  },

  props: {
    stepVisibility: {
      type: String,
      default: StepVisibility.VISIBLE,
    },
    step: {
      type: Object,
      required: true,
    },
    disableStep: {
      type: Boolean,
      default: false,
    },
    showStepNo: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      StepStates,
      ApiStatus,
    };
  },

  methods: {
    ...mapMutations(NEW_MOTOR_INSURANCE_MODULE, ['UPDATE_JEOPARDY_ACTIVE', 'SET_ACTIVE_STEP']),

    gotoFindYourCarStep() {
      this.SET_ACTIVE_STEP({ stepIndex: 0 });
    },
  },

  computed: {
    ...mapState(NEW_MOTOR_INSURANCE_MODULE, ['jeopardyActive', 'jeopardyStep', 'steps']),
    ...mapGetters(NEW_MOTOR_INSURANCE_MODULE, ['foundVehicle', 'currentActiveStep']),

    formCurtainIsVisible() {
      if (this.disableStep) return true;

      return this.stepVisibility === 'seen' || this.step.stepStatus === ApiStatus.LOADING;
    },

    carHasBeenFound() {
      if (Number(this.currentActiveStep?.stepNo) > 1) {
        return !this.jeopardyActive && this?.foundVehicle?.nvic && this.step?.component === 'CarDetails';
      }

      return false;
    },
  },
};
</script>
<style lang="scss">
.form-step {
  position: relative;
}

.form-loader {
  width: 30px;
  height: 30px;
  margin-left: 12px;
  background-image: url('~@/assets/img/loader-34.gif');
  background-size: 100%;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 50;
}
</style>
