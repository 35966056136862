// API Suffix required for running jss locally
export const ApiPrefix = process.env.NODE_ENV === 'production' ? 'formsapi/v1' : 'https://racqformscm.localhost/formsapi/v1';
// export const ApiSuffix = "?sc_apikey=" + config.sitecoreApiKey;

// login url's
export const authenticationUrl = `${ApiPrefix}/login/authenticate`;
export const getLoginUrl = `${ApiPrefix}/login/GetLoginUrl?currentUrl={0}`;
export const isAuthenticatedUrl = `${ApiPrefix}/login/isauthenticated`;
export const logoutUrl = `${ApiPrefix}/login/logout`;

// api's for assistance product
export const getAssistanceProduct = `${ApiPrefix}/assistance/getassistanceproduct?policyId={0}`;
export const setAssistanceAutoRenewFlag = `${ApiPrefix}/assistance/setautoRenewflag?policyId={0}`;
export const isEligibilityForCancellation = `${ApiPrefix}/assistance/iseligibilityforcancellation?policyId={0}`;

// api's for insurance products
export const getInsuranceProduct = `${ApiPrefix}/insurance/getinsuranceproduct?policyNumber={0}`;
export const isInsuranceEligibleForCancellation = `${ApiPrefix}/insurance/IsEligibleForCancellation?policyNumber={0}`;
export const isCancellationDateEligible = `${ApiPrefix}/insurance/iscancellationdateeligible`;
export const cancelInsurance = `${ApiPrefix}/insurance/cancelinsurance`;

//api's for insurance quote session
export const getQuoteSessionId = `${ApiPrefix}/quotesession/getquotesession?policyNumber={0}&quoteSessionType={1}`;
export const getQuoteSessionIdByCoverage = `${ApiPrefix}/quotesession/getquotesessionbycoverage?policyNumber={0}&quoteSessionType={1}&coverageId={2}`;
export const getSelectableMotorInsurancePolicies = `${ApiPrefix}/addvehicleform/getselectablemotorinsurancepolicies`;
export const getInsurancePolicies = `${ApiPrefix}/insurance/getinsurancepolicies`;

// api's for add vehicle
export const withdrawQuoteSession = `${ApiPrefix}/quotesession/WithdrawQuoteSession`;
export const applyBusinessRulesUrl = `${ApiPrefix}/addvehicleform/ApplyBusinessRules`;
export const savePaymentReceiptUrl = `${ApiPrefix}/quotesession/SavePaymentReceipt`;
export const bindQuoteSessionUrl = `${ApiPrefix}/quotesession/BindQuoteSession`;
export const processPaymentResponseUrl = `${ApiPrefix}/quotesession/ProcessPayment`;

// api's for vehicle lookup
export const getVehicleByRego = `${ApiPrefix}/vehicle/getvehiclebyrego`;
export const getVehiclesByMake = `${ApiPrefix}/vehicle/getmakes`;
export const getVehicleModels = `${ApiPrefix}/vehicle/getmodels`;
export const getVehicleVariants = `${ApiPrefix}/vehicle/getvariants`;
export const getVehicleBodies = `${ApiPrefix}/vehicle/getbodies`;
export const getVehicleDetails = `${ApiPrefix}/vehicle/getvehicledetails`;
export const getVehiclesByNVIC = `${ApiPrefix}/vehicle/getvehiclebynvic`;
export const getVehicleYears = `${ApiPrefix}/vehicle/getvehicleyears`;

// api's for reference data
export const getPersonTitles = `${ApiPrefix}/refdata/getpersontitles`;
export const getGenders = `${ApiPrefix}/refdata/getgenders`;
export const getFinanceTypes = `${ApiPrefix}/refdata/getfinancetypes`;
export const getParkingLocation = `${ApiPrefix}/refdata/getparkinglocations`;
export const getCombinedAccessoriesList = `${ApiPrefix}/refdata/getcombinedaccessorieslist`;
export const getInsuranceRiskSuburbs = `${ApiPrefix}/refdata/getinsurancerisksuburbs`;
export const getVehicleBusinessUseTypesList = `${ApiPrefix}/refdata/getVehicleBusinessUseTypes`;
export const getYearsRatingOneHeld = `${ApiPrefix}/refdata/getYearsRatingOneHeld`;
export const getExcess = `${ApiPrefix}/refdata/GetExcess`;
export const getVehicleAgreedValueRange = `${ApiPrefix}/refdata/GetVehicleAgreedValueRange`;

// api's for profile data
export const getIdentityDetails = `${ApiPrefix}/identity/getidentitydetails`;

// api's for reference data
export const getNumOfStoreys = `${ApiPrefix}/refdata/getnumofstoreys?buildingType={0}`;
export const getLowestOccupiedFloor = `${ApiPrefix}/refdata/getlowestoccupiedfloor?buildingType={0}`;
export const getPrimaryConstructionMaterials = `${ApiPrefix}/refdata/getprimaryconstructionmaterials?quoteType={0}`;
export const getRoofTypeMaterials = `${ApiPrefix}/refdata/getrooftypematerials?quoteType={0}`;
export const getBuildingTypes = `${ApiPrefix}/refdata/getbuildingtypes`;

// common services
export const getFinancialInstitutionFromBsb = `${ApiPrefix}/payment/getfinancialinstitutionfrombsb?bsb={0}`;
export const getPaymentConfiguration = `${ApiPrefix}/paymentgateway/getpaymentconfiguration`;

// api's for renewal offer data
export const getRenewalPolicyDetails = `${ApiPrefix}/insurance/getRenewalPolicyDetails?policyId={0}`;
export const setRenewalOfferPreference = `${ApiPrefix}/insurance/setRenewalOffer`;
export const sendRenewalReceiptEmail = `${ApiPrefix}/insurance/sendRenewalReceiptEmail`;
export const reinstatePolicy = `${ApiPrefix}/insurance/reinstatePolicy?policyNumber={0}`;

// api's for add to cover form
export const addToCoverGetQuoteSessionId = `${ApiPrefix}/addtocover/GetQuoteSessionId?policyNumber={0}`;
export const addToCoverUpdateCoveragePricing = `${ApiPrefix}/addtocover/UpdateCoveragePricing?quoteSessionId={0}`;
export const addToCoverGetPolicyRenewal = `${ApiPrefix}/addtocover/GetPolicyRenewal?policyNumber={0}`;
export const addToCoverSubmitChanges = `${ApiPrefix}/addtocover/SubmitChanges`;

//api's for update cyclone form
export const updateFloodCycloneDetails = `${ApiPrefix}/homeinsurance/updatefloodcyclonedetails?quoteSessionId={0}`;
export const getIsCycloneCovered = `${ApiPrefix}/homeinsurance/getIsCycloneCovered`;

// callback form api
export const submitCallBackDetails = `${ApiPrefix}/callback/submitcallbackform?policyid={0}`;

export const isProduction = process.env.NODE_ENV === 'production';
export const localURL = 'https://forms.racqformscm.localhost:3000';

export const getLiveChatSessionData = `${ApiPrefix}/livechatapi/getsessiondata`;

export const getSelfServiceAccountOverviewUrl = `${ApiPrefix}/configuration/getselfserviceaccountoveriewpage`;
export const getSelfServiceChangeVehicleUrl = `${ApiPrefix}/configuration/getselfservicechangevehiclepage?isinsuranceproduct={0}`;

export const jssApiKey = '{8F768A35-1B88-47A4-B271-7A1F1CF03F78}';

export const getSelfServiceMakePaymentUrl = `${ApiPrefix}/configuration/getselfservicemakepaymentpage`;
