<template>
  <footer>
    <div class="container-fluid" id="footer">
      <div class="row">
        <div class="component container-fluid col-12 container-gray-background px-0">
          <div class="component-content">
            <div class="row">
              <div class="component container col-12 px-0">
                <div class="component-content">
                  <div class="row">
                    <div class="component link-list col-8 col-sm-9 col-lg-10 footer-links--major">
                      <div class="component-content">
                        <placeholder name="jss-top-links" :rendering="rendering" />
                      </div>
                    </div>

                    <div class="component image file-type-icon-media-link col-4 col-sm-3 col-lg-2 footer-logo">
                      <div class="component-content">
                        <sc-link :field="fields.racqWebsiteLink">
                          <sc-image :media="fields.racqLogo" />
                        </sc-link>
                      </div>
                    </div>

                    <div class="component container col-12 px-0">
                      <div class="component-content">
                        <div class="row">
                          <div class="component image file-type-icon-media-link social-icon">
                            <div class="component-content">
                              <sc-link :field="fields.facebookLink">
                                <sc-image :media="fields.facebookImage" />
                              </sc-link>
                            </div>
                          </div>

                          <div class="component image file-type-icon-media-link social-icon">
                            <div class="component-content">
                              <sc-link :field="fields.twitterLink">
                                <sc-image :media="fields.twitterImage" />
                              </sc-link>
                            </div>
                          </div>
                          <div class="component image file-type-icon-media-link social-icon">
                            <div class="component-content">
                              <sc-link :field="fields.youtubeLink">
                                <sc-image :media="fields.youtubeImage" />
                              </sc-link>
                            </div>
                          </div>

                          <div class="component image file-type-icon-media-link social-icon">
                            <div class="component-content">
                              <sc-link :field="fields.instagamLink">
                                <sc-image :media="fields.instagramImage" />
                              </sc-link>
                            </div>
                          </div>

                          <div class="component image file-type-icon-media-link social-icon">
                            <div class="component-content">
                              <sc-link :field="fields.linkedInLink">
                                <sc-image :media="fields.linkedInImage" />
                              </sc-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="component link-list col-12 footer-links--minor">
                      <div class="component-content">
                        <placeholder name="jss-bottom-links" :rendering="rendering" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import { Image, Link } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'Footer',
  components: {
    Placeholder,
    ScImage: Image,
    ScLink: Link,
    //LinkList
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
