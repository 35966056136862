import dayjs from 'dayjs';
import { STANDARD_DATE_FORMAT } from '@/components/foundation/shared/utils/constants';

/**
 * Mixin for all fields relating to drivers (add / edit / delete)
 */
export default {
  data() {
    return {};
  },

  methods: {
    checkBirthDateIsValid(value) {
      if (!value) return true;

      return dayjs(value).isBefore(new Date());
    },

    formattedDOB(dob) {
      if (!dob) return 'Date unavailable';

      const datePickerFormat = 'YYYY-MM-DD';

      return dayjs(dob, dob.includes('-') ? datePickerFormat : STANDARD_DATE_FORMAT).format(STANDARD_DATE_FORMAT);
    },
  },

  computed: {
    premiumAmount() {
      return this.prefillData?.updatedPremium;
    },

    currentTermPaymentDetails() {
      return this.selectedPolicy?.currentTermPaymentDetails;
    },

    isDirectDebit() {
      const { discriminator } = this?.currentTermPaymentDetails;
      return discriminator === 'NonResponsivePaymentDetails';
    },

    directDebitType() {
      const { isAnnual } = this?.currentTermPaymentDetails;

      const isMonthlyDirectDebit = this.isDirectDebit && !isAnnual;
      const isAnnualDirectDebit = this.isDirectDebit && isAnnual;

      if (isMonthlyDirectDebit) {
        return 'MONTHLY';
      }

      if (isAnnualDirectDebit) {
        return 'ANNUAL';
      }

      return 'INVOICE';
    },
  },
};
