import { reasonCodes } from '../../../feature/cancelroadsideform/steps/shared/reasonCodes';

const CascadeDropDownMixin = {
  methods: {
    ///get selected option for the dropdown
    getSelectedOption(currentOptions, selectedValue) {
      var optionsObject = currentOptions.filter(function (elem) {
        if (elem.id == selectedValue) return elem;
      })[0];
      return optionsObject;
    },
    getOptionsForSecondDropDown() {
      //get selected option for first dropdown
      const selectedOption = this.getSelectedOption(this.cancellationReasons, this.step1Values.reasonForCancellation);
      this.showButtons = !selectedOption.fields.triggerChangeVehicle.value;
      if (selectedOption.fields && selectedOption.fields.childItems) {
        this.secondDropDownOptions = {
          label: selectedOption.fields.listLabel,
          requiredErrorMessage: selectedOption.fields.requiredErrorMessage,
          data:
            this.assistanceProduct && (this.assistanceProduct.isAssociateMotoring || this.assistanceProduct.isCMOPolicy) //if cmo or associate motoring don't show manufacturer option
              ? selectedOption.fields.childItems.filter((item) => item.id != reasonCodes.manufacturerSitecoreId)
              : selectedOption.fields.childItems, //get's all the child items of the 1st dropdown item - they need to displayed into second dropdown, //get's all the child items of the 1st dropdown item - they need to displayed into second dropdown
        };
      } else {
        this.secondDropDownOptions = {};
      }
    },
    ///gets the options for third dropdown i.e another provider
    getOptionsForThirdDropDown() {
      const selectedOption = this.getSelectedOption(this.secondDropDownOptions.data, this.step1Values.subReasonForCancellation);
      this.showButtons = !selectedOption.fields.triggerChangeVehicle.value;
      if (selectedOption.fields && selectedOption.fields.childItems) {
        this.thirdDropDownOptions = {
          label: selectedOption.fields.listLabel,
          requiredErrorMessage: selectedOption.fields.requiredErrorMessage,
          data: selectedOption.fields.childItems,
        };
      } else {
        this.thirdDropDownOptions = {};
      }
    },
    getOptionsForFourthDropDown() {
      const selectedOption = this.getSelectedOption(this.thirdDropDownOptions.data, this.step1Values.anotherVehicle);
      this.showButtons = !selectedOption.fields.triggerChangeVehicle.value;
      if (selectedOption.fields && selectedOption.fields.childItems) {
        this.fourthDropDownOptions = {
          label: selectedOption.fields.listLabel,
          requiredErrorMessage: selectedOption.fields.requiredErrorMessage,
          data: selectedOption.fields.childItems,
        };
      } else {
        this.fourthDropDownOptions = [];
      }
    },
    showAdditionalComments() {
      if (
        this.step1Values.reasonForCancellation &&
        this.getSelectedOption(this.firstDropDownOptions, this.step1Values.reasonForCancellation).fields.additionalCommentsRequired.value //if additional comments required is ticked
      ) {
        //if Other is selected then go straight to show comments
        return true;
      }
      if (this.step1Values.subReasonForCancellation) {
        return this.getSelectedOption(this.secondDropDownOptions.data, this.step1Values.subReasonForCancellation).fields.additionalCommentsRequired.value;
      } else {
        return false;
      }
    },
  },
};

export default CascadeDropDownMixin;
