<template>
  <section id="add-primary-driver-form" class="py-4">
    <h3>Add new Primary Driver</h3>

    <TitleFirstLastName :form-values="formValues" id="add-primary-driver-title-first-last-name" />

    <FormulateInput
      label="Date of birth"
      placeholder="DD/MM/YYYY"
      validation="required|isValidDate"
      error-behaviour="submit"
      validation-name="Date of birth"
      :validation-rules="validationRules"
      :disabled-dates="disabledFutureDates"
      name="dateOfBirth"
      type="datepicker"
      format="dd/MM/yyyy"
      :tooltip="{
        content: 'tooltipContent',
        tooltipId: `dateOfBirth-id`,
      }"
      :validation-messages="{
        required: validationMessages.dobRequired,
      }"
    />

    <FormulateInput
      label="Your gender"
      name="gender"
      type="radio"
      validation="required"
      :options="[
        { value: 'MALE', label: 'Male' },
        { value: 'FEMALE', label: 'Female', id: 'gender_last-child' },
      ]"
      :validation-messages="{
        required: validationMessages.genderRequired,
      }"
    />

    <br />

    <FormulateInput
      label="Have you held your drivers licence for 10 years or more?"
      name="hasLicenceMoreThan10Years"
      type="radio"
      :options="[
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No', id: 'hasLicenceMoreThan10Years_last-child' },
      ]"
      :tooltip="{
        title: '<h5>Why are we asking this?</h5>',
        content: '<span>Hello I am a tooltip with content applied.</span>',
        tooltipId: 'hasLicenceMoreThan10Years-tooltip-id',
      }"
      validation="required"
      :validation-messages="{
        required: 'Please select if you have held your licence for 10 years or more',
      }"
    />

    <FormulateInput
      v-if="formValues['hasLicenceMoreThan10Years'] === 'false'"
      label="How many years have you held your licence?"
      name="yearsLicenced"
      type="selectbox"
      placeholder="Select item"
      :options="licenceHeldYearOptions"
    />

    <FormulateInput
      label="In the last 3 years, how many at fault claims have you had?"
      name="longTermClaims"
      type="button-group"
      :options="[
        { value: '0', label: '0' },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5+', label: '5+', id: 'longTermClaims_last-child' },
      ]"
      :tooltip="{
        content: '<h5>Why are we asking this?</h5> <span>Hello I am a tooltip with content applied.</span>',
        tooltipId: 'longTermClaims-tooltip-id',
      }"
      validation="required"
      :validation-messages="{
        required: 'Please select the number of at fault claims you have had in the last 3 years',
      }"
    />

    <div v-if="formValues['longTermClaims'] === '0'" class="custom-info custom-tag-icon">
      <img src="@/assets/img/icons/tag-icon.svg" alt="An icon of a tag" /> Congratulations, you qualify for a No Claim discount!

      <div class="info-box--basic">
        <p>The No Claim Discount you are offered is based on:</p>

        <ul class="list-disc">
          <li>The number of at fault claims the primary driver has had in the last three (3) years; and</li>
          <li>The number of years the primary driver has held a driver’s license (excluding the time on a learner’s license).</li>
        </ul>
      </div>
    </div>

    <RepeatableAtFaultClaims v-if="hasMoreThanOne('longTermClaims')" name="repeatableAtFaultClaims" id="repeatableAtFaultClaims" />

    <FormulateInput
      v-if="hasMoreThanOne('longTermClaims')"
      label="How many of these at fault claims were in the last 12 months?"
      name="shortTermClaims"
      type="button-group"
      :options="numAtFaultClaimsOptions"
      validation="required"
      :validation-messages="{
        required: 'Please select the number of at fault claims you have had in the last 12 months',
      }"
    />

    <FormulateInput
      label="Do you hold a rating 1 with your current insurance provider?"
      name="hasRatingOne"
      type="radio"
      :options="[
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No', id: 'hasRatingOne_last-child' },
      ]"
      :tooltip="{
        title: '<h5>Why are we asking this?</h5>',
        content: '<span>Hello I am a tooltip with content applied.</span>',
        tooltipId: 'hasRatingOne-tooltip-id',
      }"
    />

    <FormulateInput
      v-if="formValues['hasRatingOne'] === 'true'"
      label="How many years have you held your rating 1?"
      name="yearsRatingOneHeld"
      type="selectbox"
      placeholder="Select item"
      :searchable="false"
      :options="yearsHeldRatingOneOptions"
    />

    <FormulateInput
      label="In the last 3 years, how many of the following have you had?"
      name="licenceRestrictionCount"
      type="button-group"
      :options="[
        { value: '0', label: '0' },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5+', label: '5+', id: 'licenceRestrictionCount_last-child' },
      ]"
      :tooltip="{
        title: '<h5>Why are we asking this?</h5>',
        content: '<span>Hello I am a tooltip with content applied.</span>',
        tooltipId: 'licenceRestrictionCount-tooltip-id',
      }"
    />

    <RepeatableOccurrenceRadioGroup
      v-if="licenceRestrictionCount > 0"
      :count="licenceRestrictionCount"
      id="licenceRestrictionCount"
      :key="licenceRestrictionCount"
    />

    <!-- WIP -->
    <!-- NEED TO SOLVE CUSTOM HELP TEXT BECAUSE OF VUE FORMULATE HTML STRUCTURE ISN'T FLEXIBLE ENOUGH -->
    <div class="form-field-with-custom-help-text">
      <strong class="label">Have you, any policyholders or any other drivers had any of the following?</strong>

      <div class="flex-col custom-help-text">
        <ul class="list-disc">
          <li>In the last 5 years, been convicted of any criminal offence?</li>
          <li>In the last 3 years, had any insurance refused, cancelled or voided?</li>
        </ul>

        <FormulateInput
          name="hasCriminalOffence"
          type="radio"
          :options="[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No', id: 'hasCriminalOffence_last-child' },
          ]"
          :tooltip="{
            title: '<h5>Why are we asking this?</h5>',
            content: '<span>Hello I am a tooltip with content applied.</span>',
            tooltipId: 'hasCriminalOffence-tooltip-id',
          }"
        />
        <br />
      </div>
    </div>
    <!-- END WIP -->

    <FormulateInput
      type="checkbox"
      name="confirm-primary-driver-details"
      label="I confirm the primary driver details are correct"
      :validation-messages="{
        required: 'Please confirm that the primary driver details are correct',
      }"
      validation="required"
    />

    <div class="flex-center">
      <button class="btn btn-secondary" type="submit">Save Primary Driver</button>
    </div>
  </section>
</template>

<script>
import RACQFormsMixins from '@/components/foundation/forms/mixins/RACQFormsMixins';
import RepeatableOccurrenceRadioGroup from '@/components/feature/newmotorinsuranceform/shared/RepeatableOccurrenceRadioGroup.vue';
import RepeatableAtFaultClaims from '@/components/feature/newmotorinsuranceform/shared/RepeatableAtFaultClaims.vue';
import TitleFirstLastName from '@/components/feature/newmotorinsuranceform/shared/TitleFirstLastName.vue';
import { validationMessages } from '@/components/feature/newmotorinsuranceform/services/mappings';

import dayjs from 'dayjs';

export default {
  name: 'AddPrimaryDriver',

  components: {
    RepeatableOccurrenceRadioGroup,
    RepeatableAtFaultClaims,
    TitleFirstLastName,
  },

  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    onSave: {
      type: Function,
      required: true,
    },
    formValues: {
      type: Object,
      required: true,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
  },

  mixins: [RACQFormsMixins],

  data() {
    return {
      primaryDriverValues: {},
      validationMessages,
    };
  },

  methods: {
    checkDateIsValid(value) {
      if (!value) return true;

      return dayjs(value).isBefore(new Date());
    },

    hasMoreThanOne(fieldName) {
      const numAtFaultClaims = this.formValues[fieldName];
      return (numAtFaultClaims === '5+' ? 5 : numAtFaultClaims) > 0;
    },
  },

  computed: {
    validationRules() {
      return {
        isValidDate: ({ value }) => this.checkDateIsValid(value),
      };
    },

    disabledFutureDates() {
      return {
        to: new Date('January 01, 1900'),
        from: new Date(),
      };
    },

    licenceRestrictionCount() {
      if (this.formValues.licenceRestrictionCount) {
        return Number(this.formValues.licenceRestrictionCount?.match(/(\d+)/)[0] || 0);
      }

      return 0;
    },

    licenceHeldYearOptions() {
      const MIN_DRIVING_AGE = 17;
      const thisYear = dayjs(new Date()).year();
      const driverDOB = this?.formValues?.dateOfBirth;
      const driverBirthYear = dayjs(driverDOB).year();
      const driverAge = thisYear - driverBirthYear;
      const yearsDriving = driverAge - MIN_DRIVING_AGE;

      let options = [{ value: '0', label: 'Less than one year' }];

      if (driverAge > MIN_DRIVING_AGE) {
        for (let i = 1; i <= yearsDriving; i++) {
          options.push({ value: i, label: i + ' years' });

          if (i >= 10) break;
        }
      }

      return options;
    },

    yearsHeldRatingOneOptions() {
      return [
        { value: '<5', label: 'Less than five years' },
        { value: '5>9', label: 'Between 5 and 9 years' },
        { value: '10+', label: '10 years or more' },
      ];
    },

    numAtFaultClaimsOptions() {
      const numAtFaultClaims = this.formValues['longTermClaims'];
      const optionsLength = numAtFaultClaims === '5+' ? 5 : numAtFaultClaims;

      const options = Array.from({ length: optionsLength }, (_, i) => {
        const isLastItem = optionsLength - 1 == i;
        const optionValues = {
          label: String(i + 1),
          value: String(i + 1),
        };

        if (isLastItem) {
          optionValues.id = 'shortTermClaims_last-child';
        }

        return optionValues;
      });

      if (numAtFaultClaims === '5+') {
        options[options.length - 1].label += '+';
      }

      return [{ label: '0', value: '0' }, ...options];
    },
  },
};
</script>
<style lang="scss">
#add-primary-driver-form {
  .form-fields-flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    input[type='text'] {
      width: 100%;
    }

    .flex-field-with-label {
      .formulate-input-element--text,
      .formulate-input-element--vue-select {
        @include respond-to(desktop) {
          margin-left: 9.5rem;
          max-width: 8rem;
        }
      }
    }

    .formulate-input-element--text {
      margin-left: 1rem;
      width: 10rem;
    }
  }

  .simple-vue-select {
    .formulate-input-element--vue-select.v-select {
      margin-left: 4.25rem;
    }
  }

  .formulate-input-element--selectbox {
    max-width: 150px !important;
  }

  .forms-input-wrapper {
    min-width: min-content !important;
  }

  .custom-info {
    margin-left: 0;
    margin-top: 0;

    @media screen and (min-width: $breakpoint-lg) {
      margin-left: 247px;
      margin-top: -54px;
    }
  }

  .form-field-with-custom-help-text {
    display: flex;

    .formulate-input-errors {
      margin-left: 0;
      margin-top: -0.5rem;
      padding-left: 1.25rem;
    }

    .label {
      max-width: 180px;
      margin-right: 45px;
    }

    .custom-help-text {
      max-width: 445px;
    }

    .formulate-input-element.formulate-input-element--group.formulate-input-group {
      margin-left: 1.4rem;
    }
  }

  .formulate-input-element--checkbox label.formulate-input-element-decorator {
    display: none !important;
  }
}
</style>
