<template>
  <div class="forms-review-content" :class="hasBorder ? 'form-review-content-border' : ''">
    <div class="forms-review-image">
      <sc-image :media="fields.roadsideIcon"></sc-image>
    </div>
    <div class="review-title" v-if="assistanceProduct">
      <h5>{{ assistanceProduct.productCover }}</h5>
      <div v-if="assistanceProduct.vehicle">
        {{ assistanceProduct.vehicle.year }} {{ assistanceProduct.vehicle.make }} {{ assistanceProduct.vehicle.model }}
        {{ assistanceProduct.vehicle.colour }} ({{ assistanceProduct.vehicle.registrationNumber }})
      </div>
      <div v-if="assistanceProduct.coverageDescription && !isReviewStep">{{ assistanceProduct.coverageDescription }}</div>
      <div v-if="assistanceProduct.isForLiteProduct">Personal mobility device</div>
    </div>
  </div>
</template>

<script>
import { Image } from '@sitecore-jss/sitecore-jss-vue';
export default {
  name: 'ProductDetailsTile',
  components: {
    ScImage: Image,
  },
  props: {
    hasBorder: {
      type: Boolean,
      default: false,
    },
    isReviewStep: {
      type: Boolean,
      default: false,
    },
    assistanceProduct: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
