<template>
  <fieldset>
    <slot name="legend">
      <legend class="legend" v-text="legendTitle" />
    </slot>

    <ul class="options-list">
      <li v-for="option in options" :key="option.value">
        <div :class="['option-list-item radio-block', { 'radio-block--checked': isChecked(option) }]">
          <label :class="['radio-block-label', { 'is-checked': isChecked(option) }]" :for="option.id + '-checkbox'">
            <input v-model="checkedItems" :name="option.id" :id="option.id + '-checkbox'" :value="option.id" type="checkbox" :checked="isChecked(option)" />
            &nbsp;

            <slot name="label" :option="option">
              <span v-text="option.label" />
            </slot>
          </label>
        </div>
        <slot name="under-label" v-if="checkedItems.includes(option.id)" :option="option"></slot>
      </li>
    </ul>

    <slot name="footer"> </slot>
  </fieldset>
</template>

<script>
export default {
  name: 'CustomCheckboxInput',

  props: {
    value: {
      type: Array,
    },

    legendTitle: {
      type: String,
      default: 'Select your car',
    },

    options: {
      type: Array,
      default: () => [
        {
          label: 'TONALE Ti MHEV MY22 Turbo - 4 Door WAGON - 7 Speed Auto FWD - T4 1.469L',
          value: '0D3J23',
          id: 'car-id-1',
          selected: false,
        },
      ],
    },
  },

  computed: {
    isChecked() {
      return (item) => {
        return this.checkedItems.includes(item.id);
      };
    },
    checkedItems: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.options-list {
  padding: 0;
}

.legend {
  font-size: 1rem;
  color: $racq-navy;
  margin-bottom: 0;
  padding-top: 0.75rem;
  @include heading-regular-font-family;
}

.option-list-item {
  background: $racq-pale-white;
  border: 1px solid $racq-pale-white;
  border-radius: 3px;
  margin: 14px 0;
  padding: 1rem;
  cursor: pointer;

  &:hover {
    background: #b8cad6;
    border-color: $racq-pale-white;
  }

  &.radio-block--checked {
    background: $racq-navy;
    border-color: $racq-pale-white;
    color: #fff;
  }

  .radio-block-label {
    margin: 0;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.light-theme {
  .radio-block--checked {
    background: $racq-pale-white;
    color: #000;
    border-color: $racq-navy;
  }
}
</style>
