<template>
  <div>
    <racq-form>
      <step-tracker :steps="steps" :key="reloadSteps" />
      <div>
        <racq-form-step ref="step1" :stepNumber="fields.childItems[0].fields.stepNumber" :stepTitle="fields.childItems[0].fields.stepTitle">
          <step-1
            ref="step1-child"
            :formTitle="params.formTitle"
            @go-jeopardy="showJeopardy"
            @go-next="moveToStep('step2', true)"
            @visibility-requested="moveToStep('step1')"
            :fields="fields.childItems[0].fields"
            :step="steps[0]"
          />
        </racq-form-step>

        <racq-form-step ref="step2" :stepNumber="fields.childItems[1].fields.stepNumber" :stepTitle="fields.childItems[1].fields.stepTitle">
          <step-2 ref="step2-child" :fields="fields.childItems[1].fields" :step="steps[1]" />
        </racq-form-step>
        <racq-form-step ref="stepjeopardy" :stepTitle="fields.jeopardyFolder.childItems[0].fields.jeopardyTitle">
          <placeholder
            ref="jeopardy-child"
            :step="steps[2]"
            name="jeopardy"
            :rendering="rendering"
            :params="{ displayCode: displayCode, jpCode: jpcode, additionalComments: additionalComments, stepDetails: stepDetails, formName: formName }"
          />
        </racq-form-step>
      </div>
    </racq-form>
  </div>
</template>

<script>
import StepTracker from '../../foundation/forms/StepTracker.vue';
import Step1 from './steps/Step1CallBackForm.vue';
import Step2 from './steps/Step2CallBackForm.vue';
import RacqForm from '../../foundation/forms/RACQForm.vue';
import RacqFormStep from '../../foundation/forms/RACQFormStep.vue';
import RACQFormsMixins from '../../foundation/forms/mixins/RACQFormsMixins';
import { isExperienceEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'CallBackForm',
  components: {
    StepTracker,
    Step1,
    Step2,
    RacqForm,
    RacqFormStep,
    Placeholder,
  },
  mixins: [RACQFormsMixins],
  data: function () {
    return {
      reloadSteps: 0,
      jpcode: undefined,
      displayCode: undefined,
      additionalComments: undefined,
      stepDetails: undefined,
      cancellationReasons: undefined,
      formName: undefined,
      steps: [
        {
          stepNo: 1,
          stepTitle: '',
          stepState: 'active',
          stepControlName: 'step1',
          stepChildControlName: 'step1-child',
        },
        {
          stepNo: 2,
          stepTitle: '',
          stepState: '',
          stepControlName: 'step2',
          stepChildControlName: 'step2-child',
        },
        {
          stepNo: 3,
          stepTitle: 'Jeopardy',
          stepState: '',
          stepControlName: 'stepjeopardy',
          stepChildControlName: 'jeopardy-child',
          hideFromNavigation: true,
        },
      ],
      currentStep: {},
    };
  },
  async mounted() {
    this.currentStep = this.steps[0];
    this.$refs[this.currentStep.stepControlName].moveInto();
    for (var i = 0; i < this.steps.length - 1; i++) {
      //length - 1 because we don't want to include jeopardy step
      this.steps[i].stepTitle = this.fields.childItems[i].fields.stepTitle.value;
    }
    //global event to go next on show disclaimer step
    this.addCancelFormEvent();
    //global event to show jeopardy on any step
    this.$root.$on('show-jeopardy', (jpData) => {
      if (!isExperienceEditorActive())
        //do not show jeopardy if experience editor is running
        this.showJeopardy(jpData);
    });
  },
  methods: {},
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
    },
  },
};
</script>
